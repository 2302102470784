var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./schema.utils.js");require("./schema.spec.core.js");
'use strict';var CF,DF,vF;
$CLJS.xF=function(a,b,c){var d=$CLJS.uF.g(a),e=$CLJS.pF(a,b),f=$CLJS.p(d)?function(k){try{var l=d.g?d.g(k):d.call(null,k)}catch(m){if(m instanceof Object)l=vF;else throw m;}return $CLJS.F.h(vF,l)?$CLJS.fF($CLJS.aF($CLJS.zj.g(a),k,new $CLJS.th(function(){return new $CLJS.he(null,$CLJS.ph.g($CLJS.XE(d)),new $CLJS.he(null,$CLJS.WE(k),null,1,null),2,null)}),$CLJS.iF)):$CLJS.p(l)?e.g?e.g(k):e.call(null,k):c.g?c.g(k):c.call(null,k)}:e,g=$CLJS.wF.g(a);return $CLJS.p(g)?function(k){k=f.g?f.g(k):f.call(null,
k);var l=$CLJS.hF(k);return $CLJS.p(l)?$CLJS.fF(g.g?g.g(l):g.call(null,l)):k}:f};$CLJS.yF=function(a,b,c,d,e,f,g){this.ka=a;this.options=b;this.Jb=c;this.Fb=d;this.A=e;this.l=f;this.C=g;this.o=2230716170;this.H=139264};$CLJS.AF=function(a,b){return $CLJS.zF(a,b,null)};$CLJS.zF=function(a,b,c){return $CLJS.BF(a,b,c,null)};
$CLJS.BF=function(a,b,c,d){if(!$CLJS.p($CLJS.p(c)?c:null==$CLJS.uF.g($CLJS.id(b))))throw Error($CLJS.UE("when last option has a guard, err-f must be provided"));return new $CLJS.yF(a,b,c,d,null,null,null)};CF=new $CLJS.M(null,"err-f","err-f",651620941);DF=new $CLJS.M(null,"post","post",269697687);vF=new $CLJS.M("schema.spec.variant","exception","schema.spec.variant/exception",-253680523);$CLJS.wF=new $CLJS.M(null,"wrap-error","wrap-error",536732809);$CLJS.uF=new $CLJS.M(null,"guard","guard",-873147811);$CLJS.h=$CLJS.yF.prototype;$CLJS.h.la=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "pre":return this.ka;case "options":return this.options;case "err-f":return this.Jb;case "post":return this.Fb;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};$CLJS.h.Vc=$CLJS.pa(33);
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#schema.spec.variant.VariantSpec{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rF,this.ka],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Li,this.options],null),new $CLJS.Q(null,2,5,$CLJS.R,[CF,this.Jb],null),new $CLJS.Q(null,2,5,$CLJS.R,[DF,this.Fb],null)],null),this.l))};
$CLJS.h.na=function(){return new $CLJS.Of(this,4,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.rF,$CLJS.Li,CF,DF],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};$CLJS.h.ga=function(){return 4+$CLJS.E(this.l)};$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=-1524878868^$CLJS.Qc(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ka,b.ka)&&$CLJS.F.h(this.options,b.options)&&$CLJS.F.h(this.Jb,b.Jb)&&$CLJS.F.h(this.Fb,b.Fb)&&$CLJS.F.h(this.l,b.l)};$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,4,[$CLJS.rF,null,CF,null,DF,null,$CLJS.Li,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new $CLJS.yF(this.ka,this.options,this.Jb,this.Fb,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "pre":case "options":case "err-f":case "post":return!0;default:return $CLJS.Kd(this.l,b)}};
$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.rF,b):$CLJS.O.call(null,$CLJS.rF,b))?new $CLJS.yF(c,this.options,this.Jb,this.Fb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.Li,b):$CLJS.O.call(null,$CLJS.Li,b))?new $CLJS.yF(this.ka,c,this.Jb,this.Fb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(CF,b):$CLJS.O.call(null,CF,b))?new $CLJS.yF(this.ka,this.options,c,this.Fb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(DF,b):$CLJS.O.call(null,DF,b))?new $CLJS.yF(this.ka,
this.options,this.Jb,c,this.A,this.l,null):new $CLJS.yF(this.ka,this.options,this.Jb,this.Fb,this.A,$CLJS.Kg.j(this.l,b,c),null)};$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Ld($CLJS.rF,this.ka),new $CLJS.Ld($CLJS.Li,this.options),new $CLJS.Ld(CF,this.Jb),new $CLJS.Ld(DF,this.Fb)],null),this.l))};$CLJS.h.M=function(a,b){return new $CLJS.yF(this.ka,this.options,this.Jb,this.Fb,b,this.l,this.C)};
$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};