var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./schema.core.js");
'use strict';var zH,AH;zH=function(a){if(null!=a&&null!=a.Uc)a=a.Uc(a);else{var b=zH[$CLJS.sa(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=zH._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Xa("HasPrecondition.precondition",a);}return a};AH=function(a,b,c,d){this.sc=a;this.A=b;this.l=c;this.C=d;this.o=2230716170;this.H=139264};$CLJS.BH=function(a){return new AH(a,null,null,null)};$CLJS.sF.prototype.Uc=$CLJS.ra(37,function(){return $CLJS.Ve(this.ka)});
$CLJS.yF.prototype.Uc=$CLJS.ra(36,function(){var a=this;return $CLJS.hk.h($CLJS.Ve(a.ka),$CLJS.Ue.h($CLJS.ik,function(){return function d(c){return new $CLJS.te(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),k=$CLJS.we(g);a:for(var l=0;;)if(l<g){var m=$CLJS.ld(f,l),r=$CLJS.Ke(m);m=$CLJS.K.h(r,$CLJS.uF);r=$CLJS.K.h(r,$CLJS.zj);m=$CLJS.p(m)?$CLJS.hk.h(m,zH($CLJS.UF(r))):zH($CLJS.UF(r));k.add(m);l+=1}else{f=!0;break a}return f?$CLJS.ze($CLJS.Be(k),d($CLJS.lc(e))):
$CLJS.ze($CLJS.Be(k),null)}k=$CLJS.B(e);f=$CLJS.Ke(k);k=$CLJS.K.h(f,$CLJS.uF);f=$CLJS.K.h(f,$CLJS.zj);return $CLJS.ne($CLJS.p(k)?$CLJS.hk.h(k,zH($CLJS.UF(f))):zH($CLJS.UF(f)),d($CLJS.Lc(e)))}return null}},null)}(a.options)}()))});$CLJS.HF.prototype.Uc=$CLJS.ra(35,function(){return $CLJS.Ve(this.ka)});$CLJS.h=AH.prototype;$CLJS.h.la=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "schemas":return this.sc;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#schema.core.CondPre{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.HG,this.sc],null)],null),this.l))};$CLJS.h.na=function(){return new $CLJS.Of(this,1,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.HG],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};$CLJS.h.ga=function(){return 1+$CLJS.E(this.l)};
$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=-56613580^$CLJS.Qc(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.sc,b.sc)&&$CLJS.F.h(this.l,b.l)};$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.HG,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new AH(this.sc,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "schemas":return!0;default:return $CLJS.Kd(this.l,b)}};$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.HG,b):$CLJS.O.call(null,$CLJS.HG,b))?new AH(c,this.A,this.l,null):new AH(this.sc,this.A,$CLJS.Kg.j(this.l,b,c),null)};$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Ld($CLJS.HG,this.sc)],null),this.l))};$CLJS.h.M=function(a,b){return new AH(this.sc,b,this.l,this.C)};
$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};
$CLJS.h.rb=function(){var a=this;return $CLJS.zF($CLJS.SF,function(){return function d(c){return new $CLJS.te(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),k=$CLJS.we(g);a:for(var l=0;;)if(l<g){var m=$CLJS.ld(f,l);m=new $CLJS.n(null,2,[$CLJS.uF,zH($CLJS.UF(m)),$CLJS.zj,m],null);k.add(m);l+=1}else{f=!0;break a}return f?$CLJS.ze($CLJS.Be(k),d($CLJS.lc(e))):$CLJS.ze($CLJS.Be(k),null)}k=$CLJS.B(e);return $CLJS.ne(new $CLJS.n(null,2,[$CLJS.uF,zH($CLJS.UF(k)),
$CLJS.zj,k],null),d($CLJS.Lc(e)))}return null}},null)}(a.sc)}(),function(b){return new $CLJS.he(null,$CLJS.$G,new $CLJS.he(null,b,null,1,null),2,null)})};$CLJS.h.za=function(){return $CLJS.ne($CLJS.OG,$CLJS.ih.h($CLJS.VF,this.sc))};$CLJS.h.pa=$CLJS.u;$CLJS.h.W=function(a,b){return $CLJS.ac(b,this.za(null))};$CLJS.CH=$CLJS.fG($CLJS.uH,$CLJS.Ve(function(a){return $CLJS.wa(null==a?"":String(a))}),"Non-blank string");$CLJS.DH=$CLJS.bG(function(a){return $CLJS.tC(a,$CLJS.UD)},"Valid field type");$CLJS.EH=$CLJS.bG(function(a){return $CLJS.tC(a,$CLJS.QD)||$CLJS.tC(a,$CLJS.NC)},"Valid semantic type");$CLJS.FH=$CLJS.fG($CLJS.xH,$CLJS.ee,"positive integer");$CLJS.GH=$CLJS.fG($CLJS.xH,$CLJS.Ve($CLJS.fe),"integer \x3e\x3d 0");$CLJS.HH=$CLJS.Je([$CLJS.tH,$CLJS.tH]);$CLJS.IH=$CLJS.BH($CLJS.H([$CLJS.yH,$CLJS.uH]));