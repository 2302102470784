var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.types.coercion_hierarchies.js");require("./metabase.util.js");
'use strict';var oC,pC,rC,sC,uC,yC,zC,AC,DC,vC,GC,HC,IC,JC,KC,LC,MC,OC,PC,QC,RC,CC,SC,TC,UC,VC,WC,XC,YC,ZC,$C,aD,bD,cD,dD,eD,fD,gD,iD,jD,kD,lD,mD,nD,oD,pD,qD,rD,sD,tD,uD,vD,wD,xD,yD,zD,AD,BD,CD,ED,FD,GD,HD,ID,JD,KD,LD,MD,ND,OD,PD,RD,SD,TD,VD,XD,YD,ZD,$D,bE,cE,dE,eE,fE,gE,hE,iE,jE,kE,lE,mE,nE,oE,pE,qE,rE,sE,tE,uE,vE,wE,xE,yE,zE,AE,BE,CE,DE,EE,FE,GE,HE,IE,JE,KE,LE,ME,NE,OE,QE;oC=function(){};pC=function(a){return function(b,c){b=a.h?a.h(b,c):a.call(null,b,c);return $CLJS.Tc(b)?$CLJS.Sc(b):b}};
$CLJS.qC=function(a){if(null!=a){var b=(null!=a?a.H&4096||$CLJS.u===a.zf||(a.H?0:$CLJS.Va(oC,a)):$CLJS.Va(oC,a))?$CLJS.pe(a):null;return $CLJS.p(b)?[b,"/",$CLJS.Sg(a)].join(""):$CLJS.Sg(a)}return null};rC=function(a,b){return $CLJS.Le($CLJS.K.h($CLJS.Fh.g(a),b))};
sC=function(a){var b=pC(a);return function(){function c(g,k){return $CLJS.$a(b,g,k)}function d(g){return a.g?a.g(g):a.call(null,g)}function e(){return a.v?a.v():a.call(null)}var f=null;f=function(g,k){switch(arguments.length){case 0:return e.call(this);case 1:return d.call(this,g);case 2:return c.call(this,g,k)}throw Error("Invalid arity: "+arguments.length);};f.v=e;f.g=d;f.h=c;return f}()};$CLJS.tC=function(a,b){return $CLJS.Hh($CLJS.t($CLJS.Gh()),a,b)};
uC=function(a){return $CLJS.p($CLJS.ik.j($CLJS.wd,$CLJS.ud,$CLJS.Oa)(a))?a:new $CLJS.Q(null,1,5,$CLJS.R,[a],null)};$CLJS.wC=function(a,b){var c=$CLJS.Ue.j(vC,a,b);return $CLJS.ne(c,$CLJS.jk.h(function(d){return c===d},b))};
yC=function(a){return $CLJS.$a(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return xC.j(b,d,c)},$CLJS.t(function(){var b=new $CLJS.Fc(function(){return $CLJS.Gh},$CLJS.It,$CLJS.Bg([$CLJS.Lj,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[!0,$CLJS.Zh,$CLJS.Ht,"cljs/core.cljs",28,1,11153,11153,$CLJS.X($CLJS.xf),null,$CLJS.p($CLJS.Gh)?$CLJS.Gh.D:null]));return b.v?b.v():b.call(null)}()),a)};
zC=function(a,b,c){b=$CLJS.Qg(uC(b));$CLJS.zh.I($CLJS.Jt,$CLJS.Kg,a,b);$CLJS.zh.I($CLJS.Kt,$CLJS.Kg,a,c)};
AC=function(){$CLJS.p($CLJS.t($CLJS.Mt))||$CLJS.p($CLJS.t($CLJS.Mt))||$CLJS.$e($CLJS.Mt,yC(function(){return function c(b){return new $CLJS.te(null,function(){for(var d=b;;){var e=$CLJS.A(d);if(e){var f=e,g=$CLJS.B(f),k=$CLJS.I(g,0,null),l=$CLJS.I(g,1,null);if(e=$CLJS.A(function(m,r,v,w,y,z){return function V(N){return new $CLJS.te(null,function(W,da,qa){return function(){for(;;){var ua=$CLJS.A(N);if(ua){if($CLJS.Cd(ua)){var Ka=$CLJS.kc(ua),mb=$CLJS.E(Ka),zb=$CLJS.we(mb);a:for(var Fb=0;;)if(Fb<mb){var Db=
$CLJS.ld(Ka,Fb);zb.add(new $CLJS.Q(null,2,5,$CLJS.R,[Db,qa],null));Fb+=1}else{Ka=!0;break a}return Ka?$CLJS.ze($CLJS.Be(zb),V($CLJS.lc(ua))):$CLJS.ze($CLJS.Be(zb),null)}zb=$CLJS.B(ua);return $CLJS.ne(new $CLJS.Q(null,2,5,$CLJS.R,[zb,qa],null),V($CLJS.Lc(ua)))}return null}}}(m,r,v,w,y,z),null)}}(d,g,k,l,f,e)(l)))return $CLJS.ek.h(e,c($CLJS.Lc(d)));d=$CLJS.Lc(d)}else return null}},null)}($CLJS.t($CLJS.Jt))}()));return $CLJS.t($CLJS.Mt)};
DC=function(a){var b=AC();$CLJS.p($CLJS.t($CLJS.Nt))||$CLJS.p($CLJS.t($CLJS.Nt))||$CLJS.$e($CLJS.Nt,yC($CLJS.A($CLJS.t($CLJS.Kt))));var c=$CLJS.t($CLJS.Nt);return $CLJS.Le($CLJS.$a($CLJS.Xe($CLJS.Yx,$CLJS.BC),$CLJS.Lg($CLJS.t($CLJS.Lt),new $CLJS.Q(null,1,5,$CLJS.R,[a],null)),function(){return function f(e){return new $CLJS.te(null,function(){for(var g=e;;){var k=$CLJS.A(g);if(k){var l=k,m=$CLJS.B(l);if($CLJS.tC(m,CC)){var r=$CLJS.Ih(c,m);if(k=$CLJS.A(function(v,w,y,z,D,N,V){return function qa(da){return new $CLJS.te(null,
function(ua,Ka,mb){return function(){for(var zb=da;;)if(zb=$CLJS.A(zb)){if($CLJS.Cd(zb)){var Fb=$CLJS.kc(zb),Db=$CLJS.E(Fb),Lb=$CLJS.we(Db);a:for(var nb=0;;)if(nb<Db){var vb=$CLJS.ld(Fb,nb);$CLJS.tC(vb,CC)||(vb=$CLJS.Je([vb,$CLJS.Og([mb])]),Lb.add(vb));nb+=1}else{Fb=!0;break a}return Fb?$CLJS.ze($CLJS.Be(Lb),qa($CLJS.lc(zb))):$CLJS.ze($CLJS.Be(Lb),null)}Lb=$CLJS.B(zb);if($CLJS.tC(Lb,CC))zb=$CLJS.Lc(zb);else return $CLJS.ne($CLJS.Je([Lb,$CLJS.Og([mb])]),qa($CLJS.Lc(zb)))}else return null}}(v,w,y,z,
D,N,V),null)}}(g,r,m,l,k,b,c)(r)))return $CLJS.ek.h(k,f($CLJS.Lc(g)))}g=$CLJS.Lc(g)}else return null}},null)}(rC(b,a))}()))};vC=function vC(a){switch(arguments.length){case 2:return vC.h(arguments[0],arguments[1]);case 3:return vC.j(arguments[0],arguments[1],arguments[2]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return vC.m(arguments[0],arguments[1],arguments[2],new $CLJS.Hc(c.slice(3),0,null))}};vC.h=function(a,b){return b};
vC.j=function(a,b,c){return(a.g?a.g(b):a.call(null,b))>(a.g?a.g(c):a.call(null,c))?b:c};vC.m=function(a,b,c,d){return $CLJS.$a(function(e,f){return vC.j(a,e,f)},vC.j(a,b,c),d)};vC.G=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);d=$CLJS.C(d);return this.m(b,a,c,d)};vC.F=3;
var EC=function EC(a){switch(arguments.length){case 1:return EC.g(arguments[0]);case 2:return EC.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.q.g(arguments.length)].join(""));}};EC.g=function(a){return EC.h($CLJS.t($CLJS.Gh()),a)};EC.h=function(a,b){return $CLJS.Le($CLJS.K.h($CLJS.Eh.g(a),b))};EC.F=2;
$CLJS.BC=function BC(a){switch(arguments.length){case 0:return BC.v();case 1:return BC.g(arguments[0]);case 2:return BC.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return BC.m(arguments[0],arguments[1],new $CLJS.Hc(c.slice(2),0,null))}};$CLJS.BC.v=function(){return $CLJS.Ng};$CLJS.BC.g=function(a){return a};$CLJS.BC.h=function(a,b){return $CLJS.E(a)<$CLJS.E(b)?$CLJS.$a($CLJS.ke,b,a):$CLJS.$a($CLJS.ke,a,b)};
$CLJS.BC.m=function(a,b,c){a=$CLJS.wC($CLJS.E,$CLJS.ke.m(c,b,$CLJS.H([a])));return $CLJS.$a($CLJS.Yf,$CLJS.B(a),$CLJS.Lc(a))};$CLJS.BC.G=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.m(b,a,c)};$CLJS.BC.F=2;var xC=function xC(a){switch(arguments.length){case 2:return xC.h(arguments[0],arguments[1]);case 3:return xC.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.q.g(arguments.length)].join(""));}};
xC.h=function(a,b){a=$CLJS.H([a,b]);$CLJS.Ue.I($CLJS.zh,$CLJS.Gh(),xC,a);return null};
xC.j=function(a,b,c){function d(k,l,m,r,v){return $CLJS.$a(function(w,y){return $CLJS.Kg.j(w,y,$CLJS.$a($CLJS.ke,$CLJS.K.j(v,y,$CLJS.Ng),$CLJS.ne(r,v.g?v.g(r):v.call(null,r))))},k,$CLJS.ne(l,m.g?m.g(l):m.call(null,l)))}var e=$CLJS.Dh.g(a),f=$CLJS.Eh.g(a),g=$CLJS.Fh.g(a);if($CLJS.Kd(e.g?e.g(b):e.call(null,b),c))b=null;else{if($CLJS.Kd(g.g?g.g(b):g.call(null,b),c))throw Error([$CLJS.q.g(b),"already has",$CLJS.q.g(c),"as ancestor"].join(""));if($CLJS.Kd(g.g?g.g(c):g.call(null,c),b))throw Error(["Cyclic derivation:",
$CLJS.q.g(c),"has",$CLJS.q.g(b),"as ancestor"].join(""));b=new $CLJS.n(null,3,[$CLJS.Dh,$CLJS.Kg.j($CLJS.Dh.g(a),b,$CLJS.ke.h($CLJS.K.j(e,b,$CLJS.Ng),c)),$CLJS.Fh,d($CLJS.Fh.g(a),b,f,c,g),$CLJS.Eh,d($CLJS.Eh.g(a),c,g,b,f)],null)}return $CLJS.p(b)?b:a};xC.F=3;$CLJS.FC=function FC(a){switch(arguments.length){case 0:return FC.v();case 1:return FC.g(arguments[0]);default:throw Error(["Invalid arity: ",$CLJS.q.g(arguments.length)].join(""));}};
$CLJS.FC.v=function(){return function(a){var b=$CLJS.bf($CLJS.Ng);return function(){function c(g,k){if($CLJS.Kd($CLJS.t(b),k))return g;b.Ye(null,$CLJS.ke.h(b.qc(null),k));return a.h?a.h(g,k):a.call(null,g,k)}function d(g){return a.g?a.g(g):a.call(null,g)}function e(){return a.v?a.v():a.call(null)}var f=null;f=function(g,k){switch(arguments.length){case 0:return e.call(this);case 1:return d.call(this,g);case 2:return c.call(this,g,k)}throw Error("Invalid arity: "+arguments.length);};f.v=e;f.g=d;f.h=
c;return f}()}};$CLJS.FC.g=function(a){return function e(c,d){return new $CLJS.te(null,function(){var f;a:{var g=c;for(f=d;;){var k=g;g=$CLJS.I(k,0,null);if(k=$CLJS.A(k))if($CLJS.Kd(f,g))g=$CLJS.Lc(k);else{f=$CLJS.ne(g,e($CLJS.Lc(k),$CLJS.ke.h(f,g)));break a}else{f=null;break a}}}return f},null)}(a,$CLJS.Ng)};$CLJS.FC.F=1;GC=new $CLJS.M("type","Location","type/Location",-1929284186);HC=new $CLJS.M("type","Score","type/Score",188189565);
IC=new $CLJS.M("type","CancelationTime","type/CancelationTime",1076177064);JC=new $CLJS.M("type","FK","type/FK",360937035);KC=new $CLJS.M("type","Company","type/Company",-1114287726);LC=new $CLJS.M("type","IPAddress","type/IPAddress",-808425343);MC=new $CLJS.M("Coercion","Number-\x3eTemporal","Coercion/Number-\x3eTemporal",-1061171580);$CLJS.NC=new $CLJS.M("Relation","*","Relation/*",-706261522);OC=new $CLJS.M("type","CreationTimestamp","type/CreationTimestamp",-687400081);
PC=new $CLJS.M("type","PK","type/PK",-1723274286);QC=new $CLJS.M("type","DateTimeWithLocalTZ","type/DateTimeWithLocalTZ",339704031);RC=new $CLJS.M("type","PostgresEnum","type/PostgresEnum",-900722397);CC=new $CLJS.M("Coercion","*","Coercion/*",1713686116);SC=new $CLJS.M("Coercion","ISO8601-\x3eDateTime","Coercion/ISO8601-\x3eDateTime",-1255976090);TC=new $CLJS.M("type","Source","type/Source",1060815848);UC=new $CLJS.M("entity","SubscriptionTable","entity/SubscriptionTable",1660366729);
VC=new $CLJS.M("type","JoinTemporal","type/JoinTemporal",-1465575331);WC=new $CLJS.M("type","DeletionTimestamp","type/DeletionTimestamp",-935598691);XC=new $CLJS.M("type","JoinTimestamp","type/JoinTimestamp",1554527110);YC=new $CLJS.M("type","JSON","type/JSON",-14729800);ZC=new $CLJS.M("type","Currency","type/Currency",713609092);$C=new $CLJS.M("Coercion","String-\x3eTemporal","Coercion/String-\x3eTemporal",946586714);aD=new $CLJS.M("type","ZipCode","type/ZipCode",845484542);
bD=new $CLJS.M("type","URL","type/URL",-1433976351);cD=new $CLJS.M("type","CreationTime","type/CreationTime",-1473681229);dD=new $CLJS.M("Coercion","UNIXSeconds-\x3eDateTime","Coercion/UNIXSeconds-\x3eDateTime",-1635093627);eD=new $CLJS.M("type","Comment","type/Comment",-1406574403);fD=new $CLJS.M("Coercion","ISO8601-\x3eTemporal","Coercion/ISO8601-\x3eTemporal",410003391);gD=new $CLJS.M("type","GrossMargin","type/GrossMargin",1961535799);$CLJS.hD=new $CLJS.M("type","Date","type/Date",-690428629);
iD=new $CLJS.M("type","SerializedJSON","type/SerializedJSON",300065547);jD=new $CLJS.M("type","Cost","type/Cost",363698341);kD=new $CLJS.M("type","Quantity","type/Quantity",-1936151227);lD=new $CLJS.M("Coercion","UNIXMilliSeconds-\x3eDateTime","Coercion/UNIXMilliSeconds-\x3eDateTime",-1296453709);mD=new $CLJS.M("type","DateTimeWithTZ","type/DateTimeWithTZ",-1919106635);nD=new $CLJS.M("type","Share","type/Share",-1285033895);oD=new $CLJS.M("type","Address","type/Address",-413689880);
pD=new $CLJS.M("type","JoinDate","type/JoinDate",-793235819);qD=new $CLJS.M("type","Structured","type/Structured",-1651495863);rD=new $CLJS.M("type","Latitude","type/Latitude",-1080544141);sD=new $CLJS.M("type","Decimal","type/Decimal",-1657568790);tD=new $CLJS.M("type","Dictionary","type/Dictionary",1352048818);uD=new $CLJS.M("type","DeletionTime","type/DeletionTime",-1426373145);vD=new $CLJS.M("type","TimeWithZoneOffset","type/TimeWithZoneOffset",959948573);
wD=new $CLJS.M("type","Product","type/Product",1803490713);xD=new $CLJS.M("type","DateTimeWithZoneID","type/DateTimeWithZoneID",-1588069560);yD=new $CLJS.M("entity","GoogleAnalyticsTable","entity/GoogleAnalyticsTable",910090965);zD=new $CLJS.M("type","UpdatedTimestamp","type/UpdatedTimestamp",-754878742);AD=new $CLJS.M("type","CreationDate","type/CreationDate",-1102411433);BD=new $CLJS.M("type","Income","type/Income",-342566883);
CD=new $CLJS.M("Coercion","Bytes-\x3eTemporal","Coercion/Bytes-\x3eTemporal",-2023274781);$CLJS.DD=new $CLJS.M(null,"base_type","base_type",1908272670);ED=new $CLJS.M("type","Discount","type/Discount",109235331);FD=new $CLJS.M("type","User","type/User",832931932);GD=new $CLJS.M("type","UpdatedTime","type/UpdatedTime",-1278858780);HD=new $CLJS.M("type","MongoBSONID","type/MongoBSONID",663302764);ID=new $CLJS.M("type","Instant","type/Instant",1168385286);JD=new $CLJS.M("type","Email","type/Email",-1486863280);
KD=new $CLJS.M("type","CancelationDate","type/CancelationDate",561287015);LD=new $CLJS.M("type","Name","type/Name",-134787639);MD=new $CLJS.M("entity","GenericTable","entity/GenericTable",1152424804);ND=new $CLJS.M("type","TextLike","type/TextLike",-1295784190);OD=new $CLJS.M("Coercion","UNIXMicroSeconds-\x3eDateTime","Coercion/UNIXMicroSeconds-\x3eDateTime",1299502399);PD=new $CLJS.M("type","City","type/City",420361040);$CLJS.QD=new $CLJS.M("Semantic","*","Semantic/*",-546724996);
RD=new $CLJS.M("type","CancelationTemporal","type/CancelationTemporal",-532782161);SD=new $CLJS.M("type","Integer","type/Integer",-638928316);TD=new $CLJS.M("type","Coordinate","type/Coordinate",1950913043);$CLJS.UD=new $CLJS.M("type","*","type/*",-1283496752);VD=new $CLJS.M("type","DeletionDate","type/DeletionDate",-2052285784);$CLJS.WD=new $CLJS.M("type","DateTime","type/DateTime",352113310);XD=new $CLJS.M("type","Birthdate","type/Birthdate",619594666);
YD=new $CLJS.M("type","CreationTemporal","type/CreationTemporal",-1324176405);ZD=new $CLJS.M("Coercion","ISO8601-\x3eDate","Coercion/ISO8601-\x3eDate",-103814729);$D=new $CLJS.M("Coercion","YYYYMMDDHHMMSSBytes-\x3eTemporal","Coercion/YYYYMMDDHHMMSSBytes-\x3eTemporal",-624663946);$CLJS.aE=new $CLJS.M(null,"effective_type","effective_type",1699478099);bE=new $CLJS.M("type","Duration","type/Duration",1970868302);cE=new $CLJS.M("type","TimeWithLocalTZ","type/TimeWithLocalTZ",-259094111);
dE=new $CLJS.M("type","AvatarURL","type/AvatarURL",-425042887);eE=new $CLJS.M("entity","UserTable","entity/UserTable",-1504290772);fE=new $CLJS.M("Coercion","UNIXTime-\x3eTemporal","Coercion/UNIXTime-\x3eTemporal",-968093468);gE=new $CLJS.M("type","UUID","type/UUID",1767712212);hE=new $CLJS.M("Coercion","ISO8601-\x3eTime","Coercion/ISO8601-\x3eTime",-1197754849);iE=new $CLJS.M("type","DeletionTemporal","type/DeletionTemporal",2136289994);jE=new $CLJS.M("type","Boolean","type/Boolean",26348630);
kE=new $CLJS.M("type","TimeWithTZ","type/TimeWithTZ",-442869120);lE=new $CLJS.M("type","DruidHyperUnique","type/DruidHyperUnique",-585863040);mE=new $CLJS.M("type","Author","type/Author",-836053084);nE=new $CLJS.M("type","Number","type/Number",-2071693690);oE=new $CLJS.M("type","DateTimeWithZoneOffset","type/DateTimeWithZoneOffset",1768393068);pE=new $CLJS.M("type","Category","type/Category",-264699550);qE=new $CLJS.M("type","UpdatedDate","type/UpdatedDate",-1756161562);
rE=new $CLJS.M("type","UpdatedTemporal","type/UpdatedTemporal",-596775941);sE=new $CLJS.M("type","Temporal","type/Temporal",-237169010);tE=new $CLJS.M("type","Description","type/Description",-680883950);uE=new $CLJS.M("type","Enum","type/Enum",-1132893505);vE=new $CLJS.M("type","Owner","type/Owner",1745970850);wE=new $CLJS.M("type","Title","type/Title",1977060721);xE=new $CLJS.M("type","Text","type/Text",603746827);yE=new $CLJS.M("type","JoinTime","type/JoinTime",1290040594);
zE=new $CLJS.M("entity","EventTable","entity/EventTable",-2132834802);AE=new $CLJS.M("type","Collection","type/Collection",1447925820);BE=new $CLJS.M("type","CancelationTimestamp","type/CancelationTimestamp",-741584330);CE=new $CLJS.M("type","Country","type/Country",2058497652);DE=new $CLJS.M("type","Longitude","type/Longitude",-196788672);EE=new $CLJS.M("type","Subscription","type/Subscription",-1076112474);FE=new $CLJS.M("type","Price","type/Price",286577051);
GE=new $CLJS.M("type","Array","type/Array",-2060534244);HE=new $CLJS.M("type","XML","type/XML",-804526569);IE=new $CLJS.M("type","ImageURL","type/ImageURL",2081541690);JE=new $CLJS.M("type","Float","type/Float",1261800143);KE=new $CLJS.M("entity","CompanyTable","entity/CompanyTable",-1166853089);LE=new $CLJS.M("Coercion","YYYYMMDDHHMMSSString-\x3eTemporal","Coercion/YYYYMMDDHHMMSSString-\x3eTemporal",41716060);ME=new $CLJS.M("type","State","type/State",-154641657);
NE=new $CLJS.M("entity","TransactionTable","entity/TransactionTable",-888813059);OE=new $CLJS.M("entity","ProductTable","entity/ProductTable",1692844366);$CLJS.PE=new $CLJS.M("type","Time","type/Time",-814852413);QE=new $CLJS.M("type","BigInteger","type/BigInteger",1152965666);xC.h(MD,new $CLJS.M("entity","*","entity/*",-2043291259));xC.h(eE,MD);xC.h(KE,MD);xC.h(NE,MD);xC.h(OE,MD);xC.h(UC,MD);xC.h(zE,MD);xC.h(yD,MD);xC.h(nE,$CLJS.UD);xC.h(SD,nE);xC.h(QE,SD);xC.h(kD,$CLJS.QD);xC.h(kD,SD);xC.h(JE,nE);xC.h(sD,JE);xC.h(nD,$CLJS.QD);xC.h(nD,JE);xC.h(ZC,sD);xC.h(ZC,$CLJS.QD);xC.h(BD,ZC);xC.h(ED,ZC);xC.h(FE,ZC);xC.h(gD,ZC);xC.h(jD,ZC);xC.h(GC,$CLJS.QD);xC.h(TD,GC);xC.h(TD,JE);xC.h(rD,TD);xC.h(DE,TD);xC.h(HC,$CLJS.QD);xC.h(HC,nE);xC.h(bE,$CLJS.QD);xC.h(bE,nE);xC.h(xE,$CLJS.UD);
xC.h(gE,xE);xC.h(bD,$CLJS.QD);xC.h(bD,xE);xC.h(IE,bD);xC.h(dE,IE);xC.h(JD,$CLJS.QD);xC.h(JD,xE);xC.h(pE,$CLJS.QD);xC.h(uE,$CLJS.QD);xC.h(oD,GC);xC.h(PD,oD);xC.h(PD,pE);xC.h(PD,xE);xC.h(ME,oD);xC.h(ME,pE);xC.h(ME,xE);xC.h(CE,oD);xC.h(CE,pE);xC.h(CE,xE);xC.h(aD,oD);xC.h(aD,xE);xC.h(LD,pE);xC.h(LD,xE);xC.h(wE,pE);xC.h(wE,xE);xC.h(tE,$CLJS.QD);xC.h(tE,xE);xC.h(eD,$CLJS.QD);xC.h(eD,xE);xC.h(RC,xE);xC.h(sE,$CLJS.UD);xC.h($CLJS.hD,sE);xC.h($CLJS.PE,sE);xC.h(kE,$CLJS.PE);xC.h(cE,kE);xC.h(vD,kE);
xC.h($CLJS.WD,sE);xC.h(mD,$CLJS.WD);xC.h(QC,mD);xC.h(oE,mD);xC.h(xD,mD);xC.h(ID,QC);xC.h(YD,$CLJS.QD);xC.h(OC,YD);xC.h(OC,$CLJS.WD);xC.h(cD,YD);xC.h(cD,$CLJS.PE);xC.h(AD,YD);xC.h(AD,$CLJS.hD);xC.h(VC,$CLJS.QD);xC.h(XC,VC);xC.h(XC,$CLJS.WD);xC.h(yE,VC);xC.h(yE,$CLJS.PE);xC.h(pD,VC);xC.h(pD,$CLJS.hD);xC.h(RD,$CLJS.QD);xC.h(BE,RD);xC.h(BE,$CLJS.WD);xC.h(IC,RD);xC.h(IC,$CLJS.hD);xC.h(KD,RD);xC.h(KD,$CLJS.hD);xC.h(iE,$CLJS.QD);xC.h(WC,iE);xC.h(WC,$CLJS.WD);xC.h(uD,iE);xC.h(uD,$CLJS.PE);xC.h(VD,iE);
xC.h(VD,$CLJS.hD);xC.h(rE,$CLJS.QD);xC.h(zD,rE);xC.h(zD,$CLJS.WD);xC.h(GD,rE);xC.h(GD,$CLJS.PE);xC.h(qE,rE);xC.h(qE,$CLJS.hD);xC.h(XD,$CLJS.QD);xC.h(XD,$CLJS.hD);xC.h(jE,$CLJS.UD);xC.h(lE,$CLJS.UD);xC.h(ND,$CLJS.UD);xC.h(HD,ND);xC.h(LC,ND);xC.h(LC,$CLJS.QD);xC.h(AE,$CLJS.UD);xC.h(qD,$CLJS.UD);xC.h(tD,AE);xC.h(GE,AE);xC.h(YC,qD);xC.h(YC,AE);xC.h(HE,qD);xC.h(HE,AE);xC.h(qD,$CLJS.QD);xC.h(qD,xE);xC.h(iD,qD);xC.h(HE,qD);xC.h(FD,$CLJS.QD);xC.h(mE,FD);xC.h(vE,FD);xC.h(wD,pE);xC.h(KC,pE);xC.h(EE,pE);
xC.h(TC,pE);xC.h(JC,$CLJS.NC);xC.h(PC,$CLJS.NC);xC.h($C,CC);xC.h(fD,$C);xC.h(SC,fD);xC.h(hE,fD);xC.h(ZD,fD);xC.h(LE,$C);xC.h(CD,CC);xC.h($D,CD);xC.h(MC,CC);xC.h(fE,MC);xC.h(dD,fE);xC.h(lD,fE);xC.h(OD,fE);
var SE=$CLJS.tk($CLJS.Yf.h($CLJS.P,function RE(a){return new $CLJS.te(null,function(){for(;;){var c=$CLJS.A(a);if(c){if($CLJS.Cd(c)){var d=$CLJS.kc(c),e=$CLJS.E(d),f=$CLJS.we(e);a:for(var g=0;;)if(g<e){var k=$CLJS.ld(d,g);k=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sg(k),$CLJS.qC(k)],null);f.add(k);g+=1}else{d=!0;break a}return d?$CLJS.ze($CLJS.Be(f),RE($CLJS.lc(c))):$CLJS.ze($CLJS.Be(f),null)}f=$CLJS.B(c);return $CLJS.ne(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sg(f),$CLJS.qC(f)],null),RE($CLJS.Lc(c)))}return null}},
null)}($CLJS.FC.g($CLJS.xs(EC,$CLJS.H([new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.UD,$CLJS.QD,$CLJS.NC],null)]))))));zC(OD,new $CLJS.S(null,new $CLJS.n(null,2,[SD,null,sD,null],null),null),ID);zC(lD,new $CLJS.S(null,new $CLJS.n(null,2,[SD,null,sD,null],null),null),ID);zC(dD,new $CLJS.S(null,new $CLJS.n(null,2,[SD,null,sD,null],null),null),ID);zC(ZD,xE,$CLJS.hD);zC(SC,xE,$CLJS.WD);zC(hE,xE,$CLJS.PE);zC(LE,xE,$CLJS.WD);
$CLJS.zh.j($CLJS.Lt,$CLJS.Xe($CLJS.Yx,$CLJS.BC),$CLJS.Yt(uC($CLJS.UD),new $CLJS.df(null,-1,$CLJS.Og([$D]),null)));$CLJS.zh.I($CLJS.Kt,$CLJS.Kg,$D,$CLJS.WD);
module.exports={isa:function(a,b){return $CLJS.tC($CLJS.jh.g(a),$CLJS.jh.g(b))},coercions_for_type:function(a){a=$CLJS.Yf.j($CLJS.Kc,$CLJS.gk.h($CLJS.FC.v(),sC),$CLJS.Hg(DC($CLJS.jh.g(a))));return $CLJS.tk($CLJS.ih.h(function(b){return[$CLJS.pe(b),"/",$CLJS.Sg(b)].join("")},a))},is_coerceable:function(a){return $CLJS.Gd($CLJS.Le(DC($CLJS.jh.g(a))))},TYPE:SE};