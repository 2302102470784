var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var nB=function(){},oB=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.q.g(b),$CLJS.q.g(" ")].join(""),a],null)},pB=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.q.g(d),"%c",$CLJS.q.g(b),"%c"].join(""),$CLJS.ke.m(a,["color:",$CLJS.q.g($CLJS.K.h($CLJS.mB,$CLJS.kB)),";background-color:",$CLJS.q.g($CLJS.K.h($CLJS.mB,c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],
null)},qB=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.q.g(d),"%c",$CLJS.q.g(b),"%c"].join(""),$CLJS.ke.m(a,["color:",$CLJS.q.g($CLJS.K.h($CLJS.mB,c))].join(""),$CLJS.H(["color:black"]))],null)},rB=function(a){return $CLJS.K.h($CLJS.$z,a).value},sB=function(a){var b=rB(a);return $CLJS.p(function(){var c=rB($CLJS.tA);return $CLJS.ck.h?$CLJS.ck.h(c,b):$CLJS.ck.call(null,c,b)}())?$CLJS.ZA:$CLJS.p(function(){var c=rB($CLJS.sA);return $CLJS.ck.h?
$CLJS.ck.h(c,b):$CLJS.ck.call(null,c,b)}())?$CLJS.iB:$CLJS.p(function(){var c=rB($CLJS.qA);return $CLJS.ck.h?$CLJS.ck.h(c,b):$CLJS.ck.call(null,c,b)}())?$CLJS.bB:$CLJS.p(function(){var c=rB($CLJS.iA);return $CLJS.ck.h?$CLJS.ck.h(c,b):$CLJS.ck.call(null,c,b)}())?$CLJS.hB:$CLJS.p(function(){var c=rB($CLJS.rA);return $CLJS.ck.h?$CLJS.ck.h(c,b):$CLJS.ck.call(null,c,b)}())?$CLJS.jB:$CLJS.p(function(){var c=rB($CLJS.mA);return $CLJS.ck.h?$CLJS.ck.h(c,b):$CLJS.ck.call(null,c,b)}())?$CLJS.eB:$CLJS.p(function(){var c=
rB($CLJS.eA);return $CLJS.ck.h?$CLJS.ck.h(c,b):$CLJS.ck.call(null,c,b)}())?$CLJS.fB:$CLJS.gB},tB=function(a){function b(c,d){return d>=c}a=rB(a);if(b(rB($CLJS.tA),a))return"error";if(b(rB($CLJS.sA),a))return"warn";if(b(rB($CLJS.qA),a))return"info";b(rB($CLJS.iA),a);return"log"},uB=function(a){return function(b){var c=$CLJS.Ke(b),d=$CLJS.K.h(c,$CLJS.fA),e=$CLJS.K.h(c,$CLJS.Hs);b=$CLJS.K.h(c,$CLJS.uA);e=tB(e);e=$CLJS.ya(console,e);e=$CLJS.p(e)?e:console.log;$CLJS.Ue.h(e,a.g?a.g(c):a.call(null,c));return $CLJS.p(b)?
(c=["[",$CLJS.q.g(d),"]"].join(""),d=$CLJS.q.g(b),b=b.stack,e.I?e.I(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},vB=function vB(a,b){for(;;){if($CLJS.F.h($CLJS.YA,b))return qB(a,", ",$CLJS.gB);if($CLJS.F.h($CLJS.$A,b))return oB(a);if(b instanceof $CLJS.M)return qB(a,b,$CLJS.bB);if(b instanceof $CLJS.x)return qB(a,b,$CLJS.hB);if("string"===typeof b)return qB(a,$CLJS.wh.m($CLJS.H([b])),$CLJS.aB);if($CLJS.uf(b)){var d=oB(function(){var f=a,g=$CLJS.Cb(b);return vB.h?vB.h(f,g):vB.call(null,f,g)}()),e=$CLJS.Eb(b);
return vB.h?vB.h(d,e):vB.call(null,d,e)}if(b instanceof $CLJS.n||b instanceof $CLJS.Ag)return d=a,d=qB(d,"{",$CLJS.lB),d=$CLJS.$a(vB,d,$CLJS.zs($CLJS.YA,b)),qB(d,"}",$CLJS.lB);if($CLJS.xd(b))return d=a,d=qB(d,["#",$CLJS.q.g(function(){var f=$CLJS.Wa(b),g=f.name;return $CLJS.sd(g)?$CLJS.wh.m($CLJS.H([f])):g}())," "].join(""),$CLJS.cB),d=qB(d,"{",$CLJS.lB),d=$CLJS.$a(vB,d,$CLJS.zs($CLJS.YA,b)),qB(d,"}",$CLJS.lB);if($CLJS.ud(b))return d=a,d=qB(d,"#{",$CLJS.lB),d=$CLJS.$a(vB,d,$CLJS.zs($CLJS.$A,b)),qB(d,
"}",$CLJS.lB);if($CLJS.Bd(b))return d=a,d=qB(d,"[",$CLJS.lB),d=$CLJS.$a(vB,d,$CLJS.zs($CLJS.$A,b)),qB(d,"]",$CLJS.lB);if($CLJS.Fd(b))return d=a,d=qB(d,"(",$CLJS.cB),d=$CLJS.$a(vB,d,$CLJS.zs($CLJS.$A,b)),qB(d,")",$CLJS.cB);if(null!=b?b.H&16384||$CLJS.u===b.ei||(b.H?0:$CLJS.Va(nB,b)):$CLJS.Va(nB,b))d=qB(a,"#atom ",$CLJS.cB),e=$CLJS.t(b),a=d,b=e;else if($CLJS.Rh(b))d=qB(a,"#uuid ",$CLJS.cB),e=$CLJS.q.g(b),a=d,b=e;else if($CLJS.Ta(b))d=qB(a,"#js ",$CLJS.cB),e=$CLJS.$a(function(f,g){return function(k,
l){return $CLJS.Kg.j(k,$CLJS.jh.g(l),$CLJS.ya(g,l))}}(a,b),$CLJS.P,Object.keys(b)),a=d,b=e;else if($CLJS.Pa(b))d=qB(a,"#js ",$CLJS.cB),e=$CLJS.Yf.h($CLJS.xf,b),a=d,b=e;else return qB(a,$CLJS.wh.m($CLJS.H([b])),$CLJS.dB)}};$CLJS.wB=uB(function(a){a=$CLJS.Ke(a);$CLJS.K.h(a,$CLJS.Hs);var b=$CLJS.K.h(a,$CLJS.fA),c=$CLJS.K.h(a,$CLJS.yi);$CLJS.K.h(a,$CLJS.uA);return new $CLJS.Q(null,2,5,$CLJS.R,[["[",$CLJS.q.g(b),"]"].join(""),c],null)});
$CLJS.xB=uB(function(a){var b=$CLJS.Ke(a),c=$CLJS.K.h(b,$CLJS.Hs);a=$CLJS.K.h(b,$CLJS.fA);var d=$CLJS.K.h(b,$CLJS.yi);$CLJS.K.h(b,$CLJS.uA);b=sB(c);d=vB(oB(pB(pB(pB(new $CLJS.Q(null,2,5,$CLJS.R,["",$CLJS.xf],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.ne(a,d)});
$CLJS.yB=uB(function(a){a=$CLJS.Ke(a);$CLJS.K.h(a,$CLJS.Hs);var b=$CLJS.K.h(a,$CLJS.fA),c=$CLJS.K.h(a,$CLJS.yi);$CLJS.K.h(a,$CLJS.uA);return new $CLJS.Q(null,2,5,$CLJS.R,[["[",$CLJS.q.g(b),"]"].join(""),$CLJS.wh.m($CLJS.H([c]))],null)});