var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");
'use strict';var KW=function(a){return a instanceof Error?a.message:null},MW=function(a){var b=LW;return $CLJS.aC(function(c){return function(d,e,f){f=b.g?b.g(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)},OW=function(a){var b=NW;return $CLJS.aC(function(c){return function(d,e,f){e=b.g?b.g(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)},PW=function(a,b,c){var d=$CLJS.Md(a,b);if($CLJS.p(d)){var e=$CLJS.Kg.j;d=$CLJS.Eb(d);c=c.g?c.g(d):c.call(null,d);a=e.call($CLJS.Kg,
a,b,c)}return a},QW=function(a,b,c){if($CLJS.ie(c)){var d=$CLJS.Ue.h($CLJS.X,$CLJS.ih.h(a,c));return b.g?b.g(d):b.call(null,d)}return $CLJS.uf(c)?(d=new $CLJS.Ld(function(){var e=$CLJS.Cb(c);return a.g?a.g(e):a.call(null,e)}(),function(){var e=$CLJS.Eb(c);return a.g?a.g(e):a.call(null,e)}()),b.g?b.g(d):b.call(null,d)):$CLJS.Fd(c)?(d=$CLJS.ts($CLJS.ih.h(a,c)),b.g?b.g(d):b.call(null,d)):$CLJS.Ad(c)?(d=$CLJS.$a(function(e,f){return $CLJS.ke.h(e,a.g?a.g(f):a.call(null,f))},c,c),b.g?b.g(d):b.call(null,
d)):$CLJS.td(c)?(d=$CLJS.Yf.h($CLJS.kd(c),$CLJS.ih.h(a,c)),b.g?b.g(d):b.call(null,d)):b.g?b.g(c):b.call(null,c)},SW=function(a){function b(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return"string"===typeof d?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.jh.g(d),c],null):new $CLJS.Q(null,2,5,$CLJS.R,[d,c],null)}return RW(function(c){return $CLJS.xd(c)?$CLJS.Yf.h($CLJS.P,$CLJS.ih.h(b,c)):c},a)},TW=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.uf(a))?$CLJS.ik.h($CLJS.oe,$CLJS.Ua)($CLJS.B(a)):b:b},NW=function(a){return $CLJS.p($CLJS.ik.h($CLJS.oe,
$CLJS.Ua)(a))?$CLJS.TV(a):a},UW=function(a,b){var c=TW(b);return $CLJS.p(c)?(b=NW($CLJS.B(b)),$CLJS.td(a)?(a=$CLJS.Qg(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,b)):c},XW=function(a){return $CLJS.K.j(new $CLJS.n(null,3,[$CLJS.Ph,$CLJS.ae,$CLJS.Ri,NW,$CLJS.uK,NW],null),a,function(b){var c=$CLJS.K,d=c.h;b=$CLJS.Je([a,b]);b=VW.h?VW.h(b,WW):VW.call(null,b,WW);return d.call(c,b,a)})},YW=function(a){a=$CLJS.Yf.j($CLJS.P,$CLJS.ih.g(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=NW(c);var d=XW(c);
return new $CLJS.Q(null,2,5,$CLJS.R,[c,d.g?d.g(b):d.call(null,b)],null)}),a);return $CLJS.F.h($CLJS.Ri.g(a),$CLJS.rI)&&$CLJS.Ra($CLJS.uK.g(a))?$CLJS.Kg.j(a,$CLJS.uK,$CLJS.HP):a},ZW=function(a){return $CLJS.Yf.j($CLJS.P,$CLJS.ih.g(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.QU(c);return new $CLJS.Q(null,2,5,$CLJS.R,[c,$CLJS.Kg.j(YW(b),$CLJS.T,c)],null)}),a)},cX=function(a){return PW(PW($CLJS.$a(function(b,c){return PW(b,c,$CLJS.jh)},a,new $CLJS.Q(null,6,5,$CLJS.R,[$CLJS.DD,$CLJS.aE,
$CLJS.tL,$W,$CLJS.aX,$CLJS.ci],null)),bX,$CLJS.gk.h(LW,VW)),$CLJS.vK,SW)},dX=function(a){return $CLJS.Id(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fN,a,null],null):a},fX=function(a){return eX.g(dX(a))},gX=function(a){var b=$CLJS.A(a);a=$CLJS.B(b);b=$CLJS.C(b);return $CLJS.TU($CLJS.Yf.h(new $CLJS.Q(null,1,5,$CLJS.R,[a],null),$CLJS.ih.h(eX,b)))},hX=function(a){var b=$CLJS.A(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Yf.h(new $CLJS.Q(null,2,5,$CLJS.R,[a,fX(b)],null),$CLJS.ih.h(eX,
c))},iX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.p(a)?new $CLJS.Q(null,2,5,$CLJS.R,[b,fX(a)],null):new $CLJS.Q(null,1,5,$CLJS.R,[b],null)},jX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,fX(a)],null)},kX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[b,eX.g(a)],null)},LW=function(a){return lX(function(b){if($CLJS.xd(b))return MW(b);if($CLJS.Ra(TW(b)))return b;try{return eX.g(b)}catch(f){if(f instanceof
Error){var c=f,d=$CLJS.$B($CLJS.Fs);if($CLJS.p($CLJS.eC("metabase.mbql.normalize",d))){var e=$CLJS.IU("Invalid clause:");e instanceof Error?$CLJS.gC("metabase.mbql.normalize",d,$CLJS.sw.m($CLJS.H([b])),e):$CLJS.gC("metabase.mbql.normalize",d,$CLJS.sw.m($CLJS.H([e,b])),null)}throw $CLJS.Th($CLJS.HU("Invalid MBQL clause: {0}",$CLJS.H([KW(c)])),new $CLJS.n(null,1,[$CLJS.NQ,b],null),c);}throw f;}},a)},nX=function(a){return $CLJS.yf($CLJS.Ye(LW,mX(a)))},oX=function(a){for(;;)if($CLJS.p(TW(a)))a=new $CLJS.Q(null,
1,5,$CLJS.R,[a],null);else return $CLJS.Le($CLJS.kk.h(dX,a))},pX=function(a){a=$CLJS.Ke(a);var b=$CLJS.K.h(a,$CLJS.rI);return $CLJS.p(b)?$CLJS.iC.j(a,$CLJS.rI,eX):a},qX=function(a){return $CLJS.Yf.h($CLJS.P,function(){return function d(c){return new $CLJS.te(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),k=$CLJS.we(g);a:for(var l=0;;)if(l<g){var m=$CLJS.ld(f,l),r=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);r=new $CLJS.Q(null,2,5,$CLJS.R,[r,pX(m)],null);k.add(r);
l+=1}else{f=!0;break a}return f?$CLJS.ze($CLJS.Be(k),d($CLJS.lc(e))):$CLJS.ze($CLJS.Be(k),null)}f=$CLJS.B(e);k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ne(new $CLJS.Q(null,2,5,$CLJS.R,[k,pX(f)],null),d($CLJS.Lc(e)))}return null}},null)}(a)}())},rX=function(a){a=$CLJS.Ke(a);var b=$CLJS.K.h(a,$CLJS.FO);return $CLJS.p(b)?$CLJS.iC.j(a,$CLJS.FO,qX):a},tX=function(a){var b=$CLJS.Ke(a);a=$CLJS.K.h(b,$CLJS.VN);b=$CLJS.Ra(a)?sX.g?sX.g(b):sX.call(null,b):b;return $CLJS.p(a)?rX(b):b},uX=function(a){return $CLJS.td(a)?
$CLJS.A(a):null!=a},wX=function(a){var b=$CLJS.Ke(a);a=$CLJS.K.h(b,$CLJS.PI);var c=$CLJS.K.h(b,$CLJS.JJ),d=$CLJS.K.h(b,$CLJS.NL),e=$CLJS.K.h(b,$CLJS.VN);try{if($CLJS.p(d)){var f=$CLJS.Ke(b),g=$CLJS.K.h(f,$CLJS.NL);var k=vX($CLJS.ak.h(f,$CLJS.NL),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PI,$CLJS.NL],null),g)}else k=b;var l=$CLJS.p(a)?$CLJS.iC.j(k,$CLJS.PI,sX):k,m=$CLJS.p(c)?$CLJS.iC.j(l,$CLJS.JJ,$CLJS.Xe($CLJS.kk,LW)):l,r=$CLJS.p(e)?$CLJS.iC.j(m,$CLJS.VN,rX):m;return LW(r)}catch(v){if(v instanceof Error)throw l=
v,$CLJS.Th($CLJS.HU("Error canonicalizing query: {0}",$CLJS.H([KW(l)])),new $CLJS.n(null,1,[$CLJS.PI,a],null),l);throw v;}},yX=function(a){a=$CLJS.Ke(a);var b=$CLJS.K.h(a,$CLJS.PI),c=$CLJS.Ke(b);b=$CLJS.K.h(c,$CLJS.zL);c=$CLJS.K.h(c,$CLJS.$I);return $CLJS.A(b)&&$CLJS.A(c)?(b=$CLJS.Qg($CLJS.Yd($CLJS.ek,$CLJS.A($CLJS.ff($CLJS.Sa,function g(e,f){try{if($CLJS.Bd(f)&&3===$CLJS.E(f))try{var k=$CLJS.G(f,0);if($CLJS.O(k,$CLJS.fN)){var l=$CLJS.G(f,1),m=$CLJS.G(f,2);return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,
2,5,$CLJS.R,[f,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fN,l,$CLJS.ak.h(m,$CLJS.ON)],null)],null)],null)}throw $CLJS.Z;}catch(r){if(r instanceof Error){k=r;if(k===$CLJS.Z)throw $CLJS.Z;throw k;}throw r;}else throw $CLJS.Z;}catch(r){if(r instanceof Error){k=r;if(k===$CLJS.Z)return $CLJS.OU(g,e,f);throw k;}throw r;}}($CLJS.xf,b))))),xX.j(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PI,$CLJS.$I],null),$CLJS.gk.h($CLJS.yf,$CLJS.Xe($CLJS.jk,b)))):a},zX=function(a){try{return yX(a)}catch(c){if(c instanceof Error){var b=
c;throw $CLJS.Th($CLJS.IU("Error performing whole query transformations"),new $CLJS.n(null,1,[$CLJS.PI,a],null),b);}throw c;}},BX=function(a,b){var c=$CLJS.Yf.h($CLJS.kd(a),function(){return function f(e){return new $CLJS.te(null,function(){for(var g=e;;)if(g=$CLJS.A(g)){if($CLJS.Cd(g)){var k=$CLJS.kc(g),l=$CLJS.E(k),m=$CLJS.we(l);return function(){for(var y=0;;)if(y<l){var z=$CLJS.ld(k,y),D=$CLJS.I(z,0,null);z=$CLJS.I(z,1,null);var N=$CLJS.ke.h(b,D);z=AX.h?AX.h(z,N):AX.call(null,z,N);null!=z&&m.add(new $CLJS.Q(null,
2,5,$CLJS.R,[D,z],null));y+=1}else return!0}()?$CLJS.ze($CLJS.Be(m),f($CLJS.lc(g))):$CLJS.ze($CLJS.Be(m),null)}var r=$CLJS.B(g),v=$CLJS.I(r,0,null),w=$CLJS.I(r,1,null);r=function(){var y=w,z=$CLJS.ke.h(b,v);return AX.h?AX.h(y,z):AX.call(null,y,z)}();if(null!=r)return $CLJS.ne(new $CLJS.Q(null,2,5,$CLJS.R,[v,r],null),f($CLJS.Lc(g)));g=$CLJS.Lc(g)}else return null},null)}(a)}());return $CLJS.A(c)?c:null},DX=function(a,b){a=$CLJS.kk.h(function(c){var d=$CLJS.ke.h(b,CX);return AX.h?AX.h(c,d):AX.call(null,
c,d)},a);return $CLJS.p($CLJS.Re($CLJS.Sa,a))?a:null},vX=function vX(a,b,c){var e=$CLJS.A(b);b=$CLJS.B(e);var f=$CLJS.C(e);if(f){e=$CLJS.Kg.j;var g=$CLJS.K.h(a,b);c=vX.j?vX.j(g,f,c):vX.call(null,g,f,c);a=e.call($CLJS.Kg,a,b,c)}else a=$CLJS.Kg.j(a,b,c);return a},xX=function xX(a){switch(arguments.length){case 3:return xX.j(arguments[0],arguments[1],arguments[2]);case 4:return xX.I(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return xX.R(arguments[0],arguments[1],arguments[2],arguments[3],
arguments[4]);case 6:return xX.$(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return xX.m(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5],new $CLJS.Hc(c.slice(6),0,null))}},lX,RW,EX,FX,GX,HX,IX,JX,KX,LX,MX,NX,OX,PX,QX,RX,SX,CX,WW,TX,UX,bX,VX,$W;
xX.j=function(a,b,c){var d=$CLJS.A(b);b=$CLJS.B(d);if(d=$CLJS.C(d))a=$CLJS.Kg.j(a,b,xX.j($CLJS.K.h(a,b),d,c));else{d=$CLJS.Kg.j;var e=$CLJS.K.h(a,b);c=c.g?c.g(e):c.call(null,e);a=d.call($CLJS.Kg,a,b,c)}return a};xX.I=function(a,b,c,d){var e=$CLJS.A(b);b=$CLJS.B(e);if(e=$CLJS.C(e))a=$CLJS.Kg.j(a,b,xX.I($CLJS.K.h(a,b),e,c,d));else{e=$CLJS.Kg.j;var f=$CLJS.K.h(a,b);c=c.h?c.h(f,d):c.call(null,f,d);a=e.call($CLJS.Kg,a,b,c)}return a};
xX.R=function(a,b,c,d,e){var f=$CLJS.A(b);b=$CLJS.B(f);if(f=$CLJS.C(f))a=$CLJS.Kg.j(a,b,xX.R($CLJS.K.h(a,b),f,c,d,e));else{f=$CLJS.Kg.j;var g=$CLJS.K.h(a,b);c=c.j?c.j(g,d,e):c.call(null,g,d,e);a=f.call($CLJS.Kg,a,b,c)}return a};xX.$=function(a,b,c,d,e,f){var g=$CLJS.A(b);b=$CLJS.B(g);if(g=$CLJS.C(g))a=$CLJS.Kg.j(a,b,xX.$($CLJS.K.h(a,b),g,c,d,e,f));else{g=$CLJS.Kg.j;var k=$CLJS.K.h(a,b);c=c.I?c.I(k,d,e,f):c.call(null,k,d,e,f);a=g.call($CLJS.Kg,a,b,c)}return a};
xX.m=function(a,b,c,d,e,f,g){var k=$CLJS.A(b);b=$CLJS.B(k);return(k=$CLJS.C(k))?$CLJS.Kg.j(a,b,$CLJS.Ue.m(xX,$CLJS.K.h(a,b),k,c,d,$CLJS.H([e,f,g]))):$CLJS.Kg.j(a,b,$CLJS.Ue.m(c,$CLJS.K.h(a,b),d,e,f,$CLJS.H([g])))};xX.G=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);var e=$CLJS.C(d);d=$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.B(f);var g=$CLJS.C(f);f=$CLJS.B(g);g=$CLJS.C(g);return this.m(b,a,c,d,e,f,g)};xX.F=6;
lX=function lX(a,b){return QW($CLJS.Xe(lX,a),$CLJS.ae,a.g?a.g(b):a.call(null,b))};RW=function RW(a,b){return QW($CLJS.Xe(RW,a),a,b)};EX=new $CLJS.M(null,"value_field","value_field",-980977878);FX=new $CLJS.M(null,"ascending","ascending",-988350486);GX=new $CLJS.M(null,"named","named",-422393479);HX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309);IX=new $CLJS.M(null,"as","as",1148689641);JX=new $CLJS.M(null,"field-id","field-id",-353751335);
KX=new $CLJS.M(null,"form","form",-1624062471);$CLJS.aX=new $CLJS.M(null,"source","source",-433931539);LX=new $CLJS.M(null,"descending","descending",-24766135);MX=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158);NX=new $CLJS.M(null,"datetime-field","datetime-field",21731696);OX=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941);PX=new $CLJS.M(null,"field-literal","field-literal",-1295883554);QX=new $CLJS.M(null,"viz-settings","viz-settings",256055379);
RX=new $CLJS.M(null,"rows","rows",850049680);SX=new $CLJS.M(null,"special-fn","special-fn",1290649344);CX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683);WW=new $CLJS.M(null,"ignore-path","ignore-path",944069061);TX=new $CLJS.M(null,"label_field","label_field",-1573182765);UX=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738);bX=new $CLJS.M(null,"field_ref","field_ref",-1985640334);VX=new $CLJS.M(null,"joined-field","joined-field",-2048778268);
$W=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var WX,XX=$CLJS.yh.g($CLJS.P),YX=$CLJS.yh.g($CLJS.P),ZX=$CLJS.yh.g($CLJS.P),$X=$CLJS.yh.g($CLJS.P),aY=$CLJS.K.j($CLJS.P,$CLJS.Ni,$CLJS.Gh.v?$CLJS.Gh.v():$CLJS.Gh.call(null));WX=new $CLJS.Qh($CLJS.ph.h("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.gk.h(NW,$CLJS.B),aY,XX,YX,ZX,$X);WX.P(null,$CLJS.Zs,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Zs,a instanceof $CLJS.M?$CLJS.QU(a):a],null)});
WX.P(null,MX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.p(a)?$CLJS.ke.h(WX.g(new $CLJS.Q(null,3,5,$CLJS.R,[MX,b,c],null)),a):new $CLJS.Q(null,3,5,$CLJS.R,[MX,VW.h?VW.h(b,WW):VW.call(null,b,WW),NW(c)],null)});
WX.P(null,$CLJS.fN,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=VW.h?VW.h(a,WW):VW.call(null,a,WW);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fN,b,function(){var d=$CLJS.p($CLJS.kO.g(c))?$CLJS.iC.j(c,$CLJS.kO,$CLJS.jh):c;d=$CLJS.p($CLJS.ON.g(c))?$CLJS.iC.j(d,$CLJS.ON,$CLJS.jh):d;return $CLJS.p($CLJS.aQ.g(c))?$CLJS.iC.j(d,$CLJS.aQ,function(e){return $CLJS.p($CLJS.UQ.g(e))?$CLJS.iC.j(e,$CLJS.UQ,$CLJS.jh):e}):d}()],null)});
WX.P(null,PX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[PX,b instanceof $CLJS.M?$CLJS.QU(b):b,$CLJS.jh.g(a)],null)});WX.P(null,NX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.p(a)?new $CLJS.Q(null,4,5,$CLJS.R,[NX,VW.h?VW.h(b,WW):VW.call(null,b,WW),IX,NW(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[NX,VW.h?VW.h(b,WW):VW.call(null,b,WW),NW(c)],null)});
WX.P(null,$CLJS.NO,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.p(a)?$CLJS.ke.h(WX.g(new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.NO,b,c,d],null)),VW.h?VW.h(a,WW):VW.call(null,a,WW)):new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.NO,VW.h?VW.h(b,WW):VW.call(null,b,WW),$CLJS.Id(c)?c:NW(c),NW(d)],null)});
WX.P(null,$CLJS.ML,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.p(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.ML,b,NW(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ML,$CLJS.Hv],null)});WX.P(null,$CLJS.OK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.OK,b,NW(a)],null)});
WX.P(null,$CLJS.dO,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.dO,VW.h?VW.h(b,WW):VW.call(null,b,WW),c,NW(a)],null)});WX.P(null,$CLJS.MP,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.MP,VW.h?VW.h(b,WW):VW.call(null,b,WW),c,NW(a)],null)});
WX.P(null,$CLJS.bO,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.p(a)?new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bO,VW.h?VW.h(b,WW):VW.call(null,b,WW),NW(a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.bO,VW.h?VW.h(b,WW):VW.call(null,b,WW)],null)});
WX.P(null,$CLJS.LO,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.p(a)?new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.LO,VW.h?VW.h(b,WW):VW.call(null,b,WW),NW(c),NW(a)],null):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.LO,VW.h?VW.h(b,WW):VW.call(null,b,WW),NW(c)],null)});
WX.P(null,$CLJS.KK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.KK,VW.h?VW.h(b,WW):VW.call(null,b,WW),VW.h?VW.h(c,WW):VW.call(null,c,WW),NW(a)],null)});WX.P(null,$CLJS.bj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.bj,b,a],null)});
WX.P(null,$CLJS.Ph,function(a){var b=$CLJS.A(a);a=$CLJS.B(b);b=$CLJS.C(b);return $CLJS.Yf.j(new $CLJS.Q(null,1,5,$CLJS.R,[NW(a)],null),$CLJS.ih.g(function(c){return VW.h?VW.h(c,WW):VW.call(null,c,WW)}),b)});
var bY=function bY(a){if($CLJS.p($CLJS.ik.h($CLJS.oe,$CLJS.Ua)(a))){var c=NW(a);var d=new $CLJS.S(null,new $CLJS.n(null,18,[$CLJS.si,null,$CLJS.Jj,null,$CLJS.jP,null,$CLJS.gO,null,$CLJS.kQ,null,$CLJS.jN,null,$CLJS.YP,null,$CLJS.fQ,null,$CLJS.BJ,null,$CLJS.hL,null,$CLJS.Ij,null,$CLJS.QJ,null,$CLJS.YO,null,$CLJS.QN,null,$CLJS.vj,null,$CLJS.Ry,null,$CLJS.lM,null,$CLJS.TO,null],null),null);c=d.g?d.g(c):d.call(null,c)}else c=null;return $CLJS.p(c)?c:$CLJS.p(TW(a))?(a=$CLJS.B(a),bY.g?bY.g(a):bY.call(null,
a)):null},dY=new $CLJS.n(null,8,[$CLJS.Ri,NW,$CLJS.VN,function(a){a=OW(a);return $CLJS.A($CLJS.FO.g(a))?$CLJS.iC.j(a,$CLJS.FO,ZW):a},$CLJS.PI,new $CLJS.n(null,6,[$CLJS.LL,function cY(a){if($CLJS.p($CLJS.ik.h($CLJS.oe,$CLJS.Ua)(a)))return NW(a);if($CLJS.p(UW(GX,a))){a=$CLJS.A(a);$CLJS.B(a);var c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Yf.h(new $CLJS.Q(null,2,5,$CLJS.R,[GX,cY.g?cY.g(a):cY.call(null,a)],null),c)}return $CLJS.p($CLJS.p(TW(a))?bY($CLJS.hd(a)):null)?$CLJS.kk.h(cY,a):VW.h?VW.h(a,
WW):VW.call(null,a,WW)},$CLJS.KN,function(a){return $CLJS.Yf.h($CLJS.P,function(){return function d(c){return new $CLJS.te(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),k=$CLJS.we(g);a:for(var l=0;;)if(l<g){var m=$CLJS.ld(f,l),r=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);r=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QU(r),VW.h?VW.h(m,WW):VW.call(null,m,WW)],null);k.add(r);l+=1}else{f=!0;break a}return f?$CLJS.ze($CLJS.Be(k),d($CLJS.lc(e))):$CLJS.ze($CLJS.Be(k),null)}f=
$CLJS.B(e);k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ne(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QU(k),VW.h?VW.h(f,WW):VW.call(null,f,WW)],null),d($CLJS.Lc(e)))}return null}},null)}(a)}())},$CLJS.oQ,function(a){return $CLJS.yf(function(){return function d(c){return new $CLJS.te(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),k=$CLJS.we(g);a:for(var l=0;;)if(l<g){var m=$CLJS.ld(f,l);m=$CLJS.p(TW(m))?WX.g(m):$CLJS.le(WX.g($CLJS.le(m)));k.add(m);l+=
1}else{f=!0;break a}return f?$CLJS.ze($CLJS.Be(k),d($CLJS.lc(e))):$CLJS.ze($CLJS.Be(k),null)}k=$CLJS.B(e);return $CLJS.ne($CLJS.p(TW(k))?WX.g(k):$CLJS.le(WX.g($CLJS.le(k))),d($CLJS.Lc(e)))}return null}},null)}(a)}())},$CLJS.PO,function(a){a=OW(a);a=$CLJS.Ke(a);var b=$CLJS.K.h(a,$CLJS.VN);if($CLJS.p(b))return a=$CLJS.MH(a,new $CLJS.n(null,1,[$CLJS.VN,$CLJS.PI],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.VN],null),a=VW.h?VW.h(a,b):VW.call(null,a,b),$CLJS.MH(a,new $CLJS.n(null,1,[$CLJS.PI,$CLJS.VN],
null));b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.PI],null);return VW.h?VW.h(a,b):VW.call(null,a,b)},$CLJS.NL,new $CLJS.n(null,1,[CX,cX],null),$CLJS.XJ,new $CLJS.n(null,1,[CX,function(a){a=VW.h?VW.h(a,$CLJS.PI):VW.call(null,a,$CLJS.PI);var b=$CLJS.Ke(a),c=$CLJS.K.h(b,$CLJS.UQ),d=$CLJS.K.h(b,$CLJS.$I);a=$CLJS.K.h(b,$CLJS.gL);b=$CLJS.p(c)?$CLJS.iC.j(b,$CLJS.UQ,NW):b;d=$CLJS.p($CLJS.ik.h($CLJS.oe,$CLJS.Ua)(d))?$CLJS.iC.j(b,$CLJS.$I,NW):b;return $CLJS.p(a)?$CLJS.iC.j(d,$CLJS.gL,$CLJS.QU):d}],null)],null),
$CLJS.qA,new $CLJS.n(null,1,[$CLJS.XK,$CLJS.ae],null),$CLJS.JJ,new $CLJS.n(null,1,[CX,function(a){var b=$CLJS.Ke(a),c=$CLJS.K.h(b,$CLJS.Ri),d=$CLJS.K.h(b,$CLJS.dR),e=$CLJS.K.h(b,$CLJS.Ei);a=$CLJS.K.h(b,HX);b=$CLJS.p(e)?$CLJS.iC.j(b,$CLJS.Ei,$CLJS.QU):b;c=$CLJS.p(c)?$CLJS.iC.j(b,$CLJS.Ri,NW):b;d=$CLJS.p(d)?$CLJS.iC.j(c,$CLJS.dR,function(f){return VW.h?VW.h(f,WW):VW.call(null,f,WW)}):c;d=$CLJS.p(a)?xX.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[HX,TX],null),function(f){return VW.h?VW.h(f,WW):VW.call(null,f,WW)}):
d;return $CLJS.p(a)?xX.j(d,new $CLJS.Q(null,2,5,$CLJS.R,[HX,EX],null),function(f){return VW.h?VW.h(f,WW):VW.call(null,f,WW)}):d}],null),$CLJS.GJ,function(a){return null==a?null:NW(a)},$CLJS.NL,new $CLJS.n(null,1,[CX,cX],null),QX,NW],null),VW=function VW(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return VW.m(arguments[0],1<c.length?new $CLJS.Hc(c.slice(1),0,null):null)};
VW.m=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.Q(null,1,5,$CLJS.R,[b],null):$CLJS.yf(b);b=$CLJS.A(c)?$CLJS.Xs(dY,c):null;try{return $CLJS.nd(b)?b.g?b.g(a):b.call(null,a):$CLJS.Ad(a)?a:$CLJS.xd(a)?$CLJS.Yf.h($CLJS.P,function(){return function g(f){return new $CLJS.te(null,function(){for(;;){var k=$CLJS.A(f);if(k){if($CLJS.Cd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),r=$CLJS.we(m);a:for(var v=0;;)if(v<m){var w=$CLJS.ld(l,v),y=$CLJS.I(w,0,null);w=$CLJS.I(w,1,null);y=NW(y);y=new $CLJS.Q(null,
2,5,$CLJS.R,[y,VW.m(w,$CLJS.H([$CLJS.ke.h($CLJS.yf(c),y)]))],null);r.add(y);v+=1}else{l=!0;break a}return l?$CLJS.ze($CLJS.Be(r),g($CLJS.lc(k))):$CLJS.ze($CLJS.Be(r),null)}l=$CLJS.B(k);r=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);r=NW(r);return $CLJS.ne(new $CLJS.Q(null,2,5,$CLJS.R,[r,VW.m(l,$CLJS.H([$CLJS.ke.h($CLJS.yf(c),r)]))],null),g($CLJS.Lc(k)))}return null}},null)}(a)}()):$CLJS.p(TW(a))?WX.g(a):$CLJS.wd(a)?$CLJS.kk.h(function(e){return VW.m(e,$CLJS.H([$CLJS.ke.h($CLJS.yf(c),CX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.Th($CLJS.HU("Error normalizing form: {0}",$CLJS.H([KW(d)])),new $CLJS.n(null,3,[KX,a,$CLJS.xj,c,SX,b],null),d);}throw e;}};VW.F=1;VW.G=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.m(b,a)};
var eX=function(){var a=$CLJS.yh.g($CLJS.P),b=$CLJS.yh.g($CLJS.P),c=$CLJS.yh.g($CLJS.P),d=$CLJS.yh.g($CLJS.P),e=$CLJS.K.j($CLJS.P,$CLJS.Ni,$CLJS.Gh.v?$CLJS.Gh.v():$CLJS.Gh.call(null));return new $CLJS.Qh($CLJS.ph.h("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.p(TW(f))?$CLJS.B(f):null},e,a,b,c,d)}();eX.P(null,$CLJS.Ph,function(a){return a});
eX.P(null,$CLJS.fN,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.p(UW($CLJS.fN,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return eX.g(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fN,c,$CLJS.Le($CLJS.sk.m($CLJS.H([b,a])))],null))}return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fN,b,$CLJS.Le(a)],null)});eX.P(null,JX,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.p(TW(a))?eX.g(a):new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fN,a,null],null)});
eX.P(null,PX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fN,b,new $CLJS.n(null,1,[$CLJS.kO,a],null)],null)});eX.P(null,UX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=fX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=fX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fN,a,$CLJS.Kg.j(c,$CLJS.$J,b)],null)});
eX.P(null,VX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=fX(a);return $CLJS.Ue.I($CLJS.nV,a,$CLJS.Kg,$CLJS.H([$CLJS.JM,b]))});
eX.P(null,NX,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=fX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.Ke(c);c=$CLJS.K.h(c,$CLJS.kO);return $CLJS.Ra(c)||$CLJS.FR.h(c,b)?$CLJS.Ue.I($CLJS.nV,a,$CLJS.Kg,$CLJS.H([$CLJS.ON,b])):a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),eX.g(new $CLJS.Q(null,3,5,$CLJS.R,[NX,b,c],null));default:throw Error(["No matching clause: ",
$CLJS.q.g(b)].join(""));}});eX.P(null,MX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=fX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.fN,b,$CLJS.Kg.j(e,$CLJS.aQ,$CLJS.sk.m($CLJS.H([new $CLJS.n(null,1,[$CLJS.UQ,c],null),$CLJS.p(d)?$CLJS.Je([c,d]):null,a])))],null)});
for(var eY=$CLJS.A(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yh,$CLJS.Bi,$CLJS.Pi],null)),fY=null,gY=0,hY=0;;)if(hY<gY){var iY=fY.ta(null,hY);eX.P(null,iY,function(){return function(a){return gX(a)}}(eY,fY,gY,hY,iY));hY+=1}else{var jY=$CLJS.A(eY);if(jY){var kY=jY;if($CLJS.Cd(kY)){var lY=$CLJS.kc(kY),mY=$CLJS.lc(kY),nY=lY,oY=$CLJS.E(lY);eY=mY;fY=nY;gY=oY}else{var pY=$CLJS.B(kY);eX.P(null,pY,function(){return function(a){return gX(a)}}(eY,fY,gY,hY,pY,kY,jY));eY=$CLJS.C(kY);fY=null;gY=0}hY=0}else break}
eX.P(null,$CLJS.eK,function(a){a=$CLJS.A(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Yf.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.eK,fX(a),fX(b)],null),c)});eX.P(null,$CLJS.NO,function(a){a=$CLJS.A(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=fX(a);a=$CLJS.p($CLJS.RU($CLJS.fN,a))?$CLJS.nV.m(c,$CLJS.ak,$CLJS.H([$CLJS.ON])):c;return $CLJS.Yf.h(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.NO,a],null),b)});
for(var qY=$CLJS.A(new $CLJS.Q(null,15,5,$CLJS.R,[$CLJS.XL,$CLJS.AO,$CLJS.ZM,$CLJS.$L,$CLJS.Dj,$CLJS.nP,$CLJS.Ej,$CLJS.Qj,$CLJS.Gj,$CLJS.Pj,$CLJS.zJ,$CLJS.oK,$CLJS.eJ,$CLJS.oL,$CLJS.yJ],null)),rY=null,sY=0,tY=0;;)if(tY<sY){var uY=rY.ta(null,tY);eX.P(null,uY,function(){return function(a){return hX(a)}}(qY,rY,sY,tY,uY));tY+=1}else{var vY=$CLJS.A(qY);if(vY){var wY=vY;if($CLJS.Cd(wY)){var xY=$CLJS.kc(wY),yY=$CLJS.lc(wY),zY=xY,AY=$CLJS.E(xY);qY=yY;rY=zY;sY=AY}else{var BY=$CLJS.B(wY);eX.P(null,BY,function(){return function(a){return hX(a)}}(qY,
rY,sY,tY,BY,wY,vY));qY=$CLJS.C(wY);rY=null;sY=0}tY=0}else break}eX.P(null,RX,function(){return null});eX.P(null,$CLJS.EI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.EI,eX.g(b),a],null)});
eX.P(null,GX,function(a){a=$CLJS.A(a);$CLJS.B(a);a=$CLJS.C(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=eX.g;var e=$CLJS.R;b=eX.g(b);d=$CLJS.I(d,0,null);d=$CLJS.Ke(d);a=!1===$CLJS.K.h(d,OX)?new $CLJS.n(null,1,[$CLJS.T,a],null):new $CLJS.n(null,1,[$CLJS.ZJ,a],null);return c.call(eX,new $CLJS.Q(null,3,5,e,[$CLJS.EI,b,a],null))});
for(var CY=$CLJS.A(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ry,$CLJS.jN],null)),DY=null,EY=0,FY=0;;)if(FY<EY){var GY=DY.ta(null,FY);eX.P(null,GY,function(){return function(a){return iX(a)}}(CY,DY,EY,FY,GY));FY+=1}else{var HY=$CLJS.A(CY);if(HY){var IY=HY;if($CLJS.Cd(IY)){var JY=$CLJS.kc(IY),KY=$CLJS.lc(IY),LY=JY,MY=$CLJS.E(JY);CY=KY;DY=LY;EY=MY}else{var NY=$CLJS.B(IY);eX.P(null,NY,function(){return function(a){return iX(a)}}(CY,DY,EY,FY,NY,IY,HY));CY=$CLJS.C(IY);DY=null;EY=0}FY=0}else break}
for(var OY=$CLJS.A(new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.lM,$CLJS.tQ,$CLJS.BJ,$CLJS.jP,$CLJS.TO,$CLJS.si,$CLJS.vj,$CLJS.YO,$CLJS.QJ],null)),PY=null,QY=0,RY=0;;)if(RY<QY){var SY=PY.ta(null,RY);eX.P(null,SY,function(){return function(a){return jX(a)}}(OY,PY,QY,RY,SY));RY+=1}else{var TY=$CLJS.A(OY);if(TY){var UY=TY;if($CLJS.Cd(UY)){var VY=$CLJS.kc(UY),WY=$CLJS.lc(UY),XY=VY,YY=$CLJS.E(VY);OY=WY;PY=XY;QY=YY}else{var ZY=$CLJS.B(UY);eX.P(null,ZY,function(){return function(a){return jX(a)}}(OY,PY,QY,RY,ZY,
UY,TY));OY=$CLJS.C(UY);PY=null;QY=0}RY=0}else break}eX.P(null,$CLJS.hL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.hL,fX(b),a],null)});
for(var $Y=$CLJS.A(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.QN,$CLJS.gO],null)),aZ=null,bZ=0,cZ=0;;)if(cZ<bZ){var dZ=aZ.ta(null,cZ);eX.P(null,dZ,function(){return function(a){return kX(a)}}($Y,aZ,bZ,cZ,dZ));cZ+=1}else{var eZ=$CLJS.A($Y);if(eZ){var fZ=eZ;if($CLJS.Cd(fZ)){var gZ=$CLJS.kc(fZ),hZ=$CLJS.lc(fZ),iZ=gZ,jZ=$CLJS.E(gZ);$Y=hZ;aZ=iZ;bZ=jZ}else{var lZ=$CLJS.B(fZ);eX.P(null,lZ,function(){return function(a){return kX(a)}}($Y,aZ,bZ,cZ,lZ,fZ,eZ));$Y=$CLJS.C(fZ);aZ=null;bZ=0}cZ=0}else break}
eX.P(null,$CLJS.YP,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YP,eX.g(b),eX.g(a)],null)});
eX.P(null,$CLJS.nK,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.p(a)?$CLJS.ke.h(eX.g(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nK,b],null)),VW.m(a,$CLJS.H([WW]))):new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.nK,$CLJS.yf(function(){return function e(d){return new $CLJS.te(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.Cd(f)){var g=$CLJS.kc(f),k=$CLJS.E(g),l=$CLJS.we(k);a:for(var m=0;;)if(m<k){var r=$CLJS.ld(g,m),v=$CLJS.I(r,0,null);r=$CLJS.I(r,1,null);v=new $CLJS.Q(null,
2,5,$CLJS.R,[eX.g(v),eX.g(r)],null);l.add(v);m+=1}else{g=!0;break a}return g?$CLJS.ze($CLJS.Be(l),e($CLJS.lc(f))):$CLJS.ze($CLJS.Be(l),null)}g=$CLJS.B(f);l=$CLJS.I(g,0,null);g=$CLJS.I(g,1,null);return $CLJS.ne(new $CLJS.Q(null,2,5,$CLJS.R,[eX.g(l),eX.g(g)],null),e($CLJS.Lc(f)))}return null}},null)}(b)}())],null)});
eX.P(null,$CLJS.qI,function(a){a=$CLJS.A(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.Yf.h(new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qI,eX.g(a),$CLJS.F.h(0,b)?1:eX.g(b)],null),$CLJS.ih.h(eX,c))});
var mX=function mX(a){return function f(d,e){try{if($CLJS.p(function(){var w=$CLJS.NU($CLJS.Fd);return w.g?w.g(e):w.call(null,e)}()))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(w){if(w instanceof Error)if(d=w,d===$CLJS.Z)try{if($CLJS.p(function(){var y=$CLJS.NU($CLJS.oe);return y.g?y.g(e):y.call(null,e)}()))return new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,1,5,$CLJS.R,[e],null)],null);throw $CLJS.Z;}catch(y){if(y instanceof Error)if(d=y,d===$CLJS.Z)try{if($CLJS.Bd(e)&&2<=$CLJS.E(e))try{var g=
$CLJS.Ff(e,0,2);if($CLJS.Bd(g)&&2===$CLJS.E(g))try{var k=$CLJS.G(g,0);if($CLJS.p($CLJS.hk.h($CLJS.oe,$CLJS.Ve(new $CLJS.S(null,new $CLJS.n(null,5,[$CLJS.Jj,null,$CLJS.kQ,null,$CLJS.fQ,null,$CLJS.Ij,null,GX,null],null),null)))(k)))try{var l=$CLJS.G(g,1);if($CLJS.p(bY(l)))return $CLJS.yf($CLJS.Yd($CLJS.ek,$CLJS.ih.h(mX,a)));throw $CLJS.Z;}catch(z){if(z instanceof Error){var m=z;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw z;}else throw $CLJS.Z;}catch(z){if(z instanceof Error){m=z;if(m===$CLJS.Z)throw $CLJS.Z;
throw m;}throw z;}else throw $CLJS.Z;}catch(z){if(z instanceof Error){m=z;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw z;}else throw $CLJS.Z;}catch(z){if(z instanceof Error)if(m=z,m===$CLJS.Z)try{if($CLJS.Bd(e)&&1<=$CLJS.E(e))try{var r=$CLJS.Ff(e,0,1);if($CLJS.Bd(r)&&1===$CLJS.E(r))try{if($CLJS.G(r,0)instanceof $CLJS.M)return new $CLJS.Q(null,1,5,$CLJS.R,[e],null);throw $CLJS.Z;}catch(D){if(D instanceof Error){var v=D;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw D;}else throw $CLJS.Z;}catch(D){if(D instanceof
Error){v=D;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw D;}else throw $CLJS.Z;}catch(D){if(D instanceof Error){v=D;if(v===$CLJS.Z)return e;throw v;}throw D;}else throw m;else throw z;}else throw d;else throw y;}else throw d;else throw w;}}($CLJS.xf,a)},mZ=function mZ(a){return function f(d,e){try{var g=$CLJS.NU($CLJS.Fd);var k=g.g?g.g(e):g.call(null,e);if($CLJS.p(k))return f(d,$CLJS.yf(e));throw $CLJS.Z;}catch(z){if(z instanceof Error)if(k=z,k===$CLJS.Z)try{if($CLJS.Bd(e)&&2===$CLJS.E(e))try{var l=
$CLJS.G(e,1);if($CLJS.O(l,$CLJS.wM)){var m=$CLJS.G(e,0);return f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wM,m],null))}throw $CLJS.Z;}catch(D){if(D instanceof Error)if(k=D,k===$CLJS.Z)try{l=$CLJS.G(e,1);if($CLJS.O(l,$CLJS.SL))return m=$CLJS.G(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SL,m],null));throw $CLJS.Z;}catch(N){if(N instanceof Error){var r=N;if(r===$CLJS.Z)try{l=$CLJS.G(e,1);if($CLJS.O(l,FX))return m=$CLJS.G(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wM,m],null));throw $CLJS.Z;}catch(V){if(V instanceof
Error)if(k=V,k===$CLJS.Z)try{l=$CLJS.G(e,1);if($CLJS.O(l,LX))return m=$CLJS.G(e,0),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SL,m],null));throw $CLJS.Z;}catch(W){if(W instanceof Error)if(l=W,l===$CLJS.Z)try{var v=$CLJS.G(e,0);if($CLJS.O(v,FX))return m=$CLJS.G(e,1),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wM,m],null));throw $CLJS.Z;}catch(da){if(da instanceof Error)if(l=da,l===$CLJS.Z)try{v=$CLJS.G(e,0);if($CLJS.O(v,LX))return m=$CLJS.G(e,1),f(d,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SL,m],null));throw $CLJS.Z;
}catch(qa){if(qa instanceof Error)if(l=qa,l===$CLJS.Z)try{v=$CLJS.G(e,0);if($CLJS.O(v,$CLJS.wM))return m=$CLJS.G(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.wM,fX(m)],null);throw $CLJS.Z;}catch(ua){if(ua instanceof Error)if(l=ua,l===$CLJS.Z)try{v=$CLJS.G(e,0);if($CLJS.O(v,$CLJS.SL))return m=$CLJS.G(e,1),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.SL,fX(m)],null);throw $CLJS.Z;}catch(Ka){if(Ka instanceof Error){m=Ka;if(m===$CLJS.Z)throw $CLJS.Z;throw m;}throw Ka;}else throw l;else throw ua;}else throw l;else throw qa;
}else throw l;else throw da;}else throw l;else throw W;}else throw k;else throw V;}else throw r;}else throw N;}else throw k;else throw D;}else throw $CLJS.Z;}catch(D){if(D instanceof Error)if(k=D,k===$CLJS.Z)try{if($CLJS.Bd(e)&&0<=$CLJS.E(e))try{var w=$CLJS.Ff(e,0,0);if($CLJS.Bd(w)&&0===$CLJS.E(w))try{var y=$CLJS.Ks(e,0);if($CLJS.F.h(y,a))return $CLJS.yf($CLJS.FC.g($CLJS.ih.h(mZ,a)));throw $CLJS.Z;}catch(N){if(N instanceof Error){r=N;if(r===$CLJS.Z)throw $CLJS.Z;throw r;}throw N;}else throw $CLJS.Z;
}catch(N){if(N instanceof Error){r=N;if(r===$CLJS.Z)throw $CLJS.Z;throw r;}throw N;}else throw $CLJS.Z;}catch(N){if(N instanceof Error){r=N;if(r===$CLJS.Z)return $CLJS.PU(f,d,e);throw r;}throw N;}else throw k;else throw D;}else throw k;else throw z;}}($CLJS.xf,a)},sX=$CLJS.gk.h(LW,function(a){var b=uX($CLJS.LL.g(a))?$CLJS.iC.j(a,$CLJS.LL,nX):a;b=uX($CLJS.zL.g(a))?$CLJS.iC.j(b,$CLJS.zL,oX):b;b=uX($CLJS.$I.g(a))?$CLJS.iC.j(b,$CLJS.$I,$CLJS.Xe($CLJS.kk,dX)):b;b=uX($CLJS.oQ.g(a))?$CLJS.iC.j(b,$CLJS.oQ,
mZ):b;return uX($CLJS.PO.g(a))?$CLJS.iC.j(b,$CLJS.PO,tX):b}),nZ=new $CLJS.n(null,3,[$CLJS.VN,$CLJS.ae,$CLJS.PI,new $CLJS.n(null,2,[$CLJS.PO,function(a){a=$CLJS.Ke(a);var b=$CLJS.K.h(a,$CLJS.VN);if($CLJS.p(b))return a=$CLJS.MH(a,new $CLJS.n(null,1,[$CLJS.VN,$CLJS.PI],null)),b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.VN],null),a=AX.h?AX.h(a,b):AX.call(null,a,b),$CLJS.MH(a,new $CLJS.n(null,1,[$CLJS.PI,$CLJS.VN],null));b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.PI],null);return AX.h?AX.h(a,b):AX.call(null,a,b)},
$CLJS.XJ,new $CLJS.n(null,1,[CX,function(a){var b=new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.PI],null);return AX.h?AX.h(a,b):AX.call(null,a,b)}],null)],null),QX,$CLJS.ae],null),AX=function AX(a){switch(arguments.length){case 1:return AX.g(arguments[0]);case 2:return AX.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.q.g(arguments.length)].join(""));}};AX.g=function(a){return AX.h(a,$CLJS.xf)};
AX.h=function(a,b){try{var c=$CLJS.A(b)?$CLJS.Xs(nZ,b):null;return $CLJS.nd(c)?c.g?c.g(a):c.call(null,a):$CLJS.Ad(a)?a:$CLJS.xd(a)?BX(a,b):$CLJS.wd(a)?DX(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.Th("Error removing empty clauses from form.",new $CLJS.n(null,2,[KX,a,$CLJS.xj,b],null),d);throw d;}};AX.F=2;
$CLJS.oZ=function(){var a=$CLJS.gk.m(AX,zX,wX,$CLJS.H([VW]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.Th($CLJS.HU("Error normalizing query: {0}",$CLJS.H([KW(c)])),new $CLJS.n(null,1,[$CLJS.PI,b],null),c);}throw d;}}}();