var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");require("./clojure.set.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./schema.core.js");
'use strict';var JH,KH,LH,NH,PH,QH,RH,SH,UH,VH,WH,XH,$H,aI,bI,cI,dI,fI,gI,nI,oI,pI,sI,tI,uI,vI,wI,xI,yI,zI,AI,BI,CI,DI,FI,GI,HI,II,JI,KI,LI,MI,NI,OI,QI,RI,SI,TI,UI,VI,WI,XI,YI,ZI,bJ,cJ,dJ,fJ,gJ,hJ,iJ,jJ,kJ,lJ,mJ,nJ,rJ,sJ,tJ,uJ,wJ,xJ,CJ,DJ,EJ,FJ,HJ,IJ,KJ,LJ,MJ,NJ,OJ,PJ,UJ,VJ,WJ,YJ,aK,bK,cK,dK,fK,gK,hK,iK,jK,kK,mK,pK,qK,rK,sK,tK,wK,xK,yK,AK,BK,CK,DK,EK,FK,GK,HK,IK,JK,LK,MK,NK,PK,QK,RK,SK,TK,UK,VK,WK,YK,ZK,$K,aL,bL,cL,eL,iL,jL,kL,lL,mL,nL,pL,qL,rL,sL,uL,vL,wL,xL,yL,AL,BL,CL,DL,EL,GL,HL,IL,JL,KL,OL,PL,
RL,TL,UL,VL,WL,ZL,aM,bM,cM,dM,eM,fM,gM,iM,jM,kM,mM,nM,oM,pM,qM,rM,sM,Y,tM,uM,vM,xM,yM,zM,AM,BM,CM,DM,EM,FM,GM,HM,IM,KM,MM,NM,PM,QM,RM,SM,TM,VM,WM,XM,YM,$M,aN,bN,cN,dN,eN,gN,hN,iN,kN,lN,mN,nN,oN,pN,qN,rN,sN,tN,uN,vN,wN,xN,yN,zN,AN,BN,CN,DN,EN,FN,GN,HN,IN,JN,LN,MN,NN,PN,RN,SN,TN,UN,WN,XN,ZN,$N,aO,cO,eO,fO,hO,iO,lO,mO,nO,oO,pO,qO,rO,sO,tO,vO,wO,xO,yO,zO,BO,CO,DO,EO,GO,HO,IO,JO,KO,MO,OO,QO,RO,SO,UO,VO,WO,XO,ZO,$O,bP,cP,dP,fP,gP,hP,iP,kP,lP,mP,oP,pP,qP,rP,sP,tP,uP,vP,wP,xP,yP,zP,AP,BP,CP,DP,EP,FP,GP,IP,
JP,KP,LP,NP,OP,PP,QP,RP,SP,TP,UP,VP,WP,XP,ZP,$P,bQ,cQ,dQ,eQ,gQ,hQ,iQ,jQ,lQ,mQ,nQ,pQ,qQ,rQ,sQ,uQ,vQ,wQ,xQ,yQ,zQ,AQ,BQ,CQ,DQ,EQ,GQ,HQ,IQ,JQ,KQ,LQ,QQ,RQ,SQ,TQ,VQ,WQ,XQ,YQ,ZQ,$Q,aR,cR,eR;JH=function(a,b){if(null!=a&&null!=a.Xe)a=a.Xe(a,b);else{var c=JH[$CLJS.sa(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=JH._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Xa("ISet.-disjoin",a);}return a};
KH=function(a,b){if(null!=a&&null!=a.Vc)a=a.Vc(a,b);else{var c=KH[$CLJS.sa(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=KH._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Xa("CoreSpec.checker",a);}return a};LH=function(a,b){var c=new $CLJS.n(null,3,[$CLJS.nF,a,$CLJS.qF,!1,$CLJS.oF,$CLJS.yh.g($CLJS.P)],null);return a.h?a.h(b,c):a.call(null,b,c)};
$CLJS.MH=function(a,b){return $CLJS.$a(function(c,d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.Kd(a,e)?$CLJS.Kg.j(c,d,$CLJS.K.h(a,e)):c},$CLJS.Ue.j($CLJS.ak,a,$CLJS.Fg(b)),b)};NH=function(a,b,c){return new $CLJS.te(null,function(){var d=$CLJS.A(c);if(d){var e=$CLJS.au(a,d);return a===$CLJS.E(e)?$CLJS.ne(e,NH(a,b,$CLJS.ws(b,d))):null}return null},null)};$CLJS.OH=function(a,b){return new $CLJS.xG(a,!1,b,null,null,null)};PH=function(a){return new $CLJS.iG(a,null,null,null)};
QH=function(a,b,c,d){this.oc=a;this.A=b;this.l=c;this.C=d;this.o=2230716170;this.H=139264};RH=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return RH.m(0<b.length?new $CLJS.Hc(b.slice(0),0,null):null)};SH=function(a,b,c,d,e){this.Pb=a;this.Sb=b;this.A=c;this.l=d;this.C=e;this.o=2230716170;this.H=139264};UH=function(a,b,c){return TH.m($CLJS.H([a,b,$CLJS.We(!0),c]))};VH=function(a,b,c,d){this.ac=a;this.A=b;this.l=c;this.C=d;this.o=2230716170;this.H=139264};
WH=function(a){if(!(null!=a?a.o&32768||$CLJS.u===a.Ue||(a.o?0:$CLJS.Va($CLJS.Qt,a)):$CLJS.Va($CLJS.Qt,a)))throw Error($CLJS.TE("Not an IDeref: %s",$CLJS.H([a])));return new VH(a,null,null,null)};XH=function(a,b,c,d){this.X=a;this.A=b;this.l=c;this.C=d;this.o=2230716170;this.H=139264};$CLJS.YH=function(a){return new XH(a,null,null,null)};$CLJS.ZH=function(a){return $CLJS.gk.h($CLJS.hF,LH(function(b,c){return KH($CLJS.UF(b),c)},a))};
$H=function(a,b){if($CLJS.Bd(b)){var c=$CLJS.I(b,0,null),d=$CLJS.I(b,1,null);switch(c instanceof $CLJS.M?c.Y:null){case "optional":return new $CLJS.xG($CLJS.YH(d),!0,a,null,null,null);case "rest":return $CLJS.dG(d,a);default:return $CLJS.OH(b,a)}}else return $CLJS.OH(b,a)};
aI=function(a,b){return $CLJS.yf($CLJS.ne($CLJS.OH($CLJS.$F(a),a),function(){return function e(d){return new $CLJS.te(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.Cd(f)){var g=$CLJS.kc(f),k=$CLJS.E(g),l=$CLJS.we(k);a:for(var m=0;;)if(m<k){var r=$CLJS.ld(g,m),v=$CLJS.I(r,0,null);r=$CLJS.I(r,1,null);v=$H(v,r);l.add(v);m+=1}else{g=!0;break a}return g?$CLJS.ze($CLJS.Be(l),e($CLJS.lc(f))):$CLJS.ze($CLJS.Be(l),null)}g=$CLJS.B(f);l=$CLJS.I(g,0,null);g=$CLJS.I(g,1,null);return $CLJS.ne($H(l,g),
e($CLJS.Lc(f)))}return null}},null)}(NH(2,2,b))}()))};bI=function(a,b){var c=$CLJS.wd(b);return c?(c=$CLJS.B(b)instanceof $CLJS.M)?$CLJS.td(a)?(b=$CLJS.B(b),a=$CLJS.Qg(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,$CLJS.B(b)):c:c};
cI=function(a){return $CLJS.dG($CLJS.Ue.h(TH,$CLJS.Yd($CLJS.ek,function(){return function d(c){return new $CLJS.te(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),k=$CLJS.we(g);a:for(var l=0;;)if(l<g){var m=$CLJS.ld(f,l),r=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);k.add(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Xe(bI,r),m],null));l+=1}else{f=!0;break a}return f?$CLJS.ze($CLJS.Be(k),d($CLJS.lc(e))):$CLJS.ze($CLJS.Be(k),null)}f=$CLJS.B(e);k=$CLJS.I(f,0,null);f=$CLJS.I(f,
1,null);return $CLJS.ne(new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Xe(bI,k),f],null),d($CLJS.Lc(e)))}return null}},null)}(a)}())),["Must be a valid instance of one of these clauses: ",$CLJS.Ws(", ",$CLJS.ih.h($CLJS.B,a))].join(""))};dI=function(a){return $CLJS.fG(a,$CLJS.A,"Non-empty")};fI=function(a){return $CLJS.A(a)?$CLJS.Ue.h($CLJS.eI,a):!0};gI=function(a){return"string"===typeof a?$CLJS.Jg.h(Date.parse(a),NaN):null};
$CLJS.sF.prototype.Vc=$CLJS.ra(34,function(){var a=this;return function(b){var c=a.ka.g?a.ka.g(b):a.ka.call(null,b);return $CLJS.p(c)?c:b}});
$CLJS.yF.prototype.Vc=$CLJS.ra(33,function(a,b){var c=this,d=this,e=$CLJS.$a(function(f,g){return $CLJS.xF(g,b,f)},function(f){return $CLJS.fF($CLJS.aF(d,f,new $CLJS.th(function(){var g=$CLJS.WE(f);return c.Jb.g?c.Jb.g(g):c.Jb.call(null,g)}),null))},$CLJS.le(c.options));return $CLJS.p(c.Fb)?function(f){var g=c.ka.g?c.ka.g(f):c.ka.call(null,f);if($CLJS.p(g))return g;g=e.g?e.g(f):e.call(null,f);if($CLJS.gF(g))return g;f=$CLJS.p($CLJS.qF.g(b))?g:f;f=c.Fb.g?c.Fb.g(f):c.Fb.call(null,f);return $CLJS.p(f)?
f:g}:function(f){var g=c.ka.g?c.ka.g(f):c.ka.call(null,f);return $CLJS.p(g)?g:e.g?e.g(f):e.call(null,f)}});
$CLJS.HF.prototype.Vc=$CLJS.ra(32,function(a,b){var c=this,d=$CLJS.p($CLJS.qF.g(b))?c.Ub:function(){return null},e=$CLJS.EF(c.elements,b,function(f,g){return g});return function(f){var g=c.ka.g?c.ka.g(f):c.ka.call(null,f);if($CLJS.p(g))return g;var k=$CLJS.yh.g($CLJS.xf);g=e.h?e.h(k,f):e.call(null,k,f);k=$CLJS.t(k);var l=(l=$CLJS.A(g))?l:$CLJS.Re($CLJS.gF,k);return $CLJS.p(l)?$CLJS.fF(c.Kb.j?c.Kb.j(f,k,g):c.Kb.call(null,f,k,g)):d.g?d.g(k):d.call(null,k)}});
$CLJS.S.prototype.Xe=$CLJS.ra(9,function(a,b){return new $CLJS.S(this.S,$CLJS.Bb(this.zc,b),null)});var hI={},iI={},jI={},kI=function kI(a){switch(arguments.length){case 1:return kI.g(arguments[0]);case 2:return kI.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return kI.m(arguments[0],arguments[1],new $CLJS.Hc(c.slice(2),0,null))}};kI.g=function(a){return a};kI.h=function(a,b){return null==a?null:JH(a,b)};
kI.m=function(a,b,c){for(;;){if(null==a)return null;a=kI.h(a,b);if($CLJS.p(c))b=$CLJS.B(c),c=$CLJS.C(c);else return a}};kI.G=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.m(b,a,c)};kI.F=2;
var lI=function lI(a){switch(arguments.length){case 1:return lI.g(arguments[0]);case 2:return lI.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return lI.m(arguments[0],arguments[1],new $CLJS.Hc(c.slice(2),0,null))}};lI.g=function(a){return a};lI.h=function(a,b){for(;;)if($CLJS.E(b)<$CLJS.E(a)){var c=a;a=b;b=c}else return $CLJS.$a(function(d,e){return function(f,g){return $CLJS.Kd(e,g)?f:kI.h(f,g)}}(a,b),a,a)};
lI.m=function(a,b,c){a=$CLJS.wC(function(d){return-$CLJS.E(d)},$CLJS.ke.m(c,b,$CLJS.H([a])));return $CLJS.$a(lI,$CLJS.B(a),$CLJS.Lc(a))};lI.G=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.m(b,a,c)};lI.F=2;$CLJS.h=QH.prototype;$CLJS.h.la=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "vs":return this.oc;default:return $CLJS.K.j(this.l,b,c)}};
$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#schema.core.EnumSchema{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.TG,this.oc],null)],null),this.l))};
$CLJS.h.na=function(){return new $CLJS.Of(this,1,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.TG],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};$CLJS.h.ga=function(){return 1+$CLJS.E(this.l)};$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=2002701468^$CLJS.Qc(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.oc,b.oc)&&$CLJS.F.h(this.l,b.l)};
$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.TG,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new QH(this.oc,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "vs":return!0;default:return $CLJS.Kd(this.l,b)}};
$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.TG,b):$CLJS.O.call(null,$CLJS.TG,b))?new QH(c,this.A,this.l,null):new QH(this.oc,this.A,$CLJS.Kg.j(this.l,b,c),null)};$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Ld($CLJS.TG,this.oc)],null),this.l))};$CLJS.h.M=function(a,b){return new QH(this.oc,b,this.l,this.C)};$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};
$CLJS.h.rb=function(){var a=this;return $CLJS.tF($CLJS.jF(this,function(b){return $CLJS.Kd(a.oc,b)},function(b){return new $CLJS.he(null,a.oc,new $CLJS.he(null,b,null,1,null),2,null)}))};$CLJS.h.za=function(){return $CLJS.ne($CLJS.mH,this.oc)};$CLJS.h.pa=$CLJS.u;$CLJS.h.W=function(a,b){return $CLJS.ac(b,this.za(null))};RH.m=function(a){return new QH($CLJS.Qg(a),null,null,null)};RH.F=0;RH.G=function(a){return this.m($CLJS.A(a))};$CLJS.h=SH.prototype;$CLJS.h.la=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "preds-and-schemas":return this.Pb;case "error-symbol":return this.Sb;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#schema.core.ConditionalSchema{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aH,this.Pb],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.VG,this.Sb],null)],null),this.l))};$CLJS.h.na=function(){return new $CLJS.Of(this,2,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aH,$CLJS.VG],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};
$CLJS.h.ga=function(){return 2+$CLJS.E(this.l)};$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=1418435858^$CLJS.Qc(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Pb,b.Pb)&&$CLJS.F.h(this.Sb,b.Sb)&&$CLJS.F.h(this.l,b.l)};
$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,2,[$CLJS.aH,null,$CLJS.VG,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new SH(this.Pb,this.Sb,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "preds-and-schemas":case "error-symbol":return!0;default:return $CLJS.Kd(this.l,b)}};
$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.aH,b):$CLJS.O.call(null,$CLJS.aH,b))?new SH(c,this.Sb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.VG,b):$CLJS.O.call(null,$CLJS.VG,b))?new SH(this.Pb,c,this.A,this.l,null):new SH(this.Pb,this.Sb,this.A,$CLJS.Kg.j(this.l,b,c),null)};$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Ld($CLJS.aH,this.Pb),new $CLJS.Ld($CLJS.VG,this.Sb)],null),this.l))};
$CLJS.h.M=function(a,b){return new SH(this.Pb,this.Sb,b,this.l,this.C)};$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};
$CLJS.h.rb=function(){var a=this;return $CLJS.zF($CLJS.SF,function(){return function d(c){return new $CLJS.te(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),k=$CLJS.we(g);a:for(var l=0;;)if(l<g){var m=$CLJS.ld(f,l),r=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);k.add(new $CLJS.n(null,2,[$CLJS.uF,r,$CLJS.zj,m],null));l+=1}else{f=!0;break a}return f?$CLJS.ze($CLJS.Be(k),d($CLJS.lc(e))):$CLJS.ze($CLJS.Be(k),null)}f=$CLJS.B(e);k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,
null);return $CLJS.ne(new $CLJS.n(null,2,[$CLJS.uF,k,$CLJS.zj,f],null),d($CLJS.Lc(e)))}return null}},null)}(a.Pb)}(),function(b){var c=a.Sb;c=$CLJS.p(c)?c:$CLJS.F.h(1,$CLJS.E(a.Pb))?$CLJS.ph.g($CLJS.XE($CLJS.B($CLJS.B(a.Pb)))):$CLJS.AG;return new $CLJS.he(null,c,new $CLJS.he(null,b,null,1,null),2,null)})};
$CLJS.h.za=function(){return $CLJS.ne($CLJS.BG,$CLJS.ek.h($CLJS.xs(function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ph.g($CLJS.XE(b)),$CLJS.VF(a)],null)},$CLJS.H([this.Pb])),$CLJS.p(this.Sb)?new $CLJS.Q(null,1,5,$CLJS.R,[this.Sb],null):null))};$CLJS.h.pa=$CLJS.u;$CLJS.h.W=function(a,b){return $CLJS.ac(b,this.za(null))};
var TH=function TH(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return TH.m(0<c.length?new $CLJS.Hc(c.slice(0),0,null):null)};
TH.m=function(a){if(!$CLJS.A(a)||!($CLJS.Te($CLJS.E(a))||$CLJS.id(a)instanceof $CLJS.x))throw Error($CLJS.TE("Expected even, nonzero number of args (with optional trailing symbol); got %s",$CLJS.H([$CLJS.E(a)])));return new SH($CLJS.yf(function(){return function d(c){return new $CLJS.te(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),k=$CLJS.we(g);a:for(var l=0;;)if(l<g){var m=$CLJS.ld(f,l),r=$CLJS.I(m,0,null),v=$CLJS.I(m,1,null);m=k;if(!$CLJS.Hd(r))throw Error($CLJS.UE(["Conditional predicate ",
$CLJS.q.g(r)," must be a function"].join("")));r=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.F.h(r,$CLJS.Es)?$CLJS.We(!0):r,v],null);m.add(r);l+=1}else{f=!0;break a}return f?$CLJS.ze($CLJS.Be(k),d($CLJS.lc(e))):$CLJS.ze($CLJS.Be(k),null)}f=$CLJS.B(e);k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);if(!$CLJS.Hd(k))throw Error($CLJS.UE(["Conditional predicate ",$CLJS.q.g(k)," must be a function"].join("")));k=new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.F.h(k,$CLJS.Es)?$CLJS.We(!0):k,f],null);return $CLJS.ne(k,d($CLJS.Lc(e)))}return null}},
null)}(NH(2,2,a))}()),$CLJS.Te($CLJS.E(a))?null:$CLJS.id(a),null,null,null)};TH.F=0;TH.G=function(a){return this.m($CLJS.A(a))};$CLJS.h=VH.prototype;$CLJS.h.la=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "derefable":return this.ac;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#schema.core.Recursive{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.eH,this.ac],null)],null),this.l))};$CLJS.h.na=function(){return new $CLJS.Of(this,1,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.eH],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};$CLJS.h.ga=function(){return 1+$CLJS.E(this.l)};
$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=1521498755^$CLJS.Qc(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ac,b.ac)&&$CLJS.F.h(this.l,b.l)};$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.eH,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new VH(this.ac,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "derefable":return!0;default:return $CLJS.Kd(this.l,b)}};$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.eH,b):$CLJS.O.call(null,$CLJS.eH,b))?new VH(c,this.A,this.l,null):new VH(this.ac,this.A,$CLJS.Kg.j(this.l,b,c),null)};$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Ld($CLJS.eH,this.ac)],null),this.l))};$CLJS.h.M=function(a,b){return new VH(this.ac,b,this.l,this.C)};
$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};$CLJS.h.rb=function(){return $CLJS.AF($CLJS.SF,new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.n(null,1,[$CLJS.zj,$CLJS.t(this.ac)],null)],null))};
$CLJS.h.za=function(){if(this.ac instanceof $CLJS.Fc){var a=$CLJS.pd(this.ac);var b=$CLJS.Ke(a);a=$CLJS.K.h(b,$CLJS.Di);b=$CLJS.K.h(b,$CLJS.T);a=$CLJS.ph.g([$CLJS.q.g(a),"/",$CLJS.q.g(b)].join(""));a=new $CLJS.he(null,$CLJS.mj,new $CLJS.he(null,a,null,1,null),2,null)}else a=$CLJS.oH;return new $CLJS.he(null,$CLJS.XG,new $CLJS.he(null,a,null,1,null),2,null)};$CLJS.h.pa=$CLJS.u;$CLJS.h.W=function(a,b){return $CLJS.ac(b,this.za(null))};$CLJS.h=XH.prototype;
$CLJS.h.la=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "schema":return this.X;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#schema.core.Maybe{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.zj,this.X],null)],null),this.l))};$CLJS.h.na=function(){return new $CLJS.Of(this,1,new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.zj],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};$CLJS.h.ga=function(){return 1+$CLJS.E(this.l)};
$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=-805411239^$CLJS.Qc(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.X,b.X)&&$CLJS.F.h(this.l,b.l)};$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.zj,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new XH(this.X,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "schema":return!0;default:return $CLJS.Kd(this.l,b)}};$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.zj,b):$CLJS.O.call(null,$CLJS.zj,b))?new XH(c,this.A,this.l,null):new XH(this.X,this.A,$CLJS.Kg.j(this.l,b,c),null)};$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Ld($CLJS.zj,this.X)],null),this.l))};$CLJS.h.M=function(a,b){return new XH(this.X,b,this.l,this.C)};
$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};$CLJS.h.rb=function(){return $CLJS.AF($CLJS.SF,new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.n(null,2,[$CLJS.uF,$CLJS.Oa,$CLJS.zj,$CLJS.$F(null)],null),new $CLJS.n(null,1,[$CLJS.zj,this.X],null)],null))};$CLJS.h.za=function(){return new $CLJS.he(null,$CLJS.YG,new $CLJS.he(null,$CLJS.VF(this.X),null,1,null),2,null)};$CLJS.h.pa=$CLJS.u;$CLJS.h.W=function(a,b){return $CLJS.ac(b,this.za(null))};
$CLJS.eI=function eI(a){switch(arguments.length){case 1:return eI.g(arguments[0]);case 2:return eI.h(arguments[0],arguments[1]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return eI.m(arguments[0],arguments[1],new $CLJS.Hc(c.slice(2),0,null))}};$CLJS.eI.g=function(){return!0};$CLJS.eI.h=function(a,b){return!$CLJS.F.h(a,b)};
$CLJS.eI.m=function(a,b,c){if($CLJS.F.h(a,b))return!1;a=$CLJS.Og([a,b]);for(b=c;;){var d=$CLJS.B(b);c=$CLJS.C(b);if($CLJS.p(b)){if($CLJS.Kd(a,d))return!1;a=$CLJS.ke.h(a,d);b=c}else return!0}};$CLJS.eI.G=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.m(b,a,c)};$CLJS.eI.F=2;$CLJS.mI=new $CLJS.M(null,"get-month","get-month",-369374731);nI=new $CLJS.M(null,"exp","exp",-261706262);
oI=new $CLJS.x("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);pI=new $CLJS.M(null,"pattern","pattern",242135423);$CLJS.qI=new $CLJS.M(null,"substring","substring",1140866276);$CLJS.rI=new $CLJS.M(null,"dimension","dimension",543254198);sI=new $CLJS.x("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);tI=new $CLJS.M(null,"right-join","right-join",-56349359);
uI=new $CLJS.x("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);vI=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);wI=new $CLJS.M(null,"query-hash","query-hash",-1524484965);xI=new $CLJS.M(null,"from","from",1815293044);yI=new $CLJS.x(null,"does-not-contain","does-not-contain",1365584674,null);zI=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);
AI=new $CLJS.M(null,"snippet-name","snippet-name",819240328);BI=new $CLJS.x("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);CI=new $CLJS.x("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);DI=new $CLJS.M(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.EI=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);FI=new $CLJS.x("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);
GI=new $CLJS.M("location","zip_code","location/zip_code",1641155222);HI=new $CLJS.x(null,"ExpressionArg","ExpressionArg",1060743736,null);II=new $CLJS.x(null,"get-hour","get-hour",-2031721710,null);JI=new $CLJS.x(null,"ag:var","ag:var",-1804440286,null);KI=new $CLJS.M(null,"lon-max","lon-max",1590224717);LI=new $CLJS.M(null,"ceil","ceil",-1824929952);MI=new $CLJS.x(null,"stddev","stddev",775056588,null);NI=new $CLJS.M(null,"snippet","snippet",953581994);
OI=new $CLJS.x("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.PI=new $CLJS.M(null,"query","query",-1288509510);QI=new $CLJS.M(null,"power","power",-937852079);RI=new $CLJS.M(null,"segment","segment",-964921196);SI=new $CLJS.M(null,"concat","concat",-2108183992);TI=new $CLJS.M(null,"lat-field","lat-field",-830652957);UI=new $CLJS.M(null,"second","second",-444702010);VI=new $CLJS.x("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);
WI=new $CLJS.x(null,"FieldOptions","FieldOptions",-1536320891,null);XI=new $CLJS.M("location","country","location/country",1666636202);YI=new $CLJS.M(null,"abs","abs",-246026477);ZI=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);$CLJS.$I=new $CLJS.M(null,"fields","fields",-1932066230);$CLJS.aJ=new $CLJS.M(null,"iso","iso",-1366207543);bJ=new $CLJS.x("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",-1908264889,null);
cJ=new $CLJS.x("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);dJ=new $CLJS.M(null,"unary","unary",-989314568);$CLJS.eJ=new $CLJS.M(null,"is-null","is-null",-1997050930);fJ=new $CLJS.x(null,"ceil","ceil",-184398425,null);gJ=new $CLJS.M(null,"lon-min","lon-min",-787291357);hJ=new $CLJS.M(null,"match","match",1220059550);iJ=new $CLJS.x(null,"count-where","count-where",2025939247,null);jJ=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);
kJ=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);lJ=new $CLJS.x(null,"sum","sum",1777518341,null);mJ=new $CLJS.M(null,"num-bins","num-bins",847672055);nJ=new $CLJS.x("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);$CLJS.oJ=new $CLJS.M("date","range","date/range",1647265776);$CLJS.pJ=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.qJ=new $CLJS.M(null,"get-year","get-year",-936011274);
rJ=new $CLJS.x(null,"between","between",-1523336493,null);sJ=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);tJ=new $CLJS.M(null,"clause-form","clause-form",1820463737);uJ=new $CLJS.x("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);$CLJS.vJ=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);wJ=new $CLJS.x(null,"field","field",338095027,null);xJ=new $CLJS.M(null,"segment-id","segment-id",1810133590);
$CLJS.yJ=new $CLJS.M(null,"between","between",1131099276);$CLJS.zJ=new $CLJS.M(null,"is-empty","is-empty",-1040302908);$CLJS.AJ=new $CLJS.M(null,"filter","filter",-948537934);$CLJS.BJ=new $CLJS.M(null,"distinct","distinct",-1788879121);CJ=new $CLJS.x(null,"not-null","not-null",313812992,null);DJ=new $CLJS.M(null,"template-tag","template-tag",310841038);EJ=new $CLJS.x("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);
FJ=new $CLJS.x(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.GJ=new $CLJS.M(null,"context","context",-830191113);HJ=new $CLJS.x(null,"get-year","get-year",704520253,null);IJ=new $CLJS.M(null,"format-rows?","format-rows?",992129486);$CLJS.JJ=new $CLJS.M(null,"parameters","parameters",-1229919748);KJ=new $CLJS.M(null,"json-download","json-download",-971130133);LJ=new $CLJS.M(null,"card","card",-1430355152);MJ=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);
NJ=new $CLJS.x(null,"!\x3d","!\x3d",-201205829,null);OJ=new $CLJS.x("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);PJ=new $CLJS.x("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);$CLJS.QJ=new $CLJS.M(null,"var","var",-769682797);$CLJS.RJ=new $CLJS.M(null,"next","next",-117701485);$CLJS.SJ=new $CLJS.x(null,"expression","expression",1842843403,null);$CLJS.TJ=new $CLJS.M("date","month-year","date/month-year",1948031290);
UJ=new $CLJS.x(null,"ends-with","ends-with",1183740516,null);VJ=new $CLJS.x("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-469303836,null);WJ=new $CLJS.x("s","recursive","s/recursive",-1935549792,null);$CLJS.XJ=new $CLJS.M(null,"joins","joins",1033962699);YJ=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.ZJ=new $CLJS.M(null,"display-name","display-name",694513143);$CLJS.$J=new $CLJS.M(null,"source-field","source-field",933829534);
aK=new $CLJS.x(null,"Field","Field",430385967,null);bK=new $CLJS.x(null,"convert-timezone","convert-timezone",1515728526,null);cK=new $CLJS.x("helpers","IntGreaterThanZero","helpers/IntGreaterThanZero",1743901629,null);dK=new $CLJS.x("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);$CLJS.eK=new $CLJS.M(null,"inside","inside",1972503011);fK=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);
gK=new $CLJS.M(null,"items","items",1031954938);hK=new $CLJS.M(null,"more","more",-2058821800);iK=new $CLJS.M(null,"first-clause","first-clause",-20953491);jK=new $CLJS.x(null,"OrderComparable","OrderComparable",772072595,null);kK=new $CLJS.x("metabase.mbql.schema","NumericExpression*","metabase.mbql.schema/NumericExpression*",-254856038,null);$CLJS.lK=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);
mK=new $CLJS.x("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);$CLJS.nK=new $CLJS.M(null,"case","case",1143702196);$CLJS.oK=new $CLJS.M(null,"not-empty","not-empty",388922063);pK=new $CLJS.x(null,"contains","contains",-1977535957,null);qK=new $CLJS.x("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);rK=new $CLJS.x("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);
sK=new $CLJS.x(null,"BooleanExpression","BooleanExpression",1027887421,null);tK=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.uK=new $CLJS.M(null,"widget-type","widget-type",1836256899);$CLJS.vK=new $CLJS.M(null,"fingerprint","fingerprint",598613022);wK=new $CLJS.M(null,"millisecond","millisecond",-540123566);xK=new $CLJS.x(null,"is-null","is-null",-356519403,null);yK=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);
$CLJS.zK=new $CLJS.M(null,"year-of-era","year-of-era",682445876);AK=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);BK=new $CLJS.M(null,"required","required",1807647006);CK=new $CLJS.x(null,"Filter*","Filter*",-1193205185,null);DK=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);EK=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);FK=new $CLJS.x(null,"CaseClauses","CaseClauses",-1749071354,null);GK=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);
HK=new $CLJS.M("string","contains","string/contains",1602423827);IK=new $CLJS.x("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);JK=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);$CLJS.KK=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);LK=new $CLJS.x(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);MK=new $CLJS.x("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
NK=new $CLJS.x("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);$CLJS.OK=new $CLJS.M(null,"interval","interval",1708495417);PK=new $CLJS.x(null,"share","share",1051097594,null);QK=new $CLJS.x("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);RK=new $CLJS.x("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);SK=new $CLJS.x("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);
TK=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);UK=new $CLJS.x(null,"FieldOrAggregationReference","FieldOrAggregationReference",85477856,null);VK=new $CLJS.x("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);WK=new $CLJS.M(null,"collection","collection",-683361892);$CLJS.XK=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);YK=new $CLJS.x("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);
ZK=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);$K=new $CLJS.x(null,"Aggregation","Aggregation",-955933538,null);aL=new $CLJS.x(null,"metric","metric",2049329604,null);bL=new $CLJS.x(null,"concat","concat",-467652465,null);cL=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.dL=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);eL=new $CLJS.x(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.fL=new $CLJS.M("date","relative","date/relative",25987732);
$CLJS.gL=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.hL=new $CLJS.M(null,"percentile","percentile",-601188752);iL=new $CLJS.M("location","city","location/city",-1746973325);jL=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);kL=new $CLJS.x("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);lL=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);mL=new $CLJS.M("number","between","number/between",97700581);
nL=new $CLJS.x("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);$CLJS.oL=new $CLJS.M(null,"not-null","not-null",-1326718535);pL=new $CLJS.M(null,"replacement","replacement",-1836238839);qL=new $CLJS.x(null,"sqrt","sqrt",370479598,null);rL=new $CLJS.x("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",-1440012226,null);sL=new $CLJS.M(null,"sqrt","sqrt",-1270051929);$CLJS.tL=new $CLJS.M(null,"semantic_type","semantic_type",272485089);
uL=new $CLJS.M(null,"metric-id","metric-id",-686486942);vL=new $CLJS.x(null,"*","*",345799209,null);wL=new $CLJS.x(null,"+","+",-740910886,null);xL=new $CLJS.x(null,"-","-",-471816912,null);yL=new $CLJS.x(null,"/","/",-1371932971,null);$CLJS.zL=new $CLJS.M(null,"breakout","breakout",-732419050);AL=new $CLJS.x(null,"template-tag","template-tag",1951372565,null);BL=new $CLJS.M(null,"allowed-for","allowed-for",122724334);CL=new $CLJS.M(null,"question","question",-1411720117);
DL=new $CLJS.x(null,"asc","asc",1997386096,null);EL=new $CLJS.x("metabase.mbql.schema","DatetimeExpression*","metabase.mbql.schema/DatetimeExpression*",-1766428848,null);$CLJS.FL=new $CLJS.M(null,"us","us",746429226);GL=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);HL=new $CLJS.x("metabase.mbql.schema","BooleanExpression*","metabase.mbql.schema/BooleanExpression*",-630544184,null);IL=new $CLJS.x(null,"\x3c\x3d","\x3c\x3d",1244895369,null);JL=new $CLJS.x(null,"\x3c","\x3c",993667236,null);
KL=new $CLJS.x(null,"\x3e","\x3e",1085014381,null);$CLJS.LL=new $CLJS.M(null,"aggregation","aggregation",1597476696);$CLJS.ML=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);$CLJS.NL=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);OL=new $CLJS.M("string","ends-with","string/ends-with",302681156);PL=new $CLJS.x("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);$CLJS.QL=new $CLJS.x("s","Str","s/Str",907974338,null);
RL=new $CLJS.x(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);$CLJS.SL=new $CLJS.M(null,"desc","desc",2093485764);TL=new $CLJS.x(null,"and","and",668631710,null);UL=new $CLJS.x(null,"\x3e\x3d","\x3e\x3d",1016916022,null);VL=new $CLJS.x(null,"round","round",-645002441,null);WL=new $CLJS.M(null,"to","to",192099007);$CLJS.XL=new $CLJS.M(null,"starts-with","starts-with",366503009);$CLJS.YL=new $CLJS.M("date","single","date/single",1554682003);
ZL=new $CLJS.x(null,"DatetimeExpression*","DatetimeExpression*",1609939947,null);$CLJS.$L=new $CLJS.M(null,"does-not-contain","does-not-contain",-274946853);aM=new $CLJS.x("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);bM=new $CLJS.x(null,"exp","exp",1378825265,null);cM=new $CLJS.x(null,"Filter","Filter",-424893332,null);dM=new $CLJS.x("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",1964355302,null);
eM=new $CLJS.x(null,"cum-count","cum-count",-323900016,null);fM=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);gM=new $CLJS.x(null,"BooleanExpression*","BooleanExpression*",623370113,null);$CLJS.hM=new $CLJS.M(null,"source-table","source-table",-225307692);iM=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);jM=new $CLJS.M(null,"datetime","datetime",494675702);kM=new $CLJS.x("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);
$CLJS.lM=new $CLJS.M(null,"avg","avg",197406200);mM=new $CLJS.M(null,"now","now",-1650525531);nM=new $CLJS.M(null,"metric","metric",408798077);oM=new $CLJS.x(null,"floor","floor",-772394748,null);pM=new $CLJS.M(null,"middleware","middleware",1462115504);qM=new $CLJS.x("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);rM=new $CLJS.M(null,"requires-features","requires-features",-101116256);sM=new $CLJS.M(null,"percentile-aggregations","percentile-aggregations",1241763983);
Y=new $CLJS.M(null,"clause-name","clause-name",-996419059);tM=new $CLJS.x(null,"now","now",-9994004,null);uM=new $CLJS.x("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);vM=new $CLJS.x(null,"avg","avg",1837937727,null);$CLJS.wM=new $CLJS.M(null,"asc","asc",356854569);xM=new $CLJS.x(null,"sum-where","sum-where",-519087341,null);yM=new $CLJS.M(null,"max-results","max-results",-32858165);
zM=new $CLJS.x("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);AM=new $CLJS.x(null,"case","case",-1510733573,null);BM=new $CLJS.x(null,"ParameterType","ParameterType",-442355043,null);CM=new $CLJS.x("js","Date","js/Date",946858373,null);DM=new $CLJS.x(null,"StringExpression*","StringExpression*",1110382599,null);EM=new $CLJS.x(null,"distinct","distinct",-148347594,null);FM=new $CLJS.M(null,"floor","floor",1882041021);GM=new $CLJS.M(null,"none","none",1333468478);
HM=new $CLJS.x(null,"get-second","get-second",-425414791,null);IM=new $CLJS.x(null,"DatetimeExpression","DatetimeExpression",1944354145,null);$CLJS.JM=new $CLJS.M(null,"join-alias","join-alias",1454206794);KM=new $CLJS.x(null,"is-empty","is-empty",600228619,null);$CLJS.LM=new $CLJS.M(null,"instance","instance",-2121349050);MM=new $CLJS.x(null,"StringExpression","StringExpression",-233088561,null);NM=new $CLJS.x("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
$CLJS.OM=new $CLJS.x("s","pred","s/pred",-727014287,null);PM=new $CLJS.x(null,"relative-datetime","relative-datetime",-1369266491,null);QM=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);RM=new $CLJS.x(null,"abs","abs",1394505050,null);SM=new $CLJS.x(null,"datetime-diff","datetime-diff",-1521323614,null);TM=new $CLJS.x("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);$CLJS.UM=new $CLJS.x("s","cond-pre","s/cond-pre",-923707600,null);
VM=new $CLJS.M(null,"date","date",-1463434462);WM=new $CLJS.M(null,"second-clause","second-clause",-461435645);XM=new $CLJS.M(null,"left-join","left-join",-672831855);YM=new $CLJS.x("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);$CLJS.ZM=new $CLJS.M(null,"contains","contains",676899812);$M=new $CLJS.x(null,"rtrim","rtrim",979195078,null);aN=new $CLJS.x("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);
bN=new $CLJS.x(null,"CaseOptions","CaseOptions",1989286806,null);cN=new $CLJS.x("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",-1444327113,null);dN=new $CLJS.x(null,"or","or",1876275696,null);eN=new $CLJS.x("s","Int","s/Int",-2116888657,null);$CLJS.fN=new $CLJS.M(null,"field","field",-1302436500);gN=new $CLJS.x("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);hN=new $CLJS.x(null,"TemporalExtractUnits","TemporalExtractUnits",-1308527018,null);
iN=new $CLJS.M(null,"constraints","constraints",422775616);$CLJS.jN=new $CLJS.M(null,"cum-count","cum-count",-1964431543);kN=new $CLJS.x("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);lN=new $CLJS.M(null,"csv-download","csv-download",2141432084);mN=new $CLJS.M(null,"regex-match-first","regex-match-first",-657299718);nN=new $CLJS.M(null,"operator","operator",-1860875338);oN=new $CLJS.x(null,"datetime-add","datetime-add",1850134938,null);
pN=new $CLJS.x("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);qN=new $CLJS.x("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);rN=new $CLJS.x("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);sN=new $CLJS.x("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);tN=new $CLJS.x("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);
uN=new $CLJS.x("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);vN=new $CLJS.x(null,"get-quarter","get-quarter",-1326657176,null);wN=new $CLJS.x("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);xN=new $CLJS.x("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);yN=new $CLJS.x("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);zN=new $CLJS.x("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);
AN=new $CLJS.x(null,"temporal-extract","temporal-extract",-872749364,null);BN=new $CLJS.x("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);CN=new $CLJS.x("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);DN=new $CLJS.M(null,"display_name","display_name",-1494335013);EN=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);FN=new $CLJS.M(null,"database","database",1849087575);
GN=new $CLJS.x(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);HN=new $CLJS.M(null,"coalesce","coalesce",654622029);IN=new $CLJS.x(null,"get-day-of-week","get-day-of-week",-644295017,null);JN=new $CLJS.x("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);$CLJS.KN=new $CLJS.M(null,"expressions","expressions",255689909);LN=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);
MN=new $CLJS.x("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);NN=new $CLJS.M(null,"round","round",2009433328);$CLJS.ON=new $CLJS.M(null,"temporal-unit","temporal-unit",-1892306358);PN=new $CLJS.x(null,"absolute-datetime","absolute-datetime",1080191062,null);$CLJS.QN=new $CLJS.M(null,"share","share",-589433933);RN=new $CLJS.x(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);SN=new $CLJS.x(null,"get-day","get-day",1768100384,null);
TN=new $CLJS.x(null,"Fields","Fields",430617156,null);UN=new $CLJS.x(null,"not-empty","not-empty",2029453590,null);$CLJS.VN=new $CLJS.M(null,"native","native",-613060878);WN=new $CLJS.x("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);XN=new $CLJS.M(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.YN=new $CLJS.M(null,"get-day","get-day",127568857);ZN=new $CLJS.M(null,"page","page",849072397);$N=new $CLJS.x(null,"length","length",-2065447907,null);
aO=new $CLJS.M(null,"dashboard","dashboard",-631747508);$CLJS.bO=new $CLJS.M(null,"get-week","get-week",-888059349);cO=new $CLJS.x(null,"get-week","get-week",752472178,null);$CLJS.dO=new $CLJS.M(null,"datetime-add","datetime-add",209603411);eO=new $CLJS.x(null,"get-month","get-month",1271156796,null);fO=new $CLJS.x(null,"dimension","dimension",-2111181571,null);$CLJS.gO=new $CLJS.M(null,"count-where","count-where",385407720);hO=new $CLJS.M(null,"regex","regex",939488856);
iO=new $CLJS.x(null,"StringFilterOptions","StringFilterOptions",-847398229,null);$CLJS.jO=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.kO=new $CLJS.M(null,"base-type","base-type",1167971299);lO=new $CLJS.x("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);mO=new $CLJS.x("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);nO=new $CLJS.x(null,"substring","substring",-1513569493,null);
oO=new $CLJS.x("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);pO=new $CLJS.M(null,"internal","internal",-854870097);qO=new $CLJS.x("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);rO=new $CLJS.x("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);sO=new $CLJS.M(null,"declared","declared",92336021);
tO=new $CLJS.x("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",-1385848876,null);$CLJS.uO=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);vO=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);wO=new $CLJS.x(null,"ltrim","ltrim",-1000166486,null);xO=new $CLJS.M(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);
yO=new $CLJS.x("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);zO=new $CLJS.x("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);$CLJS.AO=new $CLJS.M(null,"ends-with","ends-with",-456791011);BO=new $CLJS.x("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);CO=new $CLJS.x("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);
DO=new $CLJS.x("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);EO=new $CLJS.x(null,"desc","desc",-560950005,null);$CLJS.FO=new $CLJS.M(null,"template-tags","template-tags",1853115685);GO=new $CLJS.M(null,"public-question","public-question",629369976);HO=new $CLJS.x("metabase.mbql.schema","Field*","metabase.mbql.schema/Field*",-1168278623,null);IO=new $CLJS.M(null,"fk-field-id","fk-field-id",1124127357);
JO=new $CLJS.x("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);KO=new $CLJS.M(null,"binary","binary",-1802232288);$CLJS.LO=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);MO=new $CLJS.x(null,"time-interval","time-interval",-1949813754,null);$CLJS.NO=new $CLJS.M(null,"time-interval","time-interval",704622015);OO=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);
$CLJS.PO=new $CLJS.M(null,"source-query","source-query",198004422);QO=new $CLJS.M(null,"settings","settings",1556144875);RO=new $CLJS.x("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",-781973827,null);SO=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.TO=new $CLJS.M(null,"sum","sum",136986814);UO=new $CLJS.x(null,"Field*","Field*",-2104480026,null);VO=new $CLJS.x("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);
WO=new $CLJS.x("s","eq","s/eq",1021992833,null);XO=new $CLJS.M("cljs.analyzer","no-resolve","cljs.analyzer/no-resolve",-1872351017);$CLJS.YO=new $CLJS.M(null,"median","median",569566131);ZO=new $CLJS.M(null,"executed-by","executed-by",-739811161);$O=new $CLJS.x("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);$CLJS.aP=new $CLJS.M(null,"get-hour","get-hour",622714059);bP=new $CLJS.M(null,"amount","amount",364489504);
cP=new $CLJS.x(null,"percentile","percentile",1039342775,null);dP=new $CLJS.x(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);$CLJS.eP=new $CLJS.M(null,"include-current","include-current",-1602371981);fP=new $CLJS.x(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);gP=new $CLJS.x(null,"trim","trim",-1880116002,null);hP=new $CLJS.x(null,"MBQLQuery","MBQLQuery",-1395590243,null);iP=new $CLJS.x(null,"NumericExpression","NumericExpression",-2082340941,null);
$CLJS.jP=new $CLJS.M(null,"stddev","stddev",-865474939);kP=new $CLJS.x("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",-859895030,null);lP=new $CLJS.x(null,"coalesce","coalesce",-1999813740,null);mP=new $CLJS.M(null,"trim","trim",774319767);$CLJS.nP=new $CLJS.M(null,"!\x3d","!\x3d",-1841737356);oP=new $CLJS.M("string","\x3d","string/\x3d",983744235);pP=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);
qP=new $CLJS.x("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);rP=new $CLJS.x("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);sP=new $CLJS.x(null,"DatetimeDiffUnits","DatetimeDiffUnits",1899101514,null);tP=new $CLJS.x(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);uP=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);vP=new $CLJS.x(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);
wP=new $CLJS.M(null,"lat-min","lat-min",1630784161);xP=new $CLJS.M(null,"ltrim","ltrim",1654269283);yP=new $CLJS.x(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);zP=new $CLJS.x(null,"inside","inside",-681932758,null);AP=new $CLJS.x("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);BP=new $CLJS.x("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);
CP=new $CLJS.x(null,"starts-with","starts-with",2007034536,null);DP=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);EP=new $CLJS.x("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);FP=new $CLJS.M(null,"card-id","card-id",-1770060179);GP=new $CLJS.M(null,"variadic","variadic",882626057);$CLJS.HP=new $CLJS.M(null,"category","category",-593092832);IP=new $CLJS.x(null,"NumericExpression*","NumericExpression*",-1459070895,null);
JP=new $CLJS.x(null,"upper","upper",1886775433,null);KP=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);LP=new $CLJS.x("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);$CLJS.MP=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);NP=new $CLJS.M(null,"sugar","sugar",-73788488);OP=new $CLJS.M(null,"lat-max","lat-max",841568226);PP=new $CLJS.M(null,"full-join","full-join",1305476385);QP=new $CLJS.x(null,"power","power",702679448,null);
RP=new $CLJS.x("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);SP=new $CLJS.x("metabase.mbql.schema","StringExpression*","metabase.mbql.schema/StringExpression*",224546636,null);TP=new $CLJS.x(null,"median","median",-2084869638,null);UP=new $CLJS.x(null,"cum-sum","cum-sum",-589533420,null);VP=new $CLJS.x("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);WP=new $CLJS.M(null,"y","y",-1757859776);XP=new $CLJS.M(null,"x","x",2099068185);
$CLJS.YP=new $CLJS.M(null,"sum-where","sum-where",2135348428);ZP=new $CLJS.x("helpers","NonBlankString","helpers/NonBlankString",-2060904596,null);$P=new $CLJS.x("metabase.mbql.schema","UnnamedAggregation*","metabase.mbql.schema/UnnamedAggregation*",652628682,null);$CLJS.aQ=new $CLJS.M(null,"binning","binning",1709835866);bQ=new $CLJS.x(null,"TimezoneId","TimezoneId",-1406242397,null);cQ=new $CLJS.M(null,"b","b",1482224470);dQ=new $CLJS.M(null,"a","a",-2123407586);
eQ=new $CLJS.x(null,"replace","replace",853943757,null);$CLJS.fQ=new $CLJS.M(null,"/","/",1282502798);gQ=new $CLJS.x("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);hQ=new $CLJS.x(null,"segment","segment",675610331,null);iQ=new $CLJS.x("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);jQ=new $CLJS.x(null,"ExtractWeekModes","ExtractWeekModes",687144014,null);$CLJS.kQ=new $CLJS.M(null,"-","-",-2112348439);
lQ=new $CLJS.x(null,"UnnamedAggregation*","UnnamedAggregation*",1873018243,null);mQ=new $CLJS.x("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);nQ=new $CLJS.M("string","starts-with","string/starts-with",1266861170);$CLJS.oQ=new $CLJS.M(null,"order-by","order-by",1527318070);pQ=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);qQ=new $CLJS.x("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);
rQ=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);sQ=new $CLJS.x("metabase.mbql.schema","Filter*","metabase.mbql.schema/Filter*",-140275836,null);$CLJS.tQ=new $CLJS.M(null,"cum-sum","cum-sum",2064902349);uQ=new $CLJS.M(null,"condition","condition",1668437652);vQ=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);wQ=new $CLJS.x("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",970196104,null);
xQ=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);yQ=new $CLJS.M(null,"log","log",-1595516004);zQ=new $CLJS.x("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);AQ=new $CLJS.M(null,"card-name","card-name",-2035606807);BQ=new $CLJS.x("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);CQ=new $CLJS.x("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);
DQ=new $CLJS.x(null,"log","log",45015523,null);EQ=new $CLJS.x("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);$CLJS.FQ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);GQ=new $CLJS.x(null,"time","time",-1268547887,null);HQ=new $CLJS.x("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-475072702,null);IQ=new $CLJS.M(null,"database_type","database_type",-54700895);JQ=new $CLJS.M(null,"bin-width","bin-width",1377922579);
KQ=new $CLJS.x(null,"EqualityComparable","EqualityComparable",-2076258858,null);LQ=new $CLJS.x(null,"get-minute","get-minute",-1186349426,null);$CLJS.MQ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);$CLJS.NQ=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.OQ=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.PQ=new $CLJS.M("date","all-options","date/all-options",549325958);
QQ=new $CLJS.x("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);RQ=new $CLJS.M(null,"replace","replace",-786587770);SQ=new $CLJS.M("location","state","location/state",-114378652);TQ=new $CLJS.x(null,"datetime-subtract","datetime-subtract",1915839224,null);$CLJS.UQ=new $CLJS.M(null,"strategy","strategy",-1471631918);VQ=new $CLJS.M(null,"lon-field","lon-field",517872067);
WQ=new $CLJS.x(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);XQ=new $CLJS.M(null,"numeric","numeric",-1495594714);YQ=new $CLJS.x(null,"variable","variable",1359185035,null);ZQ=new $CLJS.x(null,"lower","lower",-1534114948,null);$Q=new $CLJS.M(null,"limit","limit",-1355822363);aR=new $CLJS.M(null,"rtrim","rtrim",-661336449);$CLJS.bR=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);cR=new $CLJS.x("s","enum","s/enum",-975416934,null);
$CLJS.dR=new $CLJS.M(null,"target","target",253001721);eR=new $CLJS.M(null,"pulse","pulse",-244494476);var fR,gR,hR,mR,nR,oR,pR,qR,rR,sR,tR,uR,vR,wR,xR,yR,zR,AR,BR,CR,DR,ER,IR,KR,MR,OR,PR,QR,RR,SR,VR,YR,$R,aS,bS,cS,dS,eS,fS,gS,hS,iS,jS,kS,lS,mS,nS,oS,pS,qS,rS,sS,tS,uS,vS,wS,xS,yS,TR,AS,BS,CS,DS,ES,FS,GS,HS,IS,JS,KS,LS,MS,NS,OS,PS,QS,NR,TS,US,VS,WS,XS,YS,ZS,$S,aT,bT,cT,dT,eT,fT,gT,hT,iT,jT,kT,lT,mT,nT,oT,pT,qT,rT,ZR,sT,tT,uT,vT,wT,zS,RS,yT,zT,AT,BT,CT,DT,ET,FT,GT,HT,IT,JT,KT,LT,MT,NT,OT,PT,QT,ST,TT,VT,WT,XT,YT,$T,aU,bU,cU,dU,eU,gU,hU,jU,kU,mU,nU,lU,oU,ZT,pU,qU,rU,sU,tU,uU,vU,wU,xU,yU,zU,AU;
fR=new $CLJS.S(null,new $CLJS.n(null,12,[$CLJS.Ds,null,$CLJS.mC,null,$CLJS.QB,null,$CLJS.Ph,null,$CLJS.zk,null,$CLJS.RB,null,$CLJS.Dk,null,$CLJS.Ck,null,$CLJS.vk,null,$CLJS.Bk,null,$CLJS.wk,null,$CLJS.yk,null],null),null);gR=new $CLJS.S(null,new $CLJS.n(null,7,[$CLJS.lC,null,UI,null,$CLJS.Ph,null,$CLJS.xk,null,wK,null,$CLJS.Ak,null,$CLJS.jC,null],null),null);hR=$CLJS.BC.h(fR,gR);$CLJS.iR=$CLJS.dG($CLJS.Ue.h(RH,fR),"date-bucketing-unit");$CLJS.jR=$CLJS.dG($CLJS.Ue.h(RH,gR),"time-bucketing-unit");
$CLJS.kR=$CLJS.dG($CLJS.Ue.h(RH,hR),"datetime-bucketing-unit");$CLJS.lR=$CLJS.dG($CLJS.Ue.h(RH,$CLJS.Dt.tz.names()),"timezone-id");mR=$CLJS.dG($CLJS.Ue.h(RH,new $CLJS.S(null,new $CLJS.n(null,11,[$CLJS.bR,null,$CLJS.zk,null,$CLJS.xk,null,$CLJS.Dk,null,$CLJS.Ck,null,$CLJS.zK,null,$CLJS.vJ,null,$CLJS.MQ,null,$CLJS.lK,null,$CLJS.yk,null,$CLJS.Ak,null],null),null)),"temporal-extract-units");
nR=$CLJS.dG($CLJS.Ue.h(RH,new $CLJS.S(null,new $CLJS.n(null,8,[$CLJS.Ds,null,$CLJS.mC,null,$CLJS.lC,null,$CLJS.QB,null,UI,null,$CLJS.RB,null,$CLJS.vk,null,$CLJS.jC,null],null),null)),"datetime-diff-units");oR=$CLJS.dG($CLJS.Ue.h(RH,new $CLJS.S(null,new $CLJS.n(null,3,[$CLJS.LM,null,$CLJS.aJ,null,$CLJS.FL,null],null),null)),"extract-week-modes");
pR=$CLJS.dG($CLJS.Ue.h(RH,new $CLJS.S(null,new $CLJS.n(null,8,[$CLJS.Ds,null,$CLJS.mC,null,$CLJS.lC,null,$CLJS.QB,null,$CLJS.Ph,null,$CLJS.RB,null,$CLJS.vk,null,$CLJS.jC,null],null),null)),"relative-datetime-unit");qR=$CLJS.fG($CLJS.CH,gI,"valid ISO-8601 datetime string literal");rR=$CLJS.fG($CLJS.CH,gI,"valid ISO-8601 datetime string literal");sR=$CLJS.fG($CLJS.CH,gI,"valid ISO-8601 time string literal");
tR=aI($CLJS.ML,$CLJS.H(["n",$CLJS.BH($CLJS.H([$CLJS.$F($CLJS.Hv),$CLJS.xH])),"unit",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,pR],null)]));uR=aI($CLJS.OK,$CLJS.H(["n",$CLJS.xH,"unit",pR]));vR=TH.m($CLJS.H([function(a){return $CLJS.Ra(bI(sJ,a))},aI(sJ,$CLJS.H(["t",Date,"unit",$CLJS.kR])),function(a){return $CLJS.hd(a)instanceof Date},aI(sJ,$CLJS.H(["date",Date,"unit",$CLJS.iR])),$CLJS.Es,aI(sJ,$CLJS.H(["datetime",Date,"unit",$CLJS.kR]))]));wR=aI($CLJS.pA,$CLJS.H(["time",Date,"unit",$CLJS.jR]));
xR=TH.m($CLJS.H([$CLJS.Xe(bI,sJ),vR,gI,rR,gI,qR,$CLJS.Es,$CLJS.BH($CLJS.H([Date]))]));yR=TH.m($CLJS.H([$CLJS.Xe(bI,$CLJS.pA),wR,gI,sR,$CLJS.Es,$CLJS.BH($CLJS.H([Date]))]));zR=$CLJS.BH($CLJS.H([yR,xR]));
cI($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof vR?new $CLJS.Fc(function(){return vR},$CLJS.J(dK,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,pO,$CLJS.Si,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
PN,"metabase/mbql/schema.cljc",69,sJ,1,!0,197,197,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.p(vR)?vR.D:null])):null));return $CLJS.p(a)?a:PN}(),vR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof tR?new $CLJS.Fc(function(){return tR},$CLJS.J(QQ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,
$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PM,"metabase/mbql/schema.cljc",29,$CLJS.ML,1,179,179,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.ML,$CLJS.Yw,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.UM,$CLJS.X(WO,$CLJS.Hv),eN)),$CLJS.ci,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,RL))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",$CLJS.p(tR)?tR.D:null])):null));return $CLJS.p(a)?a:PM}(),tR],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof wR?new $CLJS.Fc(function(){return wR},$CLJS.J(zN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,pO,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GQ,"metabase/mbql/schema.cljc",
27,$CLJS.pA,1,!0,230,230,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.pA,$CLJS.pA,$CLJS.X($CLJS.jj,CM),$CLJS.ci,$CLJS.X($CLJS.jj,eL)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.p(wR)?wR.D:null])):null));return $CLJS.p(a)?a:GQ}(),wR],null)]));AR=$CLJS.Je([PH(IQ),$CLJS.YH($CLJS.CH),PH($CLJS.DD),$CLJS.YH($CLJS.DH),PH($CLJS.tL),$CLJS.YH($CLJS.EH),PH($CLJS.ci),$CLJS.YH($CLJS.kR),PH($CLJS.T),$CLJS.YH($CLJS.CH),$CLJS.yH,$CLJS.tH]);BR=aI($CLJS.bj,$CLJS.H(["value",$CLJS.tH,"type-info",$CLJS.YH(AR)]));
CR=aI($CLJS.Zs,$CLJS.H(["expression-name",$CLJS.CH,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,$CLJS.bG($CLJS.xd,"map")],null)]));DR=RH.m($CLJS.H([mJ,JQ,$CLJS.Ph]));
ER=function(a){return $CLJS.fG(a,function(b){var c=$CLJS.Ke(b);b=$CLJS.K.h(c,$CLJS.UQ);c=$CLJS.K.h(c,mJ);return $CLJS.F.h(b,mJ)?c:!0},"You must specify :num-bins when using the :num-bins strategy.")}(function(a){return $CLJS.fG(a,function(b){var c=$CLJS.Ke(b);b=$CLJS.K.h(c,$CLJS.UQ);c=$CLJS.K.h(c,JQ);return $CLJS.F.h(b,JQ)?c:!0},"You must specify :bin-width when using the :bin-width strategy.")}($CLJS.Je([$CLJS.UQ,DR,PH(mJ),$CLJS.FH,PH(JQ),$CLJS.fG($CLJS.wH,$CLJS.Ve($CLJS.fe),"bin width must be \x3e\x3d 0."),
$CLJS.yH,$CLJS.tH])));$CLJS.FR=function FR(a){switch(arguments.length){case 1:return FR.g(arguments[0]);case 2:return FR.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.q.g(arguments.length)].join(""));}};$CLJS.FR.g=function(a){var b=$CLJS.Ke(a);a=$CLJS.K.h(b,$CLJS.kO);b=$CLJS.K.h(b,$CLJS.ON);return $CLJS.FR.h(a,b)};
$CLJS.FR.h=function(a,b){a=$CLJS.p($CLJS.p(b)?a:b)?$CLJS.tC(a,$CLJS.hD)?fR:$CLJS.tC(a,$CLJS.PE)?gR:$CLJS.tC(a,$CLJS.WD)?hR:null:null;return $CLJS.p(a)?$CLJS.Kd(a,b):!0};$CLJS.FR.F=2;
var GR=$CLJS.fG($CLJS.fG($CLJS.Je([PH($CLJS.kO),$CLJS.YH($CLJS.DH),PH($CLJS.$J),$CLJS.YH($CLJS.BH($CLJS.H([$CLJS.FH,$CLJS.CH]))),PH($CLJS.ON),$CLJS.YH($CLJS.kR),PH($CLJS.JM),$CLJS.YH($CLJS.CH),PH($CLJS.aQ),$CLJS.YH(ER),$CLJS.yH,$CLJS.tH]),$CLJS.FR,"Invalid :temporal-unit for the specified :base-type."),$CLJS.Ve($CLJS.UQ),"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."),HR=function(a){return $CLJS.fG(a,function(b){$CLJS.I(b,0,null);var c=
$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.Ke(b);b=$CLJS.K.h(b,$CLJS.kO);return"string"===typeof c?b:!0},":field clauses using a string field name must specify :base-type.")}(aI($CLJS.fN,$CLJS.H(["id-or-name",$CLJS.BH($CLJS.H([$CLJS.FH,$CLJS.CH])),"options",$CLJS.YH(WH(new $CLJS.Fc(function(){return GR},HQ,$CLJS.Bg([$CLJS.Lj,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[!0,$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WI,"metabase/mbql/schema.cljc",28,1,388,388,$CLJS.Kc,null,$CLJS.p(GR)?GR.D:null]))))])));$CLJS.fG(HR,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.Id(a)},"Must be a :field with an integer Field ID.");$CLJS.fG(HR,function(a){$CLJS.I(a,0,null);return"string"===typeof $CLJS.I(a,1,null)},"Must be a :field with a string Field name.");
IR=cI($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof CR?new $CLJS.Fc(function(){return CR},$CLJS.J(rK,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.SJ,"metabase/mbql/schema.cljc",60,$CLJS.Zs,1,323,323,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Zs,$CLJS.OQ,$CLJS.X($CLJS.jj,ZP),$CLJS.Li,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,$CLJS.X($CLJS.OM,$CLJS.ji,"map")))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.p(CR)?CR.D:null])):null));return $CLJS.p(a)?a:$CLJS.SJ}(),CR],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof HR?
new $CLJS.Fc(function(){return HR},$CLJS.J(NK,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Ui,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],["0.39.0",$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wJ,"metabase/mbql/schema.cljc",51,$CLJS.fN,1,434,434,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.p(HR)?HR.D:null])):null));return $CLJS.p(a)?a:wJ}(),
HR],null)]));$CLJS.JR=WH(new $CLJS.Fc(function(){return IR},HO,$CLJS.Bg([$CLJS.Lj,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[!0,$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",22,1,458,458,$CLJS.Kc,null,$CLJS.p(IR)?IR.D:null])));
KR=aI($CLJS.LL,$CLJS.H(["aggregation-clause-index",$CLJS.xH,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,$CLJS.bG($CLJS.xd,"map")],null)]));$CLJS.LR=UH($CLJS.Xe(bI,$CLJS.LL),KR,$CLJS.JR);MR=new $CLJS.S(null,new $CLJS.n(null,11,[$CLJS.Qs,null,xP,null,$CLJS.qI,null,RQ,null,SI,null,HN,null,$CLJS.nK,null,$CLJS.Ps,null,mP,null,mN,null,aR,null],null),null);
OR=TH.m($CLJS.H([$CLJS.Ua,$CLJS.uH,$CLJS.Xe(bI,MR),WH(new $CLJS.Fc(function(){return NR},wQ,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MM,"metabase/mbql/schema.cljc",26,1,501,!0,501,$CLJS.Kc,null,$CLJS.p(NR)?NR.D:null]))),$CLJS.Xe(bI,$CLJS.bj),BR,$CLJS.Es,$CLJS.JR]));
PR=new $CLJS.S(null,new $CLJS.n(null,26,[LI,null,$CLJS.FQ,null,$CLJS.Jj,null,$CLJS.LO,null,$CLJS.jO,null,sL,null,$CLJS.kQ,null,nI,null,$CLJS.aP,null,$CLJS.bO,null,HN,null,$CLJS.fQ,null,$CLJS.uO,null,NN,null,QI,null,$CLJS.pJ,null,$CLJS.Ij,null,YI,null,$CLJS.nK,null,$CLJS.mI,null,$CLJS.Iy,null,$CLJS.qJ,null,$CLJS.YN,null,$CLJS.KK,null,yQ,null,FM,null],null),null);
QR=new $CLJS.S(null,new $CLJS.n(null,9,[$CLJS.Qj,null,$CLJS.Gj,null,$CLJS.Bi,null,$CLJS.Pi,null,$CLJS.Pj,null,$CLJS.nP,null,$CLJS.Yh,null,$CLJS.Dj,null,$CLJS.Ej,null],null),null);RR=new $CLJS.S(null,new $CLJS.n(null,17,[$CLJS.si,null,$CLJS.jP,null,$CLJS.gO,null,$CLJS.jN,null,$CLJS.YP,null,$CLJS.tQ,null,$CLJS.BJ,null,$CLJS.hL,null,$CLJS.EI,null,$CLJS.QJ,null,$CLJS.YO,null,$CLJS.QN,null,$CLJS.vj,null,$CLJS.Ry,null,$CLJS.lM,null,nM,null,$CLJS.TO,null],null),null);
SR=new $CLJS.S(null,new $CLJS.n(null,5,[mM,null,ZI,null,$CLJS.MP,null,$CLJS.Ij,null,$CLJS.dO,null],null),null);
VR=TH.m($CLJS.H([$CLJS.Qa,$CLJS.wH,$CLJS.Xe(bI,PR),WH(new $CLJS.Fc(function(){return TR},kP,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iP,"metabase/mbql/schema.cljc",27,1,535,!0,535,$CLJS.Kc,null,$CLJS.p(TR)?TR.D:null]))),$CLJS.Xe(bI,RR),WH(new $CLJS.Fc(function(){return $CLJS.UR},bJ,$CLJS.Bg([$CLJS.Di,
$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$K,"metabase/mbql/schema.cljc",21,1,538,!0,538,$CLJS.Kc,null,$CLJS.p($CLJS.UR)?$CLJS.UR.D:null]))),$CLJS.Xe(bI,$CLJS.bj),BR,$CLJS.Es,$CLJS.JR]));
YR=TH.m($CLJS.H([$CLJS.Xe(bI,RR),WH(new $CLJS.Fc(function(){return $CLJS.UR},bJ,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$K,"metabase/mbql/schema.cljc",21,1,538,!0,538,$CLJS.Kc,null,$CLJS.p($CLJS.UR)?$CLJS.UR.D:null]))),$CLJS.Xe(bI,$CLJS.bj),BR,$CLJS.Xe(bI,SR),WH(new $CLJS.Fc(function(){return $CLJS.WR},
rL,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IM,"metabase/mbql/schema.cljc",28,1,537,!0,537,$CLJS.Kc,null,$CLJS.p($CLJS.WR)?$CLJS.WR.D:null]))),$CLJS.Es,$CLJS.BH($CLJS.H([xR,$CLJS.JR]))]));
$R=TH.m($CLJS.H([$CLJS.Qa,$CLJS.wH,$CLJS.Ed,$CLJS.vH,$CLJS.Xe(bI,QR),WH(new $CLJS.Fc(function(){return ZR},VJ,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sK,"metabase/mbql/schema.cljc",27,1,536,!0,536,$CLJS.Kc,null,$CLJS.p(ZR)?ZR.D:null]))),$CLJS.Xe(bI,PR),WH(new $CLJS.Fc(function(){return TR},
kP,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iP,"metabase/mbql/schema.cljc",27,1,535,!0,535,$CLJS.Kc,null,$CLJS.p(TR)?TR.D:null]))),$CLJS.Xe(bI,SR),WH(new $CLJS.Fc(function(){return $CLJS.WR},rL,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],
[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IM,"metabase/mbql/schema.cljc",28,1,537,!0,537,$CLJS.Kc,null,$CLJS.p($CLJS.WR)?$CLJS.WR.D:null]))),$CLJS.Ua,$CLJS.uH,$CLJS.Xe(bI,MR),WH(new $CLJS.Fc(function(){return NR},wQ,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MM,"metabase/mbql/schema.cljc",26,1,501,!0,501,$CLJS.Kc,null,$CLJS.p(NR)?NR.D:null]))),$CLJS.Xe(bI,$CLJS.bj),BR,$CLJS.Es,$CLJS.JR]));aS=UH($CLJS.Xe(bI,$CLJS.OK),uR,VR);bS=UH($CLJS.Qa,$CLJS.FH,VR);cS=aI(HN,$CLJS.H(["a",$R,"b",$R,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sv,$R],null)]));dS=aI($CLJS.qI,$CLJS.H(["s",OR,"start",bS,"length",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,VR],null)]));eS=aI($CLJS.Iy,$CLJS.H(["s",OR]));fS=aI(mP,$CLJS.H(["s",OR]));gS=aI(aR,$CLJS.H(["s",OR]));
hS=aI(xP,$CLJS.H(["s",OR]));iS=aI($CLJS.Qs,$CLJS.H(["s",OR]));jS=aI($CLJS.Ps,$CLJS.H(["s",OR]));kS=aI(RQ,$CLJS.H(["s",OR,"match",$CLJS.uH,"replacement",$CLJS.uH]));lS=aI(SI,$CLJS.H(["a",OR,"b",OR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sv,OR],null)]));mS=aI(mN,$CLJS.H(["s",OR,"pattern",$CLJS.uH]));nS=aI($CLJS.Ij,$CLJS.H(["x",aS,"y",aS,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sv,aS],null)]));oS=aI($CLJS.kQ,$CLJS.H(["x",VR,"y",aS,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sv,aS],null)]));
pS=aI($CLJS.fQ,$CLJS.H(["x",VR,"y",VR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sv,VR],null)]));qS=aI($CLJS.Jj,$CLJS.H(["x",VR,"y",VR,"more",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sv,VR],null)]));rS=aI(FM,$CLJS.H(["x",VR]));sS=aI(LI,$CLJS.H(["x",VR]));tS=aI(NN,$CLJS.H(["x",VR]));uS=aI(YI,$CLJS.H(["x",VR]));vS=aI(QI,$CLJS.H(["x",VR,"y",VR]));wS=aI(sL,$CLJS.H(["x",VR]));xS=aI(nI,$CLJS.H(["x",VR]));yS=aI(yQ,$CLJS.H(["x",VR]));
TR=WH(new $CLJS.Fc(function(){return zS},kK,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",28,1,677,!0,677,$CLJS.Kc,null,$CLJS.p(zS)?zS.D:null])));AS=aI($CLJS.KK,$CLJS.H(["datetime-x",YR,"datetime-y",YR,"unit",nR]));
BS=aI($CLJS.LO,$CLJS.H(["datetime",YR,"unit",mR,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,oR],null)]));CS=aI($CLJS.qJ,$CLJS.H(["date",YR]));DS=aI($CLJS.FQ,$CLJS.H(["date",YR]));ES=aI($CLJS.mI,$CLJS.H(["date",YR]));FS=aI($CLJS.bO,$CLJS.H(["date",YR,"mode",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,oR],null)]));GS=aI($CLJS.YN,$CLJS.H(["date",YR]));HS=aI($CLJS.uO,$CLJS.H(["date",YR]));IS=aI($CLJS.aP,$CLJS.H(["datetime",YR]));JS=aI($CLJS.jO,$CLJS.H(["datetime",YR]));
KS=aI($CLJS.pJ,$CLJS.H(["datetime",YR]));LS=aI(ZI,$CLJS.H(["datetime",YR,"to",$CLJS.lR,"from",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,$CLJS.lR],null)]));MS=$CLJS.dG($CLJS.Ue.h(RH,new $CLJS.S(null,new $CLJS.n(null,9,[$CLJS.Ds,null,$CLJS.mC,null,$CLJS.lC,null,$CLJS.QB,null,UI,null,$CLJS.RB,null,$CLJS.vk,null,wK,null,$CLJS.jC,null],null),null)),"arithmetic-datetime-unit");NS=aI($CLJS.dO,$CLJS.H(["datetime",YR,"amount",VR,"unit",MS]));
OS=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return aI(arguments[0],1<b.length?new $CLJS.Hc(b.slice(1),0,null):null)}(mM);PS=aI($CLJS.MP,$CLJS.H(["datetime",YR,"amount",VR,"unit",MS]));
QS=cI($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof nS?new $CLJS.Fc(function(){return nS},$CLJS.J(xN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wL,"metabase/mbql/schema.cljc",51,$CLJS.Ij,1,643,643,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Ij,XP,$CLJS.X($CLJS.jj,GN),WP,$CLJS.X($CLJS.jj,GN),hK,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,GN))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.p(nS)?nS.D:null])):null));return $CLJS.p(a)?a:wL}(),nS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof NS?new $CLJS.Fc(function(){return NS},$CLJS.J(uM,
new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[GK,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oN,"metabase/mbql/schema.cljc",67,$CLJS.dO,1,742,742,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.dO,jM,$CLJS.X($CLJS.jj,WQ),bP,$CLJS.X($CLJS.jj,fP),$CLJS.ci,$CLJS.X($CLJS.jj,
yP)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.p(NS)?NS.D:null])):null));return $CLJS.p(a)?a:oN}(),NS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof PS?new $CLJS.Fc(function(){return PS},$CLJS.J(oI,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,
1,[GK,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",72,$CLJS.MP,1,749,749,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.MP,jM,$CLJS.X($CLJS.jj,WQ),bP,$CLJS.X($CLJS.jj,fP),$CLJS.ci,$CLJS.X($CLJS.jj,yP)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.p(PS)?PS.D:null])):null));return $CLJS.p(a)?a:TQ}(),PS],null),new $CLJS.Q(null,2,5,$CLJS.R,
[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof LS?new $CLJS.Fc(function(){return LS},$CLJS.J(BP,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[ZI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bK,"metabase/mbql/schema.cljc",71,ZI,1,732,732,new $CLJS.Q(null,7,5,$CLJS.R,[ZI,jM,$CLJS.X($CLJS.jj,WQ),WL,$CLJS.X($CLJS.jj,bQ),xI,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,bQ))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.p(LS)?LS.D:null])):null));return $CLJS.p(a)?a:bK}(),LS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof OS?new $CLJS.Fc(function(){return OS},
$CLJS.J(mO,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[mM,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tM,"metabase/mbql/schema.cljc",45,mM,1,747,747,new $CLJS.Q(null,1,5,$CLJS.R,[mM],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.p(OS)?
OS.D:null])):null));return $CLJS.p(a)?a:tM}(),OS],null)]));$CLJS.WR=WH(new $CLJS.Fc(function(){return QS},EL,$CLJS.Bg([$CLJS.Lj,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[!0,$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZL,"metabase/mbql/schema.cljc",35,1,754,754,$CLJS.Kc,null,$CLJS.p(QS)?QS.D:null])));
NR=WH(new $CLJS.Fc(function(){return RS},SP,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DM,"metabase/mbql/schema.cljc",27,1,761,!0,761,$CLJS.Kc,null,$CLJS.p(RS)?RS.D:null])));
TS=aI($CLJS.Yh,$CLJS.H(["first-clause",WH(new $CLJS.Fc(function(){return $CLJS.SS},cN,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cM,"metabase/mbql/schema.cljc",16,1,769,!0,769,$CLJS.Kc,null,$CLJS.p($CLJS.SS)?$CLJS.SS.D:null]))),"second-clause",WH(new $CLJS.Fc(function(){return $CLJS.SS},cN,$CLJS.Bg([$CLJS.Di,
$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cM,"metabase/mbql/schema.cljc",16,1,769,!0,769,$CLJS.Kc,null,$CLJS.p($CLJS.SS)?$CLJS.SS.D:null]))),"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sv,WH(new $CLJS.Fc(function(){return $CLJS.SS},cN,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,
$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cM,"metabase/mbql/schema.cljc",16,1,769,!0,769,$CLJS.Kc,null,$CLJS.p($CLJS.SS)?$CLJS.SS.D:null])))],null)]));
US=aI($CLJS.Bi,$CLJS.H(["first-clause",WH(new $CLJS.Fc(function(){return $CLJS.SS},cN,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cM,"metabase/mbql/schema.cljc",16,1,769,!0,769,$CLJS.Kc,null,$CLJS.p($CLJS.SS)?$CLJS.SS.D:null]))),"second-clause",WH(new $CLJS.Fc(function(){return $CLJS.SS},cN,$CLJS.Bg([$CLJS.Di,
$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cM,"metabase/mbql/schema.cljc",16,1,769,!0,769,$CLJS.Kc,null,$CLJS.p($CLJS.SS)?$CLJS.SS.D:null]))),"other-clauses",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sv,WH(new $CLJS.Fc(function(){return $CLJS.SS},cN,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,
$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cM,"metabase/mbql/schema.cljc",16,1,769,!0,769,$CLJS.Kc,null,$CLJS.p($CLJS.SS)?$CLJS.SS.D:null])))],null)]));
VS=aI($CLJS.Pi,$CLJS.H(["clause",WH(new $CLJS.Fc(function(){return $CLJS.SS},cN,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cM,"metabase/mbql/schema.cljc",16,1,769,!0,769,$CLJS.Kc,null,$CLJS.p($CLJS.SS)?$CLJS.SS.D:null])))]));WS=UH($CLJS.Xe(bI,$CLJS.ML),tR,$CLJS.JR);
XS=$CLJS.YH($CLJS.BH($CLJS.H([$CLJS.vH,$CLJS.wH,$CLJS.uH,zR,WS,$R,BR])));YS=UH($CLJS.Xe(bI,$CLJS.bj),BR,$CLJS.BH($CLJS.H([$CLJS.wH,$CLJS.uH,zR,$R,WS])));ZS=aI($CLJS.Dj,$CLJS.H(["field",XS,"value-or-field",XS,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sv,XS],null)]));$S=aI($CLJS.nP,$CLJS.H(["field",XS,"value-or-field",XS,"more-values-or-fields",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sv,XS],null)]));aT=aI($CLJS.Ej,$CLJS.H(["field",YS,"value-or-field",YS]));
bT=aI($CLJS.Gj,$CLJS.H(["field",YS,"value-or-field",YS]));cT=aI($CLJS.Qj,$CLJS.H(["field",YS,"value-or-field",YS]));dT=aI($CLJS.Pj,$CLJS.H(["field",YS,"value-or-field",YS]));eT=aI($CLJS.yJ,$CLJS.H(["field",YS,"min",YS,"max",YS]));fT=aI($CLJS.eK,$CLJS.H(["lat-field",YS,"lon-field",YS,"lat-max",YS,"lon-min",YS,"lat-min",YS,"lon-max",YS]));gT=aI($CLJS.eJ,$CLJS.H(["field",$CLJS.JR]));hT=aI($CLJS.oL,$CLJS.H(["field",$CLJS.JR]));iT=aI($CLJS.zJ,$CLJS.H(["field",$CLJS.JR]));
jT=aI($CLJS.oK,$CLJS.H(["field",$CLJS.JR]));kT=$CLJS.Je([PH(XN),$CLJS.vH]);lT=aI($CLJS.XL,$CLJS.H(["field",OR,"string-or-field",OR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,kT],null)]));mT=aI($CLJS.AO,$CLJS.H(["field",OR,"string-or-field",OR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,kT],null)]));nT=aI($CLJS.ZM,$CLJS.H(["field",OR,"string-or-field",OR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,kT],null)]));
oT=aI($CLJS.$L,$CLJS.H(["field",OR,"string-or-field",OR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,kT],null)]));pT=$CLJS.Je([PH($CLJS.eP),$CLJS.vH]);qT=aI($CLJS.NO,$CLJS.H(["field",$CLJS.JR,"n",$CLJS.BH($CLJS.H([$CLJS.xH,RH.m($CLJS.H([$CLJS.Hv,$CLJS.bz,$CLJS.RJ]))])),"unit",pR,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,pT],null)]));rT=aI(RI,$CLJS.H(["segment-id",$CLJS.BH($CLJS.H([$CLJS.FH,$CLJS.CH]))]));
ZR=WH(new $CLJS.Fc(function(){return sT},HL,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gM,"metabase/mbql/schema.cljc",28,1,896,!0,896,$CLJS.Kc,null,$CLJS.p(sT)?sT.D:null])));
sT=cI($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof TS?new $CLJS.Fc(function(){return TS},$CLJS.J(RP,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TL,"metabase/mbql/schema.cljc",15,$CLJS.Yh,1,771,771,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Yh,iK,$CLJS.X($CLJS.jj,$CLJS.X(WJ,$CLJS.X($CLJS.mj,cM))),WM,$CLJS.X($CLJS.jj,$CLJS.X(WJ,$CLJS.X($CLJS.mj,cM))),yK,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,$CLJS.X(WJ,$CLJS.X($CLJS.mj,cM))))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.p(TS)?TS.D:null])):null));return $CLJS.p(a)?a:TL}(),TS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&
"undefined"!==typeof jI&&"undefined"!==typeof US?new $CLJS.Fc(function(){return US},$CLJS.J(aN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dN,"metabase/mbql/schema.cljc",14,$CLJS.Bi,1,776,776,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Bi,iK,$CLJS.X($CLJS.jj,$CLJS.X(WJ,
$CLJS.X($CLJS.mj,cM))),WM,$CLJS.X($CLJS.jj,$CLJS.X(WJ,$CLJS.X($CLJS.mj,cM))),yK,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,$CLJS.X(WJ,$CLJS.X($CLJS.mj,cM))))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.p(US)?US.D:null])):null));return $CLJS.p(a)?a:dN}(),US],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof VS?new $CLJS.Fc(function(){return VS},$CLJS.J(lO,new $CLJS.n(null,1,[XO,!0],null)),
$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ZE,"metabase/mbql/schema.cljc",15,$CLJS.Pi,1,781,781,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pi,$CLJS.NQ,$CLJS.X($CLJS.jj,$CLJS.X(WJ,$CLJS.X($CLJS.mj,cM)))],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.p(VS)?VS.D:null])):null));return $CLJS.p(a)?
a:$CLJS.ZE}(),VS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof aT?new $CLJS.Fc(function(){return aT},$CLJS.J(tN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JL,"metabase/mbql/schema.cljc",13,$CLJS.Ej,1,827,827,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ej,$CLJS.fN,$CLJS.X($CLJS.jj,jK),uP,$CLJS.X($CLJS.jj,jK)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.p(aT)?aT.D:null])):null));return $CLJS.p(a)?a:JL}(),aT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof cT?new $CLJS.Fc(function(){return cT},$CLJS.J(zQ,new $CLJS.n(null,1,[XO,!0],
null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IL,"metabase/mbql/schema.cljc",14,$CLJS.Qj,1,829,829,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Qj,$CLJS.fN,$CLJS.X($CLJS.jj,jK),uP,$CLJS.X($CLJS.jj,jK)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.p(cT)?cT.D:null])):null));return $CLJS.p(a)?
a:IL}(),cT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof bT?new $CLJS.Fc(function(){return bT},$CLJS.J(rN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KL,"metabase/mbql/schema.cljc",13,$CLJS.Gj,1,828,828,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Gj,$CLJS.fN,$CLJS.X($CLJS.jj,jK),uP,$CLJS.X($CLJS.jj,jK)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.p(bT)?bT.D:null])):null));return $CLJS.p(a)?a:KL}(),bT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof dT?new $CLJS.Fc(function(){return dT},$CLJS.J(mQ,new $CLJS.n(null,1,[XO,!0],
null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UL,"metabase/mbql/schema.cljc",14,$CLJS.Pj,1,830,830,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Pj,$CLJS.fN,$CLJS.X($CLJS.jj,jK),uP,$CLJS.X($CLJS.jj,jK)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.p(dT)?dT.D:null])):null));return $CLJS.p(a)?
a:UL}(),dT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof ZS?new $CLJS.Fc(function(){return ZS},$CLJS.J(sN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.SG,"metabase/mbql/schema.cljc",13,$CLJS.Dj,1,824,824,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Dj,$CLJS.fN,$CLJS.X($CLJS.jj,KQ),uP,$CLJS.X($CLJS.jj,KQ),vO,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,KQ))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.p(ZS)?ZS.D:null])):null));return $CLJS.p(a)?a:$CLJS.SG}(),ZS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof $S?new $CLJS.Fc(function(){return $S},
$CLJS.J(nJ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NJ,"metabase/mbql/schema.cljc",14,$CLJS.nP,1,825,825,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.nP,$CLJS.fN,$CLJS.X($CLJS.jj,KQ),uP,$CLJS.X($CLJS.jj,KQ),vO,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,KQ))],null),$CLJS.Kc,
"Schema for a valid !\x3d clause.",$CLJS.p($S)?$S.D:null])):null));return $CLJS.p(a)?a:NJ}(),$S],null)]));
tT=TH.m($CLJS.H([$CLJS.Xe(bI,SR),$CLJS.WR,$CLJS.Xe(bI,PR),TR,$CLJS.Xe(bI,MR),NR,$CLJS.Xe(bI,QR),ZR,$CLJS.Es,cI($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof TS?new $CLJS.Fc(function(){return TS},$CLJS.J(RP,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TL,"metabase/mbql/schema.cljc",15,$CLJS.Yh,1,771,771,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Yh,iK,$CLJS.X($CLJS.jj,$CLJS.X(WJ,$CLJS.X($CLJS.mj,cM))),WM,$CLJS.X($CLJS.jj,$CLJS.X(WJ,$CLJS.X($CLJS.mj,cM))),yK,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,$CLJS.X(WJ,$CLJS.X($CLJS.mj,cM))))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.p(TS)?TS.D:null])):null));return $CLJS.p(a)?a:TL}(),TS],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof US?new $CLJS.Fc(function(){return US},$CLJS.J(aN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dN,"metabase/mbql/schema.cljc",
14,$CLJS.Bi,1,776,776,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Bi,iK,$CLJS.X($CLJS.jj,$CLJS.X(WJ,$CLJS.X($CLJS.mj,cM))),WM,$CLJS.X($CLJS.jj,$CLJS.X(WJ,$CLJS.X($CLJS.mj,cM))),yK,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,$CLJS.X(WJ,$CLJS.X($CLJS.mj,cM))))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.p(US)?US.D:null])):null));return $CLJS.p(a)?a:dN}(),US],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==
typeof VS?new $CLJS.Fc(function(){return VS},$CLJS.J(lO,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ZE,"metabase/mbql/schema.cljc",15,$CLJS.Pi,1,781,781,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Pi,$CLJS.NQ,$CLJS.X($CLJS.jj,$CLJS.X(WJ,$CLJS.X($CLJS.mj,cM)))],
null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.p(VS)?VS.D:null])):null));return $CLJS.p(a)?a:$CLJS.ZE}(),VS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof ZS?new $CLJS.Fc(function(){return ZS},$CLJS.J(sN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.SG,"metabase/mbql/schema.cljc",13,$CLJS.Dj,1,824,824,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Dj,$CLJS.fN,$CLJS.X($CLJS.jj,KQ),uP,$CLJS.X($CLJS.jj,KQ),vO,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,KQ))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.p(ZS)?ZS.D:null])):null));return $CLJS.p(a)?a:$CLJS.SG}(),ZS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==
typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof $S?new $CLJS.Fc(function(){return $S},$CLJS.J(nJ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NJ,"metabase/mbql/schema.cljc",14,$CLJS.nP,1,825,825,new $CLJS.Q(null,7,5,$CLJS.R,
[$CLJS.nP,$CLJS.fN,$CLJS.X($CLJS.jj,KQ),uP,$CLJS.X($CLJS.jj,KQ),vO,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,KQ))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.p($S)?$S.D:null])):null));return $CLJS.p(a)?a:NJ}(),$S],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof aT?new $CLJS.Fc(function(){return aT},$CLJS.J(tN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,
Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JL,"metabase/mbql/schema.cljc",13,$CLJS.Ej,1,827,827,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Ej,$CLJS.fN,$CLJS.X($CLJS.jj,jK),uP,$CLJS.X($CLJS.jj,jK)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.p(aT)?aT.D:null])):null));return $CLJS.p(a)?a:JL}(),aT],null),new $CLJS.Q(null,2,5,$CLJS.R,
[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof bT?new $CLJS.Fc(function(){return bT},$CLJS.J(rN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KL,"metabase/mbql/schema.cljc",13,$CLJS.Gj,
1,828,828,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Gj,$CLJS.fN,$CLJS.X($CLJS.jj,jK),uP,$CLJS.X($CLJS.jj,jK)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.p(bT)?bT.D:null])):null));return $CLJS.p(a)?a:KL}(),bT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof cT?new $CLJS.Fc(function(){return cT},$CLJS.J(zQ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,
Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IL,"metabase/mbql/schema.cljc",14,$CLJS.Qj,1,829,829,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Qj,$CLJS.fN,$CLJS.X($CLJS.jj,jK),uP,$CLJS.X($CLJS.jj,jK)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.p(cT)?cT.D:null])):null));return $CLJS.p(a)?a:IL}(),cT],null),new $CLJS.Q(null,2,5,$CLJS.R,
[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof dT?new $CLJS.Fc(function(){return dT},$CLJS.J(mQ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UL,"metabase/mbql/schema.cljc",14,$CLJS.Pj,
1,830,830,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.Pj,$CLJS.fN,$CLJS.X($CLJS.jj,jK),uP,$CLJS.X($CLJS.jj,jK)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.p(dT)?dT.D:null])):null));return $CLJS.p(a)?a:UL}(),dT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof eT?new $CLJS.Fc(function(){return eT},$CLJS.J(CN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,
Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rJ,"metabase/mbql/schema.cljc",19,$CLJS.yJ,1,833,833,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.yJ,$CLJS.fN,$CLJS.X($CLJS.jj,jK),$CLJS.si,$CLJS.X($CLJS.jj,jK),$CLJS.vj,$CLJS.X($CLJS.jj,jK)],null),$CLJS.Kc,"Schema for a valid between clause.",$CLJS.p(eT)?eT.D:null])):null));return $CLJS.p(a)?a:rJ}(),
eT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof lT?new $CLJS.Fc(function(){return lT},$CLJS.J(JN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
CP,"metabase/mbql/schema.cljc",23,$CLJS.XL,1,856,856,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.XL,$CLJS.fN,$CLJS.X($CLJS.jj,tP),MJ,$CLJS.X($CLJS.jj,tP),$CLJS.Li,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,iO))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.p(lT)?lT.D:null])):null));return $CLJS.p(a)?a:CP}(),lT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof mT?new $CLJS.Fc(function(){return mT},
$CLJS.J(mK,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UJ,"metabase/mbql/schema.cljc",21,$CLJS.AO,1,857,857,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.AO,$CLJS.fN,$CLJS.X($CLJS.jj,tP),MJ,$CLJS.X($CLJS.jj,tP),$CLJS.Li,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,iO))],null),$CLJS.Kc,
"Schema for a valid ends-with clause.",$CLJS.p(mT)?mT.D:null])):null));return $CLJS.p(a)?a:UJ}(),mT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof nT?new $CLJS.Fc(function(){return nT},$CLJS.J(VK,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pK,"metabase/mbql/schema.cljc",20,$CLJS.ZM,1,858,858,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.ZM,$CLJS.fN,$CLJS.X($CLJS.jj,tP),MJ,$CLJS.X($CLJS.jj,tP),$CLJS.Li,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,iO))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.p(nT)?nT.D:null])):null));return $CLJS.p(a)?a:pK}(),nT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof oT?new $CLJS.Fc(function(){return oT},
$CLJS.J(CQ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yI,"metabase/mbql/schema.cljc",36,$CLJS.$L,1,861,861,!0,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.$L,$CLJS.fN,$CLJS.X($CLJS.jj,tP),MJ,$CLJS.X($CLJS.jj,tP),$CLJS.Li,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,iO))],null),
$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.p(oT)?oT.D:null])):null));return $CLJS.p(a)?a:yI}(),oT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof fT?new $CLJS.Fc(function(){return fT},$CLJS.J(rP,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zP,"metabase/mbql/schema.cljc",26,$CLJS.eK,1,836,836,!0,new $CLJS.Q(null,13,5,$CLJS.R,[$CLJS.eK,TI,$CLJS.X($CLJS.jj,jK),VQ,$CLJS.X($CLJS.jj,jK),OP,$CLJS.X($CLJS.jj,jK),gJ,$CLJS.X($CLJS.jj,jK),wP,$CLJS.X($CLJS.jj,jK),KI,$CLJS.X($CLJS.jj,jK)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.p(fT)?fT.D:null])):null));return $CLJS.p(a)?a:zP}(),fT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof iT?new $CLJS.Fc(function(){return iT},$CLJS.J(YK,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KM,"metabase/mbql/schema.cljc",28,$CLJS.zJ,1,850,850,
!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.zJ,$CLJS.fN,$CLJS.X($CLJS.jj,aK)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.p(iT)?iT.D:null])):null));return $CLJS.p(a)?a:KM}(),iT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof jT?new $CLJS.Fc(function(){return jT},$CLJS.J(CO,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,
NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UN,"metabase/mbql/schema.cljc",29,$CLJS.oK,1,851,851,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oK,$CLJS.fN,$CLJS.X($CLJS.jj,aK)],null),$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.p(jT)?jT.D:null])):null));return $CLJS.p(a)?a:UN}(),jT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==
typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof gT?new $CLJS.Fc(function(){return gT},$CLJS.J(qQ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xK,"metabase/mbql/schema.cljc",27,$CLJS.eJ,1,845,845,!0,new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.eJ,$CLJS.fN,$CLJS.X($CLJS.jj,aK)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.p(gT)?gT.D:null])):null));return $CLJS.p(a)?a:xK}(),gT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof hT?new $CLJS.Fc(function(){return hT},$CLJS.J(aM,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],
[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CJ,"metabase/mbql/schema.cljc",28,$CLJS.oL,1,846,846,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.oL,$CLJS.fN,$CLJS.X($CLJS.jj,aK)],null),$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.p(hT)?hT.D:null])):null));return $CLJS.p(a)?a:CJ}(),hT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&
"undefined"!==typeof jI&&"undefined"!==typeof qT?new $CLJS.Fc(function(){return qT},$CLJS.J(sI,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",33,$CLJS.NO,1,881,881,!0,new $CLJS.Q(null,9,5,$CLJS.R,[$CLJS.NO,$CLJS.fN,$CLJS.X($CLJS.jj,
aK),$CLJS.Yw,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.UM,eN,$CLJS.X(cR,$CLJS.Hv,$CLJS.bz,$CLJS.RJ))),$CLJS.ci,$CLJS.X($CLJS.jj,RL),$CLJS.Li,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,RN))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",$CLJS.p(qT)?qT.D:null])):null));return $CLJS.p(a)?a:MO}(),qT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof rT?new $CLJS.Fc(function(){return rT},$CLJS.J(NM,
new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hQ,"metabase/mbql/schema.cljc",27,RI,1,894,894,!0,new $CLJS.Q(null,3,5,$CLJS.R,[RI,xJ,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.UM,cK,ZP))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.p(rT)?rT.D:null])):null));
return $CLJS.p(a)?a:hQ}(),rT],null)]))]));$CLJS.SS=WH(new $CLJS.Fc(function(){return tT},sQ,$CLJS.Bg([$CLJS.Lj,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[!0,$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CK,"metabase/mbql/schema.cljc",23,1,905,905,$CLJS.Kc,null,$CLJS.p(tT)?tT.D:null])));
uT=new $CLJS.Q(null,1,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.OH($CLJS.SS,"pred"),$CLJS.OH($R,"expr")],null)],null);vT=$CLJS.Je([PH($CLJS.Ph),$R]);wT=aI($CLJS.nK,$CLJS.H(["clauses",uT,"options",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,vT],null)]));
zS=cI($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof nS?new $CLJS.Fc(function(){return nS},$CLJS.J(xN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wL,"metabase/mbql/schema.cljc",51,$CLJS.Ij,1,643,643,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Ij,XP,$CLJS.X($CLJS.jj,GN),WP,$CLJS.X($CLJS.jj,GN),hK,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,GN))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.p(nS)?nS.D:null])):null));return $CLJS.p(a)?a:wL}(),nS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof oS?new $CLJS.Fc(function(){return oS},$CLJS.J(wN,
new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xL,"metabase/mbql/schema.cljc",51,$CLJS.kQ,1,646,646,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.kQ,XP,$CLJS.X($CLJS.jj,fP),WP,$CLJS.X($CLJS.jj,GN),hK,$CLJS.X($CLJS.jj,
$CLJS.X($CLJS.ui,GN))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.p(oS)?oS.D:null])):null));return $CLJS.p(a)?a:xL}(),oS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI?new $CLJS.Fc(function(){return pS},$CLJS.J(uN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,Y,$CLJS.Ai,$CLJS.Si,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),
null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yL,"metabase/mbql/schema.cljc",$CLJS.fQ,1,649,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.fQ,XP,$CLJS.X($CLJS.jj,fP),WP,$CLJS.X($CLJS.jj,fP),hK,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,fP))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.p(pS)?pS.D:null])):null));return $CLJS.p(a)?a:yL}(),pS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==
typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof qS?new $CLJS.Fc(function(){return qS},$CLJS.J(yN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vL,"metabase/mbql/schema.cljc",
51,$CLJS.Jj,1,651,651,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.Jj,XP,$CLJS.X($CLJS.jj,fP),WP,$CLJS.X($CLJS.jj,fP),hK,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,fP))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.p(qS)?qS.D:null])):null));return $CLJS.p(a)?a:vL}(),qS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof cS?new $CLJS.Fc(function(){return cS},$CLJS.J(BO,new $CLJS.n(null,1,[XO,!0],null)),
$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lP,"metabase/mbql/schema.cljc",58,HN,1,610,610,new $CLJS.Q(null,7,5,$CLJS.R,[HN,dQ,$CLJS.X($CLJS.jj,HI),cQ,$CLJS.X($CLJS.jj,HI),hK,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,HI))],null),$CLJS.Kc,
"Schema for a valid coalesce clause.",$CLJS.p(cS)?cS.D:null])):null));return $CLJS.p(a)?a:lP}(),cS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof eS?new $CLJS.Fc(function(){return eS},$CLJS.J(zO,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],
null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$N,"metabase/mbql/schema.cljc",56,$CLJS.Iy,1,616,616,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Iy,$CLJS.Sz,$CLJS.X($CLJS.jj,tP)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.p(eS)?eS.D:null])):null));return $CLJS.p(a)?a:$N}(),eS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==
typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof rS?new $CLJS.Fc(function(){return rS},$CLJS.J(qK,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oM,"metabase/mbql/schema.cljc",55,FM,1,
653,653,new $CLJS.Q(null,3,5,$CLJS.R,[FM,XP,$CLJS.X($CLJS.jj,fP)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.p(rS)?rS.D:null])):null));return $CLJS.p(a)?a:oM}(),rS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof sS?new $CLJS.Fc(function(){return sS},$CLJS.J(PJ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,
$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fJ,"metabase/mbql/schema.cljc",54,LI,1,656,656,new $CLJS.Q(null,3,5,$CLJS.R,[LI,XP,$CLJS.X($CLJS.jj,fP)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.p(sS)?sS.D:null])):null));return $CLJS.p(a)?a:fJ}(),sS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof tS?new $CLJS.Fc(function(){return tS},$CLJS.J(RK,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VL,"metabase/mbql/schema.cljc",55,NN,1,659,659,new $CLJS.Q(null,3,5,$CLJS.R,[NN,XP,$CLJS.X($CLJS.jj,fP)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.p(tS)?tS.D:null])):null));return $CLJS.p(a)?a:VL}(),tS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof uS?new $CLJS.Fc(function(){return uS},$CLJS.J(EQ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,
$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RM,"metabase/mbql/schema.cljc",53,YI,1,662,662,new $CLJS.Q(null,3,5,$CLJS.R,[YI,XP,$CLJS.X($CLJS.jj,fP)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.p(uS)?uS.D:null])):null));return $CLJS.p(a)?a:RM}(),uS],null),
new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof vS?new $CLJS.Fc(function(){return vS},$CLJS.J(EP,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[pQ,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),QP,"metabase/mbql/schema.cljc",69,QI,1,665,665,new $CLJS.Q(null,5,5,$CLJS.R,[QI,XP,$CLJS.X($CLJS.jj,fP),WP,$CLJS.X($CLJS.jj,fP)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.p(vS)?vS.D:null])):null));return $CLJS.p(a)?a:QP}(),vS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof wS?new $CLJS.Fc(function(){return wS},$CLJS.J(kM,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,
$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[pQ,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qL,"metabase/mbql/schema.cljc",68,sL,1,668,668,new $CLJS.Q(null,3,5,$CLJS.R,[sL,XP,$CLJS.X($CLJS.jj,fP)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.p(wS)?wS.D:null])):null));return $CLJS.p(a)?
a:qL}(),wS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof xS?new $CLJS.Fc(function(){return xS},$CLJS.J(LP,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[pQ,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bM,"metabase/mbql/schema.cljc",67,nI,1,671,671,new $CLJS.Q(null,3,5,$CLJS.R,[nI,XP,$CLJS.X($CLJS.jj,fP)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.p(xS)?xS.D:null])):null));return $CLJS.p(a)?a:bM}(),xS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof yS?new $CLJS.Fc(function(){return yS},$CLJS.J(uJ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,
$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[pQ,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DQ,"metabase/mbql/schema.cljc",67,yQ,1,674,674,new $CLJS.Q(null,3,5,$CLJS.R,[yQ,XP,$CLJS.X($CLJS.jj,fP)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.p(yS)?yS.D:null])):null));return $CLJS.p(a)?a:DQ}(),yS],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof wT?new $CLJS.Fc(function(){return wT},$CLJS.J(OI,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[DI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AM,"metabase/mbql/schema.cljc",61,$CLJS.nK,1,929,929,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.nK,$CLJS.ax,$CLJS.X($CLJS.jj,FK),$CLJS.Li,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,bN))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.p(wT)?wT.D:null])):null));return $CLJS.p(a)?a:AM}(),wT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof AS?new $CLJS.Fc(function(){return AS},$CLJS.J(EJ,new $CLJS.n(null,
1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KK,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SM,"metabase/mbql/schema.cljc",65,$CLJS.KK,1,693,693,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.KK,DK,$CLJS.X($CLJS.jj,WQ),EK,$CLJS.X($CLJS.jj,WQ),$CLJS.ci,$CLJS.X($CLJS.jj,
sP)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",$CLJS.p(AS)?AS.D:null])):null));return $CLJS.p(a)?a:SM}(),AS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof BS?new $CLJS.Fc(function(){return BS},$CLJS.J(JO,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,
1,[$CLJS.LO,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AN,"metabase/mbql/schema.cljc",71,$CLJS.LO,1,698,698,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.LO,jM,$CLJS.X($CLJS.jj,WQ),$CLJS.ci,$CLJS.X($CLJS.jj,hN),$CLJS.ii,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,jQ))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.p(BS)?BS.D:null])):null));return $CLJS.p(a)?a:AN}(),BS],null),
new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof CS?new $CLJS.Fc(function(){return CS},$CLJS.J(PL,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.LO,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HJ,"metabase/mbql/schema.cljc",71,$CLJS.qJ,1,704,704,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.qJ,VM,$CLJS.X($CLJS.jj,WQ)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.p(CS)?CS.D:null])):null));return $CLJS.p(a)?a:HJ}(),CS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof DS?new $CLJS.Fc(function(){return DS},$CLJS.J(SK,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,
$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.LO,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vN,"metabase/mbql/schema.cljc",74,$CLJS.FQ,1,707,707,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.FQ,VM,$CLJS.X($CLJS.jj,WQ)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",$CLJS.p(DS)?DS.D:null])):
null));return $CLJS.p(a)?a:vN}(),DS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof ES?new $CLJS.Fc(function(){return ES},$CLJS.J(qN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.LO,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eO,"metabase/mbql/schema.cljc",72,$CLJS.mI,1,710,710,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.mI,VM,$CLJS.X($CLJS.jj,WQ)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.p(ES)?ES.D:null])):null));return $CLJS.p(a)?a:eO}(),ES],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof FS?new $CLJS.Fc(function(){return FS},$CLJS.J(iQ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,
$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.LO,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cO,"metabase/mbql/schema.cljc",71,$CLJS.bO,1,713,713,!0,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.bO,VM,$CLJS.X($CLJS.jj,WQ),$CLJS.ii,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,jQ))],null),$CLJS.Kc,"Schema for a valid get-week clause.",
$CLJS.p(FS)?FS.D:null])):null));return $CLJS.p(a)?a:cO}(),FS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof GS?new $CLJS.Fc(function(){return GS},$CLJS.J(CI,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.LO,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SN,"metabase/mbql/schema.cljc",70,$CLJS.YN,1,717,717,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YN,VM,$CLJS.X($CLJS.jj,WQ)],null),$CLJS.Kc,"Schema for a valid get-day clause.",$CLJS.p(GS)?GS.D:null])):null));return $CLJS.p(a)?a:SN}(),GS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==
typeof HS?new $CLJS.Fc(function(){return HS},$CLJS.J(YM,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.LO,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IN,"metabase/mbql/schema.cljc",78,$CLJS.uO,1,720,720,!0,new $CLJS.Q(null,3,5,$CLJS.R,
[$CLJS.uO,VM,$CLJS.X($CLJS.jj,WQ)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.p(HS)?HS.D:null])):null));return $CLJS.p(a)?a:IN}(),HS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof IS?new $CLJS.Fc(function(){return IS},$CLJS.J(TM,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,
$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.LO,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),II,"metabase/mbql/schema.cljc",71,$CLJS.aP,1,723,723,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.aP,jM,$CLJS.X($CLJS.jj,WQ)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.p(IS)?IS.D:null])):null));return $CLJS.p(a)?a:II}(),IS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=
Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof JS?new $CLJS.Fc(function(){return JS},$CLJS.J(VO,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.LO,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LQ,"metabase/mbql/schema.cljc",73,$CLJS.jO,1,726,726,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jO,jM,$CLJS.X($CLJS.jj,WQ)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.p(JS)?JS.D:null])):null));return $CLJS.p(a)?a:LQ}(),JS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof KS?new $CLJS.Fc(function(){return KS},$CLJS.J(zM,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,
$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.LO,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HM,"metabase/mbql/schema.cljc",73,$CLJS.pJ,1,729,729,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.pJ,jM,$CLJS.X($CLJS.jj,WQ)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.p(KS)?KS.D:
null])):null));return $CLJS.p(a)?a:HM}(),KS],null)]));
RS=cI($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof dS?new $CLJS.Fc(function(){return dS},$CLJS.J(rO,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),nO,"metabase/mbql/schema.cljc",59,$CLJS.qI,1,613,613,new $CLJS.Q(null,7,5,$CLJS.R,[$CLJS.qI,$CLJS.Sz,$CLJS.X($CLJS.jj,tP),$CLJS.mv,$CLJS.X($CLJS.jj,LK),$CLJS.Iy,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,fP))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.p(dS)?dS.D:null])):null));return $CLJS.p(a)?a:nO}(),dS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof fS?new $CLJS.Fc(function(){return fS},
$CLJS.J(VP,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gP,"metabase/mbql/schema.cljc",54,mP,1,619,619,new $CLJS.Q(null,3,5,$CLJS.R,[mP,$CLJS.Sz,$CLJS.X($CLJS.jj,tP)],null),$CLJS.Kc,"Schema for a valid trim clause.",
$CLJS.p(fS)?fS.D:null])):null));return $CLJS.p(a)?a:gP}(),fS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof hS?new $CLJS.Fc(function(){return hS},$CLJS.J(gN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wO,"metabase/mbql/schema.cljc",55,xP,1,625,625,new $CLJS.Q(null,3,5,$CLJS.R,[xP,$CLJS.Sz,$CLJS.X($CLJS.jj,tP)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.p(hS)?hS.D:null])):null));return $CLJS.p(a)?a:wO}(),hS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof gS?
new $CLJS.Fc(function(){return gS},$CLJS.J(yO,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$M,"metabase/mbql/schema.cljc",55,aR,1,622,622,new $CLJS.Q(null,3,5,$CLJS.R,[aR,$CLJS.Sz,$CLJS.X($CLJS.jj,
tP)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.p(gS)?gS.D:null])):null));return $CLJS.p(a)?a:$M}(),gS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof kS?new $CLJS.Fc(function(){return kS},$CLJS.J(uI,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,
1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eQ,"metabase/mbql/schema.cljc",57,RQ,1,634,634,new $CLJS.Q(null,7,5,$CLJS.R,[RQ,$CLJS.Sz,$CLJS.X($CLJS.jj,tP),hJ,$CLJS.X($CLJS.jj,$CLJS.QL),pL,$CLJS.X($CLJS.jj,$CLJS.QL)],null),$CLJS.Kc,"Schema for a valid replace clause.",$CLJS.p(kS)?kS.D:null])):null));return $CLJS.p(a)?a:eQ}(),kS],null),new $CLJS.Q(null,2,5,$CLJS.R,
[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof jS?new $CLJS.Fc(function(){return jS},$CLJS.J(DO,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZQ,"metabase/mbql/schema.cljc",55,$CLJS.Ps,1,631,631,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ps,$CLJS.Sz,$CLJS.X($CLJS.jj,tP)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.p(jS)?jS.D:null])):null));return $CLJS.p(a)?a:ZQ}(),jS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof iS?new $CLJS.Fc(function(){return iS},$CLJS.J(gQ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,
$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JP,"metabase/mbql/schema.cljc",55,$CLJS.Qs,1,628,628,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Qs,$CLJS.Sz,$CLJS.X($CLJS.jj,tP)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.p(iS)?iS.D:null])):null));
return $CLJS.p(a)?a:JP}(),iS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof lS?new $CLJS.Fc(function(){return lS},$CLJS.J(MN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bL,"metabase/mbql/schema.cljc",56,SI,1,637,637,new $CLJS.Q(null,7,5,$CLJS.R,[SI,dQ,$CLJS.X($CLJS.jj,tP),cQ,$CLJS.X($CLJS.jj,tP),hK,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,tP))],null),$CLJS.Kc,"Schema for a valid concat clause.",$CLJS.p(lS)?lS.D:null])):null));return $CLJS.p(a)?a:bL}(),lS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof mS?new $CLJS.Fc(function(){return mS},$CLJS.J(qO,
new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,2,[$CLJS.KN,"null",hO,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FJ,"metabase/mbql/schema.cljc",74,mN,1,640,640,new $CLJS.Q(null,5,5,$CLJS.R,[mN,$CLJS.Sz,$CLJS.X($CLJS.jj,tP),pI,$CLJS.X($CLJS.jj,$CLJS.QL)],
null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.p(mS)?mS.D:null])):null));return $CLJS.p(a)?a:FJ}(),mS],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof cS?new $CLJS.Fc(function(){return cS},$CLJS.J(BO,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,
1,[$CLJS.KN,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lP,"metabase/mbql/schema.cljc",58,HN,1,610,610,new $CLJS.Q(null,7,5,$CLJS.R,[HN,dQ,$CLJS.X($CLJS.jj,HI),cQ,$CLJS.X($CLJS.jj,HI),hK,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.ui,HI))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.p(cS)?cS.D:null])):null));return $CLJS.p(a)?a:lP}(),cS],null),new $CLJS.Q(null,2,5,$CLJS.R,
[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof wT?new $CLJS.Fc(function(){return wT},$CLJS.J(OI,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[DI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AM,"metabase/mbql/schema.cljc",61,$CLJS.nK,1,929,929,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.nK,$CLJS.ax,$CLJS.X($CLJS.jj,FK),$CLJS.Li,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,bN))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.p(wT)?wT.D:null])):null));return $CLJS.p(a)?a:AM}(),wT],null)]));$CLJS.xT=TH.m($CLJS.H([$CLJS.Xe(bI,PR),TR,$CLJS.Xe(bI,MR),NR,$CLJS.Xe(bI,QR),ZR,$CLJS.Xe(bI,SR),$CLJS.WR,$CLJS.Xe(bI,$CLJS.nK),wT,$CLJS.Es,$CLJS.JR]));
yT=aI($CLJS.Ry,$CLJS.H(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,$CLJS.JR],null)]));zT=aI($CLJS.jN,$CLJS.H(["field",new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ai,$CLJS.JR],null)]));AT=aI($CLJS.lM,$CLJS.H(["field-or-expression",$CLJS.xT]));BT=aI($CLJS.tQ,$CLJS.H(["field-or-expression",$CLJS.xT]));CT=aI($CLJS.BJ,$CLJS.H(["field-or-expression",$CLJS.xT]));DT=aI($CLJS.TO,$CLJS.H(["field-or-expression",$CLJS.xT]));ET=aI($CLJS.si,$CLJS.H(["field-or-expression",$CLJS.xT]));
FT=aI($CLJS.vj,$CLJS.H(["field-or-expression",$CLJS.xT]));GT=aI($CLJS.YP,$CLJS.H(["field-or-expression",$CLJS.xT,"pred",$CLJS.SS]));HT=aI($CLJS.gO,$CLJS.H(["pred",$CLJS.SS]));IT=aI($CLJS.QN,$CLJS.H(["pred",$CLJS.SS]));JT=aI($CLJS.jP,$CLJS.H(["field-or-expression",$CLJS.xT]));KT=aI($CLJS.QJ,$CLJS.H(["field-or-expression",$CLJS.xT]));LT=aI($CLJS.YO,$CLJS.H(["field-or-expression",$CLJS.xT]));MT=aI($CLJS.hL,$CLJS.H(["field-or-expression",$CLJS.xT,"percentile",VR]));
NT=aI(nM,$CLJS.H(["metric-id",$CLJS.BH($CLJS.H([$CLJS.FH,$CLJS.CH]))]));
OT=UH($CLJS.Xe(bI,PR),TR,cI($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof AT?new $CLJS.Fc(function(){return AT},$CLJS.J(oO,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[DI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vM,"metabase/mbql/schema.cljc",60,$CLJS.lM,1,968,968,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.lM,KP,$CLJS.X($CLJS.jj,vP)],null),$CLJS.Kc,"Schema for a valid avg clause.",$CLJS.p(AT)?AT.D:null])):null));return $CLJS.p(a)?a:vM}(),AT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof BT?new $CLJS.Fc(function(){return BT},$CLJS.J(nL,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,
$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[DI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UP,"metabase/mbql/schema.cljc",64,$CLJS.tQ,1,969,969,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.tQ,KP,$CLJS.X($CLJS.jj,vP)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.p(BT)?BT.D:null])):null));return $CLJS.p(a)?
a:UP}(),BT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof CT?new $CLJS.Fc(function(){return CT},$CLJS.J(QK,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[DI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EM,"metabase/mbql/schema.cljc",65,$CLJS.BJ,1,970,970,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.BJ,KP,$CLJS.X($CLJS.jj,vP)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.p(CT)?CT.D:null])):null));return $CLJS.p(a)?a:EM}(),CT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof JT?new $CLJS.Fc(function(){return JT},$CLJS.J(AP,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,
$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[xO,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MI,"metabase/mbql/schema.cljc",76,$CLJS.jP,1,984,984,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jP,KP,$CLJS.X($CLJS.jj,vP)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.p(JT)?JT.D:null])):null));return $CLJS.p(a)?
a:MI}(),JT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof DT?new $CLJS.Fc(function(){return DT},$CLJS.J(BN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[DI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),lJ,"metabase/mbql/schema.cljc",60,$CLJS.TO,1,971,971,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.TO,KP,$CLJS.X($CLJS.jj,vP)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.p(DT)?DT.D:null])):null));return $CLJS.p(a)?a:lJ}(),DT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof ET?new $CLJS.Fc(function(){return ET},$CLJS.J(VI,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,
$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[DI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.cj,"metabase/mbql/schema.cljc",60,$CLJS.si,1,972,972,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.si,KP,$CLJS.X($CLJS.jj,vP)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.p(ET)?ET.D:null])):null));return $CLJS.p(a)?
a:$CLJS.cj}(),ET],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof FT?new $CLJS.Fc(function(){return FT},$CLJS.J(BI,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[DI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.yj,"metabase/mbql/schema.cljc",60,$CLJS.vj,1,973,973,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.vj,KP,$CLJS.X($CLJS.jj,vP)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.p(FT)?FT.D:null])):null));return $CLJS.p(a)?a:$CLJS.yj}(),FT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof NT?new $CLJS.Fc(function(){return NT},$CLJS.J(IK,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,
$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aL,"metabase/mbql/schema.cljc",26,nM,1,1003,1003,!0,new $CLJS.Q(null,3,5,$CLJS.R,[nM,uL,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.UM,cK,ZP))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.p(NT)?NT.D:null])):null));return $CLJS.p(a)?a:aL}(),NT],null),new $CLJS.Q(null,
2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof IT?new $CLJS.Fc(function(){return IT},$CLJS.J(qM,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[DI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),PK,"metabase/mbql/schema.cljc",62,$CLJS.QN,1,981,981,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QN,$CLJS.fj,$CLJS.X($CLJS.jj,cM)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.p(IT)?IT.D:null])):null));return $CLJS.p(a)?a:PK}(),IT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof HT?new $CLJS.Fc(function(){return HT},$CLJS.J(cJ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,
$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[DI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iJ,"metabase/mbql/schema.cljc",68,$CLJS.gO,1,978,978,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.gO,$CLJS.fj,$CLJS.X($CLJS.jj,cM)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.p(HT)?HT.D:null])):null));
return $CLJS.p(a)?a:iJ}(),HT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof GT?new $CLJS.Fc(function(){return GT},$CLJS.J(BQ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[DI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xM,"metabase/mbql/schema.cljc",66,$CLJS.YP,1,975,975,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.YP,KP,$CLJS.X($CLJS.jj,vP),$CLJS.fj,$CLJS.X($CLJS.jj,cM)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",$CLJS.p(GT)?GT.D:null])):null));return $CLJS.p(a)?a:xM}(),GT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof wT?new $CLJS.Fc(function(){return wT},$CLJS.J(OI,new $CLJS.n(null,1,[XO,
!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[DI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AM,"metabase/mbql/schema.cljc",61,$CLJS.nK,1,929,929,new $CLJS.Q(null,5,5,$CLJS.R,[$CLJS.nK,$CLJS.ax,$CLJS.X($CLJS.jj,FK),$CLJS.Li,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,bN))],null),
$CLJS.Kc,"Schema for a valid case clause.",$CLJS.p(wT)?wT.D:null])):null));return $CLJS.p(a)?a:AM}(),wT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof LT?new $CLJS.Fc(function(){return LT},$CLJS.J($O,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[sM,"null"],
null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",68,$CLJS.YO,1,991,991,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.YO,KP,$CLJS.X($CLJS.jj,vP)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.p(LT)?LT.D:null])):null));return $CLJS.p(a)?a:TP}(),LT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&
"undefined"!==typeof jI&&"undefined"!==typeof MT?new $CLJS.Fc(function(){return MT},$CLJS.J(pN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[sM,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cP,"metabase/mbql/schema.cljc",72,$CLJS.hL,1,994,994,new $CLJS.Q(null,
5,5,$CLJS.R,[$CLJS.hL,KP,$CLJS.X($CLJS.jj,vP),$CLJS.hL,$CLJS.X($CLJS.jj,fP)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.p(MT)?MT.D:null])):null));return $CLJS.p(a)?a:cP}(),MT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof KT?new $CLJS.Fc(function(){return KT},$CLJS.J(OJ,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,
$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JI,"metabase/mbql/schema.cljc",77,$CLJS.QJ,1,988,988,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.QJ,KP,$CLJS.X($CLJS.jj,vP)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.p(KT)?KT.D:null])):null));return $CLJS.p(a)?a:JI}(),KT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&
"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof zT?new $CLJS.Fc(function(){return zT},$CLJS.J(FI,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[DI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eM,"metabase/mbql/schema.cljc",
74,$CLJS.jN,1,958,958,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.jN,$CLJS.fN,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,aK))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",$CLJS.p(zT)?zT.D:null])):null));return $CLJS.p(a)?a:eM}(),zT],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof yT?new $CLJS.Fc(function(){return yT},$CLJS.J(MK,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([rM,$CLJS.Di,$CLJS.T,
$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,NP,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[new $CLJS.S(null,new $CLJS.n(null,1,[DI,"null"],null),null),$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.hi,"metabase/mbql/schema.cljc",70,$CLJS.Ry,1,957,957,!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Ry,$CLJS.fN,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.lH,aK))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.p(yT)?
yT.D:null])):null));return $CLJS.p(a)?a:$CLJS.hi}(),yT],null)])));PT=WH(new $CLJS.Fc(function(){return OT},$P,$CLJS.Bg([$CLJS.Lj,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[!0,$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lQ,"metabase/mbql/schema.cljc",35,1,1009,1009,$CLJS.Kc,null,$CLJS.p(OT)?OT.D:null])));
QT=$CLJS.Je([PH($CLJS.T),$CLJS.CH,PH($CLJS.ZJ),$CLJS.CH,$CLJS.yH,$CLJS.tH]);$CLJS.RT=aI($CLJS.EI,$CLJS.H(["aggregation",PT,"options",QT]));$CLJS.UR=UH($CLJS.Xe(bI,$CLJS.EI),$CLJS.RT,PT);ST=aI($CLJS.wM,$CLJS.H(["field",$CLJS.LR]));TT=aI($CLJS.SL,$CLJS.H(["field",$CLJS.LR]));
$CLJS.UT=cI($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof ST?new $CLJS.Fc(function(){return ST},$CLJS.J(qP,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DL,"metabase/mbql/schema.cljc",15,$CLJS.wM,1,1047,1047,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.wM,$CLJS.fN,$CLJS.X($CLJS.jj,UK)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.p(ST)?ST.D:null])):null));return $CLJS.p(a)?a:DL}(),ST],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof TT?new $CLJS.Fc(function(){return TT},$CLJS.J(kL,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,
$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EO,"metabase/mbql/schema.cljc",16,$CLJS.SL,1,1048,1048,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.SL,$CLJS.fN,$CLJS.X($CLJS.jj,UK)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.p(TT)?TT.D:null])):null));return $CLJS.p(a)?a:EO}(),TT],null)]));
RH.m($CLJS.H([NI,LJ,$CLJS.rI,$CLJS.Ns,$CLJS.kC,VM]));VT=$CLJS.Je([PH($CLJS.Ei),$CLJS.CH,$CLJS.T,$CLJS.CH,$CLJS.ZJ,$CLJS.CH,$CLJS.yH,$CLJS.tH]);WT=$CLJS.sk.m($CLJS.H([VT,$CLJS.Je([$CLJS.Ri,$CLJS.$F(NI),AI,$CLJS.CH,EN,$CLJS.FH,PH(FN),$CLJS.FH])]));XT=$CLJS.sk.m($CLJS.H([VT,new $CLJS.n(null,2,[$CLJS.Ri,$CLJS.$F(LJ),FP,$CLJS.FH],null)]));YT=$CLJS.sk.m($CLJS.H([VT,$CLJS.Je([PH($CLJS.Ph),$CLJS.tH,PH(BK),$CLJS.vH])]));
$T=$CLJS.sk.m($CLJS.H([YT,new $CLJS.n(null,3,[$CLJS.Ri,$CLJS.$F($CLJS.rI),$CLJS.rI,HR,$CLJS.uK,WH(new $CLJS.Fc(function(){return ZT},tO,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BM,"metabase/mbql/schema.cljc",23,1,1144,!0,1144,$CLJS.Kc,null,$CLJS.p(ZT)?ZT.D:null])))],null)]));
aU=$CLJS.Ue.h(RH,new $CLJS.S(null,new $CLJS.n(null,4,[VM,null,$CLJS.Ns,null,$CLJS.oi,null,$CLJS.kC,null],null),null));bU=$CLJS.sk.m($CLJS.H([YT,new $CLJS.n(null,1,[$CLJS.Ri,aU],null)]));cU=TH.m($CLJS.H([function(a){return $CLJS.F.h($CLJS.Ri.g(a),$CLJS.rI)},$T,function(a){return $CLJS.F.h($CLJS.Ri.g(a),NI)},WT,function(a){return $CLJS.F.h($CLJS.Ri.g(a),LJ)},XT,$CLJS.Es,bU]));
dU=$CLJS.fG($CLJS.Je([$CLJS.CH,cU]),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.F.h(c,$CLJS.T.g(b))},a)},"keys in template tag map must match the :name of their values");eU=$CLJS.Je([$CLJS.PI,$CLJS.tH,PH($CLJS.FO),dU,PH(WK),$CLJS.YH($CLJS.CH),$CLJS.yH,$CLJS.tH]);
gU=UH($CLJS.hk.h($CLJS.xd,$CLJS.VN),$CLJS.MH(eU,new $CLJS.n(null,1,[$CLJS.PI,$CLJS.VN],null)),WH(new $CLJS.Fc(function(){return $CLJS.fU},dM,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",25,1,1224,!0,1224,$CLJS.Kc,null,$CLJS.p($CLJS.fU)?$CLJS.fU.D:null]))));
hU=$CLJS.Je([$CLJS.T,$CLJS.CH,$CLJS.DD,$CLJS.DH,DN,$CLJS.CH,PH($CLJS.tL),$CLJS.YH($CLJS.EH),PH($CLJS.vK),$CLJS.YH($CLJS.HH),$CLJS.tH,$CLJS.tH]);$CLJS.iU=/^card__[1-9]\d*$/;jU=$CLJS.BH($CLJS.H([$CLJS.FH,$CLJS.iU]));kU=$CLJS.Ue.h(RH,new $CLJS.S(null,new $CLJS.n(null,4,[PP,null,tI,null,XM,null,SO,null],null),null));
mU=$CLJS.fG($CLJS.Bg([$CLJS.yH,PH($CLJS.NL),PH($CLJS.hM),PH($CLJS.gL),PH($CLJS.$I),uQ,PH($CLJS.PO),PH(IO),PH($CLJS.UQ)],[$CLJS.tH,$CLJS.YH(new $CLJS.Q(null,1,5,$CLJS.R,[hU],null)),jU,$CLJS.CH,$CLJS.dG($CLJS.BH($CLJS.H([RH.m($CLJS.H([$CLJS.dA,GM])),WH(new $CLJS.Fc(function(){return lU},RO,$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,sO,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TN,"metabase/mbql/schema.cljc",16,1,1271,!0,1271,$CLJS.Kc,null,$CLJS.p(lU)?lU.D:null])))])),"Valid Join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."),$CLJS.SS,gU,$CLJS.YH($CLJS.FH),kU]),$CLJS.hk.h($CLJS.ik.h($CLJS.hM,$CLJS.PO),$CLJS.Ve($CLJS.hk.h($CLJS.hM,$CLJS.PO))),"Joins must have either a `source-table` or `source-query`, but not both.");
nU=$CLJS.fG(dI(new $CLJS.Q(null,1,5,$CLJS.R,[mU],null)),function(a){return fI($CLJS.ff($CLJS.Sa,$CLJS.ih.h($CLJS.gL,a)))},"All join aliases must be unique.");lU=$CLJS.dG($CLJS.fG(dI(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.JR],null)),fI,"distinct"),"Distinct, non-empty sequence of Field clauses");
$CLJS.fU=$CLJS.fG($CLJS.fG($CLJS.Bg([$CLJS.yH,PH($CLJS.oQ),PH($CLJS.NL),PH($CLJS.XJ),PH($CLJS.hM),PH($CLJS.AJ),PH($CLJS.$I),PH($CLJS.LL),PH($CLJS.zL),PH($CLJS.PO),PH($Q),PH(ZN),PH($CLJS.KN)],[$CLJS.tH,$CLJS.fG(dI(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.UT],null)),fI,"distinct"),$CLJS.YH(new $CLJS.Q(null,1,5,$CLJS.R,[hU],null)),nU,jU,$CLJS.SS,lU,dI(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.UR],null)),dI(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.JR],null)),gU,$CLJS.GH,new $CLJS.n(null,2,[ZN,$CLJS.FH,gK,$CLJS.FH],null),
$CLJS.Je([$CLJS.CH,$CLJS.xT])]),function(a){return $CLJS.F.h(1,$CLJS.E($CLJS.Lg(a,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.PO,$CLJS.hM],null))))},"Query must specify either `:source-table` or `:source-query`, but not both."),function(a){var b=$CLJS.Ke(a);a=$CLJS.K.h(b,$CLJS.zL);b=$CLJS.K.h(b,$CLJS.$I);return $CLJS.sd(lI.h($CLJS.Qg(a),$CLJS.Qg(b)))},"Fields specified in `:breakout` should not be specified in `:fields`; this is implied.");
oU=$CLJS.Bg([$CLJS.HP,VM,JK,tK,OL,SQ,mL,$CLJS.PQ,$CLJS.Ns,lL,XI,oP,vQ,$CLJS.oJ,nQ,HK,$CLJS.YL,iL,$CLJS.Ei,$CLJS.fL,GI,$CLJS.TJ,$CLJS.dL,QM,$CLJS.oi,$CLJS.kC,ZK],[new $CLJS.n(null,1,[BL,new $CLJS.S(null,new $CLJS.n(null,5,[$CLJS.HP,null,VM,null,$CLJS.Ns,null,$CLJS.oi,null,$CLJS.kC,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.Ri,VM,BL,new $CLJS.S(null,new $CLJS.n(null,5,[$CLJS.HP,null,VM,null,$CLJS.PQ,null,$CLJS.YL,null,$CLJS.Ei,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.Ri,$CLJS.pj,nN,
GP,BL,new $CLJS.S(null,new $CLJS.n(null,1,[JK,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.Ri,XQ,nN,GP,BL,new $CLJS.S(null,new $CLJS.n(null,5,[$CLJS.HP,null,tK,null,$CLJS.Ns,null,$CLJS.Ei,null,GI,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.Ri,$CLJS.pj,nN,dJ,BL,new $CLJS.S(null,new $CLJS.n(null,1,[OL,null],null),null)],null),new $CLJS.n(null,1,[BL,new $CLJS.S(null,new $CLJS.n(null,1,[SQ,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.Ri,XQ,nN,KO,BL,new $CLJS.S(null,new $CLJS.n(null,
1,[mL,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.Ri,VM,BL,new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.PQ,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.Ri,XQ,BL,new $CLJS.S(null,new $CLJS.n(null,5,[$CLJS.HP,null,tK,null,$CLJS.Ns,null,$CLJS.Ei,null,GI,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.Ri,XQ,nN,dJ,BL,new $CLJS.S(null,new $CLJS.n(null,1,[lL,null],null),null)],null),new $CLJS.n(null,1,[BL,new $CLJS.S(null,new $CLJS.n(null,1,[XI,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.Ri,
$CLJS.pj,nN,GP,BL,new $CLJS.S(null,new $CLJS.n(null,8,[$CLJS.HP,null,SQ,null,XI,null,oP,null,iL,null,$CLJS.Ei,null,GI,null,$CLJS.kC,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.Ri,$CLJS.pj,nN,dJ,BL,new $CLJS.S(null,new $CLJS.n(null,1,[vQ,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.Ri,VM,BL,new $CLJS.S(null,new $CLJS.n(null,2,[$CLJS.PQ,null,$CLJS.oJ,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.Ri,$CLJS.pj,nN,dJ,BL,new $CLJS.S(null,new $CLJS.n(null,1,[nQ,null],null),null)],null),
new $CLJS.n(null,3,[$CLJS.Ri,$CLJS.pj,nN,dJ,BL,new $CLJS.S(null,new $CLJS.n(null,1,[HK,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.Ri,VM,BL,new $CLJS.S(null,new $CLJS.n(null,5,[$CLJS.HP,null,VM,null,$CLJS.PQ,null,$CLJS.YL,null,$CLJS.Ei,null],null),null)],null),new $CLJS.n(null,1,[BL,new $CLJS.S(null,new $CLJS.n(null,1,[iL,null],null),null)],null),new $CLJS.n(null,1,[BL,new $CLJS.S(null,new $CLJS.n(null,1,[$CLJS.Ei,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.Ri,VM,BL,new $CLJS.S(null,
new $CLJS.n(null,2,[$CLJS.PQ,null,$CLJS.fL,null],null),null)],null),new $CLJS.n(null,1,[BL,new $CLJS.S(null,new $CLJS.n(null,1,[GI,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.Ri,VM,BL,new $CLJS.S(null,new $CLJS.n(null,2,[$CLJS.PQ,null,$CLJS.TJ,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.Ri,VM,BL,new $CLJS.S(null,new $CLJS.n(null,2,[$CLJS.PQ,null,$CLJS.dL,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.Ri,XQ,nN,GP,BL,new $CLJS.S(null,new $CLJS.n(null,1,[QM,null],null),null)],null),
new $CLJS.n(null,2,[$CLJS.Ri,$CLJS.oi,BL,new $CLJS.S(null,new $CLJS.n(null,3,[$CLJS.HP,null,$CLJS.Ei,null,$CLJS.oi,null],null),null)],null),new $CLJS.n(null,2,[$CLJS.Ri,$CLJS.pj,BL,new $CLJS.S(null,new $CLJS.n(null,8,[$CLJS.HP,null,SQ,null,XI,null,oP,null,iL,null,$CLJS.Ei,null,GI,null,$CLJS.kC,null],null),null)],null),new $CLJS.n(null,3,[$CLJS.Ri,XQ,nN,dJ,BL,new $CLJS.S(null,new $CLJS.n(null,1,[ZK,null],null),null)],null)]);ZT=$CLJS.Ue.h(RH,$CLJS.Fg(oU));
pU=aI(DJ,$CLJS.H(["tag-name",$CLJS.BH($CLJS.H([$CLJS.CH,new $CLJS.n(null,1,[$CLJS.Ei,$CLJS.CH],null)]))]));qU=aI($CLJS.rI,$CLJS.H(["target",$CLJS.BH($CLJS.H([$CLJS.JR,pU]))]));rU=aI(cL,$CLJS.H(["target",pU]));
sU=$CLJS.BH($CLJS.H([$CLJS.JR,cI($CLJS.H([new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof qU?new $CLJS.Fc(function(){return qU},$CLJS.J(kN,new $CLJS.n(null,1,[XO,!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fO,"metabase/mbql/schema.cljc",21,$CLJS.rI,1,1570,1570,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.rI,$CLJS.dR,$CLJS.X($CLJS.jj,$CLJS.X($CLJS.UM,aK,AL))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.p(qU)?qU.D:null])):null));return $CLJS.p(a)?a:fO}(),qU],null),new $CLJS.Q(null,2,5,$CLJS.R,[function(){var a=Y.g($CLJS.pd("undefined"!==typeof hI&&"undefined"!==typeof iI&&"undefined"!==typeof jI&&"undefined"!==typeof rU?new $CLJS.Fc(function(){return rU},$CLJS.J(WN,new $CLJS.n(null,1,[XO,
!0],null)),$CLJS.Bg([$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,Y,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,tJ,$CLJS.fi,$CLJS.U,$CLJS.Aj],[$CLJS.J(dP,new $CLJS.n(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",20,cL,1,1573,1573,new $CLJS.Q(null,3,5,$CLJS.R,[cL,$CLJS.dR,$CLJS.X($CLJS.jj,AL)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.p(rU)?rU.D:null])):null));return $CLJS.p(a)?a:YQ}(),rU],null)]))]));
tU=$CLJS.Je([$CLJS.Ri,ZT,PH($CLJS.Ei),$CLJS.CH,PH($CLJS.dR),sU,PH($CLJS.bj),$CLJS.tH,PH($CLJS.T),$CLJS.CH,PH(YJ),$CLJS.CH,PH($CLJS.Ph),$CLJS.tH,$CLJS.yH,$CLJS.tH]);uU=new $CLJS.Q(null,1,5,$CLJS.R,[tU],null);vU=$CLJS.Je([PH(xQ),$CLJS.CH,$CLJS.yH,$CLJS.tH]);wU=$CLJS.fG($CLJS.Je([PH(yM),$CLJS.GH,PH(jJ),$CLJS.GH,$CLJS.yH,$CLJS.tH]),function(a){var b=$CLJS.Ke(a);a=$CLJS.K.h(b,yM);b=$CLJS.K.h(b,jJ);return $CLJS.Ra($CLJS.p(a)?b:a)?!0:a>=b},"max-results-bare-rows must be less or equal to than max-results");
xU=$CLJS.Je([PH(pP),$CLJS.vH,PH(IJ),$CLJS.vH,PH(vI),$CLJS.vH,PH(fK),$CLJS.vH,PH(OO),$CLJS.YH($CLJS.vH),PH(zI),$CLJS.YH($CLJS.vH),PH(DP),$CLJS.YH($CLJS.vH),$CLJS.yH,$CLJS.tH]);yU=RH.m($CLJS.H([LN,WK,lN,aO,iM,kJ,KJ,AK,jL,GO,eR,CL,TK]));
zU=$CLJS.Bg([PH(AQ),PH(GL),PH($CLJS.XK),PH(ZO),PH(fM),PH(wI),PH(rQ),PH(FP),PH($CLJS.GJ)],[$CLJS.YH($CLJS.CH),$CLJS.YH($CLJS.FH),$CLJS.YH(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Je([$CLJS.tH,$CLJS.tH])],null)),$CLJS.YH($CLJS.FH),$CLJS.YH($CLJS.FH),$CLJS.YH($CLJS.tH),$CLJS.YH($CLJS.Je([$CLJS.tH,$CLJS.tH])),$CLJS.YH($CLJS.FH),$CLJS.YH(yU)]);AU=$CLJS.BH($CLJS.H([$CLJS.$F(-1337),$CLJS.FH]));
$CLJS.BU=$CLJS.fG($CLJS.fG($CLJS.fG($CLJS.Bg([$CLJS.yH,PH($CLJS.VN),PH(QO),PH($CLJS.qA),PH(iN),PH(pM),$CLJS.Ri,PH($CLJS.PI),FN,PH($CLJS.JJ)],[$CLJS.tH,eU,$CLJS.YH(vU),$CLJS.YH(zU),$CLJS.YH(wU),$CLJS.YH(xU),RH.m($CLJS.H([$CLJS.PI,$CLJS.VN])),$CLJS.fU,AU,uU]),$CLJS.hk.h($CLJS.ik.h($CLJS.VN,$CLJS.PI),$CLJS.Ve($CLJS.hk.h($CLJS.VN,$CLJS.PI))),"Query must specify either `:native` or `:query`, but not both."),function(a){var b=$CLJS.Ke(a);a=$CLJS.K.h(b,$CLJS.VN);var c=$CLJS.K.h(b,$CLJS.PI);b=$CLJS.K.h(b,
$CLJS.Ri);b=b instanceof $CLJS.M?b.Y:null;switch(b){case "native":return a;case "query":return c;default:throw Error(["No matching clause: ",$CLJS.q.g(b)].join(""));}},"Native queries must specify `:native`; MBQL queries must specify `:query`."),$CLJS.Ve($CLJS.NL),"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)");
(function(a){var b=$CLJS.ZH(a);return function(c){var d=b(c);if($CLJS.p(d))throw $CLJS.Uh($CLJS.TE("Value does not match schema: %s",$CLJS.H([$CLJS.wh.m($CLJS.H([d]))])),new $CLJS.n(null,4,[$CLJS.Ri,$CLJS.PG,$CLJS.zj,a,$CLJS.bj,c,$CLJS.Fs,d],null));return c}})($CLJS.BU);