export function getLimit(lc) {
  return lc;
}

export function updateLimit(lc, limit) {
  return limit;
}

export function clearLimit(lc) {
  return undefined;
}
