var $CLJS = module.exports = {};
/*

 Copyright The Closure Library Authors.
 SPDX-License-Identifier: Apache-2.0
*/
'use strict';var ba,ca,fa;ba=function(){var a=$CLJS.aa.navigator;return a&&(a=a.userAgent)?a:""};ca=function(a){return-1!=ba().indexOf(a)};
$CLJS.ea=function(a,b){const c=Array.prototype.slice.call(arguments),d=c.shift();if("undefined"==typeof d)throw Error("[goog.string.format] Template required");return d.replace(/%([0\- \+]*)(\d+)?(\.(\d+))?([%sfdiu])/g,function(e,f,g,k,l,m,r,v){if("%"==m)return"%";const w=c.shift();if("undefined"==typeof w)throw Error("[goog.string.format] Not enough arguments");arguments[0]=w;return $CLJS.ea.yc[m].apply(null,arguments)})};fa=function(){return ca("iPhone")&&!ca("iPod")&&!ca("iPad")};
$CLJS.ha=$CLJS.ha||{};$CLJS.aa=global;$CLJS.ia="closure_uid_"+(1E9*Math.random()>>>0);$CLJS.aa=global;var ja=String.prototype.repeat?function(a,b){return a.repeat(b)}:function(a,b){return Array(b+1).join(a)};$CLJS.ka=class{constructor(a,b){this.name=a;this.value=b}toString(){return this.name}};$CLJS.la=new $CLJS.ka("OFF",Infinity);$CLJS.ma=[$CLJS.la,new $CLJS.ka("SHOUT",1200),new $CLJS.ka("SEVERE",1E3),new $CLJS.ka("WARNING",900),new $CLJS.ka("INFO",800),new $CLJS.ka("CONFIG",700),new $CLJS.ka("FINE",500),new $CLJS.ka("FINER",400),new $CLJS.ka("FINEST",300),new $CLJS.ka("ALL",0)];$CLJS.ea.yc={};$CLJS.ea.yc.s=function(a,b,c){return isNaN(c)||""==c||a.length>=Number(c)?a:a=-1<b.indexOf("-",0)?a+ja(" ",Number(c)-a.length):ja(" ",Number(c)-a.length)+a};
$CLJS.ea.yc.f=function(a,b,c,d,e){d=a.toString();isNaN(e)||""==e||(d=parseFloat(a).toFixed(e));let f;f=0>Number(a)?"-":0<=b.indexOf("+")?"+":0<=b.indexOf(" ")?" ":"";0<=Number(a)&&(d=f+d);if(isNaN(c)||d.length>=Number(c))return d;d=isNaN(e)?Math.abs(Number(a)).toString():Math.abs(Number(a)).toFixed(e);a=Number(c)-d.length-f.length;0<=b.indexOf("-",0)?d=f+d+ja(" ",a):(b=0<=b.indexOf("0",0)?"0":" ",d=f+ja(b,a)+d);return d};
$CLJS.ea.yc.d=function(a,b,c,d,e,f,g,k){return $CLJS.ea.yc.f(parseInt(a,10),b,c,d,0,f,g,k)};$CLJS.ea.yc.i=$CLJS.ea.yc.d;$CLJS.ea.yc.u=$CLJS.ea.yc.d;ca("Opera");ca("Trident")||ca("MSIE");ca("Edge");!ca("Gecko")||-1!=ba().toLowerCase().indexOf("webkit")&&!ca("Edge")||ca("Trident")||ca("MSIE")||ca("Edge");-1!=ba().toLowerCase().indexOf("webkit")&&!ca("Edge")&&ca("Mobile");ca("Macintosh");ca("Windows");ca("Linux")||ca("CrOS");var na=$CLJS.aa.navigator||null;na&&(na.appVersion||"").indexOf("X11");ca("Android");fa();ca("iPad");ca("iPod");fa()||ca("iPad")||ca("iPod");ba().toLowerCase().indexOf("kaios");Date.now();