var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./schema.utils.js");require("./schema.spec.core.js");
'use strict';var FF,GF,NF,KF,OF,PF;
FF=function(a,b,c){if($CLJS.Bd(a)){var d=$CLJS.B(a);d=d instanceof $CLJS.M?d.Y:null;switch(d){case "schema.spec.collection/optional":return a=$CLJS.C(a),$CLJS.EF.j?$CLJS.EF.j(a,b,c):$CLJS.EF.call(null,a,b,c);case "schema.spec.collection/remaining":if(!$CLJS.F.h(2,$CLJS.E(a)))throw Error($CLJS.UE("remaining can have only one schema."));var e=$CLJS.pF($CLJS.hd(a),b);return function(g,k){$CLJS.zh.j(g,$CLJS.Yf,$CLJS.ih.h(e,k));return c.h?c.h(g,null):c.call(null,g,null)};default:throw Error(["No matching clause: ",
$CLJS.q.g(d)].join(""));}}else{var f=$CLJS.ri.g(a);e=$CLJS.pF(a,b);return function(g,k){var l=function(){function m(r){return $CLJS.zh.j(g,$CLJS.ke,$CLJS.gF(r)?r:e.g?e.g(r):e.call(null,r))}return f.h?f.h(m,k):f.call(null,m,k)}();return c.h?c.h(g,l):c.call(null,g,l)}}};$CLJS.EF=function(a,b,c){if(!$CLJS.Se(function(d){return $CLJS.Bd(d)&&$CLJS.F.h($CLJS.B(d),GF)},$CLJS.Rg(a)))throw Error($CLJS.UE("Remaining schemas must be in tail position."));return $CLJS.$a(function(d,e){return FF(e,b,d)},c,$CLJS.le(a))};
$CLJS.HF=function(a,b,c,d,e,f,g){this.ka=a;this.Ub=b;this.elements=c;this.Kb=d;this.A=e;this.l=f;this.C=g;this.o=2230716170;this.H=139264};$CLJS.IF=function(a,b,c,d){return new $CLJS.HF(a,b,c,d,null,null,null)};$CLJS.JF=function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[GF,new $CLJS.n(null,2,[$CLJS.zj,a,$CLJS.ri,function(){throw Error("should never be not called");}],null)],null)};$CLJS.LF=function(a,b,c){b=new $CLJS.n(null,2,[$CLJS.zj,b,$CLJS.ri,c],null);return $CLJS.p(a)?b:$CLJS.yf($CLJS.ne(KF,$CLJS.H([b])))};
$CLJS.MF=function(a,b,c){return $CLJS.Yf.h($CLJS.yf($CLJS.ne(KF,$CLJS.H([new $CLJS.n(null,2,[$CLJS.zj,a,$CLJS.ri,b],null)]))),c)};GF=new $CLJS.M("schema.spec.collection","remaining","schema.spec.collection/remaining",-421177821);NF=new $CLJS.M(null,"elements","elements",657646735);KF=new $CLJS.M("schema.spec.collection","optional","schema.spec.collection/optional",-854614927);OF=new $CLJS.M(null,"on-error","on-error",1728533530);PF=new $CLJS.M(null,"konstructor","konstructor",-1422324557);$CLJS.h=$CLJS.HF.prototype;$CLJS.h.la=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "pre":return this.ka;case "konstructor":return this.Ub;case "elements":return this.elements;case "on-error":return this.Kb;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};$CLJS.h.Vc=$CLJS.pa(32);
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#schema.spec.collection.CollectionSpec{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.rF,this.ka],null),new $CLJS.Q(null,2,5,$CLJS.R,[PF,this.Ub],null),new $CLJS.Q(null,2,5,$CLJS.R,[NF,this.elements],null),new $CLJS.Q(null,2,5,$CLJS.R,[OF,this.Kb],null)],null),this.l))};
$CLJS.h.na=function(){return new $CLJS.Of(this,4,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.rF,PF,NF,OF],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};$CLJS.h.ga=function(){return 4+$CLJS.E(this.l)};$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=1800698765^$CLJS.Qc(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ka,b.ka)&&$CLJS.F.h(this.Ub,b.Ub)&&$CLJS.F.h(this.elements,b.elements)&&$CLJS.F.h(this.Kb,b.Kb)&&$CLJS.F.h(this.l,b.l)};$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,4,[$CLJS.rF,null,NF,null,PF,null,OF,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new $CLJS.HF(this.ka,this.Ub,this.elements,this.Kb,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "pre":case "konstructor":case "elements":case "on-error":return!0;default:return $CLJS.Kd(this.l,b)}};
$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.rF,b):$CLJS.O.call(null,$CLJS.rF,b))?new $CLJS.HF(c,this.Ub,this.elements,this.Kb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(PF,b):$CLJS.O.call(null,PF,b))?new $CLJS.HF(this.ka,c,this.elements,this.Kb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(NF,b):$CLJS.O.call(null,NF,b))?new $CLJS.HF(this.ka,this.Ub,c,this.Kb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(OF,b):$CLJS.O.call(null,OF,b))?new $CLJS.HF(this.ka,this.Ub,this.elements,
c,this.A,this.l,null):new $CLJS.HF(this.ka,this.Ub,this.elements,this.Kb,this.A,$CLJS.Kg.j(this.l,b,c),null)};$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Ld($CLJS.rF,this.ka),new $CLJS.Ld(PF,this.Ub),new $CLJS.Ld(NF,this.elements),new $CLJS.Ld(OF,this.Kb)],null),this.l))};$CLJS.h.M=function(a,b){return new $CLJS.HF(this.ka,this.Ub,this.elements,this.Kb,b,this.l,this.C)};
$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};