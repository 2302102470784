var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./clojure.string.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");
'use strict';var g1=function(a){var b=new $CLJS.Ca;for(a=$CLJS.A(a);;)if(null!=a)b=b.append($CLJS.q.g($CLJS.B(a))),a=$CLJS.C(a);else return b.toString()},j1=function(a){var b=h1;if("string"===typeof a)return i1(b,a);throw new TypeError("re-seq must match against a string.");},k1=function(a){return new $CLJS.te(null,function(){return $CLJS.ne(a.v?a.v():a.call(null),k1(a))},null)},l1=function(a,b){return new $CLJS.te(null,function(){var c=$CLJS.A(b);if(c){var d=$CLJS.B(c),e=a.g?a.g(d):a.call(null,d),
f=$CLJS.ne(d,$CLJS.Tg(function(g){return $CLJS.F.h(e,a.g?a.g(g):a.call(null,g))},$CLJS.C(c)));return $CLJS.ne(f,l1(a,new $CLJS.te(null,function(){return $CLJS.ws($CLJS.E(f),c)},null)))}return null},null)},m1=function(a,b,c){var d=$CLJS.Ls(a,/\{0\}/);d=$CLJS.F.h($CLJS.E(d),1)?new $CLJS.Q(null,2,5,$CLJS.R,[a,""],null):d;a=$CLJS.$g(/.*\{0\}.*/,a);return $CLJS.Rz.ngettext($CLJS.Rz.msgid($CLJS.tk(d),$CLJS.p(a)?c:""),b,c)},n1=function(a,b){switch(a){case "minutes":return m1("Minute","Minutes",b);case "hours":return m1("Hour",
"Hours",b);case "days":return m1("Day","Days",b);case "weeks":return m1("Week","Weeks",b);case "months":return m1("Month","Months",b);case "quarters":return m1("Quarter","Quarters",b);case "years":return m1("Year","Years",b);default:throw Error(["No matching clause: ",$CLJS.q.g(a)].join(""));}},o1=function(a,b,c){b=parseInt(b);c=n1(c,b);a=new $CLJS.Q(null,2,5,$CLJS.R,[a,$CLJS.F.h(b,1)],null);if($CLJS.F.h(new $CLJS.Q(null,2,5,$CLJS.R,["past",!0],null),a))return $CLJS.HU("Previous {0}",$CLJS.H([c]));
if($CLJS.F.h(new $CLJS.Q(null,2,5,$CLJS.R,["past",!1],null),a))return $CLJS.HU("Previous {0} {1}",$CLJS.H([b,c]));if($CLJS.F.h(new $CLJS.Q(null,2,5,$CLJS.R,["next",!0],null),a))return $CLJS.HU("Next {0}",$CLJS.H([c]));if($CLJS.F.h(new $CLJS.Q(null,2,5,$CLJS.R,["next",!1],null),a))return $CLJS.HU("Next {0} {1}",$CLJS.H([b,c]));throw Error(["No matching clause: ",$CLJS.q.g(a)].join(""));},q1=function(a){return $CLJS.dC(a,p1,function(b){return["\\",$CLJS.q.g(b)].join("")})},r1=function(a,b,c,d,e){this.tag=
a;this.source=b;this.A=c;this.l=d;this.C=e;this.o=2230716170;this.H=139264},t1=function(a){var b=$CLJS.Ls(a,s1),c=$CLJS.ih.h($CLJS.B,j1(a)),d=function(){var e=$CLJS.E(b),f=$CLJS.E(c);return e>f?e:f}();a=$CLJS.ek.h(b,$CLJS.au(d-$CLJS.E(b),k1($CLJS.We(""))));d=$CLJS.ek.h(c,$CLJS.au(d-$CLJS.E(c),k1($CLJS.We(""))));a=$CLJS.ys.h(a,d);return $CLJS.ih.h(function(e){var f=$CLJS.Zg(h1,e);return $CLJS.p(f)?($CLJS.I(f,0,null),f=$CLJS.I(f,1,null),new r1(f,e,null,null,null)):e},a)},u1=function(a){return $CLJS.xs(function(b){return"string"===
typeof $CLJS.B(b)?new $CLJS.Q(null,1,5,$CLJS.R,[g1(b)],null):b},$CLJS.H([l1(function(b){return"string"===typeof b||$CLJS.Ra($CLJS.bj.g(b))},a)]))},w1=function(a,b,c){return $CLJS.ih.h(function(d){if(d instanceof r1){var e=$CLJS.Kg.j;var f=$CLJS.dj.g(d),g=$CLJS.K.h(a,f);f=$CLJS.bj.g(g);g=$CLJS.Ri.g(g);if($CLJS.p(f))try{var k=q1(v1.j(g,f,b))}catch(l){if(l instanceof Error)k=v1.j($CLJS.Ph,f,b);else throw l;}else k=null;d=e.call($CLJS.Kg,d,$CLJS.bj,k)}return d},c)},z1=function(a){a=g1($CLJS.ih.h(function(b){return b instanceof
r1?b:$CLJS.dC(b,x1,"")},a));return $CLJS.dC(a,y1,$CLJS.hd)},B1=function(a){return $CLJS.bC($CLJS.B(A1(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.JJ],null),new $CLJS.Q(null,1,5,$CLJS.R,[a],null))),$CLJS.jh)},i1=function i1(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.yf(d);return $CLJS.ne(f,new $CLJS.te(null,function(){var g=e.length;g=d.index+(1>g?1:g);return g<=b.length?(g=b.substring(g),i1.h?i1.h(a,g):i1.call(null,a,g)):null},null))},A1=function A1(a,b){if($CLJS.Ra($CLJS.A(a)))a=
(0,$CLJS.oZ)(b);else{var d=$CLJS.K,e=d.h;var f=$CLJS.Rg(a);b=$CLJS.Je([$CLJS.id(a),b]);f=A1.h?A1.h(f,b):A1.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};var v1=function(){var a=$CLJS.yh.g($CLJS.P),b=$CLJS.yh.g($CLJS.P),c=$CLJS.yh.g($CLJS.P),d=$CLJS.yh.g($CLJS.P),e=$CLJS.K.j($CLJS.P,$CLJS.Ni,$CLJS.Gh.v?$CLJS.Gh.v():$CLJS.Gh.call(null));return new $CLJS.Qh($CLJS.ph.h("metabase.shared.parameters.parameters","formatted-value"),function(f){return $CLJS.jh.g(f)},e,a,b,c,d)}();v1.P(null,$CLJS.YL,function(a,b,c){return(0,$CLJS.Dt)(b).locale(c).format("MMMM D, YYYY")});
v1.P(null,$CLJS.TJ,function(a,b,c){a=(0,$CLJS.Dt)(b,"YYYY-MM").locale(c);return $CLJS.p(a.isValid())?a.format("MMMM, YYYY"):""});v1.P(null,$CLJS.dL,function(a,b,c){a=(0,$CLJS.Dt)(b,"[Q]Q-YYYY").locale(c);return $CLJS.p(a.isValid())?a.format("[Q]Q, YYYY"):""});v1.P(null,$CLJS.oJ,function(a,b,c){b=$CLJS.Ls(b,/~/);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.p($CLJS.p(a)?b:a)?[$CLJS.q.g(v1.j($CLJS.YL,a,c))," - ",$CLJS.q.g(v1.j($CLJS.YL,b,c))].join(""):""});
v1.P(null,$CLJS.fL,function(a,b){function c(d,e){return $CLJS.C($CLJS.$g(d,e))}if(c(/^today$/,b)||c(/^thisday$/,b))return $CLJS.IU("Today");if(c(/^thisweek$/,b))return $CLJS.IU("This Week");if(c(/^thismonth$/,b))return $CLJS.IU("This Month");if(c(/^thisquarter$/,b))return $CLJS.IU("This Quarter");if(c(/^thisyear$/,b))return $CLJS.IU("This Year");if(c(/^past1days$/,b))return $CLJS.IU("Yesterday");if(c(/^next1days$/,b))return $CLJS.IU("Tomorrow");if(a=c(/^(past|next)([0-9]+)([a-z]+)~?$/,b))return $CLJS.Ue.h(o1,
a);throw Error(["No matching clause: ",$CLJS.q.g(b)].join(""));});v1.P(null,$CLJS.PQ,function(a,b,c){function d(e,f){return $CLJS.hd($CLJS.$g(e,f))}a=d(/^(this[a-z]+)$/,b);if($CLJS.p(a))return v1.j($CLJS.fL,a,c);a=d(/^~?([0-9-T:]+)~?$/,b);if($CLJS.p(a))return v1.j($CLJS.YL,a,c);a=d(/^([0-9-T:]+~[0-9-T:]+)$/,b);return $CLJS.p(a)?v1.j($CLJS.oJ,a,c):v1.j($CLJS.fL,b,c)});
v1.P(null,$CLJS.Ph,function(a,b){$CLJS.wd(b)?(a=$CLJS.E(b),b=$CLJS.p($CLJS.F.h?$CLJS.F.h(1,a):$CLJS.F.call(null,1,a))?$CLJS.q.g($CLJS.B(b)):$CLJS.p($CLJS.F.h?$CLJS.F.h(2,a):$CLJS.F.call(null,2,a))?$CLJS.HU("{0} and {1}",$CLJS.H([$CLJS.B(b),$CLJS.hd(b)])):$CLJS.HU("{0}, {1}, and {2}",$CLJS.H([$CLJS.Ws(", ",$CLJS.bu(2,b)),$CLJS.G(b,$CLJS.E(b)-2),$CLJS.id(b)]))):b=$CLJS.q.g(b);return b});var p1=/[\\\/*_`'\[\](){}<>#+-.!$@%^&=|\?~]/,h1=/\{\{\s*([A-Za-z0-9_\.]+?)\s*\}\}/,s1=/\{\{\s*[A-Za-z0-9_\.]+?\s*\}\}/;
$CLJS.h=r1.prototype;$CLJS.h.toString=function(){var a=$CLJS.bj.g(this);return $CLJS.p(a)?a:this.source};$CLJS.h.la=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "tag":return this.tag;case "source":return this.source;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#metabase.shared.parameters.parameters.TextParam{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,this.tag],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.aX,this.source],null)],null),this.l))};$CLJS.h.na=function(){return new $CLJS.Of(this,2,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.dj,$CLJS.aX],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};
$CLJS.h.ga=function(){return 2+$CLJS.E(this.l)};$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=-884441273^$CLJS.Qc(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.tag,b.tag)&&$CLJS.F.h(this.source,b.source)&&$CLJS.F.h(this.l,b.l)};
$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,2,[$CLJS.aX,null,$CLJS.dj,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new r1(this.tag,this.source,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "tag":case "source":return!0;default:return $CLJS.Kd(this.l,b)}};
$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.dj,b):$CLJS.O.call(null,$CLJS.dj,b))?new r1(c,this.source,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.aX,b):$CLJS.O.call(null,$CLJS.aX,b))?new r1(this.tag,c,this.A,this.l,null):new r1(this.tag,this.source,this.A,$CLJS.Kg.j(this.l,b,c),null)};$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Ld($CLJS.dj,this.tag),new $CLJS.Ld($CLJS.aX,this.source)],null),this.l))};
$CLJS.h.M=function(a,b){return new r1(this.tag,this.source,b,this.l,this.C)};$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};var x1=/\[\[.+\]\]/,y1=/\[\[(.+?)\]\]/,C1=function C1(a){switch(arguments.length){case 2:return C1.h(arguments[0],arguments[1]);case 3:return C1.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.q.g(arguments.length)].join(""));}};
$CLJS.rs("metabase.shared.parameters.parameters.substitute_tags",C1);C1.h=function(a,b){return C1.j(a,b,"en")};C1.j=function(a,b,c){return $CLJS.p(a)?(b=$CLJS.IB(b),b=$CLJS.vs(b,B1),z1(u1(w1(b,c,t1(a))))):null};C1.F=3;module.exports={tag_names:function(a){a=$CLJS.Qg($CLJS.ih.h($CLJS.hd,j1($CLJS.p(a)?a:"")));return $CLJS.tk(a)},substitute_tags:C1};