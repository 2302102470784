var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var Pt,Rt,St,Tt,Ut,Vt,Wt,Xt,Zt,$t,eu,fu,gu,hu,iu,ju,ku,lu,mu,pu,qu,ru,su,tu,uu,vu,wu,xu,Cu,Fu,Hu,Ku,Mu,Nu,Ru,Su,Vu,Wu,Xu,cv,dv,kv,ov,sv,tv,xv,yv,Av,Fv,Jv,Ov,Qv,Rv,Lv,Uv,Vv,Xv,Wv,Yv,cw,dw,nw,ow,hw,pw,tw,ww,yw,zw,Bw,Dw,Ew,Hw,Lw,Mw,Nw,Ow,Pw,Tw,Ww,Xw,Zw,bx,cx,dx,gx,hx,ix,jx,lx,mx,kx,qx,rx,sx,tx,ux,vx,wx,xx,yx,zx,Ax,Cx,Dx,Fx,Jx,Lx,Ox,Px,Ux,Xx,Qx,ay,Kv,Nv,cy,dy,gy,hy,ky,ly,ny,oy,py,ry,sy,ty,qy,du,Au,vy,Qu,Vw,wy,Cv,xy,yy,Du,Ou,zy,Ay,qv,bw,Yu,By,Cy,nv,Dy,Rx,bv,aw,lv,$w,Ey,Tu,Pu,Fy,px,Ix,Gy,Hy,
Cw,Jy,Gw,pv,$v,zu,Hx,jv,Bv,hv,Gu,Ky,Tv,Ly,kw,Fw,Wx,My,Zx,iv,Ny,Oy,Py,Gv,Qy,nx,Vx,Ev,Sy,lw,Tx,jw,Ty,Iu,Uy,fy,$x,jy,Zu,Vy,Jw,Wy,Xy,qw,Yy,av,Zy,ey,$y,Iv,fx,Zv,az,Kw,iy,cz,ex,dz,ez,iw,Lu,fz,rv,gz,Iw,hz,iz,Eu,Gx,Dv,gv,Nx,Ju,Qw,Sx,Uw,Rw,Sw,ox,rw,jz,kz,lz,mz,ou,yu,nz,$u,oz,nu;Pt=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};$CLJS.Qt=function(){};
Rt=function(a){if(null!=a&&null!=a.rc)a=a.rc(a);else{var b=Rt[$CLJS.sa(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Rt._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Xa("IWriter.-flush",a);}return a};St=function(){};Tt=function(a){if(null!=a&&null!=a.Mc)a=a.Mc(a);else{var b=Tt[$CLJS.sa(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Tt._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Xa("IPending.-realized?",a);}return a};
Ut=function(a){if("number"===typeof a)return String.fromCharCode(a);if("string"===typeof a&&1===a.length)return a;throw Error("Argument to char must be a character or number");};Vt=function(a,b){return a-b*$CLJS.ce(a,b)};Wt=function(a){if(null==$CLJS.Ma)throw Error("No *print-fn* fn set for evaluation environment");$CLJS.Ma.call(null,a);return null};
Xt=function(a,b,c){if(null!=a&&null!=a.P)a.P(a,b,c);else{var d=Xt[$CLJS.sa(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=Xt._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.Xa("IMultiFn.-add-method",a);}};$CLJS.Yt=function(a,b){var c=$CLJS.dc($CLJS.P);a=$CLJS.A(a);for(b=$CLJS.A(b);;)if(a&&b)c=$CLJS.ag.j(c,$CLJS.B(a),$CLJS.B(b)),a=$CLJS.C(a),b=$CLJS.C(b);else return $CLJS.fc(c)};Zt=function(a,b){return 0<a?new $CLJS.df(null,a,b,null):$CLJS.Kc};
$t=function(a){return 1>=a?$CLJS.Kc:$CLJS.Id(1)&&$CLJS.Id(a)&&$CLJS.Id(1)?new $CLJS.Wg(null,1,a,1,null,null,null):new $CLJS.Xg(null,1,a,1,null,null,null)};$CLJS.au=function(a,b){return new $CLJS.te(null,function(){if(0<a){var c=$CLJS.A(b);return c?$CLJS.ne($CLJS.B(c),$CLJS.au(a-1,$CLJS.Lc(c))):null}return null},null)};$CLJS.bu=function(a,b){return $CLJS.ih.j(function(c){return c},b,$CLJS.ws(a,b))};
eu=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.Ue.h(cu,0<b.length?new $CLJS.Hc(b.slice(0),0,null):null);$CLJS.ac(du,"\n")};fu=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
gu=function(a,b,c){var d=c;for(c=$CLJS.xf;;){if($CLJS.sd(d))return new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null);var e=$CLJS.B(d);d=$CLJS.C(d);e=$CLJS.Ue.h(a,new $CLJS.Q(null,2,5,$CLJS.R,[e,b],null));b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);c=$CLJS.ke.h(c,b);b=e}};hu=function(a,b){var c=b;for(b=$CLJS.xf;;){var d=$CLJS.Ue.h(a,new $CLJS.Q(null,1,5,$CLJS.R,[c],null));c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.Ra(c))return new $CLJS.Q(null,2,5,$CLJS.R,[b,d],null);b=$CLJS.ke.h(b,c);c=d}};
iu=function(a){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yf.h($CLJS.P,function(){return function d(c){return new $CLJS.te(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),k=$CLJS.we(g);a:for(var l=0;;)if(l<g){var m=$CLJS.ld(f,l),r=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);var v=$CLJS.I(m,0,null);$CLJS.I(m,1,null);k.add(new $CLJS.Q(null,2,5,$CLJS.R,[r,v],null));l+=1}else{f=!0;break a}return f?$CLJS.ze($CLJS.Be(k),d($CLJS.lc(e))):$CLJS.ze($CLJS.Be(k),null)}f=
$CLJS.B(e);k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);g=$CLJS.I(f,0,null);$CLJS.I(f,1,null);return $CLJS.ne(new $CLJS.Q(null,2,5,$CLJS.R,[k,g],null),d($CLJS.Lc(e)))}return null}},null)}(a)}()),$CLJS.Yf.h($CLJS.P,function(){return function d(c){return new $CLJS.te(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),k=$CLJS.we(g);a:for(var l=0;;)if(l<g){var m=$CLJS.ld(f,l),r=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);k.add(new $CLJS.Q(null,
2,5,$CLJS.R,[r,m],null));l+=1}else{f=!0;break a}return f?$CLJS.ze($CLJS.Be(k),d($CLJS.lc(e))):$CLJS.ze($CLJS.Be(k),null)}f=$CLJS.B(e);k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ne(new $CLJS.Q(null,2,5,$CLJS.R,[k,f],null),d($CLJS.Lc(e)))}return null}},null)}(a)}())],null)};
ju=function(a,b){return $CLJS.Yf.h($CLJS.P,function(){return function e(d){return new $CLJS.te(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.Cd(f)){var g=$CLJS.kc(f),k=$CLJS.E(g),l=$CLJS.we(k);a:for(var m=0;;)if(m<k){var r=$CLJS.ld(g,m),v=$CLJS.I(r,0,null);r=$CLJS.I(r,1,null);l.add(new $CLJS.Q(null,2,5,$CLJS.R,[v,new $CLJS.Q(null,2,5,$CLJS.R,[r,b],null)],null));m+=1}else{g=!0;break a}return g?$CLJS.ze($CLJS.Be(l),e($CLJS.lc(f))):$CLJS.ze($CLJS.Be(l),null)}g=$CLJS.B(f);l=$CLJS.I(g,0,null);
g=$CLJS.I(g,1,null);return $CLJS.ne(new $CLJS.Q(null,2,5,$CLJS.R,[l,new $CLJS.Q(null,2,5,$CLJS.R,[g,b],null)],null),e($CLJS.Lc(f)))}return null}},null)}(a)}())};ku=function(){var a=du;if(null!=a&&null!=a.af)a.af(a);else{var b=ku[$CLJS.sa(null==a?null:a)];if(null!=b)b.g?b.g(a):b.call(null,a);else if(b=ku._,null!=b)b.g?b.g(a):b.call(null,a);else throw $CLJS.Xa("IPrettyFlush.-ppflush",a);}};lu=function(a,b){a=$CLJS.t($CLJS.t(a));return b.g?b.g(a):b.call(null,a)};
mu=function(a,b,c){$CLJS.zh.I($CLJS.t(a),$CLJS.Kg,b,c)};pu=function(a,b){$CLJS.F.h(b,"\n")?(mu(a,nu,0),mu(a,$CLJS.Si,lu(a,$CLJS.Si)+1)):mu(a,nu,lu(a,nu)+1);return $CLJS.ac(lu(a,ou),b)};qu=function(a,b,c,d){this.Ca=a;this.pf=b;this.Qc=c;this.nh=d;this.o=1074167808;this.H=0};ru=function(a,b,c,d,e,f,g,k,l,m,r,v,w){this.parent=a;this.Cb=b;this.sb=c;this.qb=d;this.vb=e;this.wb=f;this.prefix=g;this.Bb=k;this.Db=l;this.yb=m;this.A=r;this.l=v;this.C=w;this.o=2230716170;this.H=139264};
su=function(a,b){for(b=$CLJS.Js.g(b);;){if(null==b)return!1;if(a===b)return!0;b=$CLJS.Js.g(b)}};tu=function(a,b,c,d,e,f,g,k){this.ea=a;this.data=b;this.Yb=c;this.da=d;this.ca=e;this.A=f;this.l=g;this.C=k;this.o=2230716170;this.H=139264};uu=function(a,b,c,d,e,f,g,k){this.ea=a;this.type=b;this.ja=c;this.da=d;this.ca=e;this.A=f;this.l=g;this.C=k;this.o=2230716170;this.H=139264};vu=function(a,b,c,d,e,f,g){this.ea=a;this.ja=b;this.da=c;this.ca=d;this.A=e;this.l=f;this.C=g;this.o=2230716170;this.H=139264};
wu=function(a,b,c,d,e,f,g){this.ea=a;this.ja=b;this.da=c;this.ca=d;this.A=e;this.l=f;this.C=g;this.o=2230716170;this.H=139264};xu=function(a,b,c,d,e,f,g,k,l){this.ea=a;this.ja=b;this.Qb=c;this.offset=d;this.da=e;this.ca=f;this.A=g;this.l=k;this.C=l;this.o=2230716170;this.H=139264};
Cu=function(a,b,c){b=$CLJS.A(b);for(var d=null,e=0,f=0;;)if(f<e){var g=d.ta(null,f);if(!$CLJS.F.h(yu.g(g),zu)){var k=Au.g($CLJS.t($CLJS.t(a)));$CLJS.p(k)&&$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),k)}Bu.h(a,g);$CLJS.zh.I($CLJS.t(a),$CLJS.Kg,Au,Au.g(g));g=Au.g($CLJS.t($CLJS.t(a)));$CLJS.p($CLJS.p(c)?g:c)&&($CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),g),$CLJS.zh.I($CLJS.t(a),$CLJS.Kg,Au,null));f+=1}else if(b=$CLJS.A(b))$CLJS.Cd(b)?(d=$CLJS.kc(b),b=$CLJS.lc(b),g=d,e=$CLJS.E(d),d=g):(g=$CLJS.B(b),$CLJS.F.h(yu.g(g),zu)||
(d=Au.g($CLJS.t($CLJS.t(a))),$CLJS.p(d)&&$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),d)),Bu.h(a,g),$CLJS.zh.I($CLJS.t(a),$CLJS.Kg,Au,Au.g(g)),g=Au.g($CLJS.t($CLJS.t(a))),$CLJS.p($CLJS.p(c)?g:c)&&($CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),g),$CLJS.zh.I($CLJS.t(a),$CLJS.Kg,Au,null)),b=$CLJS.C(b),d=null,e=0),f=0;else break};Fu=function(a,b){var c=lu(ou.g($CLJS.t($CLJS.t(a))),$CLJS.vj),d;(d=null==c)||(a=lu(ou.g($CLJS.t($CLJS.t(a))),nu),b=(b=$CLJS.A(b))?Du.g($CLJS.id(b))-Eu.g($CLJS.B(b)):0,d=a+b<c);return d};
Hu=function(a,b,c){b=$CLJS.t(Gu.g(b));return $CLJS.p(b)?b:!Fu(a,c)};Ku=function(a,b,c){var d=Iu.g($CLJS.t($CLJS.t(a))),e=lu(ou.g($CLJS.t($CLJS.t(a))),$CLJS.vj);return $CLJS.p(d)?$CLJS.p(e)?(d=$CLJS.t(Ju.g(b))>=e-d)?Hu(a,b,c):d:e:d};Mu=function(a){var b=$CLJS.B(a),c=Lu.g(b);b=$CLJS.A($CLJS.Tg(function(d){return!($CLJS.F.h(yu.g(d),zu)&&su(Lu.g(d),c))},$CLJS.C(a)));return new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.A($CLJS.ws($CLJS.E(b)+1,a))],null)};
Nu=function(a){var b=$CLJS.B(a),c=Lu.g(b);return $CLJS.A($CLJS.Tg(function(d){var e=Lu.g(d);return!($CLJS.F.h(yu.g(d),zu)&&($CLJS.F.h(e,c)||su(e,c)))},$CLJS.C(a)))};
Ru=function(a,b){$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),"\n");$CLJS.zh.I($CLJS.t(a),$CLJS.Kg,Au,null);b=Lu.g(b);var c=Ou.g(b);$CLJS.p(c)&&$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),c);c=$CLJS.Ue.h($CLJS.q,Zt($CLJS.t(Pu.g(b))-$CLJS.E(c)," "));$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),c);a:for($CLJS.$e(Qu.g(b),!0),$CLJS.$e(Gu.g(b),!0),a=$CLJS.Js.g(b);;)if($CLJS.p(a))$CLJS.$e(Gu.g(a),!0),$CLJS.$e(Qu.g(a),!0),a=$CLJS.Js.g(a);else break a;return null};
Su=function(a){var b=$CLJS.A($CLJS.Tg(function(c){return!$CLJS.F.h(yu.g(c),zu)},a));return new $CLJS.Q(null,2,5,$CLJS.R,[b,$CLJS.A($CLJS.ws($CLJS.E(b),a))],null)};Vu=function(a){for(var b=Tu.g($CLJS.t($CLJS.t(a)));;){$CLJS.zh.I($CLJS.t(a),$CLJS.Kg,Tu,$CLJS.Yf.h($CLJS.xf,b));if(Fu(a,b))return null;var c=Uu(a,b);if(b!==c)b=c;else return null}};Wu=function(a,b){$CLJS.zh.I($CLJS.t(a),$CLJS.Kg,Tu,$CLJS.ke.h(Tu.g($CLJS.t($CLJS.t(a))),b));return Fu(a,Tu.g($CLJS.t($CLJS.t(a))))?null:Vu(a)};
Xu=function(a){var b=Au.g($CLJS.t($CLJS.t(a)));return $CLJS.p(b)?($CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),b),$CLJS.zh.I($CLJS.t(a),$CLJS.Kg,Au,null)):null};
cv=function(a,b){var c=$CLJS.Cs(b,"\n",-1);if($CLJS.F.h($CLJS.E(c),1))return b;b=Ou.g($CLJS.B(Yu.g($CLJS.t($CLJS.t(a)))));var d=$CLJS.B(c);if($CLJS.F.h(Zu,$CLJS.ii.g($CLJS.t($CLJS.t(a))))){var e=$u.g($CLJS.t($CLJS.t(a))),f=e+$CLJS.E(d);$CLJS.zh.I($CLJS.t(a),$CLJS.Kg,$u,f);Wu(a,new tu(av,d,null,e,f,null,null,null));Vu(a);d=Tu.g($CLJS.t($CLJS.t(a)));$CLJS.p(d)&&(Cu(a,d,!0),$CLJS.zh.I($CLJS.t(a),$CLJS.Kg,Tu,$CLJS.xf))}else Xu(a),$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),d);$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),
"\n");d=$CLJS.A($CLJS.C($CLJS.Rg(c)));e=null;for(var g=f=0;;)if(g<f){var k=e.ta(null,g);$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),k);$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),"\n");$CLJS.p(b)&&$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),b);g+=1}else if(d=$CLJS.A(d))e=d,$CLJS.Cd(e)?(d=$CLJS.kc(e),g=$CLJS.lc(e),e=d,f=$CLJS.E(d),d=g):(d=$CLJS.B(e),$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),d),$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),"\n"),$CLJS.p(b)&&$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),b),d=$CLJS.C(e),e=null,f=0),g=0;else break;$CLJS.zh.I($CLJS.t(a),
$CLJS.Kg,Zu,bv);return $CLJS.id(c)};dv=function(a,b,c,d,e,f){this.Ca=a;this.pf=b;this.uh=c;this.xg=d;this.Qc=e;this.oh=f;this.o=1074167808;this.H=0};
kv=function(a){var b=$CLJS.ev,c=fv,d=new ru(null,null,$CLJS.yh.g(0),$CLJS.yh.g(0),$CLJS.yh.g(!1),$CLJS.yh.g(!1),null,null,null,null,null,null,null),e=$CLJS.yh.g,f=$CLJS.yh.g(new $CLJS.n(null,4,[$CLJS.vj,b,nu,0,$CLJS.Si,0,ou,a],null));e=e.call($CLJS.yh,$CLJS.Bg([Yu,Iu,gv,hv,iv,$CLJS.ii,$u,Au,ou,jv,Tu],[d,c,d,!0,null,bv,0,null,new qu(a,b,f,$CLJS.P),1,$CLJS.xf]));return new dv(a,b,c,d,e,$CLJS.P)};
ov=function(a,b){var c=du;b=new ru(Yu.g($CLJS.t($CLJS.t(c))),null,$CLJS.yh.g(0),$CLJS.yh.g(0),$CLJS.yh.g(!1),$CLJS.yh.g(!1),a,null,b,null,null,null,null);$CLJS.zh.I($CLJS.t(c),$CLJS.Kg,Yu,b);if($CLJS.F.h($CLJS.ii.g($CLJS.t($CLJS.t(c))),bv)){Xu(c);var d=lv.g($CLJS.t($CLJS.t(c)));$CLJS.p(d)&&(d.g?d.g($CLJS.mv):d.call(null,$CLJS.mv));$CLJS.p(a)&&$CLJS.ac(ou.g($CLJS.t($CLJS.t(c))),a);c=lu(ou.g($CLJS.t($CLJS.t(c))),nu);$CLJS.$e(b.sb,c);$CLJS.$e(b.qb,c)}else d=$u.g($CLJS.t($CLJS.t(c))),a=d+($CLJS.p(a)?
$CLJS.E(a):0),$CLJS.zh.I($CLJS.t(c),$CLJS.Kg,$u,a),Wu(c,new vu(nv,b,d,a,null,null,null))};
sv=function(){var a=du,b=Yu.g($CLJS.t($CLJS.t(a))),c=pv.g(b);if($CLJS.F.h($CLJS.ii.g($CLJS.t($CLJS.t(a))),bv)){Xu(a);$CLJS.p(c)&&$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),c);var d=lv.g($CLJS.t($CLJS.t(a)));$CLJS.p(d)&&(d.g?d.g(qv):d.call(null,qv))}else d=$u.g($CLJS.t($CLJS.t(a))),c=d+($CLJS.p(c)?$CLJS.E(c):0),$CLJS.zh.I($CLJS.t(a),$CLJS.Kg,$u,c),Wu(a,new wu(rv,b,d,c,null,null,null));$CLJS.zh.I($CLJS.t(a),$CLJS.Kg,Yu,$CLJS.Js.g(b))};
tv=function(a){var b=null!=a?a.o&32768||$CLJS.u===a.Ue?!0:a.o?!1:$CLJS.Va($CLJS.Qt,a):$CLJS.Va($CLJS.Qt,a);return b?hv.g($CLJS.t($CLJS.t(a))):b};xv=function(a){var b=uv;$CLJS.p(b)&&(b=$CLJS.Ja,b=$CLJS.p(b)?uv>=$CLJS.Ja:b);$CLJS.Ra(vv)?cu.call(null,a):$CLJS.p(b)?$CLJS.ac(du,"..."):($CLJS.p(uv)&&(uv+=1),wv.call(null,a));return b};yv=function(a,b){if($CLJS.Ra(b.g?b.g(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.q.g(a),". It must be one of ",$CLJS.q.g(b)].join(""));};
Av=function(){var a=$CLJS.ah;return $CLJS.p(a)?zv>=$CLJS.ah:a};Fv=function(a){yv(a,new $CLJS.S(null,new $CLJS.n(null,4,[Bv,null,Cv,null,Dv,null,Ev,null],null),null));var b=du;$CLJS.zh.I($CLJS.t(b),$CLJS.Kg,$CLJS.ii,Zu);var c=$u.g($CLJS.t($CLJS.t(b))),d=Yu.g($CLJS.t($CLJS.t(b)));Wu(b,new uu(zu,a,d,c,c,null,null,null))};
Jv=function(a,b){yv(a,new $CLJS.S(null,new $CLJS.n(null,2,[Gv,null,$CLJS.Hv,null],null),null));var c=du,d=Yu.g($CLJS.t($CLJS.t(c)));if($CLJS.F.h($CLJS.ii.g($CLJS.t($CLJS.t(c))),bv)){Xu(c);var e=Pu.g(d);if($CLJS.p($CLJS.F.h?$CLJS.F.h(Gv,a):$CLJS.F.call(null,Gv,a)))a=$CLJS.t(Ju.g(d));else if($CLJS.p($CLJS.F.h?$CLJS.F.h($CLJS.Hv,a):$CLJS.F.call(null,$CLJS.Hv,a)))a=lu(ou.g($CLJS.t($CLJS.t(c))),nu);else throw Error(["No matching clause: ",$CLJS.q.g(a)].join(""));$CLJS.$e(e,b+a)}else e=$u.g($CLJS.t($CLJS.t(c))),
Wu(c,new xu(Iv,d,a,b,e,e,null,null,null))};Ov=function(a,b,c){b="string"===typeof b?Kv(b):b;c=Lv(c);a:{var d=new $CLJS.Ca,e=$CLJS.Ra(a)||!0===a?new $CLJS.rc(d):a,f=Mv(b)&&$CLJS.Ra(tv(e))?$CLJS.p(tv(e))?e:kv(e):e,g=du;du=f;try{try{Nv(b,c)}finally{e!==f&&Rt(f)}var k=$CLJS.Ra(a)?$CLJS.q.g(d):!0===a?Wt($CLJS.q.g(d)):null;break a}finally{du=g}k=void 0}return k};Qv=function(a,b){a=[$CLJS.q.g(a),"\n",$CLJS.q.g(Pv),"\n",$CLJS.q.g($CLJS.Ue.h($CLJS.q,Zt(b," "))),"^\n"].join("");throw Error(a);};
Rv=function(a,b,c,d,e,f){this.tc=a;this.mb=b;this.Wb=c;this.A=d;this.l=e;this.C=f;this.o=2230716170;this.H=139264};Lv=function(a){a=$CLJS.A(a);return new Rv(a,a,0,null,null,null)};Uv=function(a){var b=$CLJS.Sv.g(a);if($CLJS.p(b))return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(b),new Rv(Tv.g(a),$CLJS.C(b),$u.g(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};
Vv=function(a){var b=Uv(a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);a="string"===typeof a?Kv(a):a;return new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null)};Xv=function(a,b){return b>=$u.g(a)?(b=$u.g(a)-b,Wv.h?Wv.h(a,b):Wv.call(null,a,b)):new Rv(Tv.g(a),$CLJS.ws(b,Tv.g(a)),b,null,null,null)};Wv=function(a,b){var c=$u.g(a)+b;return 0>b?Xv(a,c):new Rv(Tv.g(a),$CLJS.ws(b,$CLJS.Sv.g(a)),c,null,null,null)};
Yv=function(a,b,c,d,e,f,g){this.bc=a;this.$b=b;this.jc=c;this.offset=d;this.A=e;this.l=f;this.C=g;this.o=2230716170;this.H=139264};
cw=function(a,b){var c=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);d=$CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,2,[Zv,null,$v,null],null),null),c)?new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null):$CLJS.F.h(d,aw)?Uv(b):$CLJS.F.h(d,bw)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.E($CLJS.Sv.g(b)),b],null):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[c,new $CLJS.Q(null,2,5,$CLJS.R,
[b,a],null)],null),d],null)};dw=function(a,b){b=gu(cw,b,a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yf.h($CLJS.P,a),b],null)};
nw=function(a,b,c){c=Uv(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Id(d))if($CLJS.F.h(ew,10))var e=[$CLJS.q.g(d),$CLJS.p(fw)?".":null].join("");else{e=$CLJS.q;var f=e.g;if($CLJS.p(fw)){var g=$CLJS.K.h(gw,ew);g=$CLJS.p(g)?g:["#",$CLJS.q.g(ew),"r"].join("")}else g=null;e=[f.call(e,g),$CLJS.q.g(hw(ew,d))].join("")}else e=null;a=$CLJS.p(e)?e:a.g?a.g(d):a.call(null,d);d=a.length;e=d+iw.g(b);e=e>=jw.g(b)?e:e+($CLJS.ce(jw.g(b)-e-1,kw.g(b))+1)*kw.g(b);d=$CLJS.Ue.h($CLJS.q,Zt(e-d,lw.g(b)));$CLJS.p($v.g(b))?
mw.m($CLJS.H([[$CLJS.q.g(d),$CLJS.q.g(a)].join("")])):mw.m($CLJS.H([[$CLJS.q.g(a),$CLJS.q.g(d)].join("")]));return c};ow=function(a,b){return $CLJS.le($CLJS.B(hu(function(c){return 0<c?new $CLJS.Q(null,2,5,$CLJS.R,[Vt(c,a),$CLJS.ce(c,a)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,null],null)},b)))};hw=function(a,b){return 0===b?"0":$CLJS.Ue.h($CLJS.q,$CLJS.ih.h(function(c){return 10>c?Ut(fu("0")+c):Ut(fu("a")+(c-10))},ow(a,b)))};
pw=function(a,b){return $CLJS.le($CLJS.B(hu(function(c){return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.A($CLJS.le($CLJS.au(a,c))),$CLJS.A($CLJS.ws(a,c))],null)},$CLJS.le(b))))};
tw=function(a,b,c){c=Uv(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Id(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.F.h(d,Math.floor(d))){var e=0>d,f=hw(a,e?-d:d);a=$CLJS.p(Zv.g(b))?function(){var g=$CLJS.ih.h(function(l){return $CLJS.Ue.h($CLJS.q,l)},pw(qw.g(b),f)),k=Zt($CLJS.E(g),rw.g(b));return $CLJS.Ue.h($CLJS.q,$CLJS.C($CLJS.ys.h(k,g)))}():f;a=e?["-",$CLJS.q.g(a)].join(""):$CLJS.p($v.g(b))?["+",$CLJS.q.g(a)].join(""):a;a=a.length<jw.g(b)?
[$CLJS.q.g($CLJS.Ue.h($CLJS.q,Zt(jw.g(b)-a.length,lw.g(b)))),$CLJS.q.g(a)].join(""):a;mw.m($CLJS.H([a]))}else nw($CLJS.sw,new $CLJS.n(null,5,[jw,jw.g(b),kw,1,iw,0,lw,lw.g(b),$v,!0],null),Lv(new $CLJS.Q(null,1,5,$CLJS.R,[d],null)));return c};
ww=function(a){var b=$CLJS.ce(a,100);a=Vt(a,100);var c=0<b?[$CLJS.q.g($CLJS.G(uw,b))," hundred"].join(""):null,d=$CLJS.q,e=d.g;if(0<a)if(20>a)var f=$CLJS.G(uw,a);else{f=$CLJS.ce(a,10);var g=Vt(a,10);f=[$CLJS.q.g(0<f?$CLJS.G(vw,f):null),0<f&&0<g?"-":null,$CLJS.q.g(0<g?$CLJS.G(uw,g):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
yw=function(a,b){var c=$CLJS.E(a),d=$CLJS.xf;--c;var e=$CLJS.B(a);for(a=$CLJS.C(a);;){if(null==a)return[$CLJS.q.g($CLJS.Ue.h($CLJS.q,$CLJS.zs(", ",d))),$CLJS.sd(e)||$CLJS.sd(d)?null:", ",$CLJS.q.g(e),!$CLJS.sd(e)&&0<c+b?[" ",$CLJS.q.g($CLJS.G(xw,c+b))].join(""):null].join("");d=$CLJS.sd(e)?d:$CLJS.ke.h(d,[$CLJS.q.g(e)," ",$CLJS.q.g($CLJS.G(xw,c+b))].join(""));--c;e=$CLJS.B(a);a=$CLJS.C(a)}};
zw=function(a,b){b=Uv(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=ow(10,c);c=$CLJS.xf;for(var e=$CLJS.E(d)-1;;)if($CLJS.sd(d)){mw.m($CLJS.H([$CLJS.Ue.h($CLJS.q,c)]));break}else{var f=$CLJS.B(d);c=$CLJS.F.h(0,f)?c:$CLJS.ke.h(c,$CLJS.G($CLJS.G(a,e),f-1));--e;d=$CLJS.C(d)}}else tw(10,new $CLJS.n(null,5,[jw,0,lw," ",rw,",",qw,3,Zv,!0],null),Lv(new $CLJS.Q(null,1,5,$CLJS.R,[c],null)));return b};
Bw=function(a,b){a=Uv(b);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var c=fu(b);b=c&127;c&=128;var d=$CLJS.K.h(Aw,b);0<c&&mw.m($CLJS.H(["Meta-"]));mw.m($CLJS.H([$CLJS.p(d)?d:32>b?["Control-",$CLJS.q.g(Ut(b+64))].join(""):$CLJS.F.h(b,127)?"Control-?":Ut(b)]));return a};
Dw=function(a,b){var c=Uv(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);a=Cw.g(a);if($CLJS.p($CLJS.F.h?$CLJS.F.h("o",a):$CLJS.F.call(null,"o",a)))Ov(!0,"\\o~3,'0o",$CLJS.H([fu(b)]));else if($CLJS.p($CLJS.F.h?$CLJS.F.h("u",a):$CLJS.F.call(null,"u",a)))Ov(!0,"\\u~4,'0x",$CLJS.H([fu(b)]));else if($CLJS.p($CLJS.F.h?$CLJS.F.h(null,a):$CLJS.F.call(null,null,a)))$CLJS.ac(du,$CLJS.p($CLJS.F.h?$CLJS.F.h("\b",b):$CLJS.F.call(null,"\b",b))?"\\backspace":$CLJS.p($CLJS.F.h?$CLJS.F.h(" ",b):$CLJS.F.call(null," ",
b))?"\\space":$CLJS.p($CLJS.F.h?$CLJS.F.h("\t",b):$CLJS.F.call(null,"\t",b))?"\\tab":$CLJS.p($CLJS.F.h?$CLJS.F.h("\n",b):$CLJS.F.call(null,"\n",b))?"\\newline":$CLJS.p($CLJS.F.h?$CLJS.F.h("\f",b):$CLJS.F.call(null,"\f",b))?"\\formfeed":$CLJS.p($CLJS.F.h?$CLJS.F.h("\r",b):$CLJS.F.call(null,"\r",b))?"\\return":$CLJS.p($CLJS.F.h?$CLJS.F.h('"',b):$CLJS.F.call(null,'"',b))?'\\"':$CLJS.p($CLJS.F.h?$CLJS.F.h("\\",b):$CLJS.F.call(null,"\\",b))?"\\\\":["\\",$CLJS.q.g(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.q.g(a)].join(""));return c};Ew=function(a,b){b=Uv(b);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);mw.m($CLJS.H([a]));return b};Hw=function(a){a=$CLJS.B(a);return $CLJS.F.h(Fw,a)||$CLJS.F.h(Gw,a)};
Lw=function(a,b,c){return $CLJS.hd(gu(function(d,e){if(Hw(e))return new $CLJS.Q(null,2,5,$CLJS.R,[null,e],null);e=dw(Iw.g(d),e);var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var g=iu(f);f=$CLJS.I(g,0,null);g=$CLJS.I(g,1,null);f=$CLJS.Kg.j(f,Jw,c);return new $CLJS.Q(null,2,5,$CLJS.R,[null,$CLJS.Ue.h(Kw.g(d),new $CLJS.Q(null,3,5,$CLJS.R,[f,e,g],null))],null)},b,a))};
Mw=function(a){a=$CLJS.As($CLJS.q.g(a));var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.Q(null,2,5,$CLJS.R,[a,$CLJS.q.g(a.length-1)],null):new $CLJS.Q(null,2,5,$CLJS.R,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.q.g(c-1)],null):0>c?new $CLJS.Q(null,2,5,$CLJS.R,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.Q(null,2,5,$CLJS.R,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);a:if(c=$CLJS.E(b),0<c&&$CLJS.F.h($CLJS.G(b,
$CLJS.E(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.F.h($CLJS.G(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.E(c);if(0<d&&$CLJS.F.h($CLJS.G(c,0),"0"))for(var e=0;;){if($CLJS.F.h(e,d)||!$CLJS.F.h($CLJS.G(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.E(b)-$CLJS.E(c);a=0<$CLJS.E(a)&&$CLJS.F.h($CLJS.G(a,0),"+")?a.substring(1):a;return $CLJS.sd(c)?new $CLJS.Q(null,2,5,$CLJS.R,["0",0],null):new $CLJS.Q(null,2,5,$CLJS.R,[c,parseInt(a,10)-b],null)};
Nw=function(a,b,c,d){if($CLJS.p($CLJS.p(c)?c:d)){var e=$CLJS.E(a);d=$CLJS.p(d)?2>d?2:d:0;$CLJS.p(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.F.h(c,0)?new $CLJS.Q(null,4,5,$CLJS.R,[["0",$CLJS.q.g(a)].join(""),b+1,1,e+1],null):new $CLJS.Q(null,4,5,$CLJS.R,[a,b,c,e],null);c=$CLJS.I(f,0,null);e=$CLJS.I(f,1,null);d=$CLJS.I(f,2,null);f=$CLJS.I(f,3,null);if($CLJS.p(d)){if(0>d)return new $CLJS.Q(null,3,5,$CLJS.R,["0",0,!1],null);if(f>d){b=$CLJS.G(c,d);a=c.substring(0,d);if(fu(b)>=fu("5")){a:for(b=
$CLJS.E(a)-1,c=b|0;;){if(0>c){b=$CLJS.Ue.j($CLJS.q,"1",Zt(b+1,"0"));break a}if($CLJS.F.h("9",a.charAt(c)))--c;else{b=$CLJS.Ue.I($CLJS.q,a.substring(0,c),Ut(fu(a.charAt(c))+1),Zt(b-c,"0"));break a}}a=$CLJS.E(b)>a.length;c=$CLJS.R;a&&(d=$CLJS.E(b)-1,b=b.substring(0,d));return new $CLJS.Q(null,3,5,c,[b,e,a],null)}return new $CLJS.Q(null,3,5,$CLJS.R,[a,e,!1],null)}}}return new $CLJS.Q(null,3,5,$CLJS.R,[a,b,!1],null)};
Ow=function(a,b,c){var d=0>b?new $CLJS.Q(null,2,5,$CLJS.R,[[$CLJS.q.g($CLJS.Ue.h($CLJS.q,Zt(-b-1,"0"))),$CLJS.q.g(a)].join(""),-1],null):new $CLJS.Q(null,2,5,$CLJS.R,[a,b],null);a=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);d=$CLJS.E(a);c=$CLJS.p(c)?e+c+1:e+1;c=d<c?[$CLJS.q.g(a),$CLJS.q.g($CLJS.Ue.h($CLJS.q,Zt(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.q.g(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
Pw=function(a,b){return 0>b?[".",$CLJS.q.g(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
Tw=function(a,b){var c=Qw.g(a),d=Rw.g(a);b=Uv(b);var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var f=0>e?new $CLJS.Q(null,2,5,$CLJS.R,["-",-e],null):new $CLJS.Q(null,2,5,$CLJS.R,["+",e],null),g=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);f=Mw(f);var k=$CLJS.I(f,0,null),l=$CLJS.I(f,1,null)+$CLJS.Is.g(a);f=function(){var w=$v.g(a);return $CLJS.p(w)?w:0>e}();var m=$CLJS.Ra(d)&&$CLJS.E(k)-1<=l,r=Nw(k,l,d,$CLJS.p(c)?c-($CLJS.p(f)?1:0):null);k=$CLJS.I(r,0,null);l=$CLJS.I(r,1,null);r=$CLJS.I(r,2,null);k=Ow(k,$CLJS.p(r)?
l+1:l,d);d=$CLJS.p($CLJS.p(c)?$CLJS.p(d)?1<=d&&$CLJS.F.h(k.charAt(0),"0")&&$CLJS.F.h(k.charAt(1),".")&&k.length>c-($CLJS.p(f)?1:0):d:c)?k.substring(1):k;l=$CLJS.F.h($CLJS.B(d),".");if($CLJS.p(c)){k=d.length;k=$CLJS.p(f)?k+1:k;l=l&&!(k>=c);m=m&&!(k>=c);var v=l||m?k+1:k;$CLJS.p(function(){var w=v>c;return w?Sw.g(a):w}())?mw.m($CLJS.H([$CLJS.Ue.h($CLJS.q,Zt(c,Sw.g(a)))])):mw.m($CLJS.H([[$CLJS.q.g($CLJS.Ue.h($CLJS.q,Zt(c-v,lw.g(a)))),$CLJS.q.g($CLJS.p(f)?g:null),l?"0":null,d,m?"0":null].join("")]))}else mw.m($CLJS.H([[$CLJS.q.g($CLJS.p(f)?
g:null),l?"0":null,d,m?"0":null].join("")]));return b};
Ww=function(a,b){b=Uv(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=Mw(0>c?-c:c);$CLJS.I(d,0,null);for($CLJS.I(d,1,null);;){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),g=Qw.g(a),k=Rw.g(a),l=Uw.g(a),m=$CLJS.Is.g(a),r=function(){var N=Vw.g(a);return $CLJS.p(N)?N:"E"}();d=function(){var N=$v.g(a);return $CLJS.p(N)?N:0>c}();var v=0>=m,w=f-(m-1),y=$CLJS.q.g(Math.abs(w));r=[$CLJS.q.g(r),0>w?"-":"+",$CLJS.q.g($CLJS.p(l)?$CLJS.Ue.h($CLJS.q,Zt(l-y.length,"0")):null),y].join("");var z=r.length;w=$CLJS.E(e);
e=[$CLJS.q.g($CLJS.Ue.h($CLJS.q,Zt(-m,"0"))),$CLJS.q.g(e),$CLJS.q.g($CLJS.p(k)?$CLJS.Ue.h($CLJS.q,Zt(k-(w-1)-(0>m?-m:0),"0")):null)].join("");w=$CLJS.p(g)?g-z:null;e=Nw(e,0,$CLJS.F.h(m,0)?k-1:0<m?k:0>m?k-1:null,$CLJS.p(w)?w-($CLJS.p(d)?1:0):null);w=$CLJS.I(e,0,null);$CLJS.I(e,1,null);y=$CLJS.I(e,2,null);e=Pw(w,m);k=$CLJS.F.h(m,$CLJS.E(w))&&null==k;if($CLJS.Ra(y)){if($CLJS.p(g)){f=e.length+z;f=$CLJS.p(d)?f+1:f;var D=(v=v&&!$CLJS.F.h(f,g))?f+1:f;f=k&&D<g;$CLJS.p(function(){var N=D>g;N||(N=l,N=$CLJS.p(N)?
z-2>l:N);return $CLJS.p(N)?Sw.g(a):N}())?mw.m($CLJS.H([$CLJS.Ue.h($CLJS.q,Zt(g,Sw.g(a)))])):mw.m($CLJS.H([[$CLJS.q.g($CLJS.Ue.h($CLJS.q,Zt(g-D-(f?1:0),lw.g(a)))),$CLJS.p(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,r].join("")]))}else mw.m($CLJS.H([[$CLJS.p(d)?0>c?"-":"+":null,v?"0":null,e,k?"0":null,r].join("")]));break}else d=new $CLJS.Q(null,2,5,$CLJS.R,[w,f+1],null)}return b};
Xw=function(a,b,c){var d=Uv(b),e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);d=Mw(0>e?-e:e);var f=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var g=Qw.g(a),k=Rw.g(a),l=Uw.g(a);d=$CLJS.F.h(e,0)?0:d+1;e=$CLJS.p(l)?l+2:4;g=$CLJS.p(g)?g-e:null;$CLJS.p(k)?f=k:(f=$CLJS.E(f),k=7>d?d:7,f=f>k?f:k);d=f-d;return 0<=d&&d<=f?(a=Tw(new $CLJS.n(null,6,[Qw,g,Rw,d,$CLJS.Is,0,Sw,Sw.g(a),lw,lw.g(a),$v,$v.g(a)],null),b,c),mw.m($CLJS.H([$CLJS.Ue.h($CLJS.q,Zt(e," "))])),a):Ww(a,b,c)};
Zw=function(a,b){b=Uv(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=Mw(Math.abs(c)),e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),g=Rw.g(a),k=$CLJS.Yw.g(a);d=Qw.g(a);var l=function(){var r=$v.g(a);return $CLJS.p(r)?r:0>c}(),m=Nw(e,f,g,null);e=$CLJS.I(m,0,null);f=$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);g=Ow(e,$CLJS.p(m)?f+1:f,g);k=[$CLJS.q.g($CLJS.Ue.h($CLJS.q,Zt(k-g.indexOf("."),"0"))),g].join("");g=k.length+($CLJS.p(l)?1:0);mw.m($CLJS.H([[$CLJS.p(function(){var r=Zv.g(a);return $CLJS.p(r)?l:r}())?
0>c?"-":"+":null,$CLJS.q.g($CLJS.Ue.h($CLJS.q,Zt(d-g,lw.g(a)))),$CLJS.p(function(){var r=$CLJS.Ra(Zv.g(a));return r?l:r}())?0>c?"-":"+":null,k].join("")]));return b};bx=function(a,b){var c=$w.g(a);c=$CLJS.p(c)?new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null):Uv(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.ax.g(a);b=0>b||b>=$CLJS.E(d)?$CLJS.B($CLJS.Es.g(a)):$CLJS.G(d,b);return $CLJS.p(b)?Lw(b,c,Jw.g(a)):c};
cx=function(a,b){var c=Uv(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.ax.g(a);b=$CLJS.p(b)?$CLJS.hd(d):$CLJS.B(d);return $CLJS.p(b)?Lw(b,c,Jw.g(a)):c};dx=function(a,b){var c=Uv(b),d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.ax.g(a);e=$CLJS.p(d)?$CLJS.B(e):null;return $CLJS.p(d)?$CLJS.p(e)?Lw(e,b,Jw.g(a)):b:c};
gx=function(a,b){var c=ex.g(a),d=$CLJS.B($CLJS.ax.g(a));d=$CLJS.sd(d)?Vv(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=Uv(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=0;e=Lv(e);for(var g=-1;;){if($CLJS.Ra(c)&&$CLJS.F.h($u.g(e),g)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");g=$CLJS.sd($CLJS.Sv.g(e))&&($CLJS.Ra(Zv.g(fx.g(a)))||0<f);if($CLJS.p(g?g:$CLJS.p(c)?f>=c:c))return d;g=Lw(b,e,Jw.g(a));if($CLJS.F.h(Fw,$CLJS.B(g)))return d;
f+=1;var k=$u.g(e);e=g;g=k}};hx=function(a,b){var c=ex.g(a),d=$CLJS.B($CLJS.ax.g(a));d=$CLJS.sd(d)?Vv(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=Uv(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);for(var f=0;;){var g=$CLJS.sd(e)&&($CLJS.Ra(Zv.g(fx.g(a)))||0<f);if($CLJS.p(g?g:$CLJS.p(c)?f>=c:c))return d;g=Lw(b,Lv($CLJS.B(e)),Lv($CLJS.C(e)));if($CLJS.F.h(Gw,$CLJS.B(g)))return d;f+=1;e=$CLJS.C(e)}};
ix=function(a,b){var c=ex.g(a),d=$CLJS.B($CLJS.ax.g(a)),e=$CLJS.sd(d)?Vv(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.I(e,0,null);d=0;e=$CLJS.I(e,1,null);for(var f=-1;;){if($CLJS.Ra(c)&&$CLJS.F.h($u.g(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.sd($CLJS.Sv.g(e))&&($CLJS.Ra(Zv.g(fx.g(a)))||0<d);if($CLJS.p(f?f:$CLJS.p(c)?d>=c:c))return e;f=Lw(b,e,Jw.g(a));if($CLJS.F.h(Fw,$CLJS.B(f)))return $CLJS.hd(f);d+=1;var g=$u.g(e);e=f;f=g}};
jx=function(a,b){var c=ex.g(a),d=$CLJS.B($CLJS.ax.g(a)),e=$CLJS.sd(d)?Vv(b):new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);b=$CLJS.I(e,0,null);d=0;for(e=$CLJS.I(e,1,null);;){var f=$CLJS.sd($CLJS.Sv.g(e))&&($CLJS.Ra(Zv.g(fx.g(a)))||0<d);if($CLJS.p(f?f:$CLJS.p(c)?d>=c:c))return e;f=$CLJS.Sv.g(e);f=$CLJS.p(f)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(f),new Rv(Tv.g(e),$CLJS.C(f),$u.g(e)+1,null,null,null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,e],null);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=Lw(b,Lv(e),f);
if($CLJS.F.h(Gw,$CLJS.B(e)))return f;e=f;d+=1}};lx=function(a,b){if($CLJS.p(Zv.g(fx.g(a)))){var c=$CLJS.ax.g(a),d=$CLJS.E(c),e=1<d?$CLJS.pj.g(Iw.g($CLJS.B($CLJS.B(c)))):$CLJS.p(Zv.g(a))?"(":null,f=$CLJS.G(c,1<d?1:0);c=2<d?$CLJS.pj.g(Iw.g($CLJS.B($CLJS.G(c,2)))):$CLJS.p(Zv.g(a))?")":null;d=Uv(b);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.p(Av()))$CLJS.ac(du,"#");else{var g=zv,k=uv;zv+=1;uv=0;try{ov(e,c),Lw(f,Lv(b),Jw.g(a)),sv()}finally{uv=k,zv=g}}a=d}else a=kx(a,b);return a};
mx=function(a,b,c){for(var d=$CLJS.xf;;){if($CLJS.sd(a))return new $CLJS.Q(null,2,5,$CLJS.R,[d,b],null);var e=$CLJS.B(a);a:{var f=new $CLJS.Ca,g=du;du=new $CLJS.rc(f);try{var k=new $CLJS.Q(null,2,5,$CLJS.R,[Lw(e,b,c),$CLJS.q.g(f)],null);break a}finally{du=g}k=void 0}b=$CLJS.I(k,0,null);e=$CLJS.I(k,1,null);if($CLJS.F.h(Fw,$CLJS.B(b)))return new $CLJS.Q(null,2,5,$CLJS.R,[d,$CLJS.hd(b)],null);a=$CLJS.C(a);d=$CLJS.ke.h(d,e)}};
kx=function(a,b){var c=function(){var W=$CLJS.Es.g(a);return $CLJS.p(W)?mx(W,b,Jw.g(a)):null}(),d=$CLJS.I(c,0,null);d=$CLJS.I(d,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.p(c)?c:b;c=function(){var W=nx.g(a);return $CLJS.p(W)?dw(W,e):null}();var f=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var g=$CLJS.p(c)?c:e;c=function(){var W=$CLJS.B(ox.g(f));return $CLJS.p(W)?W:0}();var k=function(){var W=$CLJS.B(px.g(f));return $CLJS.p(W)?W:lu(du,$CLJS.vj)}(),l=$CLJS.ax.g(a);g=mx(l,g,Jw.g(a));var m=$CLJS.I(g,0,null);
g=$CLJS.I(g,1,null);var r=function(){var W=$CLJS.E(m)-1+($CLJS.p(Zv.g(a))?1:0)+($CLJS.p($v.g(a))?1:0);return 1>W?1:W}();l=$CLJS.Yd($CLJS.bk,$CLJS.ih.h($CLJS.E,m));var v=jw.g(a),w=iw.g(a),y=kw.g(a),z=l+r*w;v=z<=v?v:v+y*(1+$CLJS.ce(z-v-1,y));var D=v-l;l=function(){var W=$CLJS.ce(D,r);return w>W?w:W}();y=D-l*r;l=$CLJS.Ue.h($CLJS.q,Zt(l,lw.g(a)));$CLJS.p($CLJS.p(d)?lu(ou.g($CLJS.t($CLJS.t(du))),nu)+c+v>k:d)&&mw.m($CLJS.H([d]));c=y;for(var N=m,V=function(){var W=Zv.g(a);return $CLJS.p(W)?W:$CLJS.F.h($CLJS.E(N),
1)&&$CLJS.Ra($v.g(a))}();;)if($CLJS.A(N))mw.m($CLJS.H([[$CLJS.q.g($CLJS.Ra(V)?$CLJS.B(N):null),$CLJS.q.g($CLJS.p(function(){var W=V;return $CLJS.p(W)?W:(W=$CLJS.C(N))?W:$v.g(a)}())?l:null),$CLJS.q.g(0<c?lw.g(a):null)].join("")])),--c,N=d=$CLJS.p(V)?N:$CLJS.C(N),V=!1;else break;return g};qx=function(a,b){this.Ca=a;this.ph=b;this.o=1074135040;this.H=0};rx=function(a){return new qx(a,$CLJS.P)};sx=function(a,b){this.Ca=a;this.qh=b;this.o=1074135040;this.H=0};tx=function(a){return new sx(a,$CLJS.P)};
ux=function(a,b){var c=$CLJS.B(a);return $CLJS.Ue.h($CLJS.q,$CLJS.B(hu(function(d){if($CLJS.sd(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.p(e)?e.index+1:e;return $CLJS.p(e)?new $CLJS.Q(null,2,5,$CLJS.R,[[d.substring(0,e),$CLJS.G(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.Q(null,2,5,$CLJS.R,[d,null],null)},$CLJS.p($CLJS.p(b)?$CLJS.p(c)?Pt(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
vx=function(a,b,c){this.Ca=a;this.Hd=b;this.rh=c;this.o=1074135040;this.H=0};wx=function(a){var b=$CLJS.yh.g(!0);return new vx(a,b,$CLJS.P)};xx=function(a,b,c){this.Ca=a;this.td=b;this.sh=c;this.o=1074135040;this.H=0};yx=function(a){var b=$CLJS.yh.g(!1);return new xx(a,b,$CLJS.P)};zx=function(a,b){var c=$CLJS.p(Zv.g(a))?$CLJS.Hv:Gv;Jv(c,$CLJS.Yw.g(a));return b};Ax=function(a,b){a=$CLJS.p(Zv.g(a))?$CLJS.p($v.g(a))?Bv:Dv:$CLJS.p($v.g(a))?Cv:Ev;Fv(a);return b};
Cx=function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null);a=new RegExp(Bx.source,"g");var e=a.exec(b);return $CLJS.p(e)?(d=$CLJS.B(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.F.h(",",$CLJS.G(b,0))?new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[d,c],null),new $CLJS.Q(null,3,5,$CLJS.R,[b.substring(1),a+1,!0],null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[d,c],null),new $CLJS.Q(null,3,5,$CLJS.R,[b,a,!1],null)],null)):$CLJS.p(d)?Qv("Badly formed parameters in format directive",
c):new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,2,5,$CLJS.R,[b,c],null)],null)};Dx=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.F.h(b.length,0)?null:$CLJS.F.h(b.length,1)&&$CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,2,["V",null,"v",null],null),null),$CLJS.G(b,0))?aw:$CLJS.F.h(b.length,1)&&$CLJS.F.h("#",$CLJS.G(b,0))?bw:$CLJS.F.h(b.length,2)&&$CLJS.F.h("'",$CLJS.G(b,0))?$CLJS.G(b,1):parseInt(b,10),a],null)};
Fx=function(a,b){return hu(function(c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.sd(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,3,5,$CLJS.R,[d,e,c],null)],null);var f=$CLJS.K.h(Ex,$CLJS.B(d));return $CLJS.p(f)?$CLJS.Kd(c,f)?Qv(['Flag "',$CLJS.q.g($CLJS.B(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,3,5,$CLJS.R,[d.substring(1),e+1,$CLJS.Kg.j(c,f,new $CLJS.Q(null,2,5,$CLJS.R,[!0,e],null))],
null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,3,5,$CLJS.R,[d,e,c],null)],null)},new $CLJS.Q(null,3,5,$CLJS.R,[a,b,$CLJS.P],null))};
Jx=function(a,b){var c=Gx.g(a);$CLJS.p(function(){var d=$CLJS.Ra($v.g(c));return d?$v.g(b):d}())&&Qv(['"@" is an illegal flag for format directive "',$CLJS.q.g(Hx.g(a)),'"'].join(""),$CLJS.G($v.g(b),1));$CLJS.p(function(){var d=$CLJS.Ra(Zv.g(c));return d?Zv.g(b):d}())&&Qv(['":" is an illegal flag for format directive "',$CLJS.q.g(Hx.g(a)),'"'].join(""),$CLJS.G(Zv.g(b),1));$CLJS.p(function(){var d=$CLJS.Ra(Ix.g(c));return d?(d=$v.g(b),$CLJS.p(d)?Zv.g(b):d):d}())&&Qv(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.q.g(Hx.g(a)),'"'].join(""),function(){var d=$CLJS.G(Zv.g(b),1),e=$CLJS.G($v.g(b),1);return d<e?d:e}())};
Lx=function(a,b,c,d){Jx(a,c);$CLJS.E(b)>$CLJS.E(Iw.g(a))&&Qv(Ov(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.H([Hx.g(a),$CLJS.E(b),$CLJS.E(Iw.g(a))])),$CLJS.hd($CLJS.B(b)));$CLJS.ts($CLJS.ih.j(function(e,f){var g=$CLJS.B(e);return null==g||$CLJS.Kd(Kx,g)||$CLJS.F.h($CLJS.hd($CLJS.hd(f)),$CLJS.Wa(g))?null:Qv(["Parameter ",$CLJS.Sg($CLJS.B(f)),' has bad type in directive "',$CLJS.q.g(Hx.g(a)),'": ',$CLJS.q.g($CLJS.Wa(g))].join(""),
$CLJS.hd(e))},b,Iw.g(a)));return $CLJS.sk.m($CLJS.H([$CLJS.Yf.h($CLJS.P,$CLJS.le(function(){return function g(f){return new $CLJS.te(null,function(){for(;;){var k=$CLJS.A(f);if(k){if($CLJS.Cd(k)){var l=$CLJS.kc(k),m=$CLJS.E(l),r=$CLJS.we(m);a:for(var v=0;;)if(v<m){var w=$CLJS.ld(l,v),y=$CLJS.I(w,0,null);w=$CLJS.I(w,1,null);w=$CLJS.I(w,0,null);r.add(new $CLJS.Q(null,2,5,$CLJS.R,[y,new $CLJS.Q(null,2,5,$CLJS.R,[w,d],null)],null));v+=1}else{l=!0;break a}return l?$CLJS.ze($CLJS.Be(r),g($CLJS.lc(k))):
$CLJS.ze($CLJS.Be(r),null)}l=$CLJS.B(k);r=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.I(l,0,null);return $CLJS.ne(new $CLJS.Q(null,2,5,$CLJS.R,[r,new $CLJS.Q(null,2,5,$CLJS.R,[l,d],null)],null),g($CLJS.Lc(k)))}return null}},null)}(Iw.g(a))}())),$CLJS.$a(function(e,f){return $CLJS.Ue.j($CLJS.Kg,e,f)},$CLJS.P,$CLJS.ff(function(e){return $CLJS.B($CLJS.G(e,1))},$CLJS.Yt($CLJS.Fg(Iw.g(a)),b))),c]))};
Ox=function(a,b){b=hu(Cx,new $CLJS.Q(null,3,5,$CLJS.R,[a,b,!1],null));a=$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);b=Fx(b,c);$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);c=$CLJS.B(d);var f=$CLJS.K.h(Mx,c.toUpperCase()),g=$CLJS.p(f)?Lx(f,$CLJS.ih.h(Dx,a),b,e):null;$CLJS.Ra(c)&&Qv("Format string ended in the middle of a directive",e);$CLJS.Ra(f)&&Qv(['Directive "',$CLJS.q.g(c),'" is undefined'].join(""),
e);return new $CLJS.Q(null,2,5,$CLJS.R,[new Yv(function(){var k=Nx.g(f);return k.h?k.h(g,e):k.call(null,g,e)}(),f,g,e,null,null,null),function(){var k=d.substring(1),l=e+1;if($CLJS.F.h("\n",Hx.g(f))&&$CLJS.Ra(Zv.g(g)))a:{var m=new $CLJS.Q(null,2,5,$CLJS.R,[" ","\t"],null);m=$CLJS.td(m)?$CLJS.Qg(m):$CLJS.Og([m]);for(var r=0;;){var v;(v=$CLJS.F.h(r,$CLJS.E(k)))||(v=$CLJS.G(k,r),v=m.g?m.g(v):m.call(null,v),v=$CLJS.Ra(v));if(v){m=r;break a}r+=1}}else m=0;return new $CLJS.Q(null,2,5,$CLJS.R,[k.substring(m),
l+m],null)}()],null)};Px=function(a,b){return new Yv(function(c,d){mw.m($CLJS.H([a]));return d},null,new $CLJS.n(null,1,[$CLJS.pj,a],null),b,null,null,null)};Ux=function(a,b){var c=Qx(Rx.g(Sx.g(a)),Tx.g(a),b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return new $CLJS.Q(null,2,5,$CLJS.R,[new Yv(Kw.g(a),Sx.g(a),$CLJS.sk.m($CLJS.H([Iw.g(a),ju(b,Tx.g(a))])),Tx.g(a),null,null,null),c],null)};
Xx=function(a,b,c){return hu(function(d){if($CLJS.sd(d))return Qv("No closing bracket found.",b);var e=$CLJS.B(d);d=$CLJS.C(d);if($CLJS.p(Vx.g(Rx.g(Sx.g(e)))))e=Ux(e,d);else if($CLJS.F.h(Vx.g(a),Hx.g(Sx.g(e))))e=new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,4,5,$CLJS.R,[Wx,Iw.g(e),null,d],null)],null);else{var f=$CLJS.Gs.g(Rx.g(Sx.g(e)));f=$CLJS.p(f)?Zv.g(Iw.g(e)):f;e=$CLJS.p(f)?new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Es,null,Iw.g(e),d],null)],null):$CLJS.p($CLJS.Gs.g(Rx.g(Sx.g(e))))?
new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,4,5,$CLJS.R,[$CLJS.Gs,null,null,d],null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[e,d],null)}return e},c)};
Qx=function(a,b,c){return $CLJS.hd(hu(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);d=$CLJS.I(d,2,null);var g=Xx(a,b,d);d=$CLJS.I(g,0,null);var k=$CLJS.I(g,1,null);g=$CLJS.I(k,0,null);var l=$CLJS.I(k,1,null),m=$CLJS.I(k,2,null);k=$CLJS.I(k,3,null);return $CLJS.F.h(g,Wx)?new $CLJS.Q(null,2,5,$CLJS.R,[null,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Yx.m($CLJS.ek,$CLJS.H([e,$CLJS.Je([$CLJS.p(f)?$CLJS.Es:$CLJS.ax,new $CLJS.Q(null,1,5,$CLJS.R,[d],null),fx,l])])),k],null)],null):$CLJS.F.h(g,$CLJS.Es)?
$CLJS.p($CLJS.Es.g(e))?Qv('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Ra($CLJS.Es.g(a))?Qv('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.F.h(Zx,$CLJS.Es.g(a))&&$CLJS.A($CLJS.ax.g(e))?Qv('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.F.h(Zx,$CLJS.Es.g(a))?new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yx.m($CLJS.ek,$CLJS.H([e,new $CLJS.n(null,2,[$CLJS.Es,new $CLJS.Q(null,1,5,
$CLJS.R,[d],null),nx,m],null)])),!1,k],null)],null):new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,3,5,$CLJS.R,[$CLJS.Yx.m($CLJS.ek,$CLJS.H([e,new $CLJS.n(null,1,[$CLJS.ax,new $CLJS.Q(null,1,5,$CLJS.R,[d],null)],null)])),!0,k],null)],null):$CLJS.F.h(g,$CLJS.Gs)?$CLJS.p(f)?Qv('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Ra($x.g(a))?Qv('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.Q(null,2,5,$CLJS.R,[!0,new $CLJS.Q(null,
3,5,$CLJS.R,[$CLJS.Yx.m($CLJS.ek,$CLJS.H([e,new $CLJS.n(null,1,[$CLJS.ax,new $CLJS.Q(null,1,5,$CLJS.R,[d],null)],null)])),!1,k],null)],null):null},new $CLJS.Q(null,3,5,$CLJS.R,[new $CLJS.n(null,1,[$CLJS.ax,$CLJS.xf],null),!1,c],null)))};ay=function(a){return $CLJS.B(hu(function(b){var c=$CLJS.B(b);b=$CLJS.C(b);var d=Rx.g(Sx.g(c));return $CLJS.p(Vx.g(d))?Ux(c,b):new $CLJS.Q(null,2,5,$CLJS.R,[c,b],null)},a))};
Kv=function(a){var b=Pv;Pv=a;try{return ay($CLJS.B(hu(function(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.sd(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.Q(null,2,5,$CLJS.R,[Px(d,c),new $CLJS.Q(null,2,5,$CLJS.R,["",c+d.length],null)],null):0===e?Ox(d.substring(1),c+1):new $CLJS.Q(null,2,5,$CLJS.R,[Px(d.substring(0,e),c),new $CLJS.Q(null,2,5,$CLJS.R,[d.substring(e),e+c],null)],null)},new $CLJS.Q(null,2,5,$CLJS.R,[a,0],null))))}finally{Pv=
b}};Nv=function(a,b){gu(function(c,d){if(Hw(d))return new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);d=dw(Iw.g(c),d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=iu(e);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=$CLJS.Kg.j(e,Jw,d);return new $CLJS.Q(null,2,5,$CLJS.R,[null,$CLJS.Ue.h(Kw.g(c),new $CLJS.Q(null,3,5,$CLJS.R,[e,d,f],null))],null)},b,a);return null};
cy=function(a){var b=$CLJS.B(a);b=by.g?by.g(b):by.call(null,b);return $CLJS.p($CLJS.p(b)?$CLJS.F.h(2,$CLJS.E(a)):b)?($CLJS.ac(du,b),xv($CLJS.hd(a)),!0):null};dy=function(a){if($CLJS.p(Av()))$CLJS.ac(du,"#");else{var b=zv,c=uv;zv+=1;uv=0;try{ov("[","]");for(var d=0,e=$CLJS.A(a);;){if($CLJS.Ra($CLJS.Ja)||d<$CLJS.Ja){if(e&&(xv($CLJS.B(e)),$CLJS.C(e))){$CLJS.ac(du," ");Fv(Ev);a=d+1;var f=$CLJS.C(e);d=a;e=f;continue}}else $CLJS.ac(du,"...");break}sv()}finally{uv=c,zv=b}}return null};
gy=function(a){var b=$CLJS.Ad(a)?null:function(){var l=new $CLJS.Fc(function(){return $CLJS.rh},ey,$CLJS.Bg([$CLJS.Lj,$CLJS.Di,$CLJS.T,$CLJS.Bj,$CLJS.gi,$CLJS.Ai,$CLJS.Si,$CLJS.Rj,$CLJS.fi,$CLJS.U,$CLJS.Aj],[!0,$CLJS.Zh,fy,"cljs/core.cljs",15,1,10543,10543,$CLJS.X(new $CLJS.Q(null,1,5,$CLJS.R,[$CLJS.Ki],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.p($CLJS.rh)?$CLJS.rh.D:null]));return l.g?l.g(a):l.call(null,a)}(),c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.p(b)?
b:a,e=$CLJS.p(c)?["#:",$CLJS.q.g(c),"{"].join(""):"{";if($CLJS.p(Av()))$CLJS.ac(du,"#");else{c=zv;b=uv;zv+=1;uv=0;try{ov(e,"}");e=0;for(var f=$CLJS.A(d);;){if($CLJS.Ra($CLJS.Ja)||e<$CLJS.Ja){if(f){if($CLJS.p(Av()))$CLJS.ac(du,"#");else{d=zv;var g=uv;zv+=1;uv=0;try{ov(null,null),xv($CLJS.B($CLJS.B(f))),$CLJS.ac(du," "),Fv(Ev),uv=0,xv($CLJS.B($CLJS.C($CLJS.B(f)))),sv()}finally{uv=g,zv=d}}if($CLJS.C(f)){$CLJS.ac(du,", ");Fv(Ev);d=e+1;var k=$CLJS.C(f);e=d;f=k;continue}}}else $CLJS.ac(du,"...");break}sv()}finally{uv=
b,zv=c}}return null};hy=function(a){return $CLJS.ac(du,$CLJS.wh.m($CLJS.H([a])))};ky=function(a){return(null!=a?a.o&32768||$CLJS.u===a.Ue||(a.o?0:$CLJS.Va($CLJS.Qt,a)):$CLJS.Va($CLJS.Qt,a))?iy:a instanceof $CLJS.x?$CLJS.pi:$CLJS.Fd(a)?jy:$CLJS.xd(a)?$CLJS.uj:$CLJS.Bd(a)?$CLJS.gj:$CLJS.ud(a)?$CLJS.Fi:null==a?null:$CLJS.Ph};ly=function(a){return $CLJS.Bd(a)?new $CLJS.Q(null,2,5,$CLJS.R,["[","]"],null):new $CLJS.Q(null,2,5,$CLJS.R,["(",")"],null)};
ny=function(a){if($CLJS.wd(a)){var b=ly(a),c=$CLJS.I(b,0,null),d=$CLJS.I(b,1,null),e=$CLJS.A(a),f=$CLJS.B(e),g=$CLJS.C(e);if($CLJS.p(Av()))$CLJS.ac(du,"#");else{var k=zv,l=uv,m=zv+1;zv=m;uv=0;try{ov(c,d);(function(){var Ka=my("~w~:i");return function(){function mb(Fb){var Db=null;if(0<arguments.length){Db=0;for(var Lb=Array(arguments.length-0);Db<Lb.length;)Lb[Db]=arguments[Db+0],++Db;Db=new $CLJS.Hc(Lb,0,null)}return zb.call(this,Db)}function zb(Fb){Fb=Lv(Fb);return Nv(Ka,Fb)}mb.F=0;mb.G=function(Fb){Fb=
$CLJS.A(Fb);return zb(Fb)};mb.m=zb;return mb}()})()(f);for(var r=g;;)if($CLJS.A(r)){(function(){var Ka=my(" ");return function(mb,zb,Fb){return function(){function Db(nb){var vb=null;if(0<arguments.length){vb=0;for(var yc=Array(arguments.length-0);vb<yc.length;)yc[vb]=arguments[vb+0],++vb;vb=new $CLJS.Hc(yc,0,null)}return Lb.call(this,vb)}function Lb(nb){nb=Lv(nb);return Nv(Fb,nb)}Db.F=0;Db.G=function(nb){nb=$CLJS.A(nb);return Lb(nb)};Db.m=Lb;return Db}()}(r," ",Ka,k,l,m,0,b,c,d,a,e,f,g,f,g)})()();
var v=$CLJS.B(r);if($CLJS.wd(v)){var w=ly(v),y=$CLJS.I(w,0,null),z=$CLJS.I(w,1,null);if($CLJS.p(Av()))$CLJS.ac(du,"#");else{var D=zv,N=uv,V=zv+1;zv=V;uv=0;try{ov(y,z);if($CLJS.F.h($CLJS.E(v),3)&&$CLJS.hd(v)instanceof $CLJS.M){var W=v,da=$CLJS.I(W,0,null),qa=$CLJS.I(W,1,null),ua=$CLJS.I(W,2,null);(function(){var Ka=my("~w ~w ");return function(mb,zb,Fb){return function(){function Db(nb){var vb=null;if(0<arguments.length){vb=0;for(var yc=Array(arguments.length-0);vb<yc.length;)yc[vb]=arguments[vb+0],
++vb;vb=new $CLJS.Hc(yc,0,null)}return Lb.call(this,vb)}function Lb(nb){nb=Lv(nb);return Nv(Fb,nb)}Db.F=0;Db.G=function(nb){nb=$CLJS.A(nb);return Lb(nb)};Db.m=Lb;return Db}()}(r,"~w ~w ",Ka,W,da,qa,ua,D,N,V,0,w,y,z,v,k,l,m,0,b,c,d,a,e,f,g,f,g)})()(da,qa);$CLJS.wd(ua)?function(){var Ka=$CLJS.Bd(ua)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",mb="string"===typeof Ka?my(Ka):Ka;return function(zb,Fb,Db){return function(){function Lb(vb){var yc=null;if(0<arguments.length){yc=0;for(var vq=
Array(arguments.length-0);yc<vq.length;)vq[yc]=arguments[yc+0],++yc;yc=new $CLJS.Hc(vq,0,null)}return nb.call(this,yc)}function nb(vb){vb=Lv(vb);return Nv(Db,vb)}Lb.F=0;Lb.G=function(vb){vb=$CLJS.A(vb);return nb(vb)};Lb.m=nb;return Lb}()}(r,Ka,mb,W,da,qa,ua,D,N,V,0,w,y,z,v,k,l,m,0,b,c,d,a,e,f,g,f,g)}()(ua):xv(ua)}else $CLJS.Ue.h(function(){var Ka=my("~w ~:i~@{~w~^ ~:_~}");return function(mb,zb,Fb){return function(){function Db(nb){var vb=null;if(0<arguments.length){vb=0;for(var yc=Array(arguments.length-
0);vb<yc.length;)yc[vb]=arguments[vb+0],++vb;vb=new $CLJS.Hc(yc,0,null)}return Lb.call(this,vb)}function Lb(nb){nb=Lv(nb);return Nv(Fb,nb)}Db.F=0;Db.G=function(nb){nb=$CLJS.A(nb);return Lb(nb)};Db.m=Lb;return Db}()}(r,"~w ~:i~@{~w~^ ~:_~}",Ka,D,N,V,0,w,y,z,v,k,l,m,0,b,c,d,a,e,f,g,f,g)}(),v);sv()}finally{uv=N,zv=D}}$CLJS.C(r)&&function(){var Ka=my("~_");return function(mb,zb,Fb){return function(){function Db(nb){var vb=null;if(0<arguments.length){vb=0;for(var yc=Array(arguments.length-0);vb<yc.length;)yc[vb]=
arguments[vb+0],++vb;vb=new $CLJS.Hc(yc,0,null)}return Lb.call(this,vb)}function Lb(nb){nb=Lv(nb);return Nv(Fb,nb)}Db.F=0;Db.G=function(nb){nb=$CLJS.A(nb);return Lb(nb)};Db.m=Lb;return Db}()}(r,"~_",Ka,w,y,z,v,k,l,m,0,b,c,d,a,e,f,g,f,g)}()()}else xv(v),$CLJS.C(r)&&function(){var Ka=my("~:_");return function(mb,zb,Fb){return function(){function Db(nb){var vb=null;if(0<arguments.length){vb=0;for(var yc=Array(arguments.length-0);vb<yc.length;)yc[vb]=arguments[vb+0],++vb;vb=new $CLJS.Hc(yc,0,null)}return Lb.call(this,
vb)}function Lb(nb){nb=Lv(nb);return Nv(Fb,nb)}Db.F=0;Db.G=function(nb){nb=$CLJS.A(nb);return Lb(nb)};Db.m=Lb;return Db}()}(r,"~:_",Ka,v,k,l,m,0,b,c,d,a,e,f,g,f,g)}()();r=$CLJS.C(r)}else break;sv()}finally{uv=l,zv=k}}}else xv(a)};
oy=function(a,b){$CLJS.A(a)&&($CLJS.p(b)?function(){var c=my(" ~_");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var k=Array(arguments.length-0);g<k.length;)k[g]=arguments[g+0],++g;g=new $CLJS.Hc(k,0,null)}return e.call(this,g)}function e(f){f=Lv(f);return Nv(c,f)}d.F=0;d.G=function(f){f=$CLJS.A(f);return e(f)};d.m=e;return d}()}()():function(){var c=my(" ~@_");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var k=Array(arguments.length-0);g<
k.length;)k[g]=arguments[g+0],++g;g=new $CLJS.Hc(k,0,null)}return e.call(this,g)}function e(f){f=Lv(f);return Nv(c,f)}d.F=0;d.G=function(f){f=$CLJS.A(f);return e(f)};d.m=e;return d}()}()(),function(){var c=my("~{~w~^ ~_~}");return function(){function d(f){var g=null;if(0<arguments.length){g=0;for(var k=Array(arguments.length-0);g<k.length;)k[g]=arguments[g+0],++g;g=new $CLJS.Hc(k,0,null)}return e.call(this,g)}function e(f){f=Lv(f);return Nv(c,f)}d.F=0;d.G=function(f){f=$CLJS.A(f);return e(f)};d.m=
e;return d}()}()(a))};py=function(a){$CLJS.A(a)&&function(){var b=my(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var g=Array(arguments.length-0);f<g.length;)g[f]=arguments[f+0],++f;f=new $CLJS.Hc(g,0,null)}return d.call(this,f)}function d(e){e=Lv(e);return Nv(b,e)}c.F=0;c.G=function(e){e=$CLJS.A(e);return d(e)};c.m=d;return c}()}()(a)};
ry=function(a){if($CLJS.C(a)){var b=$CLJS.A(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d="string"===typeof $CLJS.B(c)?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(c),$CLJS.C(c)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=$CLJS.xd($CLJS.B(d))?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(d),$CLJS.C(d)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.p(Av()))$CLJS.ac(du,"#");else{var f=zv,g=
uv;zv+=1;uv=0;try{ov("(",")"),function(){var k=my("~w ~1I~@_~w");return function(){function l(r){var v=null;if(0<arguments.length){v=0;for(var w=Array(arguments.length-0);v<w.length;)w[v]=arguments[v+0],++v;v=new $CLJS.Hc(w,0,null)}return m.call(this,v)}function m(r){r=Lv(r);return Nv(k,r)}l.F=0;l.G=function(r){r=$CLJS.A(r);return m(r)};l.m=m;return l}()}()(a,b),$CLJS.p(c)&&function(){var k=my(" ~_~w");return function(){function l(r){var v=null;if(0<arguments.length){v=0;for(var w=Array(arguments.length-
0);v<w.length;)w[v]=arguments[v+0],++v;v=new $CLJS.Hc(w,0,null)}return m.call(this,v)}function m(r){r=Lv(r);return Nv(k,r)}l.F=0;l.G=function(r){r=$CLJS.A(r);return m(r)};l.m=m;return l}()}()(c),$CLJS.p(d)&&function(){var k=my(" ~_~w");return function(){function l(r){var v=null;if(0<arguments.length){v=0;for(var w=Array(arguments.length-0);v<w.length;)w[v]=arguments[v+0],++v;v=new $CLJS.Hc(w,0,null)}return m.call(this,v)}function m(r){r=Lv(r);return Nv(k,r)}l.F=0;l.G=function(r){r=$CLJS.A(r);return m(r)};
l.m=m;return l}()}()(d),$CLJS.Bd($CLJS.B(e))?oy(e,$CLJS.p(c)?c:d):py(e),sv()}finally{uv=g,zv=f}}return null}return qy(a)};
sy=function(a){if($CLJS.p(Av()))$CLJS.ac(du,"#");else{var b=zv,c=uv;zv+=1;uv=0;try{ov("[","]");for(var d=0;;){if($CLJS.Ra($CLJS.Ja)||d<$CLJS.Ja){if($CLJS.A(a)){if($CLJS.p(Av()))$CLJS.ac(du,"#");else{var e=zv,f=uv;zv+=1;uv=0;try{ov(null,null),xv($CLJS.B(a)),$CLJS.C(a)&&($CLJS.ac(du," "),Fv(Cv),xv($CLJS.hd(a))),sv()}finally{uv=f,zv=e}}if($CLJS.C($CLJS.Lc(a))){$CLJS.ac(du," ");Fv(Ev);e=d+1;var g=$CLJS.C($CLJS.Lc(a));d=e;a=g;continue}}}else $CLJS.ac(du,"...");break}sv()}finally{uv=c,zv=b}}};
ty=function(a){var b=$CLJS.B(a);if($CLJS.p(Av()))$CLJS.ac(du,"#");else{var c=zv,d=uv;zv+=1;uv=0;try{ov("(",")"),$CLJS.C(a)&&$CLJS.Bd($CLJS.hd(a))?(function(){var e=my("~w ~1I~@_");return function(){function f(k){var l=null;if(0<arguments.length){l=0;for(var m=Array(arguments.length-0);l<m.length;)m[l]=arguments[l+0],++l;l=new $CLJS.Hc(m,0,null)}return g.call(this,l)}function g(k){k=Lv(k);return Nv(e,k)}f.F=0;f.G=function(k){k=$CLJS.A(k);return g(k)};f.m=g;return f}()}()(b),sy($CLJS.hd(a)),function(){var e=
my(" ~_~{~w~^ ~_~}");return function(){function f(k){var l=null;if(0<arguments.length){l=0;for(var m=Array(arguments.length-0);l<m.length;)m[l]=arguments[l+0],++l;l=new $CLJS.Hc(m,0,null)}return g.call(this,l)}function g(k){k=Lv(k);return Nv(e,k)}f.F=0;f.G=function(k){k=$CLJS.A(k);return g(k)};f.m=g;return f}()}()($CLJS.C($CLJS.Lc(a)))):qy(a),sv()}finally{uv=d,zv=c}}return null};
qy=function(a){if($CLJS.p(Av()))$CLJS.ac(du,"#");else{var b=zv,c=uv;zv+=1;uv=0;try{ov("(",")");Jv(Gv,1);for(var d=0,e=$CLJS.A(a);;){if($CLJS.Ra($CLJS.Ja)||d<$CLJS.Ja){if(e&&(xv($CLJS.B(e)),$CLJS.C(e))){$CLJS.ac(du," ");Fv(Ev);a=d+1;var f=$CLJS.C(e);d=a;e=f;continue}}else $CLJS.ac(du,"...");break}sv()}finally{uv=c,zv=b}}return null};$CLJS.te.prototype.Mc=$CLJS.ra(7,function(){return $CLJS.Ra(this.md)});$CLJS.df.prototype.Mc=$CLJS.ra(6,function(){return!1});$CLJS.th.prototype.Mc=$CLJS.ra(5,function(){return $CLJS.Ra(this.f)});
du=null;$CLJS.Yx=function Yx(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Yx.m(arguments[0],1<c.length?new $CLJS.Hc(c.slice(1),0,null):null)};
$CLJS.Yx.m=function(a,b){if($CLJS.p($CLJS.Re($CLJS.ae,b))){var c=function(d,e){var f=$CLJS.Cb(e),g=$CLJS.Eb(e);if($CLJS.Kd(d,f)){e=$CLJS.Kg.j;var k=$CLJS.K.h(d,f);g=a.h?a.h(k,g):a.call(null,k,g);d=e.call($CLJS.Kg,d,f,g)}else d=$CLJS.Kg.j(d,f,g);return d};return $CLJS.Yd(function(d,e){return $CLJS.$a(c,$CLJS.p(d)?d:$CLJS.P,$CLJS.A(e))},b)}return null};$CLJS.Yx.F=1;$CLJS.Yx.G=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.m(b,a)};
var uy=function uy(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return uy.m(0<c.length?new $CLJS.Hc(c.slice(0),0,null):null)};uy.m=function(a){a=$CLJS.A(a);for(var b=$CLJS.dc($CLJS.$f);;)if(a){var c=$CLJS.C(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.q.g($CLJS.B(a))].join(""));var d=$CLJS.C(c);b=$CLJS.ag.j(b,$CLJS.B(a),$CLJS.B(c));a=d}else return $CLJS.fc(b)};uy.F=0;uy.G=function(a){return this.m($CLJS.A(a))};
$CLJS.sw=function sw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return sw.m(0<c.length?new $CLJS.Hc(c.slice(0),0,null):null)};$CLJS.sw.m=function(a){return $CLJS.oh(a,$CLJS.Kg.j($CLJS.La(),$CLJS.Ea,!1))};$CLJS.sw.F=0;$CLJS.sw.G=function(a){return this.m($CLJS.A(a))};Au=new $CLJS.M(null,"trailing-white-space","trailing-white-space",1496006996);vy=new $CLJS.M(null,"relative-to","relative-to",-470100051);
Qu=new $CLJS.M(null,"intra-block-nl","intra-block-nl",1808826875);Vw=new $CLJS.M(null,"exponentchar","exponentchar",1986664222);wy=new $CLJS.x(null,"when-let","when-let",-1383043480,null);Cv=new $CLJS.M(null,"miser","miser",-556060186);$CLJS.Hv=new $CLJS.M(null,"current","current",-1088038603);xy=new $CLJS.x(null,"-\x3e","-\x3e",-2139605430,null);yy=new $CLJS.x(null,"..","..",-300507420,null);Du=new $CLJS.M(null,"end-pos","end-pos",-1643883926);
Ou=new $CLJS.M(null,"per-line-prefix","per-line-prefix",846941813);zy=new $CLJS.M(null,"queue","queue",1455835879);Ay=new $CLJS.x(null,"if-let","if-let",1803593690,null);qv=new $CLJS.M(null,"end","end",-268185958);bw=new $CLJS.M(null,"remaining-arg-count","remaining-arg-count",-1216589335);Yu=new $CLJS.M(null,"logical-blocks","logical-blocks",-1466339776);By=new $CLJS.x(null,"defmacro","defmacro",2054157304,null);Cy=new $CLJS.x(null,"when-first","when-first",821699168,null);
nv=new $CLJS.M(null,"start-block-t","start-block-t",-373430594);Dy=new $CLJS.x(null,"binding","binding",-2114503176,null);Rx=new $CLJS.M(null,"bracket-info","bracket-info",-1600092774);bv=new $CLJS.M(null,"writing","writing",-1486865108);aw=new $CLJS.M(null,"parameter-from-args","parameter-from-args",-758446196);lv=new $CLJS.M(null,"logical-block-callback","logical-block-callback",1612691194);$w=new $CLJS.M(null,"selector","selector",762528866);Ey=new $CLJS.x(null,"struct","struct",325972931,null);
Tu=new $CLJS.M(null,"buffer","buffer",617295198);Pu=new $CLJS.M(null,"indent","indent",-148200125);Fy=new $CLJS.x(null,"loop","loop",1244978678,null);px=new $CLJS.M(null,"max-columns","max-columns",1742323262);Ix=new $CLJS.M(null,"both","both",-393648840);Gy=new $CLJS.M(null,"colnum","colnum",2023796854);Hy=new $CLJS.x(null,"doseq","doseq",221164135,null);$CLJS.Iy=new $CLJS.M(null,"length","length",588987862);Cw=new $CLJS.M(null,"char-format","char-format",-1016499218);
Jy=new $CLJS.M(null,"prefix","prefix",-265908465);$CLJS.Sv=new $CLJS.M(null,"rest","rest",-1241696419);Gw=new $CLJS.M(null,"colon-up-arrow","colon-up-arrow",244853007);pv=new $CLJS.M(null,"suffix","suffix",367373057);$v=new $CLJS.M(null,"at","at",1476951349);zu=new $CLJS.M(null,"nl-t","nl-t",-1608382114);Hx=new $CLJS.M(null,"directive","directive",793559132);jv=new $CLJS.M(null,"buffer-level","buffer-level",928864731);Bv=new $CLJS.M(null,"mandatory","mandatory",542802336);
hv=new $CLJS.M(null,"pretty-writer","pretty-writer",-1222834267);Gu=new $CLJS.M(null,"done-nl","done-nl",-381024340);Ky=new $CLJS.x(null,"condp","condp",1054325175,null);Tv=new $CLJS.M(null,"seq","seq",-1817803783);Ly=new $CLJS.x(null,"defn","defn",-126010802,null);kw=new $CLJS.M(null,"colinc","colinc",-584873385);Fw=new $CLJS.M(null,"up-arrow","up-arrow",1705310333);Wx=new $CLJS.M(null,"right-bracket","right-bracket",951856080);My=new $CLJS.M(null,"radix","radix",857016463);
Zx=new $CLJS.M(null,"first","first",-644103046);iv=new $CLJS.M(null,"sections","sections",-886710106);$CLJS.mv=new $CLJS.M(null,"start","start",-355208981);Ny=new $CLJS.x("cljs.core","unquote","cljs.core/unquote",1013085760,null);Oy=new $CLJS.x(null,"defn-","defn-",1097765044,null);Py=new $CLJS.x("cljs.core","deref","cljs.core/deref",1901963335,null);Gv=new $CLJS.M(null,"block","block",664686210);Qy=new $CLJS.x(null,"when","when",1064114221,null);nx=new $CLJS.M(null,"else-params","else-params",-832171646);
$CLJS.Ry=new $CLJS.M(null,"count","count",2139924085);Vx=new $CLJS.M(null,"right","right",-452581833);Ev=new $CLJS.M(null,"linear","linear",872268697);Sy=new $CLJS.x(null,"when-not","when-not",-1223136340,null);lw=new $CLJS.M(null,"padchar","padchar",2018584530);Tx=new $CLJS.M(null,"offset","offset",296498311);jw=new $CLJS.M(null,"mincol","mincol",1230695445);Ty=new $CLJS.M(null,"not-delivered","not-delivered",1599158697);Iu=new $CLJS.M(null,"miser-width","miser-width",-1310049437);
Uy=new $CLJS.x(null,"with-local-vars","with-local-vars",837642072,null);fy=new $CLJS.x(null,"lift-ns","lift-ns",602311926,null);$x=new $CLJS.M(null,"allows-separator","allows-separator",-818967742);jy=new $CLJS.M(null,"list","list",765357683);Zu=new $CLJS.M(null,"buffering","buffering",-876713613);Vy=new $CLJS.M(null,"arg1","arg1",951899358);Jw=new $CLJS.M(null,"base-args","base-args",-1268706822);Wy=new $CLJS.M(null,"arg3","arg3",-1486822496);Xy=new $CLJS.M(null,"arg2","arg2",1729550917);
qw=new $CLJS.M(null,"commainterval","commainterval",-1980061083);Yy=new $CLJS.M(null,"right-margin","right-margin",-810413306);av=new $CLJS.M(null,"buffer-blob","buffer-blob",-1830112173);Zy=new $CLJS.x(null,"with-open","with-open",172119667,null);ey=new $CLJS.x("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);$y=new $CLJS.M(null,"lines","lines",-700165781);Iv=new $CLJS.M(null,"indent-t","indent-t",528318969);fx=new $CLJS.M(null,"right-params","right-params",-1790676237);
Zv=new $CLJS.M(null,"colon","colon",-965200945);az=new $CLJS.x(null,"if-not","if-not",-265415609,null);Kw=new $CLJS.M(null,"func","func",-238706040);$CLJS.bz=new $CLJS.M(null,"last","last",1105735132);iy=new $CLJS.M(null,"deref","deref",-145586795);cz=new $CLJS.x(null,"dotimes","dotimes",-818708397,null);ex=new $CLJS.M(null,"max-iterations","max-iterations",2021275563);dz=new $CLJS.x(null,"cond","cond",1606708055,null);ez=new $CLJS.x("clojure.core","deref","clojure.core/deref",188719157,null);
iw=new $CLJS.M(null,"minpad","minpad",323570901);Lu=new $CLJS.M(null,"logical-block","logical-block",-581022564);fz=new $CLJS.x(null,"struct-map","struct-map",-1387540878,null);rv=new $CLJS.M(null,"end-block-t","end-block-t",1544648735);gz=new $CLJS.M(null,"stream","stream",1534941648);Iw=new $CLJS.M(null,"params","params",710516235);hz=new $CLJS.x("clojure.core","unquote","clojure.core/unquote",843087510,null);iz=new $CLJS.M(null,"circle","circle",1903212362);
Eu=new $CLJS.M(null,"start-pos","start-pos",668789086);Gx=new $CLJS.M(null,"flags","flags",1775418075);Dv=new $CLJS.M(null,"fill","fill",883462889);gv=new $CLJS.M(null,"buffer-block","buffer-block",-10937307);Nx=new $CLJS.M(null,"generator-fn","generator-fn",811851656);Ju=new $CLJS.M(null,"start-col","start-col",668080143);Qw=new $CLJS.M(null,"w","w",354169001);$CLJS.Yw=new $CLJS.M(null,"n","n",562130025);Sx=new $CLJS.M(null,"def","def",-1043430536);Uw=new $CLJS.M(null,"e","e",1381269198);
Rw=new $CLJS.M(null,"d","d",1972142424);Sw=new $CLJS.M(null,"overflowchar","overflowchar",-1620088106);ox=new $CLJS.M(null,"min-remaining","min-remaining",962687677);rw=new $CLJS.M(null,"commachar","commachar",652859327);jz=new $CLJS.M(null,"section","section",-300141526);kz=new $CLJS.M(null,"pretty","pretty",-1916372486);lz=new $CLJS.x(null,"let","let",358118826,null);$CLJS.ax=new $CLJS.M(null,"clauses","clauses",1454841241);mz=new $CLJS.x(null,"defonce","defonce",-1681484013,null);
ou=new $CLJS.M(null,"base","base",185279322);yu=new $CLJS.M(null,"type-tag","type-tag",-1873863267);nz=new $CLJS.x(null,"locking","locking",1542862874,null);$u=new $CLJS.M(null,"pos","pos",-864607220);oz=new $CLJS.M(null,"suppress-namespaces","suppress-namespaces",2130686956);nu=new $CLJS.M(null,"cur","cur",1153190599);var mw=function mw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return mw.m(0<c.length?new $CLJS.Hc(c.slice(0),0,null):null)},vv,wv,fv,qz,rz,sz,fw,ew,zv,uv,tz;mw.m=function(a){return $CLJS.ac(du,$CLJS.Ue.h($CLJS.sw,a))};mw.F=0;mw.G=function(a){return this.m($CLJS.A(a))};var cu=function cu(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return cu.m(0<c.length?new $CLJS.Hc(c.slice(0),0,null):null)};
cu.m=function(a){return $CLJS.ac(du,$CLJS.Ue.h($CLJS.wh,a))};cu.F=0;cu.G=function(a){return this.m($CLJS.A(a))};$CLJS.h=qu.prototype;$CLJS.h.M=function(a,b){return new qu(this.Ca,this.pf,this.Qc,b)};$CLJS.h.K=function(){return this.nh};$CLJS.h.qc=function(){return this.Qc};$CLJS.h.rc=function(){return Rt(this.Ca)};
$CLJS.h.Fc=function(a,b){a=$CLJS.Wa(b);if($CLJS.p($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?mu(this,nu,lu(this,nu)+$CLJS.E(b)):(mu(this,nu,$CLJS.E(b)-a-1),mu(this,$CLJS.Si,lu(this,$CLJS.Si)+$CLJS.E($CLJS.ff(function(c){return $CLJS.F.h(c,"\n")},b)))),$CLJS.ac(lu(this,ou),b);if($CLJS.p($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return pu(this,b);throw Error(["No matching clause: ",$CLJS.q.g(a)].join(""));};$CLJS.h=ru.prototype;
$CLJS.h.la=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "parent":return this.parent;case "section":return this.Cb;case "start-col":return this.sb;case "indent":return this.qb;case "done-nl":return this.vb;case "intra-block-nl":return this.wb;case "prefix":return this.prefix;case "per-line-prefix":return this.Bb;case "suffix":return this.Db;case "logical-block-callback":return this.yb;default:return $CLJS.K.j(this.l,b,c)}};
$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Js,this.parent],null),new $CLJS.Q(null,2,5,$CLJS.R,[jz,this.Cb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Ju,this.sb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Pu,this.qb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Gu,this.vb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Qu,this.wb],null),new $CLJS.Q(null,2,5,
$CLJS.R,[Jy,this.prefix],null),new $CLJS.Q(null,2,5,$CLJS.R,[Ou,this.Bb],null),new $CLJS.Q(null,2,5,$CLJS.R,[pv,this.Db],null),new $CLJS.Q(null,2,5,$CLJS.R,[lv,this.yb],null)],null),this.l))};$CLJS.h.na=function(){return new $CLJS.Of(this,10,new $CLJS.Q(null,10,5,$CLJS.R,[$CLJS.Js,jz,Ju,Pu,Gu,Qu,Jy,Ou,pv,lv],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};$CLJS.h.ga=function(){return 10+$CLJS.E(this.l)};
$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=1977012399^$CLJS.Qc(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.parent,b.parent)&&$CLJS.F.h(this.Cb,b.Cb)&&$CLJS.F.h(this.sb,b.sb)&&$CLJS.F.h(this.qb,b.qb)&&$CLJS.F.h(this.vb,b.vb)&&$CLJS.F.h(this.wb,b.wb)&&$CLJS.F.h(this.prefix,b.prefix)&&$CLJS.F.h(this.Bb,b.Bb)&&$CLJS.F.h(this.Db,b.Db)&&$CLJS.F.h(this.yb,b.yb)&&$CLJS.F.h(this.l,b.l)};
$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,10,[pv,null,Pu,null,$CLJS.Js,null,jz,null,Gu,null,Ju,null,Jy,null,Ou,null,lv,null,Qu,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new ru(this.parent,this.Cb,this.sb,this.qb,this.vb,this.wb,this.prefix,this.Bb,this.Db,this.yb,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Kd(this.l,b)}};
$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.Js,b):$CLJS.O.call(null,$CLJS.Js,b))?new ru(c,this.Cb,this.sb,this.qb,this.vb,this.wb,this.prefix,this.Bb,this.Db,this.yb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(jz,b):$CLJS.O.call(null,jz,b))?new ru(this.parent,c,this.sb,this.qb,this.vb,this.wb,this.prefix,this.Bb,this.Db,this.yb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Ju,b):$CLJS.O.call(null,Ju,b))?new ru(this.parent,this.Cb,c,this.qb,this.vb,this.wb,this.prefix,
this.Bb,this.Db,this.yb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Pu,b):$CLJS.O.call(null,Pu,b))?new ru(this.parent,this.Cb,this.sb,c,this.vb,this.wb,this.prefix,this.Bb,this.Db,this.yb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Gu,b):$CLJS.O.call(null,Gu,b))?new ru(this.parent,this.Cb,this.sb,this.qb,c,this.wb,this.prefix,this.Bb,this.Db,this.yb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Qu,b):$CLJS.O.call(null,Qu,b))?new ru(this.parent,this.Cb,this.sb,this.qb,this.vb,c,this.prefix,
this.Bb,this.Db,this.yb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Jy,b):$CLJS.O.call(null,Jy,b))?new ru(this.parent,this.Cb,this.sb,this.qb,this.vb,this.wb,c,this.Bb,this.Db,this.yb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Ou,b):$CLJS.O.call(null,Ou,b))?new ru(this.parent,this.Cb,this.sb,this.qb,this.vb,this.wb,this.prefix,c,this.Db,this.yb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(pv,b):$CLJS.O.call(null,pv,b))?new ru(this.parent,this.Cb,this.sb,this.qb,this.vb,this.wb,this.prefix,
this.Bb,c,this.yb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(lv,b):$CLJS.O.call(null,lv,b))?new ru(this.parent,this.Cb,this.sb,this.qb,this.vb,this.wb,this.prefix,this.Bb,this.Db,c,this.A,this.l,null):new ru(this.parent,this.Cb,this.sb,this.qb,this.vb,this.wb,this.prefix,this.Bb,this.Db,this.yb,this.A,$CLJS.Kg.j(this.l,b,c),null)};
$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,10,5,$CLJS.R,[new $CLJS.Ld($CLJS.Js,this.parent),new $CLJS.Ld(jz,this.Cb),new $CLJS.Ld(Ju,this.sb),new $CLJS.Ld(Pu,this.qb),new $CLJS.Ld(Gu,this.vb),new $CLJS.Ld(Qu,this.wb),new $CLJS.Ld(Jy,this.prefix),new $CLJS.Ld(Ou,this.Bb),new $CLJS.Ld(pv,this.Db),new $CLJS.Ld(lv,this.yb)],null),this.l))};$CLJS.h.M=function(a,b){return new ru(this.parent,this.Cb,this.sb,this.qb,this.vb,this.wb,this.prefix,this.Bb,this.Db,this.yb,b,this.l,this.C)};
$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};$CLJS.h=tu.prototype;$CLJS.h.la=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "type-tag":return this.ea;case "data":return this.data;case "trailing-white-space":return this.Yb;case "start-pos":return this.da;case "end-pos":return this.ca;default:return $CLJS.K.j(this.l,b,c)}};
$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[yu,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Vi,this.data],null),new $CLJS.Q(null,2,5,$CLJS.R,[Au,this.Yb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Eu,this.da],null),new $CLJS.Q(null,2,5,$CLJS.R,[Du,this.ca],null)],null),this.l))};
$CLJS.h.na=function(){return new $CLJS.Of(this,5,new $CLJS.Q(null,5,5,$CLJS.R,[yu,$CLJS.Vi,Au,Eu,Du],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};$CLJS.h.ga=function(){return 5+$CLJS.E(this.l)};$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=1809113693^$CLJS.Qc(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ea,b.ea)&&$CLJS.F.h(this.data,b.data)&&$CLJS.F.h(this.Yb,b.Yb)&&$CLJS.F.h(this.da,b.da)&&$CLJS.F.h(this.ca,b.ca)&&$CLJS.F.h(this.l,b.l)};
$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,5,[Du,null,Au,null,yu,null,Eu,null,$CLJS.Vi,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new tu(this.ea,this.data,this.Yb,this.da,this.ca,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Kd(this.l,b)}};
$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h(yu,b):$CLJS.O.call(null,yu,b))?new tu(c,this.data,this.Yb,this.da,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.Vi,b):$CLJS.O.call(null,$CLJS.Vi,b))?new tu(this.ea,c,this.Yb,this.da,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Au,b):$CLJS.O.call(null,Au,b))?new tu(this.ea,this.data,c,this.da,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Eu,b):$CLJS.O.call(null,Eu,b))?new tu(this.ea,this.data,this.Yb,
c,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Du,b):$CLJS.O.call(null,Du,b))?new tu(this.ea,this.data,this.Yb,this.da,c,this.A,this.l,null):new tu(this.ea,this.data,this.Yb,this.da,this.ca,this.A,$CLJS.Kg.j(this.l,b,c),null)};$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Ld(yu,this.ea),new $CLJS.Ld($CLJS.Vi,this.data),new $CLJS.Ld(Au,this.Yb),new $CLJS.Ld(Eu,this.da),new $CLJS.Ld(Du,this.ca)],null),this.l))};
$CLJS.h.M=function(a,b){return new tu(this.ea,this.data,this.Yb,this.da,this.ca,b,this.l,this.C)};$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};$CLJS.h=uu.prototype;$CLJS.h.la=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "type-tag":return this.ea;case "type":return this.type;case "logical-block":return this.ja;case "start-pos":return this.da;case "end-pos":return this.ca;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[yu,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Ri,this.type],null),new $CLJS.Q(null,2,5,$CLJS.R,[Lu,this.ja],null),new $CLJS.Q(null,2,5,$CLJS.R,[Eu,this.da],null),new $CLJS.Q(null,2,5,$CLJS.R,[Du,this.ca],null)],null),this.l))};
$CLJS.h.na=function(){return new $CLJS.Of(this,5,new $CLJS.Q(null,5,5,$CLJS.R,[yu,$CLJS.Ri,Lu,Eu,Du],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};$CLJS.h.ga=function(){return 5+$CLJS.E(this.l)};$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=-1640656800^$CLJS.Qc(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ea,b.ea)&&$CLJS.F.h(this.type,b.type)&&$CLJS.F.h(this.ja,b.ja)&&$CLJS.F.h(this.da,b.da)&&$CLJS.F.h(this.ca,b.ca)&&$CLJS.F.h(this.l,b.l)};
$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,5,[Du,null,$CLJS.Ri,null,Lu,null,yu,null,Eu,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new uu(this.ea,this.type,this.ja,this.da,this.ca,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Kd(this.l,b)}};
$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h(yu,b):$CLJS.O.call(null,yu,b))?new uu(c,this.type,this.ja,this.da,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.Ri,b):$CLJS.O.call(null,$CLJS.Ri,b))?new uu(this.ea,c,this.ja,this.da,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Lu,b):$CLJS.O.call(null,Lu,b))?new uu(this.ea,this.type,c,this.da,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Eu,b):$CLJS.O.call(null,Eu,b))?new uu(this.ea,this.type,this.ja,
c,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Du,b):$CLJS.O.call(null,Du,b))?new uu(this.ea,this.type,this.ja,this.da,c,this.A,this.l,null):new uu(this.ea,this.type,this.ja,this.da,this.ca,this.A,$CLJS.Kg.j(this.l,b,c),null)};$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,5,5,$CLJS.R,[new $CLJS.Ld(yu,this.ea),new $CLJS.Ld($CLJS.Ri,this.type),new $CLJS.Ld(Lu,this.ja),new $CLJS.Ld(Eu,this.da),new $CLJS.Ld(Du,this.ca)],null),this.l))};
$CLJS.h.M=function(a,b){return new uu(this.ea,this.type,this.ja,this.da,this.ca,b,this.l,this.C)};$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};$CLJS.h=vu.prototype;$CLJS.h.la=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "type-tag":return this.ea;case "logical-block":return this.ja;case "start-pos":return this.da;case "end-pos":return this.ca;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[yu,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[Lu,this.ja],null),new $CLJS.Q(null,2,5,$CLJS.R,[Eu,this.da],null),new $CLJS.Q(null,2,5,$CLJS.R,[Du,this.ca],null)],null),this.l))};
$CLJS.h.na=function(){return new $CLJS.Of(this,4,new $CLJS.Q(null,4,5,$CLJS.R,[yu,Lu,Eu,Du],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};$CLJS.h.ga=function(){return 4+$CLJS.E(this.l)};$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=-414877272^$CLJS.Qc(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ea,b.ea)&&$CLJS.F.h(this.ja,b.ja)&&$CLJS.F.h(this.da,b.da)&&$CLJS.F.h(this.ca,b.ca)&&$CLJS.F.h(this.l,b.l)};$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,4,[Du,null,Lu,null,yu,null,Eu,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new vu(this.ea,this.ja,this.da,this.ca,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Kd(this.l,b)}};
$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h(yu,b):$CLJS.O.call(null,yu,b))?new vu(c,this.ja,this.da,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Lu,b):$CLJS.O.call(null,Lu,b))?new vu(this.ea,c,this.da,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Eu,b):$CLJS.O.call(null,Eu,b))?new vu(this.ea,this.ja,c,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Du,b):$CLJS.O.call(null,Du,b))?new vu(this.ea,this.ja,this.da,c,this.A,this.l,null):new vu(this.ea,this.ja,
this.da,this.ca,this.A,$CLJS.Kg.j(this.l,b,c),null)};$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Ld(yu,this.ea),new $CLJS.Ld(Lu,this.ja),new $CLJS.Ld(Eu,this.da),new $CLJS.Ld(Du,this.ca)],null),this.l))};$CLJS.h.M=function(a,b){return new vu(this.ea,this.ja,this.da,this.ca,b,this.l,this.C)};$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};$CLJS.h=wu.prototype;
$CLJS.h.la=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "type-tag":return this.ea;case "logical-block":return this.ja;case "start-pos":return this.da;case "end-pos":return this.ca;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[yu,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[Lu,this.ja],null),new $CLJS.Q(null,2,5,$CLJS.R,[Eu,this.da],null),new $CLJS.Q(null,2,5,$CLJS.R,[Du,this.ca],null)],null),this.l))};
$CLJS.h.na=function(){return new $CLJS.Of(this,4,new $CLJS.Q(null,4,5,$CLJS.R,[yu,Lu,Eu,Du],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};$CLJS.h.ga=function(){return 4+$CLJS.E(this.l)};$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=1365867980^$CLJS.Qc(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ea,b.ea)&&$CLJS.F.h(this.ja,b.ja)&&$CLJS.F.h(this.da,b.da)&&$CLJS.F.h(this.ca,b.ca)&&$CLJS.F.h(this.l,b.l)};$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,4,[Du,null,Lu,null,yu,null,Eu,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new wu(this.ea,this.ja,this.da,this.ca,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Kd(this.l,b)}};
$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h(yu,b):$CLJS.O.call(null,yu,b))?new wu(c,this.ja,this.da,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Lu,b):$CLJS.O.call(null,Lu,b))?new wu(this.ea,c,this.da,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Eu,b):$CLJS.O.call(null,Eu,b))?new wu(this.ea,this.ja,c,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Du,b):$CLJS.O.call(null,Du,b))?new wu(this.ea,this.ja,this.da,c,this.A,this.l,null):new wu(this.ea,this.ja,
this.da,this.ca,this.A,$CLJS.Kg.j(this.l,b,c),null)};$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Ld(yu,this.ea),new $CLJS.Ld(Lu,this.ja),new $CLJS.Ld(Eu,this.da),new $CLJS.Ld(Du,this.ca)],null),this.l))};$CLJS.h.M=function(a,b){return new wu(this.ea,this.ja,this.da,this.ca,b,this.l,this.C)};$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};$CLJS.h=xu.prototype;
$CLJS.h.la=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "type-tag":return this.ea;case "logical-block":return this.ja;case "relative-to":return this.Qb;case "offset":return this.offset;case "start-pos":return this.da;case "end-pos":return this.ca;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,6,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[yu,this.ea],null),new $CLJS.Q(null,2,5,$CLJS.R,[Lu,this.ja],null),new $CLJS.Q(null,2,5,$CLJS.R,[vy,this.Qb],null),new $CLJS.Q(null,2,5,$CLJS.R,[Tx,this.offset],null),new $CLJS.Q(null,2,5,$CLJS.R,[Eu,this.da],null),new $CLJS.Q(null,2,5,$CLJS.R,[Du,this.ca],null)],null),this.l))};
$CLJS.h.na=function(){return new $CLJS.Of(this,6,new $CLJS.Q(null,6,5,$CLJS.R,[yu,Lu,vy,Tx,Eu,Du],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};$CLJS.h.ga=function(){return 6+$CLJS.E(this.l)};$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=-1602780238^$CLJS.Qc(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.ea,b.ea)&&$CLJS.F.h(this.ja,b.ja)&&$CLJS.F.h(this.Qb,b.Qb)&&$CLJS.F.h(this.offset,b.offset)&&$CLJS.F.h(this.da,b.da)&&$CLJS.F.h(this.ca,b.ca)&&$CLJS.F.h(this.l,b.l)};
$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,6,[Tx,null,Du,null,vy,null,Lu,null,yu,null,Eu,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new xu(this.ea,this.ja,this.Qb,this.offset,this.da,this.ca,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Kd(this.l,b)}};
$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h(yu,b):$CLJS.O.call(null,yu,b))?new xu(c,this.ja,this.Qb,this.offset,this.da,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Lu,b):$CLJS.O.call(null,Lu,b))?new xu(this.ea,c,this.Qb,this.offset,this.da,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(vy,b):$CLJS.O.call(null,vy,b))?new xu(this.ea,this.ja,c,this.offset,this.da,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Tx,b):$CLJS.O.call(null,Tx,b))?new xu(this.ea,
this.ja,this.Qb,c,this.da,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Eu,b):$CLJS.O.call(null,Eu,b))?new xu(this.ea,this.ja,this.Qb,this.offset,c,this.ca,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Du,b):$CLJS.O.call(null,Du,b))?new xu(this.ea,this.ja,this.Qb,this.offset,this.da,c,this.A,this.l,null):new xu(this.ea,this.ja,this.Qb,this.offset,this.da,this.ca,this.A,$CLJS.Kg.j(this.l,b,c),null)};
$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,6,5,$CLJS.R,[new $CLJS.Ld(yu,this.ea),new $CLJS.Ld(Lu,this.ja),new $CLJS.Ld(vy,this.Qb),new $CLJS.Ld(Tx,this.offset),new $CLJS.Ld(Eu,this.da),new $CLJS.Ld(Du,this.ca)],null),this.l))};$CLJS.h.M=function(a,b){return new xu(this.ea,this.ja,this.Qb,this.offset,this.da,this.ca,b,this.l,this.C)};$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};
var Bu=function(){var a=$CLJS.yh.g($CLJS.P),b=$CLJS.yh.g($CLJS.P),c=$CLJS.yh.g($CLJS.P),d=$CLJS.yh.g($CLJS.P),e=$CLJS.K.j($CLJS.P,$CLJS.Ni,$CLJS.Gh.v?$CLJS.Gh.v():$CLJS.Gh.call(null));return new $CLJS.Qh($CLJS.ph.h("cljs.pprint","write-token"),function(f,g){return yu.g(g)},e,a,b,c,d)}();
Bu.P(null,nv,function(a,b){var c=lv.g($CLJS.t($CLJS.t(a)));$CLJS.p(c)&&(c.g?c.g($CLJS.mv):c.call(null,$CLJS.mv));b=Lu.g(b);c=Jy.g(b);$CLJS.p(c)&&$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),c);a=lu(ou.g($CLJS.t($CLJS.t(a))),nu);$CLJS.$e(Ju.g(b),a);return $CLJS.$e(Pu.g(b),a)});Bu.P(null,rv,function(a,b){var c=lv.g($CLJS.t($CLJS.t(a)));$CLJS.p(c)&&(c.g?c.g(qv):c.call(null,qv));b=pv.g(Lu.g(b));return $CLJS.p(b)?$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),b):null});
Bu.P(null,Iv,function(a,b){var c=Lu.g(b),d=Pu.g(c),e=Tx.g(b);b=vy.g(b);if($CLJS.p($CLJS.F.h?$CLJS.F.h(Gv,b):$CLJS.F.call(null,Gv,b)))a=$CLJS.t(Ju.g(c));else if($CLJS.p($CLJS.F.h?$CLJS.F.h($CLJS.Hv,b):$CLJS.F.call(null,$CLJS.Hv,b)))a=lu(ou.g($CLJS.t($CLJS.t(a))),nu);else throw Error(["No matching clause: ",$CLJS.q.g(b)].join(""));return $CLJS.$e(d,e+a)});Bu.P(null,av,function(a,b){return $CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),$CLJS.Vi.g(b))});
Bu.P(null,zu,function(a,b){var c=$CLJS.F.h($CLJS.Ri.g(b),Bv);c||(c=(c=!$CLJS.F.h($CLJS.Ri.g(b),Dv))?$CLJS.t(Gu.g(Lu.g(b))):c);$CLJS.p(c)?Ru.h?Ru.h(a,b):Ru.call(null,a,b):(b=Au.g($CLJS.t($CLJS.t(a))),$CLJS.p(b)&&$CLJS.ac(ou.g($CLJS.t($CLJS.t(a))),b));return $CLJS.zh.I($CLJS.t(a),$CLJS.Kg,Au,null)});
var pz=function(){var a=$CLJS.yh.g($CLJS.P),b=$CLJS.yh.g($CLJS.P),c=$CLJS.yh.g($CLJS.P),d=$CLJS.yh.g($CLJS.P),e=$CLJS.K.j($CLJS.P,$CLJS.Ni,$CLJS.Gh.v?$CLJS.Gh.v():$CLJS.Gh.call(null));return new $CLJS.Qh($CLJS.ph.h("cljs.pprint","emit-nl?"),function(f){return $CLJS.Ri.g(f)},e,a,b,c,d)}();pz.P(null,Ev,function(a,b,c){a=Lu.g(a);return Hu(b,a,c)});pz.P(null,Cv,function(a,b,c){a=Lu.g(a);return Ku(b,a,c)});
pz.P(null,Dv,function(a,b,c,d){a=Lu.g(a);var e=$CLJS.t(Qu.g(a));return $CLJS.p(e)?e:(d=!Fu(b,d))?d:Ku(b,a,c)});pz.P(null,Bv,function(){return!0});
var Uu=function Uu(a,b){var d=Su(b);b=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);$CLJS.p(b)&&Cu(a,b,!1);if($CLJS.p(e)){d=Mu(e);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=$CLJS.B(e),g=pz.I(f,a,b,Nu(e));$CLJS.p(g)&&(Ru(a,f),e=$CLJS.C(e));Fu(a,e)?a=e:(e=Uu.h?Uu.h(a,b):Uu.call(null,a,b),$CLJS.F.h(e,b)?(Cu(a,b,!1),a=d):a=$CLJS.Yf.h($CLJS.xf,$CLJS.ek.h(e,d)));return a}return null};$CLJS.h=dv.prototype;$CLJS.h.M=function(a,b){return new dv(this.Ca,this.pf,this.uh,this.xg,this.Qc,b)};$CLJS.h.K=function(){return this.oh};
$CLJS.h.qc=function(){return this.Qc};
$CLJS.h.Fc=function(a,b){a=$CLJS.Wa(b);if($CLJS.p($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a))){var c=cv(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.ii.g($CLJS.t($CLJS.t(this)));if($CLJS.F.h(d,bv))return Xu(this),$CLJS.ac(ou.g($CLJS.t($CLJS.t(this))),b),$CLJS.zh.I($CLJS.t(this),$CLJS.Kg,Au,a);d=$u.g($CLJS.t($CLJS.t(this)));c=d+$CLJS.E(c);$CLJS.zh.I($CLJS.t(this),$CLJS.Kg,$u,c);return Wu(this,new tu(av,b,a,d,c,null,null,null))}if($CLJS.p($CLJS.F.h?$CLJS.F.h(Number,
a):$CLJS.F.call(null,Number,a)))return $CLJS.F.h($CLJS.ii.g($CLJS.t($CLJS.t(this))),bv)?(Xu(this),b=$CLJS.ac(ou.g($CLJS.t($CLJS.t(this))),b)):$CLJS.F.h(b,"\n")?b=cv(this,"\n"):(a=$u.g($CLJS.t($CLJS.t(this))),c=a+1,$CLJS.zh.I($CLJS.t(this),$CLJS.Kg,$u,c),b=Ut(b),b=Wu(this,new tu(av,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.q.g(a)].join(""));};$CLJS.h.rc=function(){this.af(null);return Rt(ou.g($CLJS.t($CLJS.t(this))))};
$CLJS.h.af=function(){return $CLJS.F.h($CLJS.ii.g($CLJS.t($CLJS.t(this))),Zu)?(Cu(this,Tu.g($CLJS.t($CLJS.t(this))),!0),$CLJS.zh.I($CLJS.t(this),$CLJS.Kg,Tu,$CLJS.xf)):Xu(this)};vv=!0;wv=null;$CLJS.ev=72;fv=40;qz=null;rz=null;sz=null;fw=null;ew=10;zv=0;uv=null;tz=function tz(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return tz.m(arguments[0],1<c.length?new $CLJS.Hc(c.slice(1),0,null):null)};
tz.m=function(a,b){var c=$CLJS.sk.m($CLJS.H([new $CLJS.n(null,1,[gz,!0],null),$CLJS.Ue.h(uy,b)]));b=ew;var d=rz,e=$CLJS.Ja,f=$CLJS.ah,g=qz,k=fv,l=wv,m=vv,r=fw,v=$CLJS.Fa,w=$CLJS.ev,y=sz,z=ou.h(c,ew),D=iz.h(c,rz),N=$CLJS.Iy.h(c,$CLJS.Ja),V=$CLJS.Hs.h(c,$CLJS.ah),W=$y.h(c,qz),da=Iu.h(c,fv),qa=$CLJS.xi.h(c,wv),ua=kz.h(c,vv),Ka=My.h(c,fw),mb=$CLJS.Ea.h(c,$CLJS.Fa),zb=Yy.h(c,$CLJS.ev),Fb=oz.h(c,sz);ew=z;rz=D;$CLJS.Ja=N;$CLJS.ah=V;qz=W;fv=da;wv=qa;vv=ua;fw=Ka;$CLJS.Fa=mb;$CLJS.ev=zb;sz=Fb;try{var Db=new $CLJS.Ca,
Lb=$CLJS.Kd(c,gz)?gz.g(c):!0,nb=!0===Lb||null==Lb?new $CLJS.rc(Db):Lb;if($CLJS.p(vv)){var vb=$CLJS.Ra(tv(nb));c=du;du=vb?kv(nb):nb;try{xv(a),ku()}finally{du=c}}else{vb=du;du=nb;try{cu.call(null,a)}finally{du=vb}}!0===Lb&&Wt($CLJS.q.g(Db));return null==Lb?$CLJS.q.g(Db):null}finally{sz=y,$CLJS.ev=w,$CLJS.Fa=v,fw=r,vv=m,wv=l,fv=k,qz=g,$CLJS.ah=f,$CLJS.Ja=e,rz=d,ew=b}};tz.F=1;tz.G=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.m(b,a)};var Pv=null;$CLJS.h=Rv.prototype;
$CLJS.h.la=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "seq":return this.tc;case "rest":return this.mb;case "pos":return this.Wb;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,3,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[Tv,this.tc],null),new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.Sv,this.mb],null),new $CLJS.Q(null,2,5,$CLJS.R,[$u,this.Wb],null)],null),this.l))};$CLJS.h.na=function(){return new $CLJS.Of(this,3,new $CLJS.Q(null,3,5,$CLJS.R,[Tv,$CLJS.Sv,$u],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};
$CLJS.h.K=function(){return this.A};$CLJS.h.ga=function(){return 3+$CLJS.E(this.l)};$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=-402038447^$CLJS.Qc(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.tc,b.tc)&&$CLJS.F.h(this.mb,b.mb)&&$CLJS.F.h(this.Wb,b.Wb)&&$CLJS.F.h(this.l,b.l)};
$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,3,[$u,null,Tv,null,$CLJS.Sv,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new Rv(this.tc,this.mb,this.Wb,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Kd(this.l,b)}};
$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h(Tv,b):$CLJS.O.call(null,Tv,b))?new Rv(c,this.mb,this.Wb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h($CLJS.Sv,b):$CLJS.O.call(null,$CLJS.Sv,b))?new Rv(this.tc,c,this.Wb,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h($u,b):$CLJS.O.call(null,$u,b))?new Rv(this.tc,this.mb,c,this.A,this.l,null):new Rv(this.tc,this.mb,this.Wb,this.A,$CLJS.Kg.j(this.l,b,c),null)};
$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,3,5,$CLJS.R,[new $CLJS.Ld(Tv,this.tc),new $CLJS.Ld($CLJS.Sv,this.mb),new $CLJS.Ld($u,this.Wb)],null),this.l))};$CLJS.h.M=function(a,b){return new Rv(this.tc,this.mb,this.Wb,b,this.l,this.C)};$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};$CLJS.h=Yv.prototype;$CLJS.h.la=function(a,b){return this.T(null,b,null)};
$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.M?b.Y:null){case "func":return this.bc;case "def":return this.$b;case "params":return this.jc;case "offset":return this.offset;default:return $CLJS.K.j(this.l,b,c)}};$CLJS.h.ua=function(a,b,c){return $CLJS.$a(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.ch(b,function(d){return $CLJS.ch(b,$CLJS.hh,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.ek.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,2,5,$CLJS.R,[Kw,this.bc],null),new $CLJS.Q(null,2,5,$CLJS.R,[Sx,this.$b],null),new $CLJS.Q(null,2,5,$CLJS.R,[Iw,this.jc],null),new $CLJS.Q(null,2,5,$CLJS.R,[Tx,this.offset],null)],null),this.l))};
$CLJS.h.na=function(){return new $CLJS.Of(this,4,new $CLJS.Q(null,4,5,$CLJS.R,[Kw,Sx,Iw,Tx],null),$CLJS.p(this.l)?$CLJS.qc(this.l):$CLJS.Ne())};$CLJS.h.K=function(){return this.A};$CLJS.h.ga=function(){return 4+$CLJS.E(this.l)};$CLJS.h.fa=function(){var a=this.C;return null!=a?a:this.C=a=-829256337^$CLJS.Qc(this)};
$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.bc,b.bc)&&$CLJS.F.h(this.$b,b.$b)&&$CLJS.F.h(this.jc,b.jc)&&$CLJS.F.h(this.offset,b.offset)&&$CLJS.F.h(this.l,b.l)};$CLJS.h.wa=function(a,b){return $CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,4,[Tx,null,Kw,null,Iw,null,Sx,null],null),null),b)?$CLJS.ak.h($CLJS.Ob($CLJS.Yf.h($CLJS.P,this),this.A),b):new Yv(this.bc,this.$b,this.jc,this.offset,this.A,$CLJS.Le($CLJS.ak.h(this.l,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.M?b.Y:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Kd(this.l,b)}};
$CLJS.h.ba=function(a,b,c){return $CLJS.p($CLJS.O.h?$CLJS.O.h(Kw,b):$CLJS.O.call(null,Kw,b))?new Yv(c,this.$b,this.jc,this.offset,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Sx,b):$CLJS.O.call(null,Sx,b))?new Yv(this.bc,c,this.jc,this.offset,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Iw,b):$CLJS.O.call(null,Iw,b))?new Yv(this.bc,this.$b,c,this.offset,this.A,this.l,null):$CLJS.p($CLJS.O.h?$CLJS.O.h(Tx,b):$CLJS.O.call(null,Tx,b))?new Yv(this.bc,this.$b,this.jc,c,this.A,this.l,null):new Yv(this.bc,
this.$b,this.jc,this.offset,this.A,$CLJS.Kg.j(this.l,b,c),null)};$CLJS.h.ha=function(){return $CLJS.A($CLJS.ek.h(new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Ld(Kw,this.bc),new $CLJS.Ld(Sx,this.$b),new $CLJS.Ld(Iw,this.jc),new $CLJS.Ld(Tx,this.offset)],null),this.l))};$CLJS.h.M=function(a,b){return new Yv(this.bc,this.$b,this.jc,this.offset,b,this.l,this.C)};$CLJS.h.ia=function(a,b){return $CLJS.Bd(b)?this.ba(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.$a($CLJS.jb,this,b)};
var gw=new $CLJS.n(null,3,[2,"#b",8,"#o",16,"#x"],null),uw=new $CLJS.Q(null,20,5,$CLJS.R,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),uz=new $CLJS.Q(null,20,5,$CLJS.R,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),vw=new $CLJS.Q(null,10,5,$CLJS.R,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),vz=new $CLJS.Q(null,10,5,$CLJS.R,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),xw=new $CLJS.Q(null,22,5,$CLJS.R," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),wz=new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,9,5,$CLJS.R,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.Q(null,3,5,$CLJS.R,["M","MM","MMM"],null)],null),xz=new $CLJS.Q(null,4,5,$CLJS.R,[new $CLJS.Q(null,9,5,$CLJS.R,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.Q(null,9,5,$CLJS.R,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.Q(null,3,5,$CLJS.R,["M","MM","MMM"],null)],null),Aw=new $CLJS.n(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);qx.prototype.M=function(a,b){return new qx(this.Ca,b)};qx.prototype.K=function(){return this.ph};qx.prototype.rc=function(){return Rt(this.Ca)};
qx.prototype.Fc=function(a,b){a=$CLJS.Wa(b);if($CLJS.p($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return $CLJS.ac(this.Ca,$CLJS.As(b));if($CLJS.p($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return $CLJS.ac(this.Ca,$CLJS.As(Ut(b)));throw Error(["No matching clause: ",$CLJS.q.g(a)].join(""));};sx.prototype.M=function(a,b){return new sx(this.Ca,b)};sx.prototype.K=function(){return this.qh};sx.prototype.rc=function(){return Rt(this.Ca)};
sx.prototype.Fc=function(a,b){a=$CLJS.Wa(b);if($CLJS.p($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return $CLJS.ac(this.Ca,b.toUpperCase());if($CLJS.p($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return $CLJS.ac(this.Ca,Ut(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.q.g(a)].join(""));};vx.prototype.M=function(a,b){return new vx(this.Ca,this.Hd,b)};vx.prototype.K=function(){return this.rh};vx.prototype.rc=function(){return Rt(this.Ca)};
vx.prototype.Fc=function(a,b){a=$CLJS.Wa(b);if($CLJS.p($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return $CLJS.ac(this.Ca,ux(b.toLowerCase(),$CLJS.t(this.Hd))),0<b.length?$CLJS.$e(this.Hd,$CLJS.wa($CLJS.G(b,$CLJS.E(b)-1))):null;if($CLJS.p($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return b=Ut(b),a=$CLJS.p($CLJS.t(this.Hd))?b.toUpperCase():b,$CLJS.ac(this.Ca,a),$CLJS.$e(this.Hd,$CLJS.wa(b));throw Error(["No matching clause: ",$CLJS.q.g(a)].join(""));};
xx.prototype.M=function(a,b){return new xx(this.Ca,this.td,b)};xx.prototype.K=function(){return this.sh};xx.prototype.rc=function(){return Rt(this.Ca)};
xx.prototype.Fc=function(a,b){a=$CLJS.Wa(b);if($CLJS.p($CLJS.F.h?$CLJS.F.h(String,a):$CLJS.F.call(null,String,a)))return b=$CLJS.As(b),$CLJS.Ra($CLJS.t(this.td))?(a=RegExp("\\S","g").exec(b),a=$CLJS.p(a)?a.index:a,$CLJS.p(a)?($CLJS.ac(this.Ca,[b.substring(0,a),$CLJS.G(b,a).toUpperCase(),$CLJS.As(b.substring(a+1))].join("")),$CLJS.$e(this.td,!0)):$CLJS.ac(this.Ca,b)):$CLJS.ac(this.Ca,$CLJS.As(b));if($CLJS.p($CLJS.F.h?$CLJS.F.h(Number,a):$CLJS.F.call(null,Number,a)))return b=Ut(b),a=$CLJS.Ra($CLJS.t(this.td)),
$CLJS.p(a?Pt(b):a)?($CLJS.$e(this.td,!0),$CLJS.ac(this.Ca,b.toUpperCase())):$CLJS.ac(this.Ca,$CLJS.As(b));throw Error(["No matching clause: ",$CLJS.q.g(a)].join(""));};
var Mx=$CLJS.Bg("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.n(null,5,[Hx,"A",Iw,new $CLJS.n(null,4,[jw,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),kw,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),iw,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),lw,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,3,[Zv,null,$v,null,Ix,null],null),null),Rx,$CLJS.P,Nx,function(){return function(a,b){return nw($CLJS.sw,a,b)}}],null),new $CLJS.n(null,
5,[Hx,"S",Iw,new $CLJS.n(null,4,[jw,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),kw,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),iw,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),lw,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,3,[Zv,null,$v,null,Ix,null],null),null),Rx,$CLJS.P,Nx,function(){return function(a,b){return nw($CLJS.wh,a,b)}}],null),new $CLJS.n(null,5,[Hx,"D",Iw,new $CLJS.n(null,4,[jw,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),lw,new $CLJS.Q(null,
2,5,$CLJS.R,[" ",String],null),rw,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),qw,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,3,[Zv,null,$v,null,Ix,null],null),null),Rx,$CLJS.P,Nx,function(){return function(a,b){return tw(10,a,b)}}],null),new $CLJS.n(null,5,[Hx,"B",Iw,new $CLJS.n(null,4,[jw,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),lw,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),rw,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),qw,new $CLJS.Q(null,
2,5,$CLJS.R,[3,Number],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,3,[Zv,null,$v,null,Ix,null],null),null),Rx,$CLJS.P,Nx,function(){return function(a,b){return tw(2,a,b)}}],null),new $CLJS.n(null,5,[Hx,"O",Iw,new $CLJS.n(null,4,[jw,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),lw,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),rw,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),qw,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,3,[Zv,null,$v,null,Ix,
null],null),null),Rx,$CLJS.P,Nx,function(){return function(a,b){return tw(8,a,b)}}],null),new $CLJS.n(null,5,[Hx,"X",Iw,new $CLJS.n(null,4,[jw,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),lw,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),rw,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),qw,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,3,[Zv,null,$v,null,Ix,null],null),null),Rx,$CLJS.P,Nx,function(){return function(a,b){return tw(16,a,b)}}],null),new $CLJS.n(null,
5,[Hx,"R",Iw,new $CLJS.n(null,5,[ou,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),jw,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),lw,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),rw,new $CLJS.Q(null,2,5,$CLJS.R,[",",String],null),qw,new $CLJS.Q(null,2,5,$CLJS.R,[3,Number],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,3,[Zv,null,$v,null,Ix,null],null),null),Rx,$CLJS.P,Nx,function(a){return $CLJS.p($CLJS.B(ou.g(a)))?function(b,c){return tw(ou.g(b),b,c)}:$CLJS.p(function(){var b=$v.g(a);return $CLJS.p(b)?
Zv.g(a):b}())?function(b,c){return zw(wz,c)}:$CLJS.p($v.g(a))?function(b,c){return zw(xz,c)}:$CLJS.p(Zv.g(a))?function(b,c){b=Uv(c);c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if($CLJS.F.h(0,c))mw.m($CLJS.H(["zeroth"]));else{var d=ow(1E3,0>c?-c:c);if($CLJS.E(d)<=$CLJS.E(xw)){var e=$CLJS.ih.h(ww,$CLJS.bu(1,d));e=yw(e,1);var f=$CLJS.id(d);d=$CLJS.ce(f,100);f=Vt(f,100);var g=0<d?[$CLJS.q.g($CLJS.G(uw,d))," hundred"].join(""):null,k=$CLJS.q,l=k.g;if(0<f)if(20>f)var m=$CLJS.G(uz,f);else{m=$CLJS.ce(f,10);var r=
Vt(f,10);m=0<m&&!(0<r)?$CLJS.G(vz,m):[$CLJS.q.g(0<m?$CLJS.G(vw,m):null),0<m&&0<r?"-":null,$CLJS.q.g(0<r?$CLJS.G(uz,r):null)].join("")}else m=0<d?"th":null;d=[g,0<d&&0<f?" ":null,l.call(k,m)].join("");mw.m($CLJS.H([[0>c?"minus ":null,$CLJS.sd(e)||$CLJS.sd(d)?$CLJS.sd(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else tw(10,new $CLJS.n(null,5,[jw,0,lw," ",rw,",",qw,3,Zv,!0],null),Lv(new $CLJS.Q(null,1,5,$CLJS.R,[c],null))),e=Vt(c,100),c=11<e||19>e,e=Vt(e,10),mw.m($CLJS.H([1===e&&c?"st":2===
e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=Uv(c);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.F.h(0,b))mw.m($CLJS.H(["zero"]));else{var d=ow(1E3,0>b?-b:b);$CLJS.E(d)<=$CLJS.E(xw)?(d=$CLJS.ih.h(ww,d),d=yw(d,0),mw.m($CLJS.H([[0>b?"minus ":null,d].join("")]))):tw(10,new $CLJS.n(null,5,[jw,0,lw," ",rw,",",qw,3,Zv,!0],null),Lv(new $CLJS.Q(null,1,5,$CLJS.R,[b],null)))}return c}}],null),new $CLJS.n(null,5,[Hx,"P",Iw,$CLJS.P,Gx,new $CLJS.S(null,new $CLJS.n(null,3,[Zv,null,$v,null,Ix,null],
null),null),Rx,$CLJS.P,Nx,function(){return function(a,b){b=$CLJS.p(Zv.g(a))?Wv(b,-1):b;a=$CLJS.p($v.g(a))?new $CLJS.Q(null,2,5,$CLJS.R,["y","ies"],null):new $CLJS.Q(null,2,5,$CLJS.R,["","s"],null);var c=Uv(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);mw.m($CLJS.H([$CLJS.F.h(b,1)?$CLJS.B(a):$CLJS.hd(a)]));return c}}],null),new $CLJS.n(null,5,[Hx,"C",Iw,new $CLJS.n(null,1,[Cw,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,3,[Zv,null,$v,null,Ix,null],null),
null),Rx,$CLJS.P,Nx,function(a){return $CLJS.p(Zv.g(a))?Bw:$CLJS.p($v.g(a))?Dw:Ew}],null),new $CLJS.n(null,5,[Hx,"F",Iw,new $CLJS.n(null,5,[Qw,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Rw,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),$CLJS.Is,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),Sw,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null),lw,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,1,[$v,null],null),null),Rx,$CLJS.P,Nx,function(){return Tw}],
null),new $CLJS.n(null,5,[Hx,"E",Iw,new $CLJS.n(null,7,[Qw,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Rw,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Uw,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),$CLJS.Is,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Sw,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null),lw,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),Vw,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,1,[$v,null],null),null),Rx,$CLJS.P,
Nx,function(){return Ww}],null),new $CLJS.n(null,5,[Hx,"G",Iw,new $CLJS.n(null,7,[Qw,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Rw,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Uw,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),$CLJS.Is,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Sw,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null),lw,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null),Vw,new $CLJS.Q(null,2,5,$CLJS.R,[null,String],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,1,[$v,null],
null),null),Rx,$CLJS.P,Nx,function(){return Xw}],null),new $CLJS.n(null,5,[Hx,"$",Iw,new $CLJS.n(null,4,[Rw,new $CLJS.Q(null,2,5,$CLJS.R,[2,Number],null),$CLJS.Yw,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),Qw,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),lw,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,3,[Zv,null,$v,null,Ix,null],null),null),Rx,$CLJS.P,Nx,function(){return Zw}],null),new $CLJS.n(null,5,[Hx,"%",Iw,new $CLJS.n(null,1,[$CLJS.Ry,new $CLJS.Q(null,
2,5,$CLJS.R,[1,Number],null)],null),Gx,$CLJS.Ng,Rx,$CLJS.P,Nx,function(){return function(a,b){a=$CLJS.Ry.g(a);for(var c=0;;)if(c<a)eu(),c+=1;else break;return b}}],null),new $CLJS.n(null,5,[Hx,"\x26",Iw,new $CLJS.n(null,1,[$CLJS.Ry,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,1,[kz,null],null),null),Rx,$CLJS.P,Nx,function(){return function(a,b){a=$CLJS.Ry.g(a);0<a&&((null!=du?du.o&32768||$CLJS.u===du.Ue||(du.o?0:$CLJS.Va($CLJS.Qt,du)):$CLJS.Va($CLJS.Qt,
du))?$CLJS.F.h(0,lu(ou.g($CLJS.t($CLJS.t(du))),nu))||eu():eu());--a;for(var c=0;;)if(c<a)eu(),c+=1;else break;return b}}],null),new $CLJS.n(null,5,[Hx,"|",Iw,new $CLJS.n(null,1,[$CLJS.Ry,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),Gx,$CLJS.Ng,Rx,$CLJS.P,Nx,function(){return function(a,b){a=$CLJS.Ry.g(a);for(var c=0;;)if(c<a)mw.m($CLJS.H(["\f"])),c+=1;else break;return b}}],null),new $CLJS.n(null,5,[Hx,"~",Iw,new $CLJS.n(null,1,[$CLJS.Yw,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),
Gx,$CLJS.Ng,Rx,$CLJS.P,Nx,function(){return function(a,b){a=$CLJS.Yw.g(a);mw.m($CLJS.H([$CLJS.Ue.h($CLJS.q,Zt(a,"~"))]));return b}}],null),new $CLJS.n(null,5,[Hx,"\n",Iw,$CLJS.P,Gx,new $CLJS.S(null,new $CLJS.n(null,2,[Zv,null,$v,null],null),null),Rx,$CLJS.P,Nx,function(){return function(a,b){$CLJS.p($v.g(a))&&eu();return b}}],null),new $CLJS.n(null,5,[Hx,"T",Iw,new $CLJS.n(null,2,[Gy,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),kw,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),Gx,new $CLJS.S(null,
new $CLJS.n(null,2,[$v,null,kz,null],null),null),Rx,$CLJS.P,Nx,function(a){return $CLJS.p($v.g(a))?function(b,c){var d=Gy.g(b);b=kw.g(b);var e=d+lu(ou.g($CLJS.t($CLJS.t(du))),nu);e=0<b?Vt(e,b):0;d+=$CLJS.F.h(0,e)?0:b-e;mw.m($CLJS.H([$CLJS.Ue.h($CLJS.q,Zt(d," "))]));return c}:function(b,c){var d=Gy.g(b);b=kw.g(b);var e=lu(ou.g($CLJS.t($CLJS.t(du))),nu);d=e<d?d-e:$CLJS.F.h(b,0)?0:b-Vt(e-d,b);mw.m($CLJS.H([$CLJS.Ue.h($CLJS.q,Zt(d," "))]));return c}}],null),new $CLJS.n(null,5,[Hx,"*",Iw,new $CLJS.n(null,
1,[$CLJS.Yw,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,2,[Zv,null,$v,null],null),null),Rx,$CLJS.P,Nx,function(){return function(a,b){var c=$CLJS.Yw.g(a);return $CLJS.p($v.g(a))?Xv(b,c):Wv(b,$CLJS.p(Zv.g(a))?-c:c)}}],null),new $CLJS.n(null,5,[Hx,"?",Iw,$CLJS.P,Gx,new $CLJS.S(null,new $CLJS.n(null,1,[$v,null],null),null),Rx,$CLJS.P,Nx,function(a){return $CLJS.p($v.g(a))?function(b,c){var d=Vv(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return Lw(c,d,Jw.g(b))}:
function(b,c){var d=Vv(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=Uv(d);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);d=Lv(d);Lw(c,d,Jw.g(b));return e}}],null),new $CLJS.n(null,5,[Hx,"(",Iw,$CLJS.P,Gx,new $CLJS.S(null,new $CLJS.n(null,3,[Zv,null,$v,null,Ix,null],null),null),Rx,new $CLJS.n(null,3,[Vx,")",$x,null,$CLJS.Es,null],null),Nx,function(a){var b=$CLJS.p(function(){var c=$v.g(a);return $CLJS.p(c)?Zv.g(a):c}())?tx:$CLJS.p(Zv.g(a))?wx:$CLJS.p($v.g(a))?yx:rx;return function(c,d){a:{var e=$CLJS.B($CLJS.ax.g(c)),
f=du;du=b.g?b.g(du):b.call(null,du);try{var g=Lw(e,d,Jw.g(c));break a}finally{du=f}g=void 0}return g}}],null),new $CLJS.n(null,5,[Hx,")",Iw,$CLJS.P,Gx,$CLJS.Ng,Rx,$CLJS.P,Nx,function(){return null}],null),new $CLJS.n(null,5,[Hx,"[",Iw,new $CLJS.n(null,1,[$w,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,2,[Zv,null,$v,null],null),null),Rx,new $CLJS.n(null,3,[Vx,"]",$x,!0,$CLJS.Es,$CLJS.bz],null),Nx,function(a){return $CLJS.p(Zv.g(a))?cx:$CLJS.p($v.g(a))?
dx:bx}],null),new $CLJS.n(null,5,[Hx,";",Iw,new $CLJS.n(null,2,[ox,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),px,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,1,[Zv,null],null),null),Rx,new $CLJS.n(null,1,[$CLJS.Gs,!0],null),Nx,function(){return null}],null),new $CLJS.n(null,5,[Hx,"]",Iw,$CLJS.P,Gx,$CLJS.Ng,Rx,$CLJS.P,Nx,function(){return null}],null),new $CLJS.n(null,5,[Hx,"{",Iw,new $CLJS.n(null,1,[ex,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],
null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,3,[Zv,null,$v,null,Ix,null],null),null),Rx,new $CLJS.n(null,2,[Vx,"}",$x,!1],null),Nx,function(a){var b=$v.g(a);b=$CLJS.p(b)?Zv.g(a):b;return $CLJS.p(b)?jx:$CLJS.p(Zv.g(a))?hx:$CLJS.p($v.g(a))?ix:gx}],null),new $CLJS.n(null,5,[Hx,"}",Iw,$CLJS.P,Gx,new $CLJS.S(null,new $CLJS.n(null,1,[Zv,null],null),null),Rx,$CLJS.P,Nx,function(){return null}],null),new $CLJS.n(null,5,[Hx,"\x3c",Iw,new $CLJS.n(null,4,[jw,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),
kw,new $CLJS.Q(null,2,5,$CLJS.R,[1,Number],null),iw,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null),lw,new $CLJS.Q(null,2,5,$CLJS.R,[" ",String],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,4,[Zv,null,$v,null,Ix,null,kz,null],null),null),Rx,new $CLJS.n(null,3,[Vx,"\x3e",$x,!0,$CLJS.Es,Zx],null),Nx,function(){return lx}],null),new $CLJS.n(null,5,[Hx,"\x3e",Iw,$CLJS.P,Gx,new $CLJS.S(null,new $CLJS.n(null,1,[Zv,null],null),null),Rx,$CLJS.P,Nx,function(){return null}],null),new $CLJS.n(null,5,[Hx,
"^",Iw,new $CLJS.n(null,3,[Vy,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Xy,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null),Wy,new $CLJS.Q(null,2,5,$CLJS.R,[null,Number],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,1,[Zv,null],null),null),Rx,$CLJS.P,Nx,function(){return function(a,b){var c=Vy.g(a),d=Xy.g(a),e=Wy.g(a),f=$CLJS.p(Zv.g(a))?Gw:Fw;return $CLJS.p($CLJS.p(c)?$CLJS.p(d)?e:d:c)?c<=d&&d<=e?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b:$CLJS.p($CLJS.p(c)?d:c)?$CLJS.F.h(c,d)?new $CLJS.Q(null,
2,5,$CLJS.R,[f,b],null):b:$CLJS.p(c)?$CLJS.F.h(c,0)?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b:($CLJS.p(Zv.g(a))?$CLJS.sd($CLJS.Sv.g(Jw.g(a))):$CLJS.sd($CLJS.Sv.g(b)))?new $CLJS.Q(null,2,5,$CLJS.R,[f,b],null):b}}],null),new $CLJS.n(null,5,[Hx,"W",Iw,$CLJS.P,Gx,new $CLJS.S(null,new $CLJS.n(null,4,[Zv,null,$v,null,Ix,null,kz,null],null),null),Rx,$CLJS.P,Nx,function(a){if($CLJS.p(function(){var c=$v.g(a);return $CLJS.p(c)?c:Zv.g(a)}())){var b=$CLJS.ek.h($CLJS.p($v.g(a))?new $CLJS.Q(null,4,5,$CLJS.R,
[$CLJS.Hs,null,$CLJS.Iy,null],null):$CLJS.xf,$CLJS.p(Zv.g(a))?new $CLJS.Q(null,2,5,$CLJS.R,[kz,!0],null):$CLJS.xf);return function(c,d){d=Uv(d);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.p($CLJS.Ue.j(tz,c,b))?new $CLJS.Q(null,2,5,$CLJS.R,[Fw,d],null):d}}return function(c,d){d=Uv(d);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.p(xv(c))?new $CLJS.Q(null,2,5,$CLJS.R,[Fw,d],null):d}}],null),new $CLJS.n(null,5,[Hx,"_",Iw,$CLJS.P,Gx,new $CLJS.S(null,new $CLJS.n(null,3,[Zv,null,$v,null,
Ix,null],null),null),Rx,$CLJS.P,Nx,function(){return Ax}],null),new $CLJS.n(null,5,[Hx,"I",Iw,new $CLJS.n(null,1,[$CLJS.Yw,new $CLJS.Q(null,2,5,$CLJS.R,[0,Number],null)],null),Gx,new $CLJS.S(null,new $CLJS.n(null,1,[Zv,null],null),null),Rx,$CLJS.P,Nx,function(){return zx}],null)]),Bx=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,Kx=new $CLJS.S(null,new $CLJS.n(null,2,[bw,null,aw,null],null),null),Ex=new $CLJS.n(null,2,[":",Zv,"@",$v],null),Mv=function Mv(a){for(;;){if($CLJS.sd(a))return!1;var c=kz.g(Gx.g(Sx.g($CLJS.B(a))));
$CLJS.p(c)||(c=$CLJS.Re(Mv,$CLJS.B($CLJS.ax.g(Iw.g($CLJS.B(a))))),c=$CLJS.p(c)?c:$CLJS.Re(Mv,$CLJS.B($CLJS.Es.g(Iw.g($CLJS.B(a))))));if($CLJS.p(c))return!0;a=$CLJS.C(a)}},my=$CLJS.Ah(Kv),by=new $CLJS.n(null,6,[$CLJS.jj,"'",$CLJS.mj,"#'",ez,"@",hz,"~",Py,"@",Ny,"~"],null);
(function(){var a=my("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.Hc(f,0,null)}return c.call(this,e)}function c(d){d=Lv(d);return Nv(a,d)}b.F=0;b.G=function(d){d=$CLJS.A(d);return c(d)};b.m=c;return b}()})();
var yz=function(){var a=my("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.Hc(f,0,null)}return c.call(this,e)}function c(d){d=Lv(d);return Nv(a,d)}b.F=0;b.G=function(d){d=$CLJS.A(d);return c(d)};b.m=c;return b}()}(),zz=new $CLJS.n(null,2,["core$future_call","Future","core$promise","Promise"],null),Az=function(){var a=my("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.Hc(f,0,null)}return c.call(this,e)}function c(d){d=Lv(d);return Nv(a,d)}b.F=0;b.G=function(d){d=$CLJS.A(d);return c(d)};b.m=c;return b}()}(),Bz,Cz=$CLJS.yh.g($CLJS.P),Dz=$CLJS.yh.g($CLJS.P),Ez=$CLJS.yh.g($CLJS.P),Fz=$CLJS.yh.g($CLJS.P),Gz=$CLJS.K.j($CLJS.P,$CLJS.Ni,$CLJS.Gh.v?$CLJS.Gh.v():$CLJS.Gh.call(null));
Bz=new $CLJS.Qh($CLJS.ph.h("cljs.pprint","simple-dispatch"),ky,Gz,Cz,Dz,Ez,Fz);Xt(Bz,jy,function(a){if($CLJS.Ra(cy(a)))if($CLJS.p(Av()))$CLJS.ac(du,"#");else{var b=zv,c=uv;zv+=1;uv=0;try{ov("(",")");for(var d=0,e=$CLJS.A(a);;){if($CLJS.Ra($CLJS.Ja)||d<$CLJS.Ja){if(e&&(xv($CLJS.B(e)),$CLJS.C(e))){$CLJS.ac(du," ");Fv(Ev);a=d+1;var f=$CLJS.C(e);d=a;e=f;continue}}else $CLJS.ac(du,"...");break}sv()}finally{uv=c,zv=b}}return null});Xt(Bz,$CLJS.gj,dy);Xt(Bz,$CLJS.uj,gy);Xt(Bz,$CLJS.Fi,yz);
Xt(Bz,null,function(){return $CLJS.ac(du,$CLJS.wh.m($CLJS.H([null])))});Xt(Bz,$CLJS.Ph,hy);wv=Bz;
var Hz=function(){var a=my("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.Hc(f,0,null)}return c.call(this,e)}function c(d){d=Lv(d);return Nv(a,d)}b.F=0;b.G=function(d){d=$CLJS.A(d);return c(d)};b.m=c;return b}()}(),Iz=function(){var a=my("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.Hc(f,0,null)}return c.call(this,e)}function c(d){d=Lv(d);return Nv(a,d)}b.F=0;b.G=function(d){d=$CLJS.A(d);return c(d)};b.m=c;return b}()}(),Jz=$CLJS.P,Kz=function(a){return $CLJS.Yf.h($CLJS.P,$CLJS.xs($CLJS.ae,$CLJS.H([function(){return function d(c){return new $CLJS.te(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.kc(e),g=$CLJS.E(f),k=$CLJS.we(g);a:for(var l=0;;)if(l<g){var m=$CLJS.ld(f,l);m=new $CLJS.Q(null,
2,5,$CLJS.R,[m,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ph.g($CLJS.Sg($CLJS.B(m))),$CLJS.hd(m)],null)],null);k.add(m);l+=1}else{f=!0;break a}return f?$CLJS.ze($CLJS.Be(k),d($CLJS.lc(e))):$CLJS.ze($CLJS.Be(k),null)}k=$CLJS.B(e);return $CLJS.ne(new $CLJS.Q(null,2,5,$CLJS.R,[k,new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ph.g($CLJS.Sg($CLJS.B(k))),$CLJS.hd(k)],null)],null),d($CLJS.Lc(e)))}return null}},null)}(a)}()])))}(function(a){return $CLJS.Yf.h($CLJS.P,$CLJS.ih.h(function(b){var c=$CLJS.I(b,0,null),d=$CLJS.I(b,
1,null);var e=$CLJS.pe(c);e=$CLJS.p(e)?e:$CLJS.Kd(new $CLJS.S(null,new $CLJS.n(null,24,[$CLJS.Gi,"null",$CLJS.Ti,"null",$CLJS.Cj,"null",$CLJS.Hj,"null",$CLJS.Qi,"null",$CLJS.hj,"null",$CLJS.ej,"null",$CLJS.ij,"null",$CLJS.bi,"null",$CLJS.nj,"null",$CLJS.aj,"null",$CLJS.Wi,"null",$CLJS.rj,"null",$CLJS.Fj,"null",$CLJS.zi,"null",$CLJS.vi,"null",$CLJS.Oi,"null",$CLJS.$i,"null",$CLJS.Hi,"null",$CLJS.mj,"null",$CLJS.jj,"null",$CLJS.wj,"null",$CLJS.ti,"null",$CLJS.tj,"null"],null),null),c);return $CLJS.Ra(e)?
new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.ph.h("clojure.core",$CLJS.Sg(c)),d],null):b},a))}($CLJS.Bg([$CLJS.Hi,$CLJS.vi,Cy,$CLJS.nj,Ky,yy,By,Ly,Fy,Ey,Hy,az,Sy,$CLJS.tj,Qy,Zy,Uy,mz,wy,$CLJS.Wi,cz,dz,lz,$CLJS.kj,Oy,nz,xy,Ay,Dy,fz],[Hz,function(a){var b=$CLJS.hd(a),c=$CLJS.B($CLJS.Lc($CLJS.Lc(a)));if($CLJS.Bd(b)){a=Jz;Jz=$CLJS.F.h(1,$CLJS.E(b))?$CLJS.Je([$CLJS.B(b),"%"]):$CLJS.Yf.h($CLJS.P,$CLJS.ih.j(function(d,e){return new $CLJS.Q(null,2,5,$CLJS.R,[d,["%",$CLJS.q.g(e)].join("")],null)},b,$t($CLJS.E(b)+1)));
try{return function(){var d=my("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(g){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.Hc(l,0,null)}return f.call(this,k)}function f(g){g=Lv(g);return Nv(d,g)}e.F=0;e.G=function(g){g=$CLJS.A(g);return f(g)};e.m=f;return e}()}()(c)}finally{Jz=a}}else return qy(a)},ty,Iz,function(a){if(3<$CLJS.E(a)){if($CLJS.p(Av()))$CLJS.ac(du,"#");else{var b=zv,c=uv;zv+=1;uv=0;try{ov("(",
")");Jv(Gv,1);$CLJS.Ue.h(function(){var k=my("~w ~@_~w ~@_~w ~_");return function(){function l(r){var v=null;if(0<arguments.length){v=0;for(var w=Array(arguments.length-0);v<w.length;)w[v]=arguments[v+0],++v;v=new $CLJS.Hc(w,0,null)}return m.call(this,v)}function m(r){r=Lv(r);return Nv(k,r)}l.F=0;l.G=function(r){r=$CLJS.A(r);return m(r)};l.m=m;return l}()}(),a);for(var d=0,e=$CLJS.A($CLJS.ws(3,a));;){if($CLJS.Ra($CLJS.Ja)||d<$CLJS.Ja){if(e){if($CLJS.p(Av()))$CLJS.ac(du,"#");else{a=zv;var f=uv;zv+=
1;uv=0;try{ov(null,null),xv($CLJS.B(e)),$CLJS.C(e)&&($CLJS.ac(du," "),Fv(Cv),xv($CLJS.hd(e))),sv()}finally{uv=f,zv=a}}if($CLJS.C($CLJS.Lc(e))){$CLJS.ac(du," ");Fv(Ev);a=d+1;var g=$CLJS.C($CLJS.Lc(e));d=a;e=g;continue}}}else $CLJS.ac(du,"...");break}sv()}finally{uv=c,zv=b}}return null}return qy(a)},Hz,ry,ry,ty,Hz,ty,Iz,Iz,Hz,Iz,ty,ty,Hz,ty,function(a){if($CLJS.C(a)){var b=$CLJS.A(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d="string"===typeof $CLJS.B(c)?new $CLJS.Q(null,2,5,$CLJS.R,
[$CLJS.B(c),$CLJS.C(c)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=$CLJS.xd($CLJS.B(d))?new $CLJS.Q(null,2,5,$CLJS.R,[$CLJS.B(d),$CLJS.C(d)],null):new $CLJS.Q(null,2,5,$CLJS.R,[null,d],null);var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);if($CLJS.p(Av()))$CLJS.ac(du,"#");else{d=zv;var g=uv;zv+=1;uv=0;try{ov("(",")");(function(){var l=my("~w ~1I~@_~w");return function(){function m(v){var w=null;if(0<arguments.length){w=0;for(var y=Array(arguments.length-
0);w<y.length;)y[w]=arguments[w+0],++w;w=new $CLJS.Hc(y,0,null)}return r.call(this,w)}function r(v){v=Lv(v);return Nv(l,v)}m.F=0;m.G=function(v){v=$CLJS.A(v);return r(v)};m.m=r;return m}()})()(a,b);$CLJS.p($CLJS.p(c)?c:$CLJS.p(e)?e:$CLJS.A(f))&&function(){var l=my("~@:_");return function(){function m(v){var w=null;if(0<arguments.length){w=0;for(var y=Array(arguments.length-0);w<y.length;)y[w]=arguments[w+0],++w;w=new $CLJS.Hc(y,0,null)}return r.call(this,w)}function r(v){v=Lv(v);return Nv(l,v)}m.F=
0;m.G=function(v){v=$CLJS.A(v);return r(v)};m.m=r;return m}()}()();$CLJS.p(c)&&Ov(!0,'"~a"~:[~;~:@_~]',$CLJS.H([c,$CLJS.p(e)?e:$CLJS.A(f)]));$CLJS.p(e)&&function(){var l=my("~w~:[~;~:@_~]");return function(){function m(v){var w=null;if(0<arguments.length){w=0;for(var y=Array(arguments.length-0);w<y.length;)y[w]=arguments[w+0],++w;w=new $CLJS.Hc(y,0,null)}return r.call(this,w)}function r(v){v=Lv(v);return Nv(l,v)}m.F=0;m.G=function(v){v=$CLJS.A(v);return r(v)};m.m=r;return m}()}()(e,$CLJS.A(f));for(a=
f;;){ny($CLJS.B(a));var k=$CLJS.C(a);if(k)b=k,Fv(Ev),a=b;else break}sv()}finally{uv=g,zv=d}}return null}return xv(a)},ty,function(a){if($CLJS.p(Av()))$CLJS.ac(du,"#");else{var b=zv,c=uv;zv+=1;uv=0;try{ov("(",")");Jv(Gv,1);xv($CLJS.B(a));if($CLJS.C(a)){$CLJS.ac(du," ");Fv(Ev);for(var d=0,e=$CLJS.C(a);;){if($CLJS.Ra($CLJS.Ja)||d<$CLJS.Ja){if(e){if($CLJS.p(Av()))$CLJS.ac(du,"#");else{a=zv;var f=uv;zv+=1;uv=0;try{ov(null,null),xv($CLJS.B(e)),$CLJS.C(e)&&($CLJS.ac(du," "),Fv(Cv),xv($CLJS.hd(e))),sv()}finally{uv=
f,zv=a}}if($CLJS.C($CLJS.Lc(e))){$CLJS.ac(du," ");Fv(Ev);a=d+1;var g=$CLJS.C($CLJS.Lc(e));d=a;e=g;continue}}}else $CLJS.ac(du,"...");break}}sv()}finally{uv=c,zv=b}}return null},ty,ry,ry,Hz,Hz,ty,ty,Hz]))),Lz,Mz=$CLJS.yh.g($CLJS.P),Nz=$CLJS.yh.g($CLJS.P),Oz=$CLJS.yh.g($CLJS.P),Pz=$CLJS.yh.g($CLJS.P),Qz=$CLJS.K.j($CLJS.P,$CLJS.Ni,$CLJS.Gh.v?$CLJS.Gh.v():$CLJS.Gh.call(null));Lz=new $CLJS.Qh($CLJS.ph.h("cljs.pprint","code-dispatch"),ky,Qz,Mz,Nz,Oz,Pz);
Xt(Lz,jy,function(a){if($CLJS.Ra(cy(a))){var b=Kz.call(null,$CLJS.B(a));return $CLJS.p(b)?b.g?b.g(a):b.call(null,a):qy(a)}return null});Xt(Lz,$CLJS.pi,function(a){var b=a.g?a.g(Jz):a.call(null,Jz);return $CLJS.p(b)?mw.m($CLJS.H([b])):$CLJS.p(sz)?mw.m($CLJS.H([$CLJS.Sg(a)])):cu.call(null,a)});Xt(Lz,$CLJS.gj,dy);Xt(Lz,$CLJS.uj,gy);Xt(Lz,$CLJS.Fi,yz);Xt(Lz,zy,Az);
Xt(Lz,iy,function(a){var b=$CLJS.q,c=b.g,d=$CLJS.Wa(a).name;var e=$CLJS.$g(/^[^$]+\$[^$]+/,d);e=$CLJS.p(e)?zz.g?zz.g(e):zz.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.p(e)?e:d),"@",$CLJS.q.g($CLJS.va(a)),": "].join("");if($CLJS.p(Av()))$CLJS.ac(du,"#");else{c=zv;d=uv;zv+=1;uv=0;try{ov(b,"\x3e");Jv(Gv,-(b.length-2));Fv(Ev);var f=null!=a?a.H&1||$CLJS.u===a.pi?!0:a.H?!1:$CLJS.Va(St,a):$CLJS.Va(St,a);var g=f?!Tt(a):f;xv(g?Ty:$CLJS.t(a));sv()}finally{uv=d,zv=c}}return null});Xt(Lz,null,cu);
Xt(Lz,$CLJS.Ph,hy);wv=Bz;